export const BASE_API_URL = process.env.REACT_APP_BASE_URL;

export const CANCEL_API_URL = `${BASE_API_URL}api/orders/cancel/`;

export const CATALOG_API_URL = `${BASE_API_URL}api/products/`;

export const CATALOG_SEARCH_API_URL = `${BASE_API_URL}api/search/products/`;

export const CUSTOMIZATION_GRID_API_URL = `${BASE_API_URL}api/core/grid-fields-display-config/`;

export const DASHBOARD_API_URL = `${BASE_API_URL}api/core/dashboard/`;

export const LOGIN_API_URL = `${BASE_API_URL}accounts/login/`;

export const ORDERS_API_URL = `${BASE_API_URL}api/orders/`;

export const ORDER_PROPERTY_API_URL = `${BASE_API_URL}api/orders/order-properties/`;

export const PRODUCT_PROPERTIES_API_URL = `${BASE_API_URL}api/products/product-properties/`;

export const PUBLISH_API_URL = `${BASE_API_URL}api/products/publish-products/`;

export const PUB_UNPUB_API_URL = `${BASE_API_URL}api/products/publish-history/`;

export const SHIP_API_URL = `${BASE_API_URL}api/orders/ship/`;

// export const SYNC_API_URL = `${BASE_API_URL}api/products/sync-with-ca/`;
export const SYNC_API_URL = `${BASE_API_URL}api/products/sync-with-marketplaces/`;

export const UNPUBLISH_API_URL = `${BASE_API_URL}api/products/unpublish-products/`;

export const VO_API_URL = `${BASE_API_URL}api/products/marketplace-product-details/`;

export const VO_ORDER_API_URL = `${BASE_API_URL}api/orders/vo-order-details/`;

export const BULK_ARCHIVE = `${BASE_API_URL}api/orders/bulk-archive-unarchive/`;

export const BULK_PUBLISH_DETAILS = `${BASE_API_URL}api/bulk-publish/`;

export const MARKETPLACE_CONFIGURATION = `${BASE_API_URL}api/marketplaces/`;

export const MARKETPLACE_PERSISANCE = `${BASE_API_URL}api/core/user-configurations/`;

export const BUSINESS_RULE_API = `${BASE_API_URL}api/business-config/business-rules/`;

export const BUSINESS_RULE_FUNCTION = `${BASE_API_URL}api/business-config/business-rule-functions/`;

export const BUSINESS_RULE_PRODUCTION_FIELD = `${BASE_API_URL}api/business-config/product-field-list/`;

export const BUSINESS_RULE_LOOKUP_LIST = `${BASE_API_URL}api/business-config/lookup-lists`;

export const DELETE_PRODUCT = `${BASE_API_URL}api/products/`;

export const BULK_DELETE_PRODUCT = `${BASE_API_URL}api/products/bulk-delete-products/`;

export const BULK_DELETE_PRODUCT_DETAILS = `${BASE_API_URL}api/products/bulk-delete/`;

export const AUTOCOMPLETE_SKU_BUSINESS_RULE = `${BASE_API_URL}api/products/autocomplete/`;

export const FELD_FORMATED_VIEW = `${BASE_API_URL}api/business-config/field-formatted-view/`;

export const TEMPLATE_API = `${BASE_API_URL}api/business-config/product-template/`;

export const TEMPLATE_MARKETPLACE_FEILD_MAPPING = `${BASE_API_URL}api/business-config/template-field-details/`;

export const TEMPLATE_LOOKUP_LIST = `${BASE_API_URL}api/business-config/lookup-lists/`;

export const LOOKUP_LIST_DELETE_UPDATE_ITEM_LIST = `${BASE_API_URL}api/business-config/lookup-list-items/`;

export const BASIC_PRODUCT_DESTRIBUTION_CENTER = `${BASE_API_URL}api/products/distribution-centers/`;

export const BASIC_PRODUCT_AGE_RENGE = `${BASE_API_URL}api/products/age-range/`;

export const BASIC_PRODUCT_MATERIAL_LIST = `${BASE_API_URL}api/products/material-types/`;

export const BASIC_IMAGE_UPLOAD = `${BASE_API_URL}api/products/upload-images/`;

export const BASIC_PRODUCT_CREATE_API = `${BASE_API_URL}api/products/`;

export const CHILD_PRODUCT_LIST = `${BASE_API_URL}api/products/child-products/`;

export const COPY_PRODUCT = `${BASE_API_URL}api/products/copy-product/`;

export const PARENT_PRODUCT_LIST = `${BASE_API_URL}api/products/parent-products/`;

export const DELETE_IMAGE_LINK = `${BASE_API_URL}api/products/images/`;

export const EDIT_LIST_PRODUCT_DETAILS = `${BASE_API_URL}api/products`;

export const CHILD_LIST_PRODUCT_DETAILS = `${BASE_API_URL}api/products`;

export const PRODUCT_FIELD_CONFIGURATION = `${BASE_API_URL}api/products/product-field-config/`;

export const CHILD_UPDATE = `${BASE_API_URL}api/products/child/`;

export const PRODUCT_EXPORT = `${BASE_API_URL}api/products/product-export/`;

export const SHIPSTATION_ACTIVE = `${BASE_API_URL}api/marketplaces/shipstation-store/`;
export const SHIPSTATION_STORE_LIST = `${BASE_API_URL}api/marketplaces/shipstation-store/`;

export const SHIPSTATION_ORDER_LIST = `${BASE_API_URL}api/marketplaces/orders-list/`;

export const GOAT_LOWEST_PRICE = `${BASE_API_URL}api/marketplaces/goat-lowest-price/`;

export const MULTICHANNEL_SETTING = `${BASE_API_URL}api/core/multichannel-settings/`;

export const STOCKX_SELLING_INFO = `${BASE_API_URL}api/marketplaces/stockx-selling-info/`;
