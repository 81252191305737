import React, { useState } from "react";




const TemplateTextValue = ({ handleDoneValue, allCellData }) => {

    const [textValue, setTextValue] = useState(allCellData && allCellData.mapping_details && allCellData.mapping_details.name == "text_value" ? allCellData.mapping_details.value: "");

    const hanldeTextAreaChnage = (e) => {
        setTextValue(e.target.value)
    }

    const HanldeDoneClick = () => {
        handleDoneValue(textValue)
    }

    const handleCancel = () => {
        setTextValue("")
    }


    return (
        <div>
            <div>Note: This value will apply to all products associated with this template.</div>
            <textarea
                onChange={(e) => hanldeTextAreaChnage(e)}
                value={textValue}
                name="textValue"
                aria-label="empty textarea"
                style={{ width: "100%", height: '150px' }}
                placeholder="Enter Text Value"
            />  <br></br>
            <button type="button" onClick={() => HanldeDoneClick()} > Done </button>
            <button type="button" onClick={() => handleCancel()}> Cancel </button>
        </div>
    )
}

export default TemplateTextValue