import React, { useEffect, useState } from 'react';
import Permission from '../../../permission/Permission';
import MenuList from '../../../gridComponents/MenuList';
import { Grid, Backdrop, Fade, Modal } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
  BASIC_PRODUCT_AGE_RENGE,
  BASIC_PRODUCT_MATERIAL_LIST,
  BASIC_IMAGE_UPLOAD,
  BASIC_PRODUCT_CREATE_API,
  DELETE_IMAGE_LINK,
  PRODUCT_FIELD_CONFIGURATION
} from '../../../configurations/configApi';
import {
  apiPost,
  fetchUrl,
  apiDelete,
  apiEdit
} from '../../../apiActions/action';
import { handleError } from 'views/FunctionalComponents/ErrorHandle';
import LoadingOverlay from '../../../components/overlays/LoadingOverlay';
import MessageComponent from '../../FunctionalComponents/messageComponent';
import { Link } from 'react-router-dom';
import errorRedirect from 'views/FunctionalComponents/ErrorRedirect';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3)
  }
}));

const allowConsolidation = [
  { id: 1, value: 'Allowed', name: 'Allowed' },
  { id: 2, value: 'NotAllowed', name: 'NotAllowed' }
];

const conditionOption = [
  { id: 1, value: 'NEW', name: 'NEW' },
  { id: 2, value: 'Used', name: 'Used' },
  { id: 3, value: 'Refurbished', name: 'Refurbished' },
  { id: 4, value: 'Reconditioned', name: 'Reconditioned' },
  { id: 5, value: 'Like New', name: 'Like New' }
];

const sleeveLengthOption = [
  { id: 1, value: 'ShortSleeve', name: 'ShortSleeve' },
  { id: 2, value: 'LongSleeve', name: 'LongSleeve' }
];

const categoryOption = [
  { id: 1, value: 'Shoes', name: 'Shoes' },
  { id: 2, value: 'Clothing', name: 'Clothing' }
];

const displayInStoreOption = [
  { id: 1, value: 'Yes', name: 'Yes' },
  { id: 2, value: 'No', name: 'No' }
];

const defaultPackageOption = [
  { id: 1, value: '', name: '' },
  {
    id: 2,
    value: 'Use Items Attribute For Package Dimentions',
    name: 'Use Items Attribute For Package Dimentions'
  }
];

const EditBasicProduct = props => {
  const showProductButton = props.match.path
    .split('/')
    .filter(each => each)[0]
    .includes('add');
  const classes = useStyles();
  const isAddPath =
    props.match.path.split('/')[1] === 'add_basic_product';

  let allDatas =
    localStorage.getItem('allProductDataPerId') &&
    JSON.parse(localStorage.getItem('allProductDataPerId'))
      ? JSON.parse(localStorage.getItem('allProductDataPerId'))
      : [];

  const [allData, setAllData] = useState(
    allDatas && Object.keys(allDatas) ? allDatas : {}
  );

  const [product, setProeduct] = useState('');

  const [isSelctedTab, setIsSelectedTab] = useState(
    props.location.openImageSection === true ? false : true
  );
  // const [destributionCenter, setDistributionCenter] = useState([]);

  const [sku, setSku] = useState(
    allData && allData.sku ? allData.sku : ''
  );

  const [title, setTitle] = useState(
    allData && allData.title ? allData.title : ''
  );

  const [subTitle, setSubTitle] = useState(
    allData && allData.subtitle ? allData.subtitle : ''
  );

  const [brand, setBrand] = useState(allData ? allData.brand : '');

  const [manufacturer, setManufacturer] = useState(
    allData ? allData.manufacturer : ''
  );

  const [asin, setAsin] = useState(allData ? allData.asin : '');

  const [upc, setUpc] = useState(allData ? allData.upc : '');

  const [isbn, setIsbn] = useState(allData ? allData.isbn : '');

  const [ean, setEan] = useState(allData ? allData.ean : '');

  const [mpn, setMpn] = useState(allData ? allData.mpn : '');

  const [harmonizedCode, setHarmonizedCode] = useState(
    allData ? allData.harmonized_code : ''
  );

  const [
    sortDescriptionForBasic,
    setSortDescriptionForBasic
  ] = useState(allData ? allData.short_description : '');

  const [descriptionForBasic, setDescriptionForBasic] = useState(
    allData ? allData.description : ''
  );

  const [height, setHeight] = useState(
    allData ? allData.height : ''
  );

  const [widths, setWidth] = useState(allData ? allData.width : '');

  const [weight, setWeight] = useState(
    allData ? allData.weight : ''
  );

  const [supplier, setSupplier] = useState(
    allData ? allData.supplier_name : ''
  );

  const [supplierPO, setSupplierPO] = useState(
    allData ? allData.supplier_po : ''
  );

  const [warrenty, setWarrenty] = useState(
    allData ? allData.warranty : ''
  );

  const [texCode, setTexCode] = useState(
    allData ? allData.warranty : ''
  );

  const [
    allowConsolidationForBasic,
    setAllowConsolidationForBasic
  ] = useState(allData ? allData.allow_consolidation : '');

  const [defaultPackage, setDefaultPackage] = useState(
    allData ? allData.default_package : ''
  );

  const [multipackQuantity, setMultipackQuantity] = useState(
    allData ? allData.multipack_quantity : ''
  );

  const [warehouseLocation, setWarehouseLocation] = useState(
    allData ? allData.warehouse_location : ''
  );

  const [receivedInInventory, setReceivedInInventory] = useState(
    allData && allData.received_date_utc
      ? allData.received_date_utc.split('T')[0]
      : ''
  );

  const [storeTitleForBasic, setStoreTitleForBasic] = useState(
    allData ? allData.store_title : ''
  );

  const [displayInStore, setDisplayInStore] = useState(
    allData ? allData.is_display_in_store : ''
  );

  const [storeCategoryForBasic, setStoreCategoryForBasic] = useState(
    allData ? allData.store_category : ''
  );

  const [
    storeDescriptionForBasic,
    setStoreDescriptionForBasic
  ] = useState(allData ? allData.short_description : '');

  const [storeMetaDescription, setStoreMetaDescription] = useState(
    allData ? allData.store_meta_description : ''
  );

  const [uspForBasic, setUspForBasic] = useState(
    allData ? allData.usp : ''
  );

  const [condition, setCondition] = useState(
    allData ? allData.condition : ''
  );

  const [retailPrice, setRetailPrice] = useState(
    allData ? allData.retail_price : 0
  );

  const [buyItNowPrice, setBuyItNowPrice] = useState(
    allData ? allData.buy_it_now_price : 0
  );

  const [startingPrice, setStartingPrice] = useState(
    allData ? allData.starting_price : 0
  );

  const [reservePrice, setReservePrice] = useState(
    allData ? allData.reserve_price : 0
  );

  const [storePrice, setStorePrice] = useState(
    allData ? allData.store_price : 0
  );

  // const [sellerCost, setSellerCost] = useState(
  //   allData ? allData.saller_cost : 0
  // );

  const [productMargin, setProductMargin] = useState(
    allData ? allData.margin : 0
  );

  const [minimumPrice, setMinimumPrice] = useState(
    allData ? allData.min_price : 0
  );

  const [maximumPrice, setMaximumPrice] = useState(
    allData ? allData.max_price : 0
  );

  const [estimatedShippingCost, setEstimatedShippingCost] = useState(
    allData ? allData.estimated_shipping_cost : 0
  );

  const [ageRange, setAgeRange] = useState(
    allData ? allData.age_range : ''
  );
  const [amzRepricerAutoPrice, setAmzRepricerAutoPrice] = useState(
    allData ? allData.amz_repricer_auto_price : 0
  );

  const [allAgeRenge, setAllAgeRenge] = useState([]);

  // const [brandCASuggestion, setBrandCASuggestion] = useState("");

  const [care, setCare] = useState(allData ? allData.care : '');

  const [color, setColor] = useState(allData ? allData.color : '');

  // const [colorCASuggestion , setColorCASuggestion] = useState("");

  const [
    distributionDesignationAMZC,
    setDistributionDesignationAMZC
  ] = useState(allData ? allData.distribution_designation_amzc : '');

  // const [eanAMZCASuggestion, setEanAMZCASuggestion] = useState("");

  const [ebayCatogoryId, setEbayCatogoryId] = useState(
    allData ? allData.ebay_category_id : ''
  );

  const [euSize, setEUSize] = useState(
    allData ? allData.eu_size : 0
  );

  const [fightClubPrice, setFightClubPrice] = useState(
    allData ? allData.fight_club_price : 0
  );

  const [flightClubCost, setFlightClubCost] = useState(
    allData ? allData.fight_club_cost : 0
  );

  const [
    flightClubQuantityBuffered,
    setFlightClubQuantityBuffered
  ] = useState(allData ? allData.flight_club_quantity_buffered : '');

  const [
    footwearSizeSizeAMZCASu,
    setFootwearSizeSizeAMZCASu
  ] = useState(allData ? allData.footwear_size_amz_ca_su : '');

  const [
    footwearSizeSizeSystemA,
    setFootwearSizeSizeSystemA
  ] = useState(allData ? allData.footwear_size_size_systema : '');

  const [
    footwearSizeWidthAMZCAS,
    setFootwearSizeWidthAMZCAS
  ] = useState(allData ? allData.footwear_size_width_AMZ_CAS : '');

  const [gender, setGender] = useState(
    allData ? allData.gender : ''
  );

  const [goatSku, setGoatSku] = useState(
    allData ? allData.goat_sku : ''
  );

  const [itemPackageQuantity, setItemPackageQuantity] = useState(
    allData ? allData.item_package_quantity : ''
  );

  const [origin, setOrigin] = useState(
    allData ? allData.origin : ''
  );

  const [primaryColor, setPrimaryColor] = useState(
    allData ? allData.primary_color : ''
  );

  const [productWeight, setProductWeight] = useState(
    allData ? allData.product_weight : ''
  );

  const [releaseYear, setReleaseYear] = useState(
    allData ? allData.release_year : ''
  );

  const [shopifyPosition, setShopifyPosition] = useState(
    allData ? allData.shopify_position : ''
  );

  const [shopifyProductType, setShopifyProductType] = useState(
    allData ? allData.shopify_product_type : ''
  );

  const [shopifyProductURL, setShopifyProductURL] = useState(
    allData ? allData.shopify_product_url : ''
  );

  const [size, setSize] = useState(allData ? allData.size : '');

  const [cost, setCost] = useState(0);

  const [ukSize, setUkSize] = useState(
    allData ? allData.uk_size : ''
  );

  const [URBNDescription, setURBNDescription] = useState(
    allData ? allData.urbn_description : ''
  );

  const [walmartBulletPoints, setWalmartBulletPoints] = useState(
    allData ? allData.walmart_bullet_points : ''
  );

  const [walmartShoeCategory, setWalmartShoeCategory] = useState(
    allData ? allData.walmart_shoe_category : ''
  );

  const [sleeveLenght, setSleeveLenght] = useState(
    allData ? allData.sleeve_length : ''
  );
  const [allMaterailName, setAllMaterailName] = useState([]);
  const [materailValue, setMaterailValue] = useState(
    allData ? allData.material : ''
  );
  const [open, setOpen] = React.useState(false);
  const [imageName, setImageName] = useState('');
  const [imageLink, setImageLink] = useState(null);
  const [selectedFiles, setSelectedFiles] = useState({});
  const [loading, setLoading] = useState(false);
  const [configuration, setConfiguration] = useState({});
  const [
    nonActiveConfigurationfields,
    setNonActiveConfigurationfields
  ] = useState([]);

  let itemImageUrl1 =
    allData &&
    allData.images &&
    allData.images.filter(i => i.placement_name === 'ITEMIMAGEURL1');
  let itemImageUrl2 =
    allData &&
    allData.images &&
    allData.images.filter(i => i.placement_name === 'ITEMIMAGEURL2');
  let itemImageUrl3 =
    allData &&
    allData.images &&
    allData.images.filter(i => i.placement_name === 'ITEMIMAGEURL3');
  let itemImageUrl4 =
    allData &&
    allData.images &&
    allData.images &&
    allData.images.filter(i => i.placement_name === 'ITEMIMAGEURL4');
  let itemImageUrl5 =
    allData &&
    allData.images &&
    allData.images.filter(i => i.placement_name === 'ITEMIMAGEURL5');
  let itemImageUrl6 =
    allData &&
    allData.images &&
    allData.images.filter(i => i.placement_name === 'ITEMIMAGEURL6');
  let itemImageUrl7 =
    allData &&
    allData.images &&
    allData.images.filter(i => i.placement_name === 'ITEMIMAGEURL7');
  let itemImageUrl8 =
    allData &&
    allData.images &&
    allData.images.filter(i => i.placement_name === 'ITEMIMAGEURL8');

  const [ITEMIMAGEURL1, setITEMIMAGEURL1] = useState(
    itemImageUrl1 && itemImageUrl1[0] && itemImageUrl1[0].url
      ? itemImageUrl1[0].url
      : ''
  );

  const [ITEMIMAGEURL2, setITEMIMAGEURL2] = useState(
    itemImageUrl2 && itemImageUrl2[0] && itemImageUrl2[0].url
      ? itemImageUrl2[0].url
      : ''
  );

  const [ITEMIMAGEURL3, setITEMIMAGEURL3] = useState(
    itemImageUrl3 && itemImageUrl3[0] && itemImageUrl3[0].url
      ? itemImageUrl3[0].url
      : ''
  );

  const [ITEMIMAGEURL4, setITEMIMAGEURL4] = useState(
    itemImageUrl4 && itemImageUrl4[0] && itemImageUrl4[0].url
      ? itemImageUrl4[0].url
      : ''
  );

  const [ITEMIMAGEURL5, setITEMIMAGEURL5] = useState(
    itemImageUrl5 && itemImageUrl5[0] && itemImageUrl5[0].url
      ? itemImageUrl5[0].url
      : ''
  );

  const [ITEMIMAGEURL6, setITEMIMAGEURL6] = useState(
    itemImageUrl6 && itemImageUrl6[0] && itemImageUrl6[0].url
      ? itemImageUrl6[0].url
      : ''
  );

  const [ITEMIMAGEURL7, setITEMIMAGEURL7] = useState(
    itemImageUrl7 && itemImageUrl7[0] && itemImageUrl7[0].url
      ? itemImageUrl7[0].url
      : ''
  );

  const [ITEMIMAGEURL8, setITEMIMAGEURL8] = useState(
    itemImageUrl8 && itemImageUrl8[0] && itemImageUrl8[0].url
      ? itemImageUrl8[0].url
      : ''
  );

  const allImageId =
    allData && allData.images && allData.images.map(i => i.id);

  const [imageId, setImageId] = useState(
    allImageId && allImageId.length > 0 ? allImageId : []
  );

  const [category, setCategory] = useState(
    allData ? allData.category : ''
  );

  const handleChangeForProduct = name => {
    if (name === 'product') {
      setIsSelectedTab(true);
    } else {
      setIsSelectedTab(false);
    }
  };

  const handleChangeSku = e => {
    setSku(e.target.value);
    if (e.target.value) {
      document.getElementById('skuForBasic').style.borderColor =
        'blue';
    }
  };

  const handleChangeTitle = e => {
    setTitle(e.target.value);
    if (e.target.value) {
      document.getElementById('titleForBasic').style.borderColor =
        'blue';
    }
    if (sku.length === 0) {
      document.getElementById('skuForBasic').style.borderColor =
        '#D10000';
    }
    if (mpn.length === 0) {
      document.getElementById('mpnForBasic').style.borderColor =
        '#D10000';
    }
  };

  const handleChangeSubTitle = e => {
    setSubTitle(e.target.value);
  };

  const hanldeChangeBrand = e => {
    setBrand(e.target.value);
  };

  const handleChangeManufacturer = e => {
    setManufacturer(e.target.value);
  };

  const hanldeChangeAsin = e => {
    setAsin(e.target.value);
  };

  const handleChangeUpc = e => {
    setUpc(e.target.value);
  };

  const hanldeChangeIsbn = e => {
    setIsbn(e.target.value);
  };

  const hanldeChangeEan = e => {
    setEan(e.target.value);
  };

  const handleChangeMpn = e => {
    setMpn(e.target.value);
    if (e.target.value) {
      document.getElementById('mpnForBasic').style.borderColor =
        'blue';
    }
  };

  const handleChangeHarmonizedCode = e => {
    setHarmonizedCode(e.target.value);
  };

  const handleChangeSortDescriptionForBasic = e => {
    setSortDescriptionForBasic(e.target.value);
  };

  const handleChangeDescriptionForBasic = e => {
    setDescriptionForBasic(e.target.value);
  };

  const handleChangeHeight = e => {
    setHeight(e.target.value);
  };

  const handleChangeWidth = e => {
    setWidth(e.target.value);
  };

  const handleChangeWeight = e => {
    setWeight(e.target.value);
  };

  const handleChangeSupplier = e => {
    setSupplier(e.target.value);
  };

  const handleChangeSupplierPO = e => {
    setSupplierPO(e.target.value);
  };

  const handleChangeWarrenty = e => {
    setWarrenty(e.target.value);
  };

  const handleChangeTexCode = e => {
    setTexCode(e.target.value);
  };

  const handleChangeAllowConsolidationForBasic = e => {
    setAllowConsolidationForBasic(e.target.value);
  };

  const handleChangeDefaultPackage = e => {
    setDefaultPackage(e.target.value);
  };

  const handleChangeMultipackQuantity = e => {
    setMultipackQuantity(e.target.value);
  };

  const handleChangeWarehouseLocation = e => {
    setWarehouseLocation(e.target.value);
  };

  const hanldeChangeReceivedInInventory = e => {
    setReceivedInInventory(e.target.value);
  };

  const handleChangeStoreTitleForBasic = e => {
    setStoreTitleForBasic(e.target.value);
  };

  // for copy the titke value
  const handleClickForCopyTitle = () => {
    setStoreTitleForBasic(title);
  };

  const handleChangeDisplayInStore = e => {
    setDisplayInStore(e.target.value);
  };

  const handleChangeStoreCategoryForBasic = e => {
    setStoreCategoryForBasic(e.target.value);
  };

  const handleChangeStoreDescriptionForBasic = e => {
    setStoreDescriptionForBasic(e.target.value);
  };

  const hanldeChangeStoreMetaDescription = e => {
    setStoreMetaDescription(e.target.value);
  };

  const handleChnageUspForBasic = e => {
    setUspForBasic(e.target.value);
  };

  const handleChangeCondition = e => {
    setCondition(e.target.value);
  };

  const handleChangeCategory = e => {
    setCategory(e.target.value);
  };

  const handleChangeRetailPrice = e => {
    setRetailPrice(e.target.value);
  };

  const handleCHangeBuyItNowPrice = e => {
    setBuyItNowPrice(e.target.value);
  };

  const handleChangeStartingPrice = e => {
    setStartingPrice(e.target.value);
  };

  const handleChangeReservePrice = e => {
    setReservePrice(e.target.value);
  };

  const handleChangeStorePrice = e => {
    setStorePrice(e.target.value);
  };

  // const hanldeChangeSellerCost = (e) => {
  //   setSellerCost(e.target.value);
  // };

  const handleChangeProductMargin = e => {
    setProductMargin(e.target.value);
  };

  const handleChangeMinimumPrice = e => {
    setMinimumPrice(e.target.value);
  };

  const hanldeChangeMaximumPrice = e => {
    setMaximumPrice(e.target.value);
  };

  const handleChangeEstimatedShippingCost = e => {
    setEstimatedShippingCost(e.target.value);
  };

  const [dyammicData, setDynamicData] = useState({});

  useEffect(() => {
    let QtyObj = {};
    allData &&
      allData.distribution_centers &&
      allData.distribution_centers.map(item => {
        QtyObj[item.code] = item.qty;
      });
    setDynamicData(QtyObj);
  }, [allData]);

  const handleChangeDistributionCenterPhenox = (e, value) => {
    let temp = { ...dyammicData };
    temp[value] = e.target.value;
    setDynamicData(temp);
  };

  const fetchAgeRenge = () => {
    fetchUrl(`${BASIC_PRODUCT_AGE_RENGE}`, getVal => {
      if (getVal.status) {
        setAllAgeRenge(getVal.response.data.results);
      } else {
        if (
          getVal.response.response &&
          getVal.response.response.status &&
          getVal.response.response.status === 401
        ) {
          handleError(getVal.response.response);
        } else errorRedirect(getVal.response);
      }
    });
  };

  const fetchMaterialName = () => {
    fetchUrl(`${BASIC_PRODUCT_MATERIAL_LIST}`, getVal => {
      if (getVal.status) {
        setAllMaterailName(getVal.response.data.results);
      } else {
        if (
          getVal.response.response &&
          getVal.response.response.status &&
          getVal.response.response.status === 401
        ) {
          handleError(getVal.response.response);
        } else errorRedirect(getVal.response);
      }
    });
  };

  const fetchProductFieldConfiguration = () => {
    fetchUrl(PRODUCT_FIELD_CONFIGURATION, getVal => {
      if (getVal.status) {
        // console.log(getVal.response.data);
        const {
          field_configuration,
          attribute_configuration
        } = getVal.response.data;

        const configurationfields = [
          ...field_configuration,
          ...attribute_configuration
        ];

        const nonActiveConfigurationfieldList = configurationfields.filter(
          each => !each.is_active
        );

        // console.log(nonActiveConfigurationfieldList.map((each) => each.name));
        setNonActiveConfigurationfields(
          nonActiveConfigurationfieldList.map(each => each.name)
        );

        const configurationObj = configurationfields.reduce(
          (each, obj) => ({
            ...each,
            [obj.name]: obj.is_active
          }),
          {}
        );

        // console.log(configurationObj);
        setConfiguration(configurationObj);
      } else {
        if (
          getVal.response.response &&
          getVal.response.response.status &&
          getVal.response.response.status === 401
        ) {
          handleError(getVal.response.response);
        } else errorRedirect(getVal.response);
      }
    });
  };

  useEffect(() => {
    fetchProductFieldConfiguration();
    fetchAgeRenge();
    fetchMaterialName();
  }, []);

  const handleAgeRangeChange = e => {
    setAgeRange(e.target.value);
  };

  const handleAmzRepricerAutoPriceChange = e => {
    setAmzRepricerAutoPrice(e.target.value);
  };

  // const handleBrandCASuggestion = (e) => {
  //     setBrandCASuggestion(e.target.value);
  // }

  const handleCareChange = e => {
    setCare(e.target.value);
  };

  const handeColorChange = e => {
    setColor(e.target.value);
  };

  // const handleColorCASuggestion = (e) => {
  //     setColorCASuggestion(e.target.value);
  // }

  const handleDistributionDesignationAMZC = e => {
    setDistributionDesignationAMZC(e.target.value);
  };

  // const handleEanAMZCASuggestion = (e) => {
  //     setEanAMZCASuggestion(e.target.value);
  // }

  const handleEbayCatogoryId = e => {
    setEbayCatogoryId(e.target.value);
  };

  const handleEUSizeChange = e => {
    setEUSize(e.target.value);
  };

  const handleFightClubPrice = e => {
    setFightClubPrice(e.target.value);
  };

  const handleFlightClubCost = e => {
    setFlightClubCost(e.target.value);
  };

  const handleFlightClubQuantityBuffered = e => {
    setFlightClubQuantityBuffered(e.target.value);
  };

  const handleFootwearSizeSizeAMZCASu = e => {
    setFootwearSizeSizeAMZCASu(e.target.value);
  };

  const handleFootwearSizeSizeSystemA = e => {
    setFootwearSizeSizeSystemA(e.target.value);
  };

  const hanldeFootwearSizeWidthAMZCAS = e => {
    setFootwearSizeWidthAMZCAS(e.target.value);
  };

  const handleGenderChange = e => {
    setGender(e.target.value);
  };

  const handleChangeForGoatSku = e => {
    setGoatSku(e.target.value);
  };

  const handleChnageForItemPackageQuantity = e => {
    setItemPackageQuantity(e.target.value);
  };

  const handleChangeOrigin = e => {
    setOrigin(e.target.value);
  };

  const handleChangePrimaryColor = e => {
    setPrimaryColor(e.target.value);
  };

  const handleChangeProductWeight = e => {
    setProductWeight(e.target.value);
  };

  const handleChangeReleaseYear = e => {
    setReleaseYear(e.target.value);
  };

  const handleChangeShopifyPosition = e => {
    setShopifyPosition(e.target.value);
  };

  const handleChangeShopifyProductType = e => {
    setShopifyProductType(e.target.value);
  };

  const handleChangeShopifyProductURL = e => {
    setShopifyProductURL(e.target.value);
  };

  const handleChangeSize = e => {
    setSize(e.target.value);
  };

  const hanldeChangeCost = e => {
    setCost(e.target.value);
  };

  // const handleChangeSizeCASuggestion = (e) => {
  //     setSizeCASuggestion(e.target.value);
  // }

  const handleChangeUkSize = e => {
    setUkSize(e.target.value);
  };

  const handleChangeURBNDescription = e => {
    setURBNDescription(e.target.value);
  };

  const handleChangeWalmartBulletPoints = e => {
    setWalmartBulletPoints(e.target.value);
  };

  const handleChangeWalmartShoeCategory = e => {
    setWalmartShoeCategory(e.target.value);
  };

  const handleChangeSleeveLenght = e => {
    setSleeveLenght(e.target.value);
  };

  const handleChangeMaterailValue = e => {
    setMaterailValue(e.target.value);
  };

  const fileDrop = e => {
    e.preventDefault();

    const files = e.dataTransfer.files;
    if (files.length) {
      handleFiles(e.target.id, files[0]);
    }
  };

  const filesSelected = ({ target }) => {
    const { id, files } = target;
    if (files.length) {
      handleFiles(id, files[0]);
    }
  };

  const validateFile = file => {
    const validTypes = [
      'image/jpeg',
      'image/jpg',
      'image/png',
      'image/gif',
      'image/x-icon'
    ];
    if (validTypes.indexOf(file.type) === -1) {
      return false;
    }
    return true;
  };

  const handleFiles = (id, file) => {
    // console.log(id, file);
    if (validateFile(file)) {
      setSelectedFiles(prevState => ({
        ...prevState,
        [id]: file
      }));
    }
  };

  const handleDragAndDropImageUpload = image_name => {
    const formData = new FormData();
    let imagePlacements = [image_name];
    formData.append('placements', imagePlacements);
    formData.append(
      'images',
      selectedFiles[image_name],
      selectedFiles[image_name].name
    );
    // console.log(selectedFiles[image_name]);
    handleImageUploadApi(formData, image_name);
  };

  const handleOpen = ({ target }) => {
    const { id } = target;
    if (selectedFiles[id]) handleDragAndDropImageUpload(id);
    else {
      setImageName(id);
      setOpen(true);
    }
  };

  const handleClose = () => {
    setImageName('');
    setOpen(false);
  };

  const handleChangeImageLink = e => {
    setImageLink(e.target.files[0]);
  };

  const [sweetAlert, setSweetAlert] = useState('');

  // function to show/hide loading Overlay
  const loadingAction = action => {
    action ? setLoading(true) : setLoading(false);
  };

  const hideAlert = (mode, action) => {
    setSweetAlert('');
  };

  // function to show Message pops on success/error response
  const fetchMessage = (status, response, stateOfAction) => {
    const message = (
      <MessageComponent
        page="BasicProductPge"
        action={stateOfAction}
        hideAlert={hideAlert}
        hideOverlay={loadingAction}
        response={response}
        status={status}
      />
    );
    setSweetAlert(message);
  };

  const [allImageUploadedData, setAllImageUploadedData] = useState(
    allData && allData.images ? allData.images : []
  );

  // hndle clear function
  const handleClearImage = (Image, imageName) => {
    setLoading(true);
    let url = allImageUploadedData.filter(
      item => item.url === Image
    );
    let urlId = url && url[0] && url[0].id;
    setSweetAlert('');
    apiDelete(
      `${DELETE_IMAGE_LINK}${urlId}/`,
      'delete',
      ({ status, response }) => {
        if (status) {
          if (imageId.length > 1) {
            let imagesID = imageId.filter(id => id != urlId);
            setImageId([...imagesID]);
          } else {
            setImageId([]);
          }
          setLoading(false);
          if (imageName === 'ITEMIMAGEURL1') {
            setITEMIMAGEURL1('');
          } else if (imageName === 'ITEMIMAGEURL2') {
            setITEMIMAGEURL2('');
          } else if (imageName === 'ITEMIMAGEURL3') {
            setITEMIMAGEURL3('');
          } else if (imageName === 'ITEMIMAGEURL4') {
            setITEMIMAGEURL4('');
          } else if (imageName === 'ITEMIMAGEURL5') {
            setITEMIMAGEURL5('');
          } else if (imageName === 'ITEMIMAGEURL6') {
            setITEMIMAGEURL6('');
          } else if (imageName === 'ITEMIMAGEURL7') {
            setITEMIMAGEURL7('');
          } else {
            setITEMIMAGEURL8('');
          }
          fetchMessage(true, response, 'delete');
        } else {
          setLoading(false);
          if (
            response.response &&
            response.response.status &&
            response.response.status === 401
          ) {
            handleError(response.response);
          } else if (checkFunction(response)) {
            fetchMessage('redirectTrue');
          } else {
            fetchMessage(false, response.response, 'delete');
          }
        }
      }
    );
  };

  const handleImageUploadApi = (formData, image_name = '') => {
    setLoading(true);
    apiPost(
      `${BASIC_IMAGE_UPLOAD}?product_id=${allData.id}`,
      formData,
      ({ status, response }) => {
        if (status) {
          setOpen(false);
          setLoading(false);
          setAllImageUploadedData([
            ...allImageUploadedData,
            response.data.images &&
              response.data.images[0] &&
              response.data.images[0]
          ]);
          let urlImage =
            response.data.images &&
            response.data.images[0] &&
            response.data.images[0].url;
          const editedImageArray = allData.images.filter(
            i =>
              response.data.images[0].placement_name ===
              i.placement_name
          );
          const editedImageObjId =
            editedImageArray.length > 0
              ? editedImageArray[0].id
              : '';
          const newAllData = {
            ...allData,
            images: allData.images.map(each =>
              each.id === editedImageObjId
                ? {
                    ...each,
                    id: response.data.images[0].id,
                    url: response.data.images[0].url
                  }
                : each
            )
          };
          localStorage.setItem(
            'allProductDataPerId',
            JSON.stringify(newAllData)
          );
          setAllData(newAllData);

          setImageId(prevImageId => [
            ...prevImageId.filter(i => i !== editedImageObjId),
            response.data.images[0].id
          ]);
          if (image_name)
            setSelectedFiles(prevState => ({
              ...prevState,
              [image_name]: ''
            }));

          if ([imageName, image_name].includes('ITEMIMAGEURL1')) {
            setITEMIMAGEURL1(urlImage);
          } else if (
            [imageName, image_name].includes('ITEMIMAGEURL2')
          ) {
            setITEMIMAGEURL2(urlImage);
          } else if (
            [imageName, image_name].includes('ITEMIMAGEURL3')
          ) {
            setITEMIMAGEURL3(urlImage);
          } else if (
            [imageName, image_name].includes('ITEMIMAGEURL4')
          ) {
            setITEMIMAGEURL4(urlImage);
          } else if (
            [imageName, image_name].includes('ITEMIMAGEURL5')
          ) {
            setITEMIMAGEURL5(urlImage);
          } else if (
            [imageName, image_name].includes('ITEMIMAGEURL6')
          ) {
            setITEMIMAGEURL6(urlImage);
          } else if (
            [imageName, image_name].includes('ITEMIMAGEURL7')
          ) {
            setITEMIMAGEURL7(urlImage);
          } else {
            setITEMIMAGEURL8(urlImage);
          }
        } else {
          setLoading(false);
          if (
            response.response &&
            response.response.status &&
            response.response.status === 401
          ) {
            handleError(response.response);
          } else errorRedirect(response.response);
        }
      }
    );
  };

  const handleImageUpload = () => {
    const formData = new FormData();
    let imagePlacements = [imageName];
    formData.append('placements', imagePlacements);
    formData.append('images', imageLink, imageLink.name);

    handleImageUploadApi(formData);
  };

  // function to check the error due to 404/500
  const checkFunction = error => {
    if (
      error.response &&
      error.response.status &&
      (error.response.status === 404 ||
        error.response.status === 500)
      //  || !error.response
    ) {
      return true;
    }
  };

  const EditBasicNewProduct = () => {
    // distribution Center
    let destributionCenter = allData && allData.distribution_centers;
    let fData = [];
    for (let i = 0; i < destributionCenter.length; i++) {
      let temp = {};
      temp['name'] = destributionCenter[i]['name'];
      temp['qty'] = dyammicData[destributionCenter[i]['code']]
        ? Number(dyammicData[destributionCenter[i]['code']])
        : 0;
      fData.push(temp);
    }

    let data = {
      is_blocked: false,
      asin: asin,
      brand: brand,
      condition: condition,
      description: descriptionForBasic,
      ean: ean,
      harmonized_code: harmonizedCode,
      isbn: isbn,
      manufacturer: manufacturer,
      mpn: mpn,
      short_description: sortDescriptionForBasic,
      sku: sku,
      subtitle: subTitle,
      // 'tax_product_code',
      title: title,
      upc: upc,
      warehouse_location: warehouseLocation,
      warranty: warrenty,
      multipack_quantity: multipackQuantity,
      height: height,
      // 'length': length,
      width: widths,
      weight: weight,
      cost: cost,
      supplier_name: supplier,
      supplier_code: '',
      supplier_po: supplierPO,
      classification: '',
      store_title: storeTitleForBasic,
      is_display_in_store: displayInStore === 'Yes' ? true : false,
      store_category: storeCategoryForBasic,
      store_description: storeDescriptionForBasic,
      total_quantity: 0,
      tax_code: texCode,
      allow_consolidation: allowConsolidationForBasic,
      default_package: defaultPackage,
      multipack_quantity: multipackQuantity,
      received_date_utc: receivedInInventory,
      store_meta_description: storeMetaDescription,
      usp: uspForBasic,
      margin: productMargin,
      retail_price: retailPrice,
      starting_price: startingPrice,
      reserve_price: reservePrice,
      buy_it_now_price: buyItNowPrice,
      store_price: storePrice,
      // saller_cost: sellerCost,
      second_chance_price: 0,
      min_price: minimumPrice,
      max_price: maximumPrice,
      estimated_shipping_cost: estimatedShippingCost,
      category: category,
      distribution_centers: fData,
      images: imageId
    };

    let attributes = {
      age_range: ageRange,
      amz_repricer_auto_price: amzRepricerAutoPrice,
      // 'brand_ca_suggestion' : brandCASuggestion,
      care: care,
      color: color,
      // 'color_ca_suggestion': colorCASuggestion,
      distribution_designation_amzc: distributionDesignationAMZC,
      // 'ean_AMZ_ac_suggestion': eanAMZCASuggestion,
      ebay_category_id: ebayCatogoryId,
      eu_size: euSize,
      fight_club_price: fightClubPrice,
      fight_club_cost: flightClubCost,
      flight_club_quantity_buffered: flightClubQuantityBuffered,
      footwear_size_amz_ca_su: footwearSizeSizeAMZCASu,
      footwear_size_size_systema: footwearSizeSizeSystemA,
      footwear_size_width_AMZ_CAS: footwearSizeWidthAMZCAS,
      gender: gender,
      goat_sku: goatSku,
      item_package_quantity: itemPackageQuantity,
      material: materailValue,
      origin: origin,
      primary_color: primaryColor,
      product_weight: productWeight,
      release_year: releaseYear,
      shopify_position: shopifyPosition,
      shopify_product_type: shopifyProductType,
      shopify_product_url: shopifyProductURL,
      size: size,
      // 'size_ca_suggestion': sizeCASuggestion,
      sleeve_length: sleeveLenght,
      uk_size: ukSize,
      urbn_description: URBNDescription,
      walmart_bullet_points: walmartBulletPoints,
      walmart_shoe_category: walmartShoeCategory
    };

    nonActiveConfigurationfields.forEach(currentValue => {
      if (currentValue in data) delete data[currentValue];
      else delete attributes[currentValue];
    });

    data.attributes = attributes;

    let methodOfSave = isAddPath ? 'post' : 'put';
    loadingAction(true);
    setSweetAlert('');
    if (sku && title && mpn) {
      const url = `${BASIC_PRODUCT_CREATE_API}${
        isAddPath ? `?parent_id=${allData.id}/` : `${allData.id}/`
      }`;
      apiEdit(url, data, methodOfSave, ({ status, response }) => {
        if (status) {
          loadingAction(false);
          setOpen(false);
          fetchMessage(true, response, 'basic product created');
          // this.fetchCatalogDetail();
        } else {
          loadingAction(false);
          if (
            response.response &&
            response.response.status &&
            response.response.status === 401
          ) {
            handleError(response.response);
          } else if (checkFunction(response)) {
            fetchMessage('redirectTrue');
          } else {
            fetchMessage(
              false,
              response.response,
              'basic product created'
            );
          }
        }
      });
    } else {
      loadingAction(false);
      if (sku.length === 0) {
        document.getElementById('skuForBasic').style.borderColor =
          '#D10000';
      }
      if (title.length === 0) {
        document.getElementById('titleForBasic').style.borderColor =
          '#D10000';
      }
      if (mpn.length === 0) {
        document.getElementById('mpnForBasic').style.borderColor =
          '#D10000';
      }
    }
  };

  const ageRengeOption =
    allAgeRenge &&
    allAgeRenge.map(a => (
      <>
        {allData && allData.age_range !== a.name && (
          <option value={a.name} key={a.id}>
            {a.name}
          </option>
        )}
      </>
    ));
  const ageRengeSelectedOption =
    allAgeRenge &&
    allAgeRenge.map(a => (
      <>
        {allData && allData.age_range === a.name && (
          <option value={a.name} key={a.id}>
            {a.name}
          </option>
        )}
      </>
    ));

  const materialOption =
    allMaterailName &&
    allMaterailName.map(a => (
      <>
        {allData && allData.material !== a.name && (
          <option value={a.name} key={a.id}>
            {a.name}
          </option>
        )}
      </>
    ));
  const materialSelectedOption =
    allMaterailName &&
    allMaterailName.map(a => (
      <>
        {allData && allData.material === a.name && (
          <option value={a.name} key={a.id}>
            {a.name}
          </option>
        )}
      </>
    ));

  const allowConsolidations =
    allowConsolidation &&
    allowConsolidation.map(a => (
      <>
        {allData && allData.allow_consolidation !== a.name && (
          <option value={a.name} key={a.id}>
            {a.name}
          </option>
        )}
      </>
    ));

  const allowConsolidationsSelectedValue =
    allowConsolidation &&
    allowConsolidation.map(a => (
      <>
        {allData && allData.allow_consolidation === a.name && (
          <option value={a.name} key={a.id}>
            {a.name}
          </option>
        )}
      </>
    ));

  const conditionOptions =
    conditionOption &&
    conditionOption.map(a => (
      <>
        {allData && allData.condition !== a.name && (
          <option value={a.name} key={a.id}>
            {a.name}
          </option>
        )}
      </>
    ));
  const conditionOptionsSelectedPerId =
    conditionOption &&
    conditionOption.map(a => (
      <>
        {allData && allData.condition === a.name && (
          <option value={a.name} key={a.id}>
            {a.name}
          </option>
        )}
      </>
    ));

  const sleeveLengthOptions =
    sleeveLengthOption &&
    sleeveLengthOption.map(a => (
      <>
        {allData && allData.sleeve_length !== a.name && (
          <option value={a.name} key={a.id}>
            {a.name}
          </option>
        )}
      </>
    ));
  const sleeveLengthSelectedOptions =
    sleeveLengthOption &&
    sleeveLengthOption.map(a => (
      <>
        {allData && allData.sleeve_length === a.name && (
          <option value={a.name} key={a.id}>
            {a.name}
          </option>
        )}
      </>
    ));

  const categoryOptions =
    categoryOption &&
    categoryOption.map(a => (
      <>
        {allData && allData.category !== a.name && (
          <option value={a.name} key={a.id}>
            {a.name}
          </option>
        )}
      </>
    ));
  const categorySelectedOptions =
    categoryOption &&
    categoryOption.map(a => (
      <>
        {allData && allData.category === a.name && (
          <option value={a.name} key={a.id}>
            {a.name}
          </option>
        )}
      </>
    ));
  const displayInStoreOptions =
    displayInStoreOption &&
    displayInStoreOption.map(a => (
      <>
        {allData &&
          allData.is_display_in_store !==
            (a.name === 'Yes' ? true : false) && (
            <option value={a.name} key={a.id}>
              {a.name}
            </option>
          )}
      </>
    ));
  const displayInStoreSelectedOptions =
    displayInStoreOption &&
    displayInStoreOption.map(a => (
      <>
        {allData &&
          allData.is_display_in_store ===
            (a.name === 'Yes' ? true : false) && (
            <option value={a.name} key={a.id}>
              {a.name}
            </option>
          )}
      </>
    ));
  const defaultPackageOptions =
    defaultPackageOption &&
    defaultPackageOption.map(a => (
      <>
        {allData && allData.default_package !== a.name && (
          <option value={a.name} key={a.id}>
            {a.name}
          </option>
        )}
      </>
    ));
  const defaultPackageSelectedOptions =
    defaultPackageOption &&
    defaultPackageOption.map(a => (
      <>
        {allData && allData.default_package === a.name && (
          <option value={a.name} key={a.id}>
            {a.name}
          </option>
        )}
      </>
    ));

  return (
    <div className="ProdutsAddPage">
      <Permission />
      <MenuList />
      <div className="heading">
        <h1 id="catalog-title">
          {isAddPath ? 'Add' : 'Update'} Basic SKU
        </h1>
        <div className="proBtns">
          <button
            type="button"
            onClick={() => EditBasicNewProduct()}
          >
            Save
          </button>
          <Link to={`/catalogs/${allData && allData.id}`}>
            {' '}
            <i aria-hidden="true" /> Cancel
          </Link>
        </div>
      </div>
      {allData.length >= 0 ? (
        <div className="content-warpper">
          <div className="content-body BasicPdts">
            {/* <CreateBasicProductTab /> */}
            {showProductButton && (
              <div className="btnTabs">
                <button
                  id="product"
                  onClick={() => handleChangeForProduct('product')}
                >
                  Product
                </button>
                <button
                  id="Image"
                  onClick={() => handleChangeForProduct('image')}
                >
                  Image
                </button>
              </div>
            )}
            <div>
              {isSelctedTab ? (
                // <>
                <div className={classes.root}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={10}>
                      <div className="infoBox">
                        <div id="basicInfo">
                          <h5>
                            <i
                              className="fa fa-info-circle"
                              aria-hidden="true"
                            />{' '}
                            Basic Info
                          </h5>
                          {configuration.sku && (
                            <>
                              <label for="skuForBasic">
                                SKU{' '}
                                <span
                                  style={{
                                    color: '#ff0000',
                                    fontSize: '15px'
                                  }}
                                >
                                  *
                                </span>
                                :{' '}
                              </label>
                              <input
                                required
                                type="text"
                                name="sku"
                                className="form-control"
                                id="skuForBasic"
                                placeholder="Enter Sku"
                                value={sku}
                                onChange={handleChangeSku}
                                // disabled={isAddPath === false ? true : false}
                              />
                              <span className="infoLabel">
                                Example: VN000EWZBLK-8-5(sku) =
                                VN000EWZBLK(mpn)-8-5
                              </span>
                              <br />
                            </>
                          )}
                          {configuration.mpn && (
                            <>
                              <label for="mpnForBasic">
                                MPN{' '}
                                <span
                                  style={{
                                    color: '#ff0000',
                                    fontSize: '15px'
                                  }}
                                >
                                  *
                                </span>
                                :{' '}
                              </label>
                              <input
                                required
                                type="text"
                                name="mpn"
                                className="form-control"
                                id="mpnForBasic"
                                value={mpn}
                                placeholder="Enter MPN"
                                onChange={handleChangeMpn}
                                // disabled={isAddPath === false ? true : false}
                              />
                              <span className="infoLabel">
                                Example: VN000EWZBLK-8-5(sku) =
                                VN000EWZBLK(mpn)-8-5
                              </span>
                              <br />
                            </>
                          )}
                          {configuration.title && (
                            <>
                              <label for="titleForBasic">
                                Title{' '}
                                <span
                                  style={{
                                    color: '#ff0000',
                                    fontSize: '15px'
                                  }}
                                >
                                  *
                                </span>
                                :{' '}
                              </label>
                              <input
                                required
                                type="text"
                                name="title"
                                className="form-control"
                                id="titleForBasic"
                                value={title}
                                placeholder="Enter Title"
                                onChange={handleChangeTitle}
                                // disabled={isAddPath === false ? true : false}
                              />
                            </>
                          )}
                          {configuration.subtitle && (
                            <>
                              <label for="subtitleForBasic">
                                Subtitle:{' '}
                              </label>
                              <input
                                type="text"
                                name="subtitle"
                                className="form-control"
                                id="subtitleForBasic"
                                placeholder="Enter Subtitle"
                                value={subTitle}
                                onChange={handleChangeSubTitle}
                              />
                              <br />
                            </>
                          )}
                          {configuration.brand && (
                            <>
                              <label for="brandForBasic">
                                Brand:{' '}
                              </label>
                              <input
                                type="text"
                                name="brand"
                                className="form-control"
                                id="brandForBasic"
                                placeholder="Enter Brand"
                                value={brand}
                                onChange={hanldeChangeBrand}
                              />
                            </>
                          )}
                          <label for="categoryForBasic">
                            {' '}
                            Category:
                          </label>
                          <select
                            className="custom-select"
                            value={category}
                            onChange={e => handleChangeCategory(e)}
                          >
                            {categorySelectedOptions}
                            {categoryOptions}
                          </select>
                          {configuration.manufacturer && (
                            <>
                              <label for="manufacturerForBasic">
                                Manufacturer:{' '}
                              </label>
                              <input
                                type="text"
                                name="manufacturer"
                                className="form-control"
                                id="manufacturerForBasic"
                                placeholder="Enter Manufacturer"
                                value={manufacturer}
                                onChange={handleChangeManufacturer}
                              />
                            </>
                          )}
                          {configuration.condition && (
                            <>
                              <label for="conditionForBasic">
                                {' '}
                                Condition:
                              </label>
                              <select
                                className="custom-select"
                                value={condition}
                                onChange={e =>
                                  handleChangeCondition(e)
                                }
                              >
                                {conditionOptionsSelectedPerId}
                                {conditionOptions}
                              </select>
                            </>
                          )}
                          {configuration.asin && (
                            <>
                              <label for="asinForBasic">
                                ASIN:{' '}
                              </label>
                              <input
                                type="text"
                                name="asin"
                                className="form-control"
                                id="asinForBasic"
                                value={asin}
                                onChange={hanldeChangeAsin}
                              />
                            </>
                          )}
                          {configuration.upc && (
                            <>
                              <label for="upcForBasic">UPC: </label>
                              <input
                                type="text"
                                name="upc"
                                className="form-control "
                                id="upcForBasic"
                                value={upc}
                                onChange={handleChangeUpc}
                              />
                            </>
                          )}
                          {configuration.isbn && (
                            <>
                              <label for="isbnorBasic">ISBN: </label>
                              <input
                                type="text"
                                name="isbn"
                                className="form-control "
                                id="isbnForBasic"
                                value={isbn}
                                onChange={hanldeChangeIsbn}
                              />
                            </>
                          )}
                          {configuration.ean && (
                            <>
                              <label for="eanForBasic">EAN: </label>
                              <input
                                type="text"
                                name="ean"
                                className="form-control "
                                id="eanForBasic"
                                value={ean}
                                onChange={hanldeChangeEan}
                              />
                            </>
                          )}
                          {configuration.harmonized_code && (
                            <>
                              <label for="harmonizedCodeForBasic">
                                Harmonized Code:{' '}
                              </label>
                              <input
                                type="text"
                                name="harmonizedCode"
                                className="form-control "
                                id="harmonizedCodeForBasic"
                                value={harmonizedCode}
                                onChange={handleChangeHarmonizedCode}
                              />
                            </>
                          )}
                          {configuration.short_description && (
                            <>
                              <label for="sortDescriptionForBasic">
                                {' '}
                                Short Description:{' '}
                              </label>
                              <textarea
                                id="sortDescriptionForBasic"
                                className="form-control"
                                value={sortDescriptionForBasic}
                                name="sortDescriptionForBasic"
                                onChange={
                                  handleChangeSortDescriptionForBasic
                                }
                              />
                            </>
                          )}
                          {configuration.description && (
                            <>
                              <label for="descriptionForBasic">
                                Description:{' '}
                              </label>
                              <textarea
                                id="descriptionForBasic"
                                className="form-control"
                                name="descriptionForBasic"
                                value={descriptionForBasic}
                                onChange={
                                  handleChangeDescriptionForBasic
                                }
                              />
                            </>
                          )}
                          {configuration.height && (
                            <>
                              <label for="heightForBasic">
                                Height (inches):{' '}
                              </label>
                              <input
                                type="text"
                                name="height"
                                className="form-control "
                                id="heightForBasic"
                                value={height}
                                onChange={handleChangeHeight}
                              />
                            </>
                          )}
                          {configuration.width && (
                            <>
                              <label for="widthForBasic">
                                Width (inches):{' '}
                              </label>
                              <input
                                type="text"
                                name="widths"
                                className="form-control "
                                id="widthForBasic"
                                value={widths}
                                onChange={handleChangeWidth}
                              />
                            </>
                          )}
                          {configuration.weight && (
                            <>
                              <label for="weightForBasic">
                                Weight (lb):{' '}
                              </label>
                              <input
                                type="text"
                                name="weight"
                                className="form-control "
                                id="weightForBasic"
                                value={weight}
                                onChange={handleChangeWeight}
                              />
                            </>
                          )}
                          {configuration.supplier_name && (
                            <>
                              <label for="conditionForBasic">
                                Supplier:
                              </label>
                              <select
                                className="custom-select"
                                value={supplier}
                                onChange={e =>
                                  handleChangeSupplier(e)
                                }
                              >
                                <option selected value="">
                                  Select Supplier
                                </option>
                                <option value=""> </option>
                                {/* <option value="1">One</option>
                                                        <option value="2">Two</option>
                                                        <option value="3">Three</option> */}
                              </select>{' '}
                              <br />
                            </>
                          )}
                          {configuration.supplier_po && (
                            <>
                              <label for="supplierPOForBasic">
                                Supplier PO:{' '}
                              </label>
                              <input
                                type="text"
                                name="supplierPO"
                                className="form-control "
                                id="supplierPOForBasic"
                                value={supplierPO}
                                onChange={handleChangeSupplierPO}
                              />
                            </>
                          )}
                          {configuration.warranty && (
                            <>
                              <label for="warrantyForBasic">
                                {' '}
                                Warranty:{' '}
                              </label>
                              <textarea
                                id="warrantyForBasic"
                                className="form-control"
                                name="warrenty"
                                value={warrenty}
                                onChange={handleChangeWarrenty}
                              />
                            </>
                          )}
                          {configuration.tax_code && (
                            <>
                              <label for="taxCodeForBasic">
                                Tax Code:{' '}
                              </label>
                              <input
                                type="text"
                                name="texCode"
                                className="form-control "
                                id="taxCodeForBasic"
                                value={texCode}
                                onChange={handleChangeTexCode}
                              />
                            </>
                          )}
                          {configuration.allow_consolidation && (
                            <>
                              <label for="allowConsolidationForBasic">
                                Allow Consolidation:
                              </label>
                              <select
                                className="custom-select"
                                value={allowConsolidationForBasic}
                                onChange={e =>
                                  handleChangeAllowConsolidationForBasic(
                                    e
                                  )
                                }
                              >
                                {allowConsolidationsSelectedValue}
                                {allowConsolidations}
                              </select>{' '}
                              <br />
                            </>
                          )}
                          {configuration.default_package && (
                            <>
                              <label for="defaultPackageForBasic">
                                Default Package:
                              </label>
                              <select
                                className="custom-select"
                                value={defaultPackage}
                                onChange={e =>
                                  handleChangeDefaultPackage(e)
                                }
                              >
                                {defaultPackageSelectedOptions}
                                {defaultPackageOptions}
                              </select>{' '}
                              <br />
                            </>
                          )}
                          {configuration.multipack_quantity && (
                            <>
                              <label for="multipackQuantityForBasic">
                                Multipack Quantity:{' '}
                              </label>
                              <input
                                type="number"
                                step="0.01"
                                name="multipackQuantity"
                                className="form-control"
                                id="multipackQuantityForBasic"
                                value={multipackQuantity}
                                onChange={
                                  handleChangeMultipackQuantity
                                }
                              />
                            </>
                          )}
                          {configuration.warehouse_location && (
                            <>
                              <label for="warehouseLocationForBasic">
                                Warehouse Location:{' '}
                              </label>
                              <input
                                type="text"
                                name="warehouseLocation"
                                className="form-control"
                                id="warehouseLocationForBasic"
                                value={warehouseLocation}
                                onChange={
                                  handleChangeWarehouseLocation
                                }
                              />
                            </>
                          )}
                          {configuration.received_date_utc && (
                            <>
                              <label for="receivedInInventoryForBasic">
                                Received In Inventory:{' '}
                              </label>
                              <input
                                type="date"
                                name="receivedInInventory"
                                className="form-control"
                                id="receivedInInventoryForBasic"
                                value={receivedInInventory}
                                onChange={
                                  hanldeChangeReceivedInInventory
                                }
                              />
                            </>
                          )}
                          {configuration.store_title && (
                            <>
                              <label for="storeTitleForBasic">
                                Store Title:{' '}
                              </label>
                              <input
                                type="text"
                                name="storeTitleForBasic"
                                className="form-control"
                                id="storeTitleForBasic"
                                value={storeTitleForBasic}
                                onChange={
                                  handleChangeStoreTitleForBasic
                                }
                              />
                              <span
                                className="textCopy"
                                onClick={() =>
                                  handleClickForCopyTitle()
                                }
                              >
                                Copy Product Title
                              </span>
                            </>
                          )}
                          {configuration.is_display_in_store && (
                            <>
                              <label for="displayInStoreForBasic">
                                Display In Store:
                              </label>
                              <select
                                className="custom-select"
                                value={displayInStore}
                                onChange={e =>
                                  handleChangeDisplayInStore(e)
                                }
                              >
                                {displayInStoreSelectedOptions}
                                {displayInStoreOptions}
                              </select>{' '}
                              <br />
                            </>
                          )}
                          {configuration.store_category && (
                            <>
                              <label for="storeCategoryForBasic">
                                Store Category:{' '}
                              </label>
                              <input
                                type="text"
                                name="storeCategoryForBasic"
                                className="form-control "
                                id="storeCategoryForBasic"
                                value={storeCategoryForBasic}
                                onChange={
                                  handleChangeStoreCategoryForBasic
                                }
                              />
                            </>
                          )}
                          {configuration.store_description && (
                            <>
                              <label for="storeDescriptionForBasic">
                                {' '}
                                Store Description:{' '}
                              </label>
                              <textarea
                                id="storeDescriptionForBasic"
                                className="form-control"
                                name="storeDescriptionForBasic"
                                value={storeDescriptionForBasic}
                                onChange={
                                  handleChangeStoreDescriptionForBasic
                                }
                              />
                            </>
                          )}
                          {configuration.store_meta_description && (
                            <>
                              <label for="storeMetaDescriptionForBasic">
                                {' '}
                                Store Meta Description:{' '}
                              </label>
                              <textarea
                                id="storeMetaDescriptionForBasic"
                                className="form-control"
                                name="storeMetaDescription"
                                value={storeMetaDescription}
                                onChange={
                                  hanldeChangeStoreMetaDescription
                                }
                              />
                            </>
                          )}
                          <label for="uspForBasic">USP: </label>
                          <input
                            type="number"
                            step="0.01"
                            name="uspForBasic"
                            className="form-control"
                            value={uspForBasic}
                            onChange={handleChnageUspForBasic}
                            id="uspForBasic"
                          />
                        </div>
                        <div id="pricing">
                          <div>
                            <h5>
                              <i
                                className="fa fa-tags"
                                aria-hidden="true"
                              />{' '}
                              Pricing
                            </h5>
                            {configuration.retail_price && (
                              <div className="input-group ">
                                <label for="retailPrice">
                                  Retail Price:
                                </label>
                                <div className="input-group-prepend">
                                  <span className="input-group-text">
                                    $
                                  </span>
                                </div>
                                <input
                                  type="number"
                                  step="0.01"
                                  className="form-control"
                                  name="retailPrice"
                                  id="retailPrice"
                                  value={retailPrice}
                                  onChange={handleChangeRetailPrice}
                                />{' '}
                                <br />
                              </div>
                            )}
                            {configuration.buy_it_now_price && (
                              <div className="input-group ">
                                <label for="buyItNowPrice">
                                  Buy-It-Now Price:
                                </label>
                                <div className="input-group-prepend">
                                  <span className="input-group-text">
                                    $
                                  </span>
                                </div>
                                <input
                                  type="number"
                                  step="0.01"
                                  className="form-control"
                                  name="buyItNowPrice"
                                  id="buyItNowPrice"
                                  value={buyItNowPrice}
                                  onChange={
                                    handleCHangeBuyItNowPrice
                                  }
                                />
                              </div>
                            )}
                            {configuration.cost && (
                              <div className="input-group ">
                                <label for="Cost">Cost:</label>
                                <div className="input-group-prepend">
                                  <span className="input-group-text">
                                    $
                                  </span>
                                </div>
                                <input
                                  type="number"
                                  step="0.01"
                                  className="form-control"
                                  name="cost"
                                  id="cost"
                                  value={cost}
                                  onChange={hanldeChangeCost}
                                />
                              </div>
                            )}
                            {configuration.starting_price && (
                              <div className="input-group ">
                                <label for="startingPrice">
                                  Starting Price:
                                </label>
                                <div className="input-group-prepend">
                                  <span className="input-group-text">
                                    $
                                  </span>
                                </div>
                                <input
                                  type="number"
                                  step="0.01"
                                  className="form-control"
                                  name="startingPrice"
                                  id="startingPrice"
                                  value={startingPrice}
                                  onChange={
                                    handleChangeStartingPrice
                                  }
                                />{' '}
                                <br />
                              </div>
                            )}
                            {configuration.reserve_price && (
                              <div className="input-group ">
                                <label for="reservePrice">
                                  Reserve Price:
                                </label>
                                <div className="input-group-prepend">
                                  <span className="input-group-text">
                                    $
                                  </span>
                                </div>
                                <input
                                  type="number"
                                  step="0.01"
                                  className="form-control"
                                  name="reservePrice"
                                  id="reservePrice"
                                  value={reservePrice}
                                  onChange={handleChangeReservePrice}
                                />{' '}
                                <br />
                              </div>
                            )}
                            {configuration.store_price && (
                              <div className="input-group ">
                                <label for="storePrice">
                                  Store Price:
                                </label>
                                <div className="input-group-prepend">
                                  <span className="input-group-text">
                                    $
                                  </span>
                                </div>
                                <input
                                  type="number"
                                  step="0.01"
                                  className="form-control"
                                  name="storePrice"
                                  id="storePrice"
                                  value={storePrice}
                                  onChange={handleChangeStorePrice}
                                />{' '}
                                <br />
                              </div>
                            )}
                            {/* <div className="input-group ">
                              <label for="sellerCost">Seller Cost:</label>
                              <div className="input-group-prepend">
                                <span className="input-group-text">$</span>
                              </div>
                              <input
                                type="number"
                                step="0.01"
                                className="form-control"
                                name="sellerCost"
                                id="sellerCost"
                                value={sellerCost}
                                onChange={hanldeChangeSellerCost}
                              />{" "}
                              <br />
                            </div> */}
                            {configuration.margin && (
                              <div className="input-group ">
                                <label for="productMargin">
                                  Product Margin:
                                </label>
                                <div className="input-group-prepend">
                                  <span className="input-group-text">
                                    $
                                  </span>
                                </div>
                                <input
                                  type="number"
                                  step="0.01"
                                  className="form-control"
                                  name="productMargin"
                                  id="productMargin"
                                  value={productMargin}
                                  onChange={
                                    handleChangeProductMargin
                                  }
                                />{' '}
                                <br />
                              </div>
                            )}
                            {configuration.min_price && (
                              <div className="input-group ">
                                <label for="minimumPrice">
                                  Minimum Price:
                                </label>
                                <div className="input-group-prepend">
                                  <span className="input-group-text">
                                    $
                                  </span>
                                </div>
                                <input
                                  type="number"
                                  step="0.01"
                                  className="form-control"
                                  name="minimumPrice"
                                  id="minimumPrice"
                                  value={minimumPrice}
                                  onChange={handleChangeMinimumPrice}
                                />{' '}
                                <br />
                              </div>
                            )}
                            {configuration.max_price && (
                              <div className="input-group ">
                                <label for="maximumPrice">
                                  Maximum Price:
                                </label>
                                <div className="input-group-prepend">
                                  <span className="input-group-text">
                                    $
                                  </span>
                                </div>
                                <input
                                  type="number"
                                  step="0.01"
                                  className="form-control"
                                  name="maximumPrice"
                                  id="maximumPrice"
                                  value={maximumPrice}
                                  onChange={hanldeChangeMaximumPrice}
                                />{' '}
                                <br />
                              </div>
                            )}
                            {configuration.estimated_shipping_cost && (
                              <div className="input-group ">
                                <label for="estimatedShippingCost">
                                  Estimated Shipping Cost:
                                </label>
                                <div className="input-group-prepend">
                                  <span className="input-group-text">
                                    $
                                  </span>
                                </div>
                                <input
                                  type="number"
                                  step="0.01"
                                  className="form-control"
                                  name="estimatedShippingCost"
                                  id="estimatedShippingCost"
                                  value={estimatedShippingCost}
                                  onChange={
                                    handleChangeEstimatedShippingCost
                                  }
                                />
                              </div>
                            )}
                          </div>
                        </div>
                        <div id="distribution_center">
                          <h5>
                            <i
                              className="fa fa-warehouse"
                              aria-hidden="true"
                            />{' '}
                            {allData &&
                              allData.distribution_centers &&
                              allData.distribution_centers.length ===
                                0 &&
                              'No Available '}
                            Distribution Centers
                          </h5>
                          <div>
                            {allData &&
                            allData.distribution_centers &&
                            allData.distribution_centers.length >
                              0 ? (
                              <table>
                                <tr>
                                  {/* <th></th> */}
                                  <th>Distribution Center</th>
                                  <th>Code</th>
                                  <th>Type</th>
                                  <th>Quantity</th>
                                </tr>

                                {allData &&
                                  allData.distribution_centers &&
                                  allData.distribution_centers
                                    .filter(
                                      center =>
                                        center.name !== 'Croydon'
                                    )
                                    .map(center => {
                                      return (
                                        <tr>
                                          <td key={center.id}>
                                            {center.name}
                                          </td>
                                          <td key={center.id}>
                                            {center.code}
                                          </td>
                                          <td key={center.id}>
                                            {center.type}
                                          </td>
                                          <td key={center.id}>
                                            <input
                                              type="number"
                                              className="form-control w-50"
                                              name={center.code}
                                              value={
                                                dyammicData[
                                                  center.code
                                                ]
                                              }
                                              onChange={e =>
                                                handleChangeDistributionCenterPhenox(
                                                  e,
                                                  center.code
                                                )
                                              }
                                            />{' '}
                                          </td>
                                        </tr>
                                      );
                                    })}
                              </table>
                            ) : null}
                          </div>
                        </div>
                        <div id="attributes">
                          <h5>
                            <i
                              className="fa fa-server"
                              aria-hidden="true"
                            />{' '}
                            Attributes
                          </h5>
                          {configuration.age_range && (
                            <div className="input-group ">
                              <label for="ageRange">
                                Age Range:
                              </label>
                              <select
                                className="custom-select"
                                value={ageRange}
                                onChange={e =>
                                  handleAgeRangeChange(e)
                                }
                              >
                                {ageRengeSelectedOption}
                                {ageRengeOption}
                              </select>
                            </div>
                          )}
                          {configuration.amz_repricer_auto_price && (
                            <div className="input-group ">
                              <label for="amzRepricerAutoPrice">
                                {' '}
                                AmzRepricerAutoPrice:{' '}
                              </label>
                              <textarea
                                id="amzRepricerAutoPrice"
                                name="amzRepricerAutoPrice"
                                value={amzRepricerAutoPrice}
                                className="form-control"
                                onChange={
                                  handleAmzRepricerAutoPriceChange
                                }
                              >
                                AmzRepricerAutoPrice
                              </textarea>
                            </div>
                          )}
                          {/* <div className="input-group ">
                            <label for="BrandCASuggestion">
                              {" "}
                              BrandCASuggestion:{" "}
                            </label>
                            <textarea
                              id="BrandCASuggestion"
                              name="brandCASuggestion"
                              value={brandCASuggestion}
                              onChange={handleBrandCASuggestion}
                              className="form-control"
                            />
                          </div> */}
                          {configuration.care && (
                            <div className="input-group ">
                              <label for="Care"> Care: </label>
                              <textarea
                                id="Care"
                                className="form-control"
                                value={care}
                                name="care"
                                onChange={handleCareChange}
                              />
                            </div>
                          )}
                          {configuration.color && (
                            <div className="input-group ">
                              <label for="Color"> Color: </label>
                              <textarea
                                id="Color"
                                className="form-control"
                                name="color"
                                value={color}
                                onChange={handeColorChange}
                              />
                            </div>
                          )}
                          {configuration.distribution_designation_amzc && (
                            <div className="input-group ">
                              <label for="DistributionDesignationAMZC">
                                {' '}
                                DistributionDesignationAMZC:{' '}
                              </label>
                              <textarea
                                id="DistributionDesignationAMZC"
                                className="form-control"
                                name="distributionDesignationAMZC"
                                value={distributionDesignationAMZC}
                                onChange={
                                  handleDistributionDesignationAMZC
                                }
                              />
                            </div>
                          )}
                          {configuration.ebay_category_id && (
                            <div className="input-group ">
                              <label for="EBAY_CATEGORY_ID">
                                {' '}
                                EBAY_CATEGORY_ID:{' '}
                              </label>
                              <textarea
                                id="ebayCatogoryId"
                                className="form-control"
                                name="ebayCatogoryId"
                                value={ebayCatogoryId}
                                onChange={handleEbayCatogoryId}
                              />
                            </div>
                          )}
                          {configuration.eu_size && (
                            <div className="input-group ">
                              <label for="EUSize"> EU Size: </label>
                              <textarea
                                id="EUSize"
                                className="form-control"
                                name="euSize"
                                value={euSize}
                                onChange={handleEUSizeChange}
                              />
                            </div>
                          )}
                          {configuration.fight_club_price && (
                            <div className="input-group ">
                              <label for="fightClubPrice">
                                {' '}
                                Fight Club Price:{' '}
                              </label>
                              <textarea
                                id="fightClubPrice"
                                className="form-control"
                                name="fightClubPrice"
                                value={fightClubPrice}
                                onChange={handleFightClubPrice}
                              />
                            </div>
                          )}
                          {configuration.fight_club_cost && (
                            <div className="input-group ">
                              <label for="flightClubCost">
                                {' '}
                                Flight Club Cost:{' '}
                              </label>
                              <textarea
                                id="flightClubCost"
                                className="form-control"
                                name="flightClubCost"
                                value={flightClubCost}
                                onChange={handleFlightClubCost}
                              />
                            </div>
                          )}
                          {configuration.flight_club_quantity_buffered && (
                            <div className="input-group ">
                              <label for="flightClubQuantityBuffered">
                                {' '}
                                Flight Club Quantity Buffered:{' '}
                              </label>
                              <textarea
                                id="flightClubQuantityBuffered"
                                className="form-control"
                                name="flightClubQuantityBuffered"
                                value={flightClubQuantityBuffered}
                                onChange={
                                  handleFlightClubQuantityBuffered
                                }
                              />
                            </div>
                          )}
                          {configuration.footwear_size_amz_ca_su && (
                            <div className="input-group ">
                              <label for="FootwearSizeSizeAMZCASu">
                                FootwearSizeSizeAMZCASu:{' '}
                              </label>
                              <textarea
                                id="FootwearSizeSizeAMZCASu"
                                className="form-control"
                                name="footwearSizeSizeAMZCASu"
                                value={footwearSizeSizeAMZCASu}
                                onChange={
                                  handleFootwearSizeSizeAMZCASu
                                }
                              />
                            </div>
                          )}
                          {configuration.footwear_size_size_systema && (
                            <div className="input-group ">
                              <label for="FootwearSizeSizeSystemA">
                                FootwearSizeSizeSystemA:{' '}
                              </label>
                              <textarea
                                id="FootwearSizeSizeSystemA"
                                className="form-control"
                                name="footwearSizeSizeSystemA"
                                value={footwearSizeSizeSystemA}
                                onChange={
                                  handleFootwearSizeSizeSystemA
                                }
                              />
                            </div>
                          )}
                          {configuration.footwear_size_width_AMZ_CAS && (
                            <div className="input-group ">
                              <label for="FootwearSizeWidthAMZCAS">
                                FootwearSizeWidthAMZCAS:{' '}
                              </label>
                              <textarea
                                id="FootwearSizeWidthAMZCAS"
                                className="form-control"
                                name="footwearSizeWidthAMZCAS"
                                value={footwearSizeWidthAMZCAS}
                                onChange={
                                  hanldeFootwearSizeWidthAMZCAS
                                }
                              />
                            </div>
                          )}
                          {configuration.gender && (
                            <div className="input-group ">
                              <label for="gender">Gender: </label>
                              <textarea
                                id="gender"
                                className="form-control"
                                name="gender"
                                value={gender}
                                onChange={handleGenderChange}
                              />
                            </div>
                          )}
                          {configuration.goat_sku && (
                            <div className="input-group ">
                              <label for="goatSKU">GOAT SKU: </label>
                              <textarea
                                id="goatSKU"
                                className="form-control"
                                name="goatSku"
                                value={goatSku}
                                onChange={handleChangeForGoatSku}
                              />
                            </div>
                          )}
                          {configuration.item_package_quantity && (
                            <div className="input-group ">
                              <label for="itemPackageQuantity">
                                Item Package Quantity:{' '}
                              </label>
                              <textarea
                                id="itemPackageQuantity"
                                className="form-control"
                                name="itemPackageQuantity"
                                value={itemPackageQuantity}
                                onChange={
                                  handleChnageForItemPackageQuantity
                                }
                              />
                            </div>
                          )}
                          {configuration.material && (
                            <div className="input-group ">
                              <label for="material">Material:</label>
                              <select
                                className="custom-select"
                                value={materailValue}
                                onChange={e =>
                                  handleChangeMaterailValue(e)
                                }
                              >
                                {materialSelectedOption}
                                {materialOption}
                              </select>
                            </div>
                          )}
                          {configuration.origin && (
                            <div className="input-group ">
                              <label for="origin">Origin: </label>
                              <textarea
                                id="origin"
                                className="form-control"
                                name="origin"
                                value={origin}
                                onChange={handleChangeOrigin}
                              />
                            </div>
                          )}
                          {configuration.primary_color && (
                            <div className="input-group ">
                              <label for="primaryColor">
                                Primary Color:{' '}
                              </label>
                              <textarea
                                id="primaryColor"
                                className="form-control"
                                name="primaryColor"
                                value={primaryColor}
                                onChange={handleChangePrimaryColor}
                              />
                            </div>
                          )}
                          {configuration.product_weight && (
                            <div className="input-group ">
                              <label for="productWeight">
                                Product Weight:{' '}
                              </label>
                              <textarea
                                id="productWeight"
                                className="form-control"
                                name="productWeight"
                                value={productWeight}
                                onChange={handleChangeProductWeight}
                              />
                            </div>
                          )}
                          {configuration.release_year && (
                            <div className="input-group ">
                              <label for="releaseYear">
                                Release Year:{' '}
                              </label>
                              <textarea
                                id="releaseYear"
                                className="form-control"
                                name="releaseYear"
                                value={releaseYear}
                                onChange={handleChangeReleaseYear}
                              />
                            </div>
                          )}
                          {configuration.shopify_position && (
                            <div className="input-group ">
                              <label for="shopifyPosition">
                                Shopify Position:{' '}
                              </label>
                              <textarea
                                id="shopifyPosition"
                                className="form-control"
                                name="shopifyPosition"
                                value={shopifyPosition}
                                onChange={
                                  handleChangeShopifyPosition
                                }
                              />
                            </div>
                          )}
                          {configuration.shopify_product_type && (
                            <div className="input-group ">
                              <label for="shopifyProductType">
                                Shopify Product Type:{' '}
                              </label>
                              <textarea
                                id="shopifyProductType"
                                className="form-control"
                                name="shopifyProductType"
                                value={shopifyProductType}
                                onChange={
                                  handleChangeShopifyProductType
                                }
                              />
                            </div>
                          )}
                          {configuration.shopify_product_url && (
                            <div className="input-group ">
                              <label for="shopifyProductURL">
                                ShopifyProductURL:{' '}
                              </label>
                              <textarea
                                id="shopifyProductURL"
                                className="form-control"
                                name="shopifyProductURL"
                                value={shopifyProductURL}
                                onChange={
                                  handleChangeShopifyProductURL
                                }
                              />
                            </div>
                          )}
                          {configuration.size && (
                            <div className="input-group ">
                              <label for="size">Size: </label>
                              <textarea
                                id="size"
                                className="form-control"
                                name="size"
                                value={size}
                                onChange={handleChangeSize}
                              />
                            </div>
                          )}
                          {/* <div className="input-group ">
                                                        <label for="sizeCASuggestion">SizeCASuggestion: </label>
                                                        <textarea
                                                            id="sizeCASuggestion"
                                                            className="form-control"
                                                            name="sizeCASuggestion"
                                                            value={sizeCASuggestion}
                                                            onChange={handleChangeSizeCASuggestion}
                                                        >
                                                        </textarea>
                                                    </div> */}
                          {configuration.sleeve_length && (
                            <div className="input-group ">
                              <label for="sleeveLenght">
                                Sleeve Length:
                              </label>
                              <select
                                className="custom-select"
                                value={sleeveLenght}
                                onChange={e =>
                                  handleChangeSleeveLenght(e)
                                }
                              >
                                {sleeveLengthSelectedOptions}
                                {sleeveLengthOptions}
                              </select>
                            </div>
                          )}
                          {configuration.uk_size && (
                            <div className="input-group ">
                              <label for="ukSize">UK Size: </label>
                              <textarea
                                id="ukSize"
                                className="form-control"
                                name="ukSize"
                                value={ukSize}
                                onChange={handleChangeUkSize}
                              />
                            </div>
                          )}
                          {configuration.urbn_description && (
                            <div className="input-group ">
                              <label for="URBNDescription">
                                URBN Description:{' '}
                              </label>
                              <textarea
                                id="URBNDescription"
                                className="form-control"
                                name="URBNDescription"
                                value={URBNDescription}
                                onChange={
                                  handleChangeURBNDescription
                                }
                              />
                            </div>
                          )}
                          {configuration.walmart_bullet_points && (
                            <div className="input-group ">
                              <label for="walmartBulletPoints">
                                Walmart Bullet Points:{' '}
                              </label>
                              <textarea
                                id="walmartBulletPoints"
                                className="form-control"
                                name="walmartBulletPoints"
                                value={walmartBulletPoints}
                                onChange={
                                  handleChangeWalmartBulletPoints
                                }
                              />
                            </div>
                          )}
                          {configuration.walmart_shoe_category && (
                            <div className="input-group ">
                              <label for="walmartShoeCategory">
                                Walmart Shoe Category:{' '}
                              </label>
                              <textarea
                                id="walmartShoeCategory"
                                className="form-control"
                                name="walmartShoeCategory"
                                value={walmartShoeCategory}
                                onChange={
                                  handleChangeWalmartShoeCategory
                                }
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={2}>
                      <div className="RTMenu">
                        <button
                          className="tablinks"
                          id="defaultOpen"
                        >
                          <i className="fas fa-angle-left" />{' '}
                          <a href="#basicInfo">Basic Info </a>{' '}
                        </button>
                        <button className="tablinks">
                          <i className="fas fa-angle-left" />{' '}
                          <a href="#pricing"> Pricing</a>{' '}
                        </button>
                        <button className="tablinks">
                          <i className="fas fa-angle-left" />{' '}
                          <a href="#distribution_center">
                            {' '}
                            Distribution Centers{' '}
                          </a>{' '}
                        </button>
                        <button className="tablinks">
                          <i className="fas fa-angle-left" />
                          <a href="#attributes"> Attributes</a>{' '}
                        </button>
                      </div>
                    </Grid>
                  </Grid>
                </div>
              ) : (
                <div className="infoBox">
                  <h5>
                    <i className="fa fa-images" /> Images
                  </h5>
                  <div className="imageUpload">
                    <Grid container spacing={2}>
                      <Grid item xs={6} className="ImagUploadBox">
                        <h6>ITEMIMAGEURL1</h6>
                        <Grid container spacing={2}>
                          <Grid item xs={4}>
                            <img
                              src={ITEMIMAGEURL1}
                              alt="ITEMIMAGEURL1"
                            />
                          </Grid>
                          <Grid item xs={8}>
                            <div
                              id="ITEMIMAGEURL1"
                              className="drop-container"
                              onDrop={fileDrop}
                            >
                              {selectedFiles['ITEMIMAGEURL1'] && (
                                <p>
                                  {
                                    selectedFiles['ITEMIMAGEURL1']
                                      .name
                                  }
                                </p>
                              )}
                              <input
                                id="ITEMIMAGEURL1"
                                accept="image/*"
                                className="file-input"
                                type="file"
                                onChange={filesSelected}
                              />
                            </div>
                            <textarea
                              className="form-control"
                              name="ITEMIMAGEURL1"
                              value={ITEMIMAGEURL1}
                            />
                            <button
                              id="ITEMIMAGEURL1"
                              type="button"
                              onClick={handleOpen}
                            >
                              <i class="fa fa-upload" /> Upload
                            </button>
                            <button
                              type="button"
                              style={{ marginLeft: '10px' }}
                              onClick={() =>
                                handleClearImage(
                                  ITEMIMAGEURL1,
                                  'ITEMIMAGEURL1'
                                )
                              }
                            >
                              {' '}
                              <i className="fa fa-times-circle" />{' '}
                              Clear
                            </button>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={6} className="ImagUploadBox">
                        <h6>ITEMIMAGEURL2</h6>
                        <Grid container spacing={2}>
                          <Grid item xs={4}>
                            <img
                              src={ITEMIMAGEURL2}
                              alt="ITEMIMAGEURL2"
                            />
                          </Grid>
                          <Grid item xs={8}>
                            <div
                              id="ITEMIMAGEURL2"
                              className="drop-container"
                              onDrop={fileDrop}
                            >
                              {selectedFiles['ITEMIMAGEURL2'] && (
                                <p>
                                  {
                                    selectedFiles['ITEMIMAGEURL2']
                                      .name
                                  }
                                </p>
                              )}
                              <input
                                id="ITEMIMAGEURL2"
                                accept="image/*"
                                className="file-input"
                                type="file"
                                onChange={filesSelected}
                              />
                            </div>
                            <textarea
                              className="form-control"
                              name="ITEMIMAGEURL2"
                              value={ITEMIMAGEURL2}
                            />
                            <button
                              id="ITEMIMAGEURL2"
                              type="button"
                              onClick={handleOpen}
                            >
                              <i class="fa fa-upload" /> Upload
                            </button>
                            <button
                              type="button"
                              style={{ marginLeft: '10px' }}
                              onClick={() =>
                                handleClearImage(
                                  ITEMIMAGEURL2,
                                  'ITEMIMAGEURL2'
                                )
                              }
                            >
                              {' '}
                              <i class="fa fa-times-circle" /> Clear
                            </button>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={6} className="ImagUploadBox">
                        <h6>ITEMIMAGEURL3</h6>
                        <Grid container spacing={2}>
                          <Grid item xs={4}>
                            <img
                              src={ITEMIMAGEURL3}
                              alt="ITEMIMAGEURL3"
                            />
                          </Grid>
                          <Grid item xs={8}>
                            <div
                              id="ITEMIMAGEURL3"
                              className="drop-container"
                              onDrop={fileDrop}
                            >
                              {selectedFiles['ITEMIMAGEURL3'] && (
                                <p>
                                  {
                                    selectedFiles['ITEMIMAGEURL3']
                                      .name
                                  }
                                </p>
                              )}
                              <input
                                id="ITEMIMAGEURL3"
                                accept="image/*"
                                className="file-input"
                                type="file"
                                onChange={filesSelected}
                              />
                            </div>
                            <textarea
                              className="form-control"
                              name="ITEMIMAGEURL3"
                              value={ITEMIMAGEURL3}
                            />
                            <button
                              id="ITEMIMAGEURL3"
                              type="button"
                              onClick={handleOpen}
                            >
                              <i class="fa fa-upload" /> Upload
                            </button>
                            <button
                              type="button"
                              style={{ marginLeft: '10px' }}
                              onClick={() =>
                                handleClearImage(
                                  ITEMIMAGEURL3,
                                  'ITEMIMAGEURL3'
                                )
                              }
                            >
                              {' '}
                              <i class="fa fa-times-circle" /> Clear
                            </button>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={6} className="ImagUploadBox">
                        <h6>ITEMIMAGEURL4</h6>
                        <Grid container spacing={2}>
                          <Grid item xs={4}>
                            <img
                              src={ITEMIMAGEURL4}
                              alt="ITEMIMAGEURL4"
                            />
                          </Grid>
                          <Grid item xs={8}>
                            <div
                              id="ITEMIMAGEURL4"
                              className="drop-container"
                              onDrop={fileDrop}
                            >
                              {selectedFiles['ITEMIMAGEURL4'] && (
                                <p>
                                  {
                                    selectedFiles['ITEMIMAGEURL4']
                                      .name
                                  }
                                </p>
                              )}
                              <input
                                id="ITEMIMAGEURL4"
                                accept="image/*"
                                className="file-input"
                                type="file"
                                onChange={filesSelected}
                              />
                            </div>
                            <textarea
                              className="form-control"
                              name="ITEMIMAGEURL4"
                              value={ITEMIMAGEURL4}
                            />
                            <button
                              id="ITEMIMAGEURL4"
                              type="button"
                              onClick={handleOpen}
                            >
                              <i class="fa fa-upload" /> Upload
                            </button>
                            <button
                              type="button"
                              style={{ marginLeft: '10px' }}
                              onClick={() =>
                                handleClearImage(
                                  ITEMIMAGEURL4,
                                  'ITEMIMAGEURL4'
                                )
                              }
                            >
                              {' '}
                              <i class="fa fa-times-circle" /> Clear
                            </button>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={6} className="ImagUploadBox">
                        <h6>ITEMIMAGEURL5</h6>
                        <Grid container spacing={2}>
                          <Grid item xs={4}>
                            <img
                              src={ITEMIMAGEURL5}
                              alt="ITEMIMAGEURL5"
                            />
                          </Grid>
                          <Grid item xs={8}>
                            <div
                              id="ITEMIMAGEURL5"
                              className="drop-container"
                              onDrop={fileDrop}
                            >
                              {selectedFiles['ITEMIMAGEURL5'] && (
                                <p>
                                  {
                                    selectedFiles['ITEMIMAGEURL5']
                                      .name
                                  }
                                </p>
                              )}
                              <input
                                id="ITEMIMAGEURL5"
                                accept="image/*"
                                className="file-input"
                                type="file"
                                onChange={filesSelected}
                              />
                            </div>
                            <textarea
                              className="form-control"
                              name="ITEMIMAGEURL5"
                              value={ITEMIMAGEURL5}
                            />
                            <button
                              id="ITEMIMAGEURL5"
                              type="button"
                              onClick={handleOpen}
                            >
                              <i class="fa fa-upload" /> Upload
                            </button>
                            <button
                              type="button"
                              style={{ marginLeft: '10px' }}
                              onClick={() =>
                                handleClearImage(
                                  ITEMIMAGEURL5,
                                  'ITEMIMAGEURL5'
                                )
                              }
                            >
                              {' '}
                              <i class="fa fa-times-circle" /> Clear
                            </button>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={6} className="ImagUploadBox">
                        <h6>ITEMIMAGEURL7</h6>
                        <Grid container spacing={2}>
                          <Grid item xs={4}>
                            <img
                              src={ITEMIMAGEURL6}
                              alt="ITEMIMAGEURL6"
                            />
                          </Grid>
                          <Grid item xs={8}>
                            <div
                              id="ITEMIMAGEURL6"
                              className="drop-container"
                              onDrop={fileDrop}
                            >
                              {selectedFiles['ITEMIMAGEURL6'] && (
                                <p>
                                  {
                                    selectedFiles['ITEMIMAGEURL6']
                                      .name
                                  }
                                </p>
                              )}
                              <input
                                id="ITEMIMAGEURL6"
                                accept="image/*"
                                className="file-input"
                                type="file"
                                onChange={filesSelected}
                              />
                            </div>
                            <textarea
                              className="form-control"
                              name="ITEMIMAGEURL6"
                              value={ITEMIMAGEURL6}
                            />
                            <button
                              id="ITEMIMAGEURL6"
                              type="button"
                              onClick={handleOpen}
                            >
                              <i class="fa fa-upload" /> Upload
                            </button>
                            <button
                              type="button"
                              style={{ marginLeft: '10px' }}
                              onClick={() =>
                                handleClearImage(
                                  ITEMIMAGEURL6,
                                  'ITEMIMAGEURL6'
                                )
                              }
                            >
                              {' '}
                              <i class="fa fa-times-circle" /> Clear
                            </button>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={6} className="ImagUploadBox">
                        <h6>ITEMIMAGEURL7</h6>
                        <Grid container spacing={2}>
                          <Grid item xs={4}>
                            <img
                              src={ITEMIMAGEURL7}
                              alt="ITEMIMAGEURL7"
                            />
                          </Grid>
                          <Grid item xs={8}>
                            <div
                              id="ITEMIMAGEURL7"
                              className="drop-container"
                              onDrop={fileDrop}
                            >
                              {selectedFiles['ITEMIMAGEURL7'] && (
                                <p>
                                  {
                                    selectedFiles['ITEMIMAGEURL7']
                                      .name
                                  }
                                </p>
                              )}
                              <input
                                id="ITEMIMAGEURL7"
                                accept="image/*"
                                className="file-input"
                                type="file"
                                onChange={filesSelected}
                              />
                            </div>
                            <textarea
                              className="form-control"
                              name="ITEMIMAGEURL7"
                              value={ITEMIMAGEURL7}
                            />
                            <button
                              id="ITEMIMAGEURL7"
                              type="button"
                              onClick={handleOpen}
                            >
                              <i class="fa fa-upload" /> Upload
                            </button>
                            <button
                              type="button"
                              style={{ marginLeft: '10px' }}
                              onClick={() =>
                                handleClearImage(
                                  ITEMIMAGEURL7,
                                  'ITEMIMAGEURL7'
                                )
                              }
                            >
                              {' '}
                              <i class="fa fa-times-circle" /> Clear
                            </button>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={6} className="ImagUploadBox">
                        <h6>ITEMIMAGEURL8</h6>
                        <Grid container spacing={2}>
                          <Grid item xs={4}>
                            <img
                              src={ITEMIMAGEURL8}
                              alt="ITEMIMAGEURL8"
                            />
                          </Grid>
                          <Grid item xs={8}>
                            <div
                              id="ITEMIMAGEURL8"
                              className="drop-container"
                              onDrop={fileDrop}
                            >
                              {selectedFiles['ITEMIMAGEURL8'] && (
                                <p>
                                  {
                                    selectedFiles['ITEMIMAGEURL8']
                                      .name
                                  }
                                </p>
                              )}
                              <input
                                id="ITEMIMAGEURL8"
                                accept="image/*"
                                className="file-input"
                                type="file"
                                onChange={filesSelected}
                              />
                            </div>
                            <textarea
                              className="form-control"
                              name="ITEMIMAGEURL8"
                              value={ITEMIMAGEURL8}
                            />
                            <button
                              id="ITEMIMAGEURL8"
                              type="button"
                              onClick={handleOpen}
                            >
                              <i class="fa fa-upload" /> Upload
                            </button>
                            <button
                              type="button"
                              style={{ marginLeft: '10px' }}
                              onClick={() =>
                                handleClearImage(
                                  ITEMIMAGEURL8,
                                  'ITEMIMAGEURL8'
                                )
                              }
                            >
                              {' '}
                              <i className="fa fa-times-circle" />{' '}
                              Clear
                            </button>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </div>
                  <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    className="imageUploadPop"
                    open={open}
                    onClose={handleClose}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                      timeout: 500
                    }}
                  >
                    <Fade in={open}>
                      <div className={classes.paper}>
                        <h5 id="transition-modal-title">
                          Import New Image For {imageName}
                        </h5>
                        <span
                          className="close"
                          onClick={() => handleClose()}
                        >
                          {' '}
                          <i className="fa fa-times" />
                        </span>
                        <input
                          type="file"
                          id="myFile"
                          name="imageLink"
                          onChange={handleChangeImageLink}
                        />
                        <button
                          type="button"
                          onClick={() => handleImageUpload()}
                        >
                          Upload
                        </button>
                        <button
                          type="button"
                          onClick={() => handleClose()}
                        >
                          Close
                        </button>
                      </div>
                    </Fade>
                  </Modal>
                </div>
              )}
            </div>
          </div>
        </div>
      ) : (
        <LoadingOverlay showOverlay={loading} />
      )}
      <LoadingOverlay showOverlay={loading} />
      {sweetAlert}
    </div>
  );
};

export default EditBasicProduct;
