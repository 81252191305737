import React, { Component } from "react";
import JSONTree from "react-json-tree";
import Icon from "@material-ui/core/Icon";

import { VO_API_URL, VO_ORDER_API_URL } from "../../configurations/configApi";
import { fetchUrl } from "../../apiActions/action";

const theme = {
  base00: "#272822",
};

let user_marketplace = localStorage.getItem("marketplace");

let marketplace_list = localStorage.getItem("marketplaces_list");

export default class VOJson extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      responseData: {},
      // marketPlace: "VoyageOne",
      toShowData: false,
    };
  }

  fetchVO = (url, param) => {
    fetchUrl(`${url}${param}/`, ({ status, response }) => {
      if (status) {
        this.setState({ loading: false, responseData: response.data });
        console.info('responseData', response.data)
        console.log(this.props);
      }
    });
  };

  componentDidMount() {
    user_marketplace = localStorage.getItem("marketplace");
    marketplace_list = localStorage.getItem("marketplaces_list");
    if (this.props.orderPage) {
      this.setState({ loading: true, toShowData: false });
      const { orderId } = this.props;
      this.fetchVO(VO_ORDER_API_URL, orderId[0].order_id);
    } else {
      this.setState({ loading: true });
      let marketplace =
        user_marketplace && JSON.parse(user_marketplace)[0].marketplace;
      const { id } = this.props;
      if (marketplace) {
        fetchUrl(
          `${VO_API_URL}${id}/?marketplace=${marketplace}`,
          ({ status, response }) => {
            if (status) {
              this.setState({ loading: false, responseData: response.data });
            }
          }
        );
      }
      this.setState({ toShowData: true });
    }
  }

  handleInputChange = (event) => {
    const { id } = this.props;
    let marketPlace;
    if (event.target.value) {
      this.setState({
        loading: true,
        responseData: {},
      });
      marketPlace = event.target.value;
      fetchUrl(
        `${VO_API_URL}${id}/?marketplace=${marketPlace}`,
        ({ status, response }) => {
          if (status) {
            this.setState({ loading: false, responseData: response.data });
          }
        }
      );
    }
    // this.setState({
    //   marketPlace: event.target.value,
    //   responseData: {},
    //  })
  };

  // handleClick = () => {

  // }

  render() {
    const { loading, responseData, toShowData } = this.state;
    // console.log("90", marketplace_list)

    const marketplaceLabel =
      marketplace_list &&
      JSON.parse(marketplace_list).map((each) => (
        <>
          {user_marketplace &&
          JSON.parse(user_marketplace)[0].marketplace !== each.name ? (
            <option id={each.id} value={each.name} key={each.id}>
              {each.name}
            </option>
          ) : null}
        </>
      ));

    // console.log("101", JSON.parse(marketplace_list))

    const setMarketplaceLabel =
      user_marketplace &&
      JSON.parse(user_marketplace).map((each) => (
        <>
          <option id={each.id} value={each.marketplace} key={each.id}>
            {each.marketplace}
          </option>
        </>
      ));

    return (
      <div className="voJson">
        {toShowData ? (
          <div className="platformSelect">
            <select
              name={this.state.marketPlace}
              id={this.state.marketPlace}
              onChange={(event) => this.handleInputChange(event)}
            >
              {setMarketplaceLabel}
              {marketplaceLabel}
            </select>
            {/* <button type="button" onClick={() => this.handleClick()}> submit </button> */}
          </div>
        ) : null}

        {Object.keys(responseData).length > 0 && (
          <>
            <JSONTree data={responseData} theme={theme} />
          </>
        )}
        {loading && (
          <>
            <p className="loader">
              <Icon className="rotate">cached</Icon>
              <br />
              Loading ....
            </p>
          </>
        )}
      </div>
    );
  }
}
