import axios from "axios";

export const apiPost = (url, data, callBack) => {
  const headers = {
    Authorization: `Token ${localStorage.getItem("token")}`
  };

  axios(url, {
    method: "post",
    data,
    headers
  })
    .then(response => callBack({ status: true, response: response })) // need to write another call fn
    .catch(error => callBack({ status: false, response: error }));
};

export const fetchUrl = (url, callBack) => {
  const headers = {
    Authorization: `Token ${localStorage.getItem("token")}`
  };

  axios
    .get(url, {
      headers
    })
    .then(response => {
      callBack({ status: true, response: response }); // need to write another call fn
    })
    .catch(error => {
      callBack({ status: false, response: error });
    });
};

export const apiEdit = (url, data, method, callBack) => {
  const headers = {
    Authorization: `Token ${localStorage.getItem("token")}`
  };

  axios(url, {
    method,
    data,
    headers
  })
    .then(response => callBack({ status: true, response: response })) // need to write another call fn
    .catch(error => callBack({ status: false, response: error }));
};


export const apiDelete = (url, method, callBack) => {
  const headers = {
    Authorization: `Token ${localStorage.getItem("token")}`
  };

  axios(url, {
    method,
    headers
  })
    .then(response => callBack({ status: true, response: response })) // need to write another call fn
    .catch(error => callBack({ status: false, response: error }));
};
