import React, { Component } from 'react';
import { format } from 'date-fns';

export default class DateTimeRender extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: this.getCartValue()
    };
  }

  getCartValue() {
    return this.props.value;
  }

  render() {
    return this.props.data && this.props.data.id ? (
      <>
        {this.state.value
          ? format(new Date(this.state.value), 'yyyy-MM-dd HH:mm')
          : ''}
      </>
    ) : (
      ''
    );
  }
}
