import React, { Component } from "react";
import axios from "axios";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import { fetchUrl } from "../../apiActions/action";
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { TEMPLATE_API, AUTOCOMPLETE_SKU_BUSINESS_RULE } from "../../configurations/configApi";
import _ from "lodash";
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import DetailCellRenderer from "../../formatters/DetailCellRenderer";
import loadingRender from "../../formatters/loadingRender";
import CustomNoRowsOverlay from "../../formatters/customNoRowsOverlay";
import CustomTooltip from "../../formatters/customTooltip.jsx";
import BooleanRender from "../../formatters/BooleanRender";
import { Link } from "react-router-dom";
import errorRedirect from "views/FunctionalComponents/ErrorRedirect";

var displayColumns = [];
var gridParams = "";
var load = false;
var responseOnGrid = "";
var skuValue = localStorage.getItem("skuPreviewProduct") ? localStorage.getItem("skuPreviewProduct") : "";
var marketplaceId = null;


class Template extends Component {
  constructor(props) {
    super(props);

    this.state = {
      columnDefs: [
        {
          headerName: "Channel Field",
          field: "field",
          filter: "agTextColumnFilter",
          filterParams: {
            buttons: ["reset"],
            debounceMs: 1000,
            suppressAndOrCondition: true, // it will remove AND/OR conditions
            filterOptions: [
              "contains",
              "notContains",
              "equals",
              "notEqual",
              "startsWith",
              "endsWith"
            ]
          },
          cellRendererParams: {
            prop: "this is prop1"
        },
          cellRenderer: "agGroupCellRenderer",
        },
        {
          headerName: "Mapping from Your Data",
          field: "mapping_field",
          filter: "agNumberColumnFilter",
        },
          {
            headerName: skuValue,
            field: "value",
          },
        {
          headerName: "Status",
          field: "status",
          filter: 'agSetColumnFilter',
          filterParams: {
            values: function (params) {
              // simulating async delay
              setTimeout(function () {
                params.success(["True", "False"]);
              }, 500);
            }
          },
          // cellRenderer: "ColourRender",
          cellStyle: params => {
            if (params.value === "Mapped") {
              //mark mapped cells as green
              return { color: 'green' };
            } else {
              return { color: 'red' };
            }
          }
        },
        {
          headerName: "Required",
          field: "required",
          filter: "agSetColumnFilter",
          cellRenderer: "BooleanRender",
          filterParams: {
            values: function (params) {
              // simulating async delay
              setTimeout(function () {
                params.success(["True", "False"]);
              }, 500);
            }
          }
        },
      ],
      columnDefsforSku: [

        {
          headerName: "Channel Field",
          field: "field",
          filter: "agTextColumnFilter",
          filterParams: {
            buttons: ["reset"],
            debounceMs: 1000,
            suppressAndOrCondition: true, // it will remove AND/OR conditions
            filterOptions: [
              "contains",
              "notContains",
              "equals",
              "notEqual",
              "startsWith",
              "endsWith",
              // "In List",
              // "Not In List"
            ]
          },
          cellRenderer: "agGroupCellRenderer",
        },

        {
          headerName: "Mapping from Your Data",
          field: "mapping_field",
          filter: "agNumberColumnFilter",
        },
        {
          headerName: "Status",
          field: "status",
          filter: 'agSetColumnFilter',
          filterParams: {
            values: function (params) {
              // simulating async delay
              setTimeout(function () {
                params.success(["True", "False"]);
              }, 500);
            }
          },
          cellStyle: params => {
            if (params.value === "Mapped") {
              //mark mapped cells as green
              return { color: 'green' };
            } else {
              return { color: 'red' };
            }
          }
        },
        {
          headerName: "Required",
          field: "required",
          filter: "agSetColumnFilter",
          cellRenderer: "BooleanRender",
          filterParams: {
            values: function (params) {
              // simulating async delay
              setTimeout(function () {
                params.success(["True", "False"]);
              }, 500);
            }
          }
        },
      ],
      components: {
        DetailCellRenderer: {DetailCellRenderer},
      },
      defaultColDef: {
        flex: 1,
        resizable: true,
        // tooltipComponent: "customTooltip",
        floatingFilter: true,
        minWidth: 170,
        sortable: true,
      },
      rowModelType: "serverSide",
      frameworkComponents: {
        customNoRowsOverlay: CustomNoRowsOverlay,
        loadingRender: loadingRender,
        myDetailCellRenderer: DetailCellRenderer,
        customTooltip: CustomTooltip,
        BooleanRender: BooleanRender,

        // ColourRender: ColourRender,
      },
      context: { componentParent: this },
      pagination: true,
      serverSideStoreType: "full",
      rowSelection: "multiple",
      loadingCellRenderer: "loadingRender",
      loadingCellRendererParams: { loadingMessage: "One moment please..." },
      loadingOverlayComponent: "loadingRender",
      totalProductsCount: 0,
      catalogfilterCount: 0,
      noRowsOverlayComponent: "customNoRowsOverlay",
      noRowsOverlayComponentParams: {
        noRowsMessageFunc: function () {
          return "Please Select Product Preview"
        }
      },
      getDetailRowData: function (params) {
        // params.successCallback([]);
        params.successCallback(params.data.callRecords);
      },
      sideBar: 'filters',
      tooltipShowDelay: 0,
      paginationPageSize: 100,
      pagination: true,
      cacheBlockSize: 100,
      filterStatus: false,
      sweetAlert: "",
      allSKu: [],
      sku: localStorage.getItem("skuPreviewProduct"),
      isProductPrevewSelected: false,
      skuInputValue: "",
      loading: false,
      product_id: localStorage.getItem("products_ids") ? localStorage.getItem("products_ids") : null,
      // loadingForDetails:  localStorage.getItem("loadings")
    };
    // this.onSelectionChanged = this.onSelectionChanged.bind(this);
    this.onFilterCount = this.onFilterCount.bind(this);
    this.onShowAction = this.onShowAction.bind(this);
    this.setDefaultWidth = this.setDefaultWidth.bind(this);
  }

  // // this function will trigger whenever column width is changed
  onColumnMoved = event => {
    this.setState({ saveBtn: load ? true : false });
  };

  // this function will trigger whenever column visiblity is changed
  onColumnVisible = event => {
    responseOnGrid && load && this.setState({ saveBtn: load ? true : false });
  };

  // function to set the total and filtered count
  onFilterCount = (status, filterCount, totalCount) => {
    status
      ? this.setState({ filterStatus: true, countNill: true })
      : this.setState({ filterStatus: false, countNill: true });
    this.setState({ totalCount, filterCount });
  };

  // function to hide count
  onCountNill = () => {
    this.setState({ countNill: false });
  };

  // function to set width for columns visibled
  setDefaultWidth = () => {
    displayColumns.forEach(each => {
      if (each.is_active) {
        Object.keys(each.field_widths).forEach(eachKey => {
          if (eachKey === "image" || eachKey === "created_on") {
            this.gridColumnApi.setColumnWidth(eachKey, 75);
          } else {
            this.gridColumnApi.setColumnWidth(
              eachKey,
              each.field_widths[eachKey]
            );
          }
        });
      }
    });
  };

  // fn triggers, whenever filter value is changed
  filterChanged(event) {
    this.setState({ saveBtn: load ? true : false });
    // pageParams =
    //   Object.keys(this.gridApi.getFilterModel()).length > 0 ? true : false;
    this.state.columnDefs.forEach(each => {
      if (Object.keys(this.gridApi.getFilterModel()).includes(each.field)) {
        Array.from(
          document.getElementsByClassName(`ag-input-field-input`)
        ).forEach(o => {
          if (
            o.getAttribute("aria-label") === `${each.headerName} Filter Input`
          )
            o.className += " yellowFilterInput";
        });
      } else {
        Array.from(
          document.getElementsByClassName(`yellowFilterInput`)
        ).forEach(o => {
          if (
            o.getAttribute("aria-label") === `${each.headerName} Filter Input`
          ) {
            o.classList.remove("yellowFilterInput");
          }
        });
      }
    });
  }

  // to set the initially filtered param values
  callSetFilterModel = (value) => {
    this.gridApi.setFilterModel(gridParams);
    // pageParams = Object.keys(gridParams).length > 0 ? true : false;
    if (Object.keys(gridParams).length > 0) {
      this.state.columnDefs.forEach(each => {
        if (Object.keys(gridParams).includes(each.field)) {
          Array.from(
            document.getElementsByClassName(`ag-input-field-input`)
          ).forEach(o => {
            if (
              o.getAttribute("aria-label") === `${each.headerName} Filter Input`
            )
              o.className += " yellowFilterInput";
          });
        }
      });
    }
    Object.keys(gridParams).length === 0 && this.gridApi.onFilterChanged();
  };


  clearFilters = () => {
    this.gridApi.setFilterModel(null);
  };

  // function to show Action dropdown
  onShowAction = showActionBool => {
    this.setState({ showAction: showActionBool });
  };

  // function to check the error due to 404/500
  checkFunction = error => {
    if (
      error.response &&
      error.response.status &&
      (error.response.status === 404 || error.response.status === 500)
      //  || !error.response
    ) {
      return true;
    }
  };

  // function to show/hide loading overlay components
  onBtShowHideLoading = showHideState => {
    //  if true: show loading component
    // else hide loading component
    showHideState
      ? this.gridApi.showLoadingOverlay()
      : this.gridApi.hideOverlay();
  };

  // function to show no rows overlay
  onBtShowNoRows = () => {
    this.gridApi.showNoRowsOverlay();
  };

  // initialize grid components
  onGridReady = params => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.setState({ params: params });
    // params.api.getToolPanelInstance('filters').expandFilters();
    // params.api.getToolPanelInstance('filters').expandFilters();

    var datasource = createServerSideDatasource(
      this.onBtShowNoRows,
      this.onFilterCount,
      this.onShowAction,
      this.onCountNill
    );
    params.api.setServerSideDatasource(datasource);
  };

  //handle change for sku details
  handleSkuChange = (e, value) => {
    this.setState({ isProductPrevewSelected: true })
    if (value) {
      let values = value && value.split(":")[0]
      let productId = this.state.allSKu && this.state.allSKu.filter(items => items.sku === values)
      let product_id = productId && productId[0] && productId[0].id
      this.setState({ sku: values, product_id: product_id })
      localStorage.setItem("skuPreviewProduct", values)
      localStorage.setItem("products_ids", product_id)
      skuValue = values
      this.setState({
        columnDefs: [{
          headerName: "Channel Field",
          field: "field",
          filter: "agTextColumnFilter",
          filterParams: {
            buttons: ["reset"],
            debounceMs: 1000,
            suppressAndOrCondition: true, // it will remove AND/OR conditions
            filterOptions: [
              "contains",
              "notContains",
              "equals",
              "notEqual",
              "startsWith",
              "endsWith"
            ]
          },
          cellClicked: true,
          cellRenderer: "agGroupCellRenderer",
        },
        {
          headerName: "Mapping from Your Data",
          field: "mapping_field",
          filter: "agNumberColumnFilter",
        },
        skuValue && skuValue ?
          {
            headerName: skuValue,
            field: "value",
            filter: "agNumberColumnFilter"
          } : "",
        {
          headerName: "Status",
          field: "status",
          filter: 'agSetColumnFilter',
          filterParams: {
            values: function (params) {
              // simulating async delay
              setTimeout(function () {
                params.success(["True", "False"]);
              }, 500);
            }
          },
          // cellRenderer: "ColourRender",
          cellStyle: params => {
            if (params.value === "Mapped") {
              //mark mapped cells as green
              return { color: 'green' };
            } else {
              return { color: 'red' };
            }
          }
        },
        {
          headerName: "Required",
          field: "required",
          filter: "agSetColumnFilter",
          cellRenderer: "BooleanRender",
          filterParams: {
            values: function (params) {
              // simulating async delay
              setTimeout(function () {
                params.success(["True", "False"]);
              }, 500);
            }
          }
        }
        ]
      })

      this.onGridReady(this.state.params);
      this.gridApi.setColumnDefs(this.state.columnDefs);

    }
    else {
      this.setState({ sku: "", product_id: null })
      skuValue = ""
      localStorage.setItem("skuPreviewProduct", "");
      localStorage.setItem("products_ids", "")
      this.onGridReady(this.state.params);
      this.gridApi.setColumnDefs(this.state.columnDefsforSku);
    }
  }

  // handle change for clear the sku
  hanldeClearSku = () => {
    this.gridApi.setColumnDefs(this.state.columnDefsforSku);
    skuValue = ""
    this.setState({ sku: "", product_id: null })
    localStorage.setItem("skuPreviewProduct", "")
    localStorage.setItem("products_ids", "")
    this.onGridReady(this.state.params);
  }

  componentDidMount() {
    fetchUrl(AUTOCOMPLETE_SKU_BUSINESS_RULE, ({ status, response }) => {
      if (status) {
        this.setState({ allSKu: response.data.message });
      } else {
        if (response.response &&
          response.response.status &&
          ((response.response.status === 401))
        ) {
          localStorage.removeItem("token");
          localStorage.removeItem("email");
          window.location = "/auth/login-page";
        }
      }
    });
  }

  // input change for search the sku
  handleInputSkuChange = (e) => {
    this.setState({ skuInputValue: e.target.value, loading: true })
  }


  // search sku fetch function
  fetchSerachSku = (data) => {
    // this.setState({ loading: true });
    if (data.length >= 2) {
      fetchUrl(`${AUTOCOMPLETE_SKU_BUSINESS_RULE}?query=${data}`, ({ status, response }) => {
        if (status) {
          // console.log("status nitu", response.data)
          this.setState({ allSKu: response.data.message, loading: false });
          // setLoading(false)
        } else {
          if (response.response &&
            response.response.status &&
            ((response.response.status === 401))
          ) {
            localStorage.removeItem("token");
            localStorage.removeItem("email");
            window.location = "/auth/login-page";
          }
        }
      });
    }
  }



  componentDidUpdate(previousProps, previousState) {
    if (previousState.skuInputValue !== this.state.skuInputValue) {
      this.fetchSerachSku(this.state.skuInputValue)
    }
  }

  // handleCallGrid = () => {
  //   console.log("grifffdd]")
  //   this.onGridReady(this.state.params);
  //   localStorage.setItem("loadings" , false);
  //   let value = localStorage.setItem("loadings" , false);
  //   setTimeout(() => {
  //     this.setState({ loadingForDetails: value})
  //   }, 1000)

  // }

  render() {
      const marketplaceDetails = this.props.marketplaceDetails
      marketplaceId = marketplaceDetails && marketplaceDetails[0] && marketplaceDetails[0].id ? marketplaceDetails[0].id : null;

    const {
      columnDefs,
      defaultColDef,
      rowModelType,
      noRowsOverlayComponentParams,
      loadingCellRendererParams,
      loadingCellRenderer,
      noRowsOverlayComponent,
      loadingOverlayComponent,
      frameworkComponents,
      getDetailRowData,
      columnDefsforSku,
      allSKu,
      sku,
      cacheBlockSize,
      detailCellRendererParams
    } = this.state;

    return (
      <>
        <div>
          <h6> A template contains the mappings from your data to the channel fields.</h6>
          <Grid container spacing={1}>
            <Grid item xs={2}>
              <Autocomplete
                freeSolo
                id="free-solo-2-demo"
                Clearable
                options={allSKu && allSKu.map((option) => option.sku + ": " + option.title)}
                style={{ width: "100%", height: 30, float: "left" }}
                value={sku}
                onChange={(event, value) => this.handleSkuChange(event, value)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    // value={isSkuInputValue}
                    onChange={(e) => this.handleInputSkuChange(e)}
                    InputProps={{
                      ...params.InputProps, type: 'search',
                      endAdornment: (
                        <React.Fragment>
                          {this.state.loading ? <CircularProgress color="inherit" size={20} /> : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                  />
                )}
              />
              <div onClick={() => this.hanldeClearSku()} className="ClearSku">Clear SKU</div>
              {this.state.product_id && this.state.product_id ? <Link to={`/catalogs/${this.state.product_id}`}> Details Page </Link> : null}
             {/* { this.state.loadingForDetails ? <div onClick={() => this.handleCallGrid()} >Clear Sku</div> : null }  */}
            </Grid>
            <Grid item xs={10}>
              <div
                id="myGrid"
                style={{
                  height: "100%",
                  width: "100%",
                }}
                className="ag-theme-alpine template"
              >
                <AgGridReact
                  columnDefs={this.state.product_id ?  columnDefs : columnDefsforSku}
                  defaultColDef={defaultColDef}
                  onGridReady={this.onGridReady}
                  rowModelType={rowModelType}
                  masterDetail={true}
                  enableCellChangeFlash={true}
                  masterDetail={true}
                  detailCellRenderer={'myDetailCellRenderer'}
                  detailRowHeight={350}
                  height={400}
                  width={600}
                  animateRows={true}
                  frameworkComponents={frameworkComponents}
                  embedFullWidthRows={true}
                  loadingOverlayComponent={loadingOverlayComponent}
                  loadingCellRenderer={loadingCellRenderer} // default loading renderer
                  loadingCellRendererParams={loadingCellRendererParams} // renderer Params to load msg
                  noRowsOverlayComponent={noRowsOverlayComponent} // default no rows overlay component
                  noRowsOverlayComponentParams={noRowsOverlayComponentParams} // to show default no rows message
                  paginationPageSize={this.state.paginationPageSize} // pagination page size
                  onColumnVisible={this.onColumnVisible.bind(this)}
                  getDetailRowData={getDetailRowData}
                  cacheBlockSize={cacheBlockSize}
                  detailCellRendererParams={detailCellRendererParams }
                />
              </div>
            </Grid>
          </Grid>
        </div>
      </>
    );
  }
}

// Function to return filter condition key
const getCondition = (name, condition) => {
  if (["status"].includes(name)) {
    return "mapped";
  }
  else if ((["status_1"].includes(name))) {
    return "mapped";
  } else if ((["required_1"].includes(name))) {
    return "required";
  }
  else if ((["required"].includes(name))) {
    return "required";
  }
  else if (["field"].includes(name)) {
    if (condition === "contains") return `${name}__contains`;
    else if (condition === "notContains") return `${name}__not_contains`;
    else if (condition === "startsWith") return `${name}__startswith`;
    else if (condition === "endsWith") return `${name}__endswith`;
    else if (condition === "equals") return `${name}`;
    else if (condition === "notEqual") return `${name}__not_equal`;
    else if (condition === "Is Empty" || condition === "Is Not Empty")
      return `${name}__isnull`;
    else return `${name}`;
  }
  else if (["field_1"].includes(name)) {
    if (condition === "contains") return `${name}__contains`;
    else if (condition === "notContains") return `${name}__not_contains`;
    else if (condition === "startsWith") return `${name}__startswith`;
    else if (condition === "endsWith") return `${name}__endswith`;
    else if (condition === "equals") return `${name}`;
    else if (condition === "notEqual") return `${name}__not_equal`;
    else if (condition === "Is Empty" || condition === "Is Not Empty")
      return `${name}__isnull`;
    else return `${name}`;
  }
  else {
    return `${name}`;
  }
};

const getData = data => {
  /* this is our initial value i.e. the starting point*/
  let initialValue = "";

  /* numbers array */
  let dataList = data;

  /* reducer method that takes in the accumulator and next item */
  let reducer = (accumulator, item) => {
    if (accumulator === "") return item;
    else return accumulator + "__" + item;
  };

  /* we give the reduce method our reducer function
    and our initial value */
  let totalInList = dataList.reduce(reducer, initialValue);
  return totalInList;
};

// // Function to return filter value params
const getFilter = params => {
  var filterData;
  var rangeData = {};
  var data;
  var mapObj = Object.keys(params.request.filterModel).map(each => {
    data = getCondition(each, params.request.filterModel[each].type);
    filterData = {};
    if (params.request.filterModel[each].filterType === "set") {
      // to return checkbox values
      if (params.request.filterModel[each].values.length > 0) {
        filterData[data] = params.request.filterModel[each].values[0] === "True"
          ? "true"
          : "false";
      }
    } else if (
      params.request.filterModel[each].type === "In List" ||
      params.request.filterModel[each].type === "Not In List"
    ) {

      if (params.request.filterModel[each].type === "In List") {
        let splitValues = params.request.filterModel[each].filter.split(",");
        let listNon = [];
        filterData[data] = getData(splitValues);
      }
      else {
        let listNon = [];
        let splitValues = params.request.filterModel[each].filter.split(",");
        // var splitValues = params.request.filterModel[each].filter.split(",");
        // filterData[data] = getInData(splitValues, data)
      }
    } else {
      filterData[data] = encodeURIComponent(
        params.request.filterModel[each].filter
      );
    }
    return filterData;
  });;
  return mapObj;
};

function createServerSideDatasource(
  onBtShowNoRows,
  onFilterCount,
  onShowAction,
  onCountNill
) {
  return {
    getRows: function (params) {
      setTimeout(() => {
        let page = params.request.endRow / 100;
        let filter = [];

        // pId && JSON.stringify(search.push(pId));
        if (
          params.request.sortModel.length > 0 ||
          Object.keys(params.request.filterModel).length > 0 ||
          params.request.groupKeys.length > 0
        ) {
          filter = getFilter(params).filter(e => Object.keys(e).length > 0);
        } else {
          // sortId = "";
        }

        if (marketplaceId) {
          var soniServe = `${TEMPLATE_API}${marketplaceId}/?sku=${skuValue}&filter=${filter ? JSON.stringify(filter) : "[]"}`;
          // console.log("2350", urlParams)
          const headers = {
            Authorization: `Token ${localStorage.getItem("token")}`
          };
          axios(soniServe, {
            method: "get",
            headers,
          })
            .then(response => {
              // skuValue = localStorage.getItem("skuPreviewProduct");
              if (response.data.response.length === 0) {
                onBtShowNoRows();
                // onShowAction(false); // to disable Action Dropdown
                params.successCallback([{ columnNameField: "No Data" }], 4);
              }
              else if (response.data.response && response.data.response[0].mappings && response.data.response[0].mappings.length > 0) {

                // onShowAction(true); // to enable Action Dropdown
                let lastRow = (results, request) => {
                  if (!results) return undefined;
                  var currentLastRow = request.startRow + results.length;
                  return currentLastRow < request.endRow
                    ? currentLastRow
                    : undefined;
                };
                params.successCallback(
                  response.data.response[0].mappings,
                  lastRow(response.data.response[0].mappings, params.request)
                );
              } else {
                onBtShowNoRows();
                params.successCallback([{ columnNameField: "No Data" }], 4);
              }
            })
            .catch(error => {
              if (error.response &&
                error.response.status &&
                ((error.response.status === 401))

              ) {
                localStorage.removeItem("token");
                window.location = "/auth/login-page";
              }
              else errorRedirect(error.response)
            });
        }
      }, 2000)
    }
  };

}

export default Template;
