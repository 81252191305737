import React, { useState, useEffect } from "react";
import { BUSINESS_RULE_LOOKUP_LIST } from "../../configurations/configApi";
import { fetchUrl } from "../../apiActions/action";
import { makeStyles } from '@material-ui/core/styles';
import { Accordion, AccordionSummary, AccordionDetails, Typography } from '@material-ui/core/';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CircularProgress from '@material-ui/core/CircularProgress';


const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',

  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));


function BusinessRuleLookUpList(props) {

  const classes = useStyles();

  const [loading, setLoading] = useState(false)

  const [allLookUpList, setAllLookUpList] = useState([])

  const [productValue, setProductValue] = useState("")


  const fetchLookUpList = () => {
    setLoading(true)
    fetchUrl(BUSINESS_RULE_LOOKUP_LIST, getVal => {
      if (getVal.status) {
        console.log("look", getVal.response.data.results)
        setAllLookUpList(getVal.response.data.results)
        setLoading(false)
      } else {
        // if (
        //   (getVal.response &&
        //     getVal.response.status &&
        //     (getVal.response.status === 404 ||
        //       getVal.response.status === 500)) ||
        //   !getVal.response
        // ) {
        //   window.location = "/error";
        // } else {
        //   // to show some popups
        //   window.location = "/error";
        // }
      }
    });
  }

  useEffect(() => {
    fetchLookUpList()
  }, [])

  const handleClickForLookUpLists = (e) => {
    let data = allLookUpList && allLookUpList.filter(item => Number(item.id) === Number(e.target.id))
    console.log(data)
    props.handleClickForLookUpList(data, props.productField)
  }

  const table = <table>
    <tr>
      <th>Value</th>
      <th>Name</th>
    </tr>
    {
      allLookUpList && allLookUpList[0] && allLookUpList[0].list_items && allLookUpList[0].list_items.map((items) =>
        <tr>
          <td>{items.value}</td>
          <td>{items.name}</td>
        </tr>
      )
    }
  </table>


  const option = allLookUpList && allLookUpList.map(item =>
    <div>
      <div className={classes.root}>
        <Accordion id={item.id}>
          <Typography onDoubleClick={(e) => handleClickForLookUpLists(e)} id={item.id} className={classes.heading}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id={item.id}
            >
            </AccordionSummary>
            {item.name}
          </Typography>
          <AccordionDetails>
            <Typography style={{ fontSize: "10px", height: "20%" }}>
              {table}
            </Typography>
          </AccordionDetails>
        </Accordion>
      </div>
    </div>
  )

  const fetchSerachLookUpList = (data) => {
    setLoading(true)
    if (data.length >= 2) {
      fetchUrl(`${BUSINESS_RULE_LOOKUP_LIST}?query=${data}`, ({ status, response }) => {
        if (status) {
          setAllLookUpList(response.data)
          setLoading(false)
        }
        else {

        }
      })

    }
  }

  const handleSearchProductField = (e) => {
    if (e.target.value.length === 0) {
      fetchLookUpList()
    }
    else {
      fetchSerachLookUpList(e.target.value)
    }
  }

  return (
    <div>
      <div>
        <input type="search" placeholder="Search.." onChange={(e) => handleSearchProductField(e)} />
      </div>
      {
        loading ? <CircularProgress color="inherit" size={20} /> :
          <div style={{ maxHeight: "200px", overflowY: "auto" }}>
            {allLookUpList && allLookUpList.length > 0 ? option : "No data availble"}
          </div>
      }
    </div>
  )
}

export default BusinessRuleLookUpList