import React, { useState, useEffect } from "react";
import { TEMPLATE_LOOKUP_LIST, BUSINESS_RULE_PRODUCTION_FIELD } from "../../configurations/configApi";
import { fetchUrl, apiEdit } from "../../apiActions/action";
import { makeStyles } from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import LookUPListModal from "../../Modal/LookUpListModal";
import LoadingOverlay from "../../components/overlays/LoadingOverlay";
import errorRedirect from "views/FunctionalComponents/ErrorRedirect";

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',

    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
}));


function TemplateProductFieldValue({ allCellData, handleDoneLookUpList }) {

    const classes = useStyles();

    const [loading, setLoading] = useState(false)

    const [allLookUpList, setAllLookUpList] = useState([])

    const [allLookUpListValue, setAllLookUpListForValue] = useState(allCellData && allCellData.mapping_details && allCellData.mapping_details.name === "lookup_list" ? allCellData.mapping_details.value: "");

    const [lookupListSearchValue, setLookUpListSearchValue] = useState("");

    const [valueForEditLookUpList, setValueForEditLookUpList] = useState("");

    const [open, setOpen] = useState(false);

    const [stringValue, setStringValue] = useState("");

    const [singleProductionField, setSingleProductionField] = useState([]);


    // function for  Fecth all lookup list
    const fetchLookUpList = () => {
        setLoading(true)
        fetchUrl(TEMPLATE_LOOKUP_LIST, getVal => {
            if (getVal.status) {
                setAllLookUpList(getVal.response.data.results)
                setLoading(false)
            } else {
                if (getVal.response.response &&
                    getVal.response.response.status &&
                    ((getVal.response.response.status === 401))

                ) {
                    localStorage.removeItem("token");
                    window.location = "/auth/login-page";
                }
                else errorRedirect(getVal.response)
            }
        });
    }

    // function for
    const fetchProductAllField = () => {
        setLoading(true)
        fetchUrl(BUSINESS_RULE_PRODUCTION_FIELD, getVal => {
            if (getVal.status) {
                let value = getVal.response.data
                setSingleProductionField(value && value[0] && value[0].name)
                setLoading(false)
            } else {
                if (getVal.response.response &&
                    getVal.response.response.status &&
                    ((getVal.response.response.status === 401))

                ) {
                    localStorage.removeItem("token");
                    window.location = "/auth/login-page";
                }
                else errorRedirect(getVal.response)
            }
        });
    }

    useEffect(() => {
        fetchLookUpList();
        fetchProductAllField();
    }, [])


    const fetchSerachLookupListData = (data) => {
        if (data.length >= 2) {
            setLoading(true)
            fetchUrl(`${TEMPLATE_LOOKUP_LIST}?query=${data}`, ({ status, response }) => {
                if (status) {
                    // console.log("respo", response.data)
                    setAllLookUpList(response.data.results);
                    setLoading(false)
                }
                else {
                    if (response.response &&
                        response.response.status &&
                        ((response.response.status === 401))

                    ) {
                        localStorage.removeItem("token");
                        window.location = "/auth/login-page";
                    }
                    else errorRedirect(response)
                }
            })
        }
    }

    const handleLookUpListValueChange = (e, value) => {
        setAllLookUpListForValue(value)
        if(value === "") {
            fetchLookUpList()
        }
    }
    let lookUpListId = allLookUpList && allLookUpList.filter((i) => i.name === allLookUpListValue);

    const handleLookupListChange = (e) => {
        setLookUpListSearchValue(e.target.value)
    }


    // call fecth sku function
    useEffect(() => {
        fetchSerachLookupListData(lookupListSearchValue)
    }, [lookupListSearchValue])


    // handle for done / save the lookuplist in template
    const HanldeDoneClick = () => {
        handleDoneLookUpList(lookUpListId)
        fetchLookUpList()
    }

    // cancle the lookuplist selected value
    const handleCancel = () => {
        setLookUpListSearchValue("");
        setAllLookUpListForValue("");

    }

    // function for open the modal for create the lookup list
    const HandleCreateLookUpList = () => {
        setValueForEditLookUpList("")
        setOpen(true)
        setStringValue("Create")
    }

    // handle close function for close the modal
    const handleClose = () => {
        setOpen(false);
        setStringValue("")
    }

    // handle edit function for open the modal for edit the lookuplist
    const HandleEditLookUpList = () => {
        setOpen(true)
        setStringValue("Edit");
        let lookUpListData = allLookUpList &&
        allLookUpList.filter(items => items.name === allLookUpListValue);
        let Alldata = lookUpListData && lookUpListData[0];
        if(Alldata){
            setValueForEditLookUpList(Alldata && Alldata)
        }
    }

      // function to check the error due to 404/500
 const checkFunction = error => {
    if (
      error.response &&
      error.response.status &&
      (error.response.status === 404 || error.response.status === 500)
      //  || !error.response
    ) {
      return true;
    }
  };


// function for crrate/ update lookup list
    const createUpdateLookupList = (name,  description, addListItems , allTemplateProductValue) => {
        let lookUPListIdId = valueForEditLookUpList && valueForEditLookUpList.id
        let urlForSave;
        let methodOfSave;
        let dataOfSave;

        if (stringValue === "Edit") {
            setAllLookUpListForValue(name)
            urlForSave = `${TEMPLATE_LOOKUP_LIST}${lookUPListIdId}/`
            methodOfSave = "put";
            dataOfSave = {
                uploaded_by: localStorage.getItem("userId"),
                name: name,
                description: description,
                list_items: addListItems.length > 0  ? addListItems : valueForEditLookUpList && valueForEditLookUpList.list_items ,
                product_field : allTemplateProductValue
            }
        } else {
            urlForSave = TEMPLATE_LOOKUP_LIST
            methodOfSave = "post";
            dataOfSave = {
                uploaded_by: localStorage.getItem("userId"),
                name: name,
                description: description,
                list_items: addListItems,
                product_field : allTemplateProductValue
            }
        }
        if (name && description && (addListItems.length > 0 || valueForEditLookUpList.list_items)) {
            setLoading(true)
            apiEdit(urlForSave, dataOfSave, methodOfSave, ({ status, response }) => {
                if (status) {
                    setStringValue("")
                    setOpen(false)
                    setLoading(false);
                    fetchLookUpList();
                    fetchProductAllField();
                } else {
                    setOpen(true)
                    if (response.response &&
                        response.response.status &&
                        ((response.response.status === 401))
                    ) {
                        localStorage.removeItem("token");
                        window.location = "/auth/login-page";
                    } else if (
                        (response &&
                            response.status &&
                            (response.status === 404 ||
                                response.status === 500)) ||
                        !response
                    ) {
                        window.location = "/error";
                    } else {
                        // to show some popups
                        alert("Please Enter Unique Name")
                    }

                }
            });
        };
    }


    return (
        <div className="lookupList">
            <div>
                <Autocomplete
                    freeSolo
                    id="free-solo-2-demo"
                    Clearable
                    options={allLookUpList && allLookUpList.map((option) => option.name)}
                    style={{ width: "100%", height: 30 }}
                    value={allLookUpListValue}
                    onChange={(event, value) => handleLookUpListValueChange(event, value)}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            variant="outlined"
                            value={lookupListSearchValue}
                            onChange={handleLookupListChange}
                            InputProps={{
                                ...params.InputProps, type: 'search',
                                endAdornment: (
                                    <React.Fragment>
                                        {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                        {params.InputProps.endAdornment}
                                    </React.Fragment>
                                ),
                            }}
                        />
                    )}
                />
            </div>
            <div>
                <button type="button" onClick={() => HanldeDoneClick()} > Done </button>
                <button type="button" onClick={() => handleCancel()}> Cancel </button>
            </div>

                <div
                    className="CERule"
                    onClick={() => HandleCreateLookUpList()}
                    >Create</div>
                {
                    allLookUpListValue ? <div
                        className="CERule"
                        onClick={() => HandleEditLookUpList()}
                    > Edit</div> : null
                }
            {open ? <LookUPListModal
                open={open}
                handleClose={handleClose}
                stringValue={stringValue}
                valueForEditLookUpList={valueForEditLookUpList}
                createUpdateLookupList={createUpdateLookupList}
                selectedProductFeild={singleProductionField}
            /> : null}
            <LoadingOverlay showOverlay={loading} />
        </div>
    )
}

export default TemplateProductFieldValue