import React, { Component } from "react";

export default class BooleanRender extends Component {
  render() {
    return this.props.data && this.props.data.id ? (
      <p style={{ textAlign: "center" }}>
        {this.props.value ? (
          <i class="fas fa-check" aria-hidden="true" title="True" />
        ) : (
          <i class="fas fa-times-circle" aria-hidden="true" title="False" />
        )}{" "}
      </p>
    ) : (
      ""
    );
  }
}
