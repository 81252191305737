import React, { Component } from "react";

import Tabs from "components/CustomTabs/CustomTabs";
import Tasks from "components/Tasks/Tasks";

var marketplaceOptions = localStorage.getItem("marketplaces_list") ? JSON.parse(localStorage.getItem("marketplaces_list")) : [];

export default class catalogTab extends Component {
  render() {
    const { allResponse } = this.props;

    const markeplace = marketplaceOptions && marketplaceOptions.filter((i) => i.name === allResponse[0].marketplace && allResponse[0].marketplace );
    const markeplaceName =  markeplace && markeplace[0] &&  markeplace[0].name;

    return (
      <>
        <Tabs
          headerColor="info"
          tabs={[
            {
              tabName: "Invoice",
              tabContent: (
                <Tasks action="invoiceOrders" allResponse={allResponse} />
              )
            },
            {
              tabName: `${markeplaceName === "VoyageOne" ? "VO" : markeplaceName} JSON`,
              tabContent: (
                <Tasks action="voJsonOrderTab" allResponse={allResponse} />
              )
            }
          ]}
        />
      </>
    );
  }
}
