import React, { useEffect, useState } from 'react';
import { MARKETPLACE_CONFIGURATION } from '../../configurations/configApi';
import LoadingOverlay from '../../components/overlays/LoadingOverlay';
import MessageComponent from '../FunctionalComponents/messageComponent';
import Permission from '../../permission/Permission';
import MenuList from '../../gridComponents/MenuList';
import CreateIcon from '@material-ui/icons/Create';
import { fetchUrl } from '../../apiActions/action';
import { apiEdit } from '../../apiActions/action';
import { handleNegative } from '../../apiActions/checkFunctions';
import Template from 'views/Tables/Templates';
import { handleError } from 'views/FunctionalComponents/ErrorHandle';
import errorRedirect from 'views/FunctionalComponents/ErrorRedirect';

export default function ConfigurationDetails(props) {
  const [data, setData] = useState([]);

  const [marginInput, setMarginInput] = useState(false);

  const [marginValue, setMarginValue] = useState('');

  const [showPublish, setShowPublish] = useState(false);

  const [loading, setLoading] = useState(false);

  const [sweetAlert, setSweetAlert] = useState('');

  const fetchMarketplace = () => {
    loadingAction(true); // to show loading
    fetchUrl(MARKETPLACE_CONFIGURATION, getVal => {
      // console.log(getVal)
      if (getVal.status) {
        setData(getVal.response.data.results);
        localStorage.setItem('marketplaceId', props.match.params.id);
        loadingAction(false); // to show loading
      } else {
        setTimeout(() => {
          if (
            getVal.response.response &&
            getVal.response.response.status &&
            getVal.response.response.status === 401
          ) {
            handleError(getVal.response.response);
          } else errorRedirect(getVal.response);
        }, 1000);
      }
    });
  };

  useEffect(() => {
    fetchMarketplace();
  }, []);

  // function to remove sweetalert popups
  const hideAlert = (mode, action) => {
    setSweetAlert('');
    if (action !== 'manualPublish') {
      (mode === 'success' || mode === 'error') && fetchMarketplace();
    }
  };

  // function to show Message pops on success/error response
  const fetchMessage = (status, response, stateOfAction) => {
    const message = (
      <MessageComponent
        page="configuration"
        action={stateOfAction}
        hideAlert={hideAlert}
        hideOverlay={loadingAction}
        response={response}
        status={status}
      />
    );
    setSweetAlert(message);
  };

  // function to show/hide loading Overlay
  const loadingAction = action => {
    action ? setLoading(true) : setLoading(false);
  };

  const handleRetailInput = () => {
    setMarginInput(!marginInput);
  };

  // function to check the error due to 404/500
  const checkFunction = error => {
    if (
      error.response &&
      error.response.status &&
      (error.response.status === 404 ||
        error.response.status === 500)
      //  || !error.response
    ) {
      return true;
    }
  };

  const handleInputChange = event => {
    // console.log(event.target.value)
    setMarginValue(event.target.value);
    event.target.value
      ? setShowPublish(true)
      : setShowPublish(false);
  };

  // console.log("102", marginValue)
  const handleMarginPublish = () => {
    loadingAction(true); // to show loading
    const data = { margin: marginValue };
    const { id } = props.match.params;
    apiEdit(
      `${MARKETPLACE_CONFIGURATION}${id}/`,
      data,
      'patch',
      ({ status, response }) => {
        if (status) {
          loadingAction(false); // to show loading
          setMarginInput(false);
          fetchMessage(true, response, 'marginPublish');
        } else {
          loadingAction(false); // to hide input tag for fetching label
          if (
            response.response &&
            response.response.status &&
            response.response.status === 401
          ) {
            handleError(response.response);
          } else if (checkFunction(response)) {
            fetchMessage('redirectTrue');
          } else {
            // console.log("eeeeee ", response);
            fetchMessage(false, response.response, 'marginPublish');
          }
        }
      }
    );
  };

  const marketplaceDetails =
    data &&
    data.filter(i => Number(i.id) === Number(props.match.params.id));

  return (
    <>
      <Permission />
      <MenuList />
      <div className="heading">
        <h1 id="dashboard-title">
          {marketplaceDetails && marketplaceDetails[0]
            ? marketplaceDetails[0].name
            : null}{' '}
          | Template
        </h1>
      </div>
      {data ? (
        <div className="content-warpper">
          <div className="content-body configDiv">
            <table className="configTable">
              <tr>
                <td>Marketplace:</td>
                <td>
                  {marketplaceDetails && marketplaceDetails[0]
                    ? marketplaceDetails[0].name
                    : null}
                </td>
              </tr>
              <tr>
                <td>Margin:</td>
                <td>
                  {' '}
                  {marginInput ? (
                    <div className="editPrice">
                      <input
                        type="number"
                        value={marginValue ? marginValue : ''}
                        onKeyPress={event => handleNegative(event)}
                        onChange={event => handleInputChange(event)}
                      />
                      {showPublish ? (
                        <button
                          onClick={() => handleMarginPublish()}
                        >
                          Save
                        </button>
                      ) : (
                        ''
                      )}
                    </div>
                  ) : (
                    <span>
                      {marketplaceDetails && marketplaceDetails[0]
                        ? marketplaceDetails[0].margin
                        : null}
                      %
                      <i>
                        <CreateIcon
                          onClick={() => handleRetailInput()}
                          title="Update margin"
                        />
                      </i>
                    </span>
                  )}
                </td>
              </tr>
            </table>
            <Template marketplaceDetails={marketplaceDetails} />
          </div>
        </div>
      ) : null}
      {sweetAlert}
      <LoadingOverlay showOverlay={loading} />
    </>
  );
}
