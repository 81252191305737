import Axios from "axios";
import React, { Fragment, useEffect, useState } from "react";

import Permission from "permission/Permission";
import MenuList from "gridComponents/MenuList";
import LoadingOverlay from "components/overlays/LoadingOverlay";
import MessageComponent from "../FunctionalComponents/messageComponent";
import CancelIcon from "@material-ui/icons/ToggleOff";
import CheckCircleIcon from "@material-ui/icons/ToggleOn";

import {
  PRODUCT_FIELD_CONFIGURATION,
  SHIPSTATION_ACTIVE,
  SHIPSTATION_STORE_LIST,
} from "configurations/configApi";

import { handleError } from "views/FunctionalComponents/ErrorHandle";
import errorRedirect from "views/FunctionalComponents/ErrorRedirect";

import { formatName } from "../../formatterActions";
import { apiEdit } from "apiActions/action";

export default function ShipStationStoreList() {
  const [productFieldConfiguration, setProductFieldConfiguration] = useState({
    attribute_configuration: [],
    field_configuration: [],
  });
  const [storeList, setStoreList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [sweetAlert, setSweetAlert] = useState("");

  const headers = {
    Authorization: `Token ${localStorage.getItem("token")}`,
  };

  // function to remove sweetalert popups
  const hideAlert = () => setSweetAlert("");

  // function to show/hide loading Overlay
  const loadingAction = (action) => setLoading(action);

  // function to show Message pops on success/error response
  const fetchMessage = (status, response, stateOfAction) => {
    const message = (
      <MessageComponent
        page="product-configuration"
        action={stateOfAction}
        hideAlert={hideAlert}
        hideOverlay={loadingAction}
        response={response}
        status={status}
      />
    );
    setSweetAlert(message);
  };

  // function to check the error due to 404/500
  const checkFunction = (error) => {
    if (
      error.response &&
      error.response.status &&
      (error.response.status === 404 || error.response.status === 500)
    ) {
      return true;
    }
  };

  const handleActive = (status, id) => {
    console.log(id, "id");
    loadingAction(true); // to show loading
    const statusOfActive = {
      active: status,
    };
    // const { id } = 3;
    apiEdit(
      `${SHIPSTATION_ACTIVE}${id}/`,
      statusOfActive,
      "patch",
      ({ status, response }) => {
        if (status) {
          loadingAction(false); // to show loading
          fetchShipStationStoreLists();
          // this.fetchCatalogDetail(this.state.id);
        } else {
          loadingAction(false); // to hide input tag for fetching label
          if (
            response.response &&
            response.response.status &&
            response.response.status === 401
          ) {
            handleError(response.response);
          } else if (checkFunction(response)) {
            fetchMessage("redirectTrue");
          } else {
            fetchMessage(false, response.response, "manualPublish");
          }
        }
      }
    );
  };

  const fetchShipStationStoreLists = () => {
    setLoading(true);
    Axios(SHIPSTATION_STORE_LIST, {
      method: "get",
      headers,
    })
      .then((response) => {
        setStoreList(response.data.results);
        // console.log(response.data.results,'heyyy');
        setLoading(false);
      })
      .catch((error) => {
        console.log(error, "rr");
        setLoading(false);
        // if (
        //   error.response &&
        //   error.response.status &&
        //   error.response.status === 401
        // ) {
        //   handleError(error.response);
        // } else errorRedirect(error.response);
      });
  };

  useEffect(() => {
    fetchShipStationStoreLists();
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  return (
    <>
      {sweetAlert}
      <Permission />
      <MenuList />
      <LoadingOverlay showOverlay={loading} />
      <div className="heading">
        <h1>ShipStation Store list </h1>
        {/* <button onClick={handleConfigurationEdit} className="configSave">
          Save
        </button> */}
      </div>

      <div className="content-warpper">
        <div className="content-body">
          <div className="shipstation-basicinfo">
            {storeList && storeList.length > 0 ? (
              storeList.map((itm) => (
                <div className="shipstationsRow">
                  <p>
                    <span>Name:</span> {itm.store_name}
                  </p>
                  <p>
                    <span>Marketplace Name:</span> {itm.marketplace_name}
                  </p>
                  {/* <p>
                    <span>Company Name:</span> {itm.companyName}
                  </p> */}
                  <p>
                    <span>Store Id:</span> {itm.store_id}
                  </p>
                  <p style={{ display: "flex", height: "20px" }}>
                    <span>Active:</span>{" "}
                    <div className="toggleBtn">
                      {itm.active ? (
                        <div>
                          {/* <h1>heyyyss</h1> */}
                          <b title="Disable Manual Price">
                            <CancelIcon
                              alt="Disable Manual Price"
                              onClick={() => handleActive("False", itm.id)}
                              className="on"
                            />
                          </b>
                        </div>
                      ) : (
                        <b title="Enable Manual Price">
                          <CheckCircleIcon
                            alt="Enable Manual Price"
                            onClick={() => handleActive("True", itm.id)}
                            className="off"
                          />
                        </b>
                      )}
                    </div>
                    {/* {itm.active ? "True" : "False"} */}
                  </p>
                  <p>
                    <span>createdate:</span> {itm.create_date}
                  </p>
                  <p>
                    <span>modifydate:</span> {itm.modify_date}
                  </p>
                  <p>
                    <span>refreshdate:</span> {itm.refresh_date}
                  </p>
                </div>
              ))
            ) : (
              <h4>No stores</h4>
            )}
            {/* <h5>ShipStation Store list </h5> */}
            {/* <div className="checkBoxDivs">
              {productFieldConfiguration.attribute_configuration.map((each) => (
                <Fragment key={"attribute_configuration-" + each.name}>
                  <div>
                    <input
                      type="checkbox"
                      id={"attribute_configuration-" + each.name}
                      checked={each.is_active}
                      onChange={handleCheckBoxChange}
                    />
                    <p>{formatName(each.name)}</p>
                  </div>
                </Fragment>
              ))}
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
}
