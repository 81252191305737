import React, { useEffect, useState } from "react";
import { BUSINESS_RULE_FUNCTION } from "../../configurations/configApi";
import { fetchUrl } from "../../apiActions/action";
import { makeStyles } from '@material-ui/core/styles';
import { Accordion, AccordionSummary, AccordionDetails, Typography } from '@material-ui/core/';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CircularProgress from '@material-ui/core/CircularProgress';
import { handleError } from "views/FunctionalComponents/ErrorHandle";
import errorRedirect from "views/FunctionalComponents/ErrorRedirect";

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));




function BusinessRuleFunction(props) {

  const classes = useStyles();

  const [allFunction, setAllFunction] = useState([])

  const [loading, setLoading] = useState(false)

  const fetchFunctionaAllData = () => {
    setLoading(true)
    fetchUrl(BUSINESS_RULE_FUNCTION, getVal => {
      if (getVal.status) {
        // console.log("18", getVal.response.data.results)
        setAllFunction(getVal.response.data.results)
        setLoading(false)
      } else {
        if (getVal.response.response &&
          getVal.response.response.status &&
          ((getVal.response.response.status === 401))

        ) {
          handleError(getVal.response.response);
        }
        else errorRedirect(getVal.response)
      }
    });
  }

  useEffect(() => {
    fetchFunctionaAllData()
  }, [])

  const handleClick = (e) => {
    // console.log("id", e.target.id, allFunction, props)
    let data = allFunction && allFunction.filter(a => Number(a.id) === Number(e.target.id))
    // console.log("datatata",data)
    props.handleClickForFunction(data, props.function)
  }

  const fetchSerachFunctionData = (data) => {
    // console.log("length", data.length)
    setLoading(true)
    if (data.length >= 2) {
      fetchUrl(`${BUSINESS_RULE_FUNCTION}?query=${data}`, ({ status, response }) => {
        if (status) {
          // console.log("73",response)
          setAllFunction(response.data.results)
          setLoading(false)
        }
        else {
          if (response.response &&
            response.response.status &&
            ((response.response.status === 401))

          ) {
            handleError(response.response);
          }
        }
      })
    }
  }

  const handleSearchFunction = (e) => {
    // console.log("888", e.target.value)
    if (e.target.value.length === 0) {
      fetchFunctionaAllData()
    }
    else {
      fetchSerachFunctionData(e.target.value)
    }

  }

  const option = allFunction && allFunction.map(item => {
    return (
      <div className={classes.root}>
        <Accordion id={item.id} name={item.name}>
          <Typography onDoubleClick={(e) => handleClick(e)} name={item.name} id={item.id} className={classes.heading}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id={item.id}
              name={item.name}
            >
            </AccordionSummary>
            {item.name}

          </Typography>
          <AccordionDetails>
            <Typography>
              {item.description}
            </Typography>
          </AccordionDetails>
        </Accordion>
      </div>
    )
  }
  )

  return (
    <div>
      <input type="search" placeholder="Search.." onChange={(e) => handleSearchFunction(e)} />
      {
        loading ? <CircularProgress color="inherit" size={20} /> :
          <div style={{ maxHeight: "200px", overflowY: "auto" }}>
            {allFunction && allFunction.length > 0 ? option : "No data available"}
          </div>
      }

    </div>
  )
}


export default BusinessRuleFunction