import React, { Component } from 'react';

import Permission from '../../permission/Permission';
import BreadCrumb from '../../gridComponents/BreadCrumb';
import MenuList from '../../gridComponents/MenuList';
import { apiPost, fetchUrl, apiEdit } from '../../apiActions/action';
import {
  CANCEL_API_URL,
  SHIP_API_URL,
  ORDERS_API_URL,
  SHIPSTATION_STORE_LIST,
  SHIPSTATION_ORDER_LIST,
  VO_ORDER_API_URL
} from '../../configurations/configApi';
import LoadingOverlay from '../../components/overlays/LoadingOverlay';
import {
  BillingInfo,
  OrderHistory,
  ShipmentDetails,
  ShippingInfo,
  ShipstaionOrders,
  ShipstationOrderList,
  ShipstationStoreList,
  WeyBillsInfo
} from '../FunctionalComponents/orderDetailComponent';
import MessageComponent from '../FunctionalComponents/messageComponent';
import OrderComponent from '../FunctionalComponents/orderComponent';
import OrderTab from '../FunctionalComponents/orderTab';
import { handleError } from 'views/FunctionalComponents/ErrorHandle';
import errorRedirect from 'views/FunctionalComponents/ErrorRedirect';

export default class OrderDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      allResponse: [],
      shipstationStore: [],
      shipstationOrders: [],
      loading: false,
      crumbsOrder: [],
      sweetAlert: ''
    };
  }

  fetchOrderDetail = id => {
    this.loadingAction(true);
    fetchUrl(`${ORDERS_API_URL}${id}`, getVal => {
      if (getVal.status) {
        const orderDetail = getVal.response.data;
        this.setState({
          allResponse: [orderDetail],
          loading: false
        });

        const { marketplace, order_id, waybill } = orderDetail;
        if (
          marketplace &&
          marketplace === 'VoyageOne' &&
          (!waybill || !waybill.length)
        ) {
          this.fetchVoShipToInfo(order_id);
        }
      } else {
        if (
          getVal.response.response &&
          getVal.response.response.status &&
          getVal.response.response.status === 401
        ) {
          handleError(getVal.response.response);
        } else errorRedirect(getVal.response);
      }
    });
  };

  // fetch ship station store lists
  fetchShipstationStoreList = () => {
    this.loadingAction(true);
    fetchUrl(`${SHIPSTATION_STORE_LIST}`, getVal => {
      if (getVal.status) {
        let listOfResponse = [];
        listOfResponse.push(getVal.response.data);
        this.setState({
          shipstationStore: listOfResponse,
          loading: false
        });
      } else {
        if (
          getVal.response.response &&
          getVal.response.response.status &&
          getVal.response.response.status === 401
        ) {
          handleError(getVal.response.response);
        } else errorRedirect(getVal.response);
      }
    });
  };

  componentDidMount() {
    this.props.clearCrumbs(); // callBack to clear crumb values
    // callBack to add first breadcrumb as Orders
    this.props.maintainBreadCrumbsFunction({
      name: 'Orders',
      pageUrl: '/orders'
    });
    const idOfOrder = [];
    const { id } = this.props.match.params;
    idOfOrder.push(id);
    this.setState({ id: id, orderIds: idOfOrder });

    let crumbObj = {};
    crumbObj.name = id;
    crumbObj.pageUrl = this.props.location.pathname;
    // setting crumbsOrder state with current page location
    this.setState({
      crumbsOrder: this.props.maintainBreadCrumbsFunction(crumbObj)
    });
    this.fetchOrderDetail(id);
    this.fetchShipstationStoreList();
    // this.fetchShipstationOrderList()
  }

  // function to show/hide loading Overlay
  loadingAction = action => {
    action
      ? this.setState({ loading: true })
      : this.setState({ loading: false });
  };

  // function to show Message pops on success/error response
  fetchMessage = (status, response, stateOfAction) => {
    const message = (
      <MessageComponent
        action={stateOfAction}
        page="orders"
        hideAlert={this.hideAlert}
        hideOverlay={this.loadingAction}
        response={response}
        orderId={
          this.state.allResponse[0] &&
          this.state.allResponse[0].order_id
            ? this.state.allResponse[0].order_id
            : null
        }
        status={status}
      />
    );
    this.setState({ sweetAlert: message });
  };

  // function to remove sweetalert popups
  hideAlert = mode => {
    this.setState({ sweetAlert: '' });
    (mode === 'success' || mode === 'error') &&
      this.fetchOrderDetail(this.state.id);
  };

  // function to check the error due to 404/500
  checkFunction = error => {
    if (
      error.response &&
      error.response.status &&
      (error.response.status === 404 ||
        error.response.status === 500)
      //  || !error.response
    ) {
      return true;
    }
  };

  // function to call api for bulk shipment
  handleShipment = getState => {
    // console.log(getState,'gt')
    this.setState({ sweetAlert: '' }); // to hide input pop up of shipment
    this.loadingAction(true); // to show loading
    apiPost(SHIP_API_URL, getState, ({ status, response }) => {
      if (status) {
        this.fetchMessage(true, response);
      } else {
        if (
          response.response &&
          response.response.status &&
          response.response.status === 401
        ) {
          handleError(response.response);
        } else if (this.checkFunction(response)) {
          this.fetchMessage('redirectTrue');
        } else {
          this.fetchMessage(false, response.response);
        }
      }
    });
  };

  // function to fetch shipment popups
  fetchShipment = () => {
    const sweet = (
      <OrderComponent
        handleShipment={this.handleShipment}
        order_ids={this.state.orderIds}
        hideAlert={this.hideAlert}
      />
    );
    // console.log(this.state,'thisss')
    this.setState({ sweetAlert: sweet });
  };

  // function to call cancel orders
  handleCancel = () => {
    this.loadingAction(true); // to show loading
    const data = {
      order_list: this.state.orderIds
      // market_place: "VoyageOne"
    };
    apiPost(CANCEL_API_URL, data, ({ status, response }) => {
      if (status) {
        this.fetchMessage(true, response, 'cancel');
      } else {
        if (
          response.response &&
          response.response.status &&
          response.response.status === 401
        ) {
          handleError(response.response);
        } else if (this.checkFunction(response)) {
          this.fetchMessage('redirectTrue');
        } else {
          this.fetchMessage(false, response.response, 'cancel');
        }
      }
    });
  };

  handleArchive = () => {
    const { allResponse, orderIds } = this.state;
    this.loadingAction(true); // to show loading
    let isArchive =
      allResponse[0] &&
      allResponse[0].is_archived &&
      allResponse[0].is_archived
        ? false
        : true;

    apiEdit(
      `${ORDERS_API_URL}${orderIds}/`,
      { is_archived: isArchive },
      'patch',
      ({ status, response }) => {
        if (status) {
          // console.log("response ", response);
          this.fetchMessage(true, response, 'archive');
        } else {
          if (
            response.response &&
            response.response.status &&
            response.response.status === 401
          ) {
            handleError(response.response);
          } else if (this.checkFunction(response)) {
            this.fetchMessage('redirectTrue');
          } else {
            // console.log("err else ", response);
            this.fetchMessage(false, response.response, 'archive');
          }
        }
      }
    );
  };

  // get order detail
  fetchVoShipToInfo = orderId => {
    this.loadingAction(true);
    fetchUrl(`${VO_ORDER_API_URL}${orderId}`, getVal => {
      if (getVal.status) {
        const { shipToInfo } = getVal.response.data.data;
        let { allResponse } = this.state;
        allResponse[0]['waybill'] = [shipToInfo];
        this.setState({
          allResponse: allResponse,
          loading: false
        });
      } else {
        if (
          getVal.response.response &&
          getVal.response.response.status &&
          getVal.response.response.status === 401
        ) {
          handleError(getVal.response.response);
        } else errorRedirect(getVal.response);
      }
    });
  };

  render() {
    const {
      allResponse,
      loading,
      sweetAlert,
      shipstationStore
    } = this.state;

    let is_archive =
      allResponse[0] &&
      allResponse[0].is_archived &&
      allResponse[0].is_archived;
    return (
      <>
        <Permission />
        <MenuList />
        <div className="heading">
          <h1>Orders Detail</h1>
          <div class="dropdown auction">
            <button onclick="myFunction()">
              <i class="fas fa-gavel" aria-hidden="true" /> Actions
            </button>
            {allResponse.length > 0 && (
              <div id="myDropdown" class="dropdown-content">
                <p onClick={this.fetchShipment}>
                  <i
                    class="fas fa-shipping-fast"
                    aria-hidden="true"
                  />{' '}
                  Ship Orders
                </p>
                <p onClick={this.handleCancel}>
                  <i class="fas fa-ban" aria-hidden="true" /> Cancel
                  Orders
                </p>
                {is_archive ? (
                  <p onClick={this.handleArchive}>
                    <i class="fa fa-archive" aria-hidden="true" />{' '}
                    UnArchive
                  </p>
                ) : (
                  <p onClick={this.handleArchive}>
                    <i class="fa fa-archive" aria-hidden="true" />{' '}
                    Archive
                  </p>
                )}
              </div>
            )}
          </div>
        </div>
        {sweetAlert}
        <div className="content-warpper">
          {allResponse.length > 0 && (
            <div className="content-body">
              <h4>
                {allResponse[0].order_number
                  ? 'Order No: ' + allResponse[0].order_number + ' '
                  : 'Order Id :' + allResponse[0].order_id + ' '}
                <span>{allResponse[0].marketplace}</span>{' '}
              </h4>
              <BreadCrumb crumbs={this.state.crumbsOrder} />
              <div className="content-box-65">
                <div className="recetOrdersDetails">
                  <OrderTab allResponse={allResponse} />
                </div>
              </div>
              <div className="content-box-35">
                <div className="content-box">
                  <h5>Order History</h5>
                  <OrderHistory allResponse={allResponse} />
                </div>
              </div>
              <div className="content-box">
                <h5>Order Details</h5>
                <div className="shipping">
                  <ShippingInfo allResponse={allResponse} />
                </div>

                <div className="billing">
                  <BillingInfo allResponse={allResponse} />
                </div>

                <div className="billing">
                  <WeyBillsInfo allResponse={allResponse} />
                </div>

                <hr />
                <ShipmentDetails allResponse={allResponse} />

                <hr />
                {allResponse[0].shipstation_orders.length > 0 && (
                  <>
                    <h4>ShipStation Orders</h4>
                    <div className="shipstaion-orders">
                      <ShipstationOrderList
                        shipstationOrders={
                          allResponse[0].shipstation_orders
                        }
                      />
                    </div>
                  </>
                )}
              </div>
            </div>
          )}
          <LoadingOverlay showOverlay={loading} />
        </div>
      </>
    );
  }
}
