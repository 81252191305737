import ErrorPage from "./views/Pages/ErrorPage";
import Catalogs from "./views/Tables/Catalogs";

var dashRoutes = [
  {
    path: "/catalogs",
    name: "Catalog",
    rtlName: "لوحة القيادة",
    icon: "add_shopping_cart",
    component: Catalogs,
    layout: "/admin"
  },
  {
    path: "/error",
    name: "Error",
    rtlName: "لوحة القيادة",
    icon: "add_shopping_cart",
    component: ErrorPage,
    layout: "/admin"
  }
];

export default dashRoutes;
