import React, { useState } from "react";
import PropTypes from "prop-types";

import Navigation from "./Navigation";
import NoWifi from "../assets/img/no-wifi.png";

const SiteLayout = (props) => {
  const [deviceOffline, setDeviceOffline] = useState(false);

  window.addEventListener("offline", (event) => {
    setDeviceOffline(true);
  });

  window.addEventListener("online", (event) => {
    setDeviceOffline(false);
  });

  const netWorkConnectionIndicator = (
    <div className="offilne">
      <div className="offilneDiv">
        <img src={NoWifi} alt="Offline" />
        <p>
          <b>Offline</b>Please check your internet!
        </p>
      </div>
    </div>
  );

  return (
    <>
      {deviceOffline && netWorkConnectionIndicator}
      {localStorage.getItem("token") && <Navigation />}
      {props.children}
    </>
  );
};

SiteLayout.propTypes = {
  children: PropTypes.any,
};

export default SiteLayout;
