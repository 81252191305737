import React, { Component } from "react";

export default class CustomTooltip extends Component {
  getReactContainerClasses() {
    return ["custom-tooltip"];
  }

  render() {
    const data = this.props.api.getDisplayedRowAtIndex(this.props.rowIndex)
      .data;

    const distributionData =
      data &&
      data.distribution_center_quantity &&
      data.distribution_center_quantity.length > 0 &&
      data.distribution_center_quantity.map(each => {
        return (
          <>
            <span>
              {each.dist_center}: {each.qty}
            </span>
            <br />
          </>
        );
      });

    const quantityData = data && (
      <span>
        <b>Total Qty: {data.total_quantity}</b>
      </span>
    );

    return (
      <div
        className="custom-tooltip"
        style={{ backgroundColor: this.props.color || "white" }}
      >
        {distributionData}
        {quantityData}
      </div>
    );
  }
}
