import React, { Component } from "react";

import noImage from "../assets/img/comingSoon.jpg";

export default class imageRender extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: this.getImageValue()
    };
  }

  getImageValue() {
    return this.props.value;
  }

  render() {
    return this.props.data && this.props.data.id ? (
      <img src={this.state.value ? this.state.value : noImage} alt="" />
    ) : (
      <img src="https://www.ag-grid.com/example-assets/loading.gif"/>
    );
  }
}
