import React, { Component } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import GridItem from "components/Grid/GridItem";

import {
  // handleInputChange,
  handleNegative
} from "../../apiActions/checkFunctions";

export default class orderComponent extends Component {
  constructor(props) {
    // console.log(props,'props')
    super(props);
    this.state = {
      order_ids: this.props.order_ids
    };
    this.handleInputChange = this.handleInputChange.bind(this); 
  }

  handleInputChange = event => {
    // remove h6 tab, so that it will hide the text from user
    var child = document.getElementsByTagName("h6")[0];
    child.classList.remove("h6ClassToShow");
    // console.log(event.target.value,'event')
    const { name, value } = event.target;
    if (value) {
      this.setState({ [name]: value });
      // console.log(this.state,'this')
    } else {
      delete this.state[name];
    }
  };

  handleSubmit = () => {
    if (
      Object.keys(this.state).length === 5 ||
      (Object.keys(this.state).length >= 3 &&
        Object.keys(this.state).includes("carrier_code") &&
        Object.keys(this.state).includes("tracking_no"))
    ) {
      this.props.handleShipment(this.state);
    } else {
      // to show the error message inside popup
      var para = document.getElementsByTagName("h6")[0];
      para.className += " h6ClassToShow";
    }
  };

  render() {
    return (
      <>
        <SweetAlert
          style={{ display: "block", marginTop: "-150px" }}
          title="Ship Orders"
          onConfirm={() => this.handleSubmit()}
          onCancel={() => this.props.hideAlert()}
          confirmBtnText="Submit"
          cancelBtnText="Cancel"
          showCancel
        >
          <GridItem xs={12} sm={12} md={12} id="gridSweetId">
            <h6 className="errorH6">Please provide input for all fields</h6>
            <div className="row">
              <table className="col-md-12" cellspacing="5">
                <tr>
                  <td>
                    Carrier Code <span class="required">*</span>
                  </td>
                  <td>
                    <select
                      name="carrier_code"
                      id="carrier_code"
                      multiple={false}
                      onChange={event => this.handleInputChange(event)}
                    >
                      <option value="" />
                      <option value="Fedex">Fedex</option>
                      <option value="UPS">UPS</option>
                      <option value="DHL">DHL</option>
                    </select>
                  </td>
                </tr>
                <tr>
                  <td>
                    Tracking Number <span class="required">*</span>
                  </td>
                  <td>
                    {" "}
                    <input
                      type="text"
                      id="tracking_no"
                      min={0}
                      required
                      name="tracking_no"
                      onChange={event => this.handleInputChange(event)}
                      style={{ width: "50%" }}
                    />
                  </td>
                </tr>
                <tr>
                  <td>Master Tracking Number</td>
                  <td>
                    {" "}
                    <input
                      type="text"
                      id="master_tracking_no"
                      min={0}
                      required
                      name="master_tracking_no"
                      onChange={event => this.handleInputChange(event)}
                      style={{ width: "50%" }}
                    />
                  </td>
                </tr>
                <tr>
                  <td>Reference Number</td>
                  <td>
                    {" "}
                    <input
                      type="number"
                      id="reference_no"
                      min={0}
                      onKeyPress={e => handleNegative(e)}
                      name="reference_no"
                      onChange={event => this.handleInputChange(event)}
                      style={{ width: "50%" }}
                    />
                  </td>
                </tr>
              </table>
            </div>
          </GridItem>
        </SweetAlert>
      </>
    );
  }
}
