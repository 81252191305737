import React, { Component } from 'react';
import { MARKETPLACE_CONFIGURATION } from '../../configurations/configApi';
import Permission from '../../permission/Permission';
import MenuList from '../../gridComponents/MenuList';
import { fetchUrl } from '../../apiActions/action';
import loadingRender from '../../formatters/loadingRender';
import LoadingOverlay from '../../components/overlays/LoadingOverlay';
import { Link } from 'react-router-dom';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-enterprise';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import errorRedirect from 'views/FunctionalComponents/ErrorRedirect';

export default class ConfigurationPage extends Component {
  constructor() {
    super();
    this.state = {
      loading: false,
      columnDefs: [
        { headerName: 'Marketplace', field: 'name' },

        {
          headerName: 'Manage',
          field: 'manage',
          cellRendererFramework: params => (
            <Link to={`/configuration/details/${params.data.id}`}>
              Manage
            </Link>
          ) //  To add  icon or link  in cell etc
        }
      ],
      productColumnDefs: [
        { headerName: 'Products', field: 'name' },
        {
          headerName: 'Manage',
          field: 'manage',
          cellRendererFramework: params => (
            <Link to="/product-configuration">Manage</Link>
          ) //  To add  icon or link  in cell etc
        }
      ],
      shipStationColumnDefs: [
        { headerName: 'Shipstation Store', field: 'name' },
        {
          headerName: 'Manage',
          field: 'manage',
          cellRendererFramework: params => (
            <Link to="/shipstation-stores">Manage</Link>
          ) //  To add  icon or link  in cell etc
        }
      ],
      mcSettingColumnDefs: [
        { headerName: 'MC Setting', field: 'name' },
        {
          headerName: 'Manage',
          field: 'manage',
          cellRendererFramework: params => (
            <Link to="/mc-settings">Manage</Link>
          ) //  To add  icon or link  in cell etc
        }
      ],
      rowModelType: 'serverSide',
      frameworkComponents: {
        loadingRender: loadingRender
      },
      loadingCellRenderer: 'loadingRender',
      loadingCellRendererParams: {
        loadingMessage: 'One moment please...'
      },
      loadingOverlayComponent: 'loadingRender',
      noRowsOverlayComponentParams: {
        noRowsMessageFunc: function() {
          return 'Sorry - no rows';
        }
      },
      defaultColDef: {
        resizable: true,
        width: 900
      }
    };
  }

  // function to show/hide loading Overlay
  loadingAction = action => {
    action
      ? this.setState({ loading: true })
      : this.setState({ loading: false });
  };

  onShipStationGridReady = params =>
    params.api.applyTransaction({
      add: [{ name: 'Shipstation storelist' }]
    });

  onProductsGridReady = params =>
    params.api.applyTransaction({
      add: [{ name: 'Product Fields' }]
    });

  onMcSettingGridReady = params =>
    params.api.applyTransaction({
      add: [{ name: 'MultiChannel Settings' }]
    });

  onGridReady = params => {
    this.setState({ loading: true }); // to show loading
    fetchUrl(MARKETPLACE_CONFIGURATION, getVal => {
      if (getVal.status) {
        console.log(getVal.response.data.results);
        params.api.applyTransaction({
          add: getVal.response.data.results
        });
        this.setState({ loading: false });
      } else {
        console.log('congi', getVal.response.response);
        if (
          getVal.response.response &&
          getVal.response.response.status &&
          getVal.response.response.status === 401
        ) {
          localStorage.removeItem('token');
          window.location = '/auth/login-page';
        } else errorRedirect(getVal.response);
      }
    });
  };
  render() {
    const {
      loadingCellRenderer,
      defaultColDef,
      columnDefs,
      productColumnDefs,
      loadingCellRendererParams,
      loadingOverlayComponentParams,
      shipStationColumnDefs,
      mcSettingColumnDefs
    } = this.state;

    // console.log(this.state,'thisstate')

    return (
      <>
        <Permission />
        <MenuList />
        <div className="heading">
          <h1 id="dashboard-title">Configuration Page </h1>
        </div>
        <div
          className="ag-theme-alpine"
          style={{ height: '50%', width: '100%' }}
        >
          <AgGridReact
            // enableSorting={true}
            onGridReady={this.onGridReady} // when grid gets ready it invokes
            columnDefs={columnDefs} // to show all column headers and fields
            defaultColDef={defaultColDef} // to enable common props for each fields
            loadingCellRenderer={loadingCellRenderer} // default loading renderer
            loadingCellRendererParams={loadingCellRendererParams} // renderer Params to load msg
            // noRowsOverlayComponentParams={noRowsOverlayComponentParams} // default no rows overlay component
            loadingOverlayComponentParam={
              loadingOverlayComponentParams
            }
          />
        </div>
        <div
          className="ag-theme-alpine"
          style={{ height: '25%', width: '100%' }}
        >
          <AgGridReact
            onGridReady={this.onProductsGridReady} // when grid gets ready it invokes
            columnDefs={productColumnDefs} // to show all column headers and fields
            defaultColDef={defaultColDef} // to enable common props for each fields
          />
        </div>

        <div
          className="ag-theme-alpine"
          style={{ height: '25%', width: '100%' }}
        >
          <AgGridReact
            onGridReady={this.onShipStationGridReady} // when grid gets ready it invokes
            columnDefs={shipStationColumnDefs} // to show all column headers and fields
            defaultColDef={defaultColDef} // to enable common props for each fields
          />
        </div>

        <div
          className="ag-theme-alpine"
          style={{ height: '25%', width: '100%' }}
        >
          <AgGridReact
            onGridReady={this.onMcSettingGridReady} // when grid gets ready it invokes
            columnDefs={mcSettingColumnDefs} // to show all column headers and fields
            defaultColDef={defaultColDef} // to enable common props for each fields
          />
        </div>
        <LoadingOverlay showOverlay={this.state.loading} />
      </>
    );
  }
}
