import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { LOOKUP_LIST_DELETE_UPDATE_ITEM_LIST, BUSINESS_RULE_PRODUCTION_FIELD } from "../configurations/configApi";
import { fetchUrl, apiDelete, apiEdit } from "../apiActions/action";
import { CircularProgress, Modal, Backdrop, Fade } from '@material-ui/core/';
// import LoadingOverlay from "../components/overlays/LoadingOverlay";
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import MessageComponent from "../views/FunctionalComponents/messageComponent";
import errorRedirect from "views/FunctionalComponents/ErrorRedirect";

const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: '1px solid #eee',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        maxWidth: "1200px",
        position: "relative",
        width: "100%"
    },
}));


const LookUpListModal = ({ handleClose, stringValue, open, createUpdateLookupList, valueForEditLookUpList, selectedProductFeild }) => {

    const classes = useStyles();

    const [name, setName] = useState(valueForEditLookUpList && valueForEditLookUpList.name);

    const [description, setDescription] = useState(valueForEditLookUpList && valueForEditLookUpList.description);

    const [nameForListItem, setNameForListItem] = useState("");

    const [valueForListItem, setValueForListItem] = useState("");

    const [addListItems, setAddListItems] = useState([]);

    const [isClickedEditListItem, setIsClickedEditListItem] = useState(false);

    const [isCLicked, setIsClicked] = useState(false);

    const [itemListDataToShow, setItemListDataToShow] = useState(valueForEditLookUpList);

    const [loading, setLoading] = useState(false);

    const [editName, setEditName] = useState("");

    const [editValue, setEditValue] = useState("");

    const [idForListItem, setIdForListItem] = useState(null);

    const [loadingForDelete, setLoadingForDelete] = useState(false);


    const [allProductionField, setAllProductionField] = useState([]);

    const [productFeildInputValue, setProductFeildInputValue] = useState("");

    const [allTemplateProductValue, setAllProductTemplateValue] = useState(
        valueForEditLookUpList ? valueForEditLookUpList.product_field : selectedProductFeild ? selectedProductFeild : "");

    const [sweetAlert, setSweetAlert] = useState("");

    const [nameForEditAddItems, setNameFOrEditAddItems] = useState("");

    const [nameForEditItems, setNameForEditItems] = useState("");

    const [valueForEditItems, setValueForEditItems] = useState("");


// handle change function set lookuplist name
    const handleNameChane = (e) => {
        setName(e.target.value)
        if (e.target.value) {
            document.getElementById("name").style.borderColor = "blue";
        }
    }


// handle change for set the looklist description
    const handleDescriptionChange = (e) => {
        setDescription(e.target.value);
        if (e.target.value) {
            document.getElementById("description").style.borderColor = "blue";
        }
    }

    // function for create lookuplist function
    const handleLookUpListcreateUpdate = () => {
        if (name && description && (addListItems.length > 0 || valueForEditLookUpList.list_items)) {
            createUpdateLookupList(name, description, addListItems, allTemplateProductValue)
        } else {
            if (name.length === 0) {
                document.getElementById("name").style.borderColor = "#D10000";
            }
            if (nameForListItem.length === 0) {
                document.getElementById("nameList").style.borderColor = "#D10000";
            } if (description.length === 0) {
                document.getElementById("description").style.borderColor = "#D10000";
            }
            if (valueForListItem.length === 0) {
                document.getElementById("valueList").style.borderColor = "#D10000";
            }
        }

    }

    // handle change for  set name in list item lookup list
    const handleNameForListItemChagne = (e) => {
        setNameForListItem(e.target.value);
        if (e.target.value) {
            document.getElementById("nameList").style.borderColor = "blue";
        }
    }

// handle change for set value in item list object lookup list
    const handleValueForListItemChange = (e) => {
        setValueForListItem(e.target.value);
        if (e.target.value) {
            document.getElementById("valueList").style.borderColor = "blue";
        }
    }

    // handle chnage functon for edit name in state
    const handleNameForEditListItemChagne = (e) => {
        setNameForEditItems(e.target.value);
    }

    // handle chnange for set value in state item list
    const handleValueForEditListItemChange = (e) => {
        setValueForEditItems(e.target.value);
    }


    // Add name and value  for item list
    const handleClickForSetListItem = () => {
        setAddListItems([...addListItems, {
            name: nameForListItem,
            value: valueForListItem
        }])
    }

    // console.log("add", addListItems)

    // delete the value from state if sett the multiple value at one time
    const addCancelForTextItem = (e) => {
        let exceptDeleteItems = addListItems.filter(items => items.name !== e.name);
        setAddListItems([...exceptDeleteItems])
        setIsClickedEditListItem(false);
    }

    // Edit the single value in state
    const addEditForListItem = (e) => {
        setNameForEditItems(e.name);
        setValueForEditItems(e.value);
        setIsClickedEditListItem(true);
        setNameFOrEditAddItems(e.name)

    }

    // funtion for while  edit one value in state
    const addDoneForListItem = (e) => {
        let data = addListItems.filter(items => items.name !== nameForEditAddItems)
        setAddListItems([...data, {
            name: nameForEditItems,
            value: valueForEditItems
        }])
            setIsClickedEditListItem(false);
        setNameFOrEditAddItems("")
    }

// edit the name whch aleady in lookuplist item
    const handleChnageEditName = (e) => {
        setEditName(e.target.value)
    }

    //  handle change edit value  which already in lookup list item
    const handleChangeEditValue = (e) => {
        setEditValue(e.target.value)
    }

//
    const handleEditItemValue = (data) => {
        setEditName(data.name)
        setEditValue(data.value)
        setIdForListItem(data.id)
        setIsClicked(true)
    }

    //  edit the item list name and value
    const handleSaveItemsList = (items) => {
        setLoadingForDelete(true)
        setLoading(true)
        let LookUpListId = itemListDataToShow && itemListDataToShow.id
        let id = items && items.id
        setIdForListItem(id)
        let urlForSave;
        let methodOfSave;
        let dataOfSave;
        dataOfSave = {
            // marketplace: marketplace,
            name: editName,
            value: editValue,
            lookup_list: LookUpListId

        }
        urlForSave = `${LOOKUP_LIST_DELETE_UPDATE_ITEM_LIST}${id}/`
        methodOfSave = "put";
        if (editValue && editName) {
            apiEdit(urlForSave, dataOfSave, methodOfSave, ({ status, response }) => {
                if (status) {
                    let itemListObj = { ...itemListDataToShow }
                    let listItem = itemListDataToShow && itemListDataToShow.list_items.filter(e => e.id !== response.data.id)
                    let arrayListItem = [...listItem, response.data]
                    itemListObj['list_items'] = arrayListItem
                    setItemListDataToShow(itemListObj)
                    setLoading(false)
                    setIsClicked(false)
                } else {
                    if (response.response &&
                        response.response.status &&
                        ((response.response.status === 401))
                    ) {
                        localStorage.removeItem("token");
                        localStorage.removeItem("email");
                        window.location = "/auth/login-page";
                    }
                    else errorRedirect(response)
                }
            });
        };
    }

    // delete the item list
    const handleDeleteClick = (id) => {
        setLoadingForDelete(true)
        let listItem = itemListDataToShow && itemListDataToShow.list_items.filter(e => e.id !== id)
        apiDelete(`${LOOKUP_LIST_DELETE_UPDATE_ITEM_LIST}${id}/`,
            "delete",
            ({ status, response }) => {
                if (status) {
                    let itemListObj = { ...itemListDataToShow }
                    itemListObj['list_items'] = listItem
                    setItemListDataToShow(itemListObj)
                    setIsClicked(false)
                } else {
                    if (response.response &&
                        response.response.status &&
                        ((response.response.status === 401))
                    ) {
                        localStorage.removeItem("token");
                        localStorage.removeItem("email");
                        window.location = "/auth/login-page";
                    }
                    else errorRedirect(response)
                }
            });
    }



    // fetch the priduct feild value
    const fetchProductAllField = () => {
        setLoading(true)
        fetchUrl(BUSINESS_RULE_PRODUCTION_FIELD, getVal => {
            if (getVal.status) {
                setAllProductionField(getVal.response.data)
                setLoading(false)
            } else {
                if (getVal.response.response &&
                    getVal.response.response.status &&
                    ((getVal.response.response.status === 401))

                ) {
                    localStorage.removeItem("token");
                    window.location = "/auth/login-page";
                }
                else errorRedirect(getVal.response)
            }
        });
    }

    useEffect(() => {
        fetchProductAllField();
    }, [])


    // search the product value
    const fetchSerachFunctionData = (data) => {
        if (data.length >= 2) {
            setLoading(true)
            fetchUrl(`${BUSINESS_RULE_PRODUCTION_FIELD}?query=${data}`, ({ status, response }) => {
                if (status) {
                    setAllProductionField(response.data)
                    setLoading(false)
                }
                else {
                    if (response.response &&
                        response.response.status &&
                        ((response.response.status === 401))

                    ) {
                        localStorage.removeItem("token");
                        window.location = "/auth/login-page";
                    }
                    else errorRedirect(response)
                }
            })
        }
    }

    // hanlde change product field
    const handleProductValueChange = (e, value) => {
        setAllProductTemplateValue(value)
    }


    // handle change for search the product field
    const handleInputProductFieldValueChange = (e) => {
        setProductFeildInputValue(e.target.value)
    }


    // call fecth sku function
    useEffect(() => {
        fetchSerachFunctionData(productFeildInputValue)
    }, [productFeildInputValue])

    return (
        <>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <div className={classes.paper}>
                        <div className="CERulePopup lookupModal">
                            <div >
                                <div >
                                    <div >
                                        <h3 > {stringValue === "Create" ? `${stringValue} New Lookup List` : `${stringValue} Lookup List`} </h3>
                                        <button type="button"
                                            onClick={handleClose}
                                        >
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <div class="row">
                                        <div class="col-12 CbrL">
                                            <div className="div50">
                                                <label> Product Feild</label>
                                                <Autocomplete
                                                    freeSolo
                                                    id="free-solo-2-demo"
                                                    Clearable
                                                    options={allProductionField && allProductionField.map((option) => option.name)}
                                                    style={{ width: "100%" }}
                                                    value={allTemplateProductValue}
                                                    onChange={(event, value) => handleProductValueChange(event, value)}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            variant="outlined"
                                                            value={productFeildInputValue}
                                                            onChange={handleInputProductFieldValueChange}
                                                            InputProps={{
                                                                ...params.InputProps, type: 'search',
                                                                endAdornment: (
                                                                    <React.Fragment>
                                                                        {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                                                        {params.InputProps.endAdornment}
                                                                    </React.Fragment>
                                                                ),
                                                            }}
                                                        />
                                                    )}
                                                />
                                            </div>
                                            <div className="div50">
                                                <label >Name*</label>
                                                <input type="text" name="fname" id="name" value={name} required onChange={(e) => handleNameChane(e)} />
                                            </div>
                                            <label >Description*</label>
                                            <textarea name="description" id="description" value={description} onChange={(e) => handleDescriptionChange(e)} required >
                                                description....
                                            </textarea>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-12 CbrL">
                                            <table>
                                                <tr>
                                                    <th>
                                                        <label >Name*</label>
                                                        <input type="text" name="name" id="nameList" value={nameForListItem} required onChange={(e) => handleNameForListItemChagne(e)} />
                                                    </th>
                                                    <th>
                                                        <label >Value*</label>
                                                        <input name="value" id="valueList" value={valueForListItem} onChange={(e) => handleValueForListItemChange(e)} required />
                                                    </th>
                                                    <th><button type="button" onClick={() => handleClickForSetListItem()}>Add</button></th>
                                                </tr>
                                            </table>
                                        </div>
                                    </div>
                                    {sweetAlert}
                                    <div class="row">
                                        <div class="col-12 CbrL mb-3">
                                            {
                                                addListItems && addListItems.length > 0 ? addListItems.map(e => {
                                                    return (
                                                        <table className="addItemList">
                                                            <tr>
                                                                <td>
                                                                    {isClickedEditListItem && e.name == nameForEditAddItems ? <input type="text" name="name" id="nameList" value={nameForEditItems} required onChange={(e) => handleNameForEditListItemChagne(e)} /> : e.name}
                                                                </td>
                                                                <td>
                                                                    {isClickedEditListItem && e.name == nameForEditAddItems ?  <input name="value" id="valueList" value={valueForEditItems} onChange={(e) => handleValueForEditListItemChange(e)} required /> : e.value}
                                                                </td>
                                                                <td> { e.name == nameForEditAddItems ? <span onClick={() => addDoneForListItem(e)}>Done</span> :  <span onClick={() => addEditForListItem(e)}>Edit</span> }
                                                                    <span onClick={() => addCancelForTextItem(e)}>Cancel</span></td>
                                                            </tr>
                                                        </table>
                                                    )
                                                 }) : null
                                            }
                                            <div>
                                                {itemListDataToShow ? itemListDataToShow && itemListDataToShow.list_items && itemListDataToShow.list_items.map((item) => {
                                                    return (
                                                        <table>
                                                            <tr>
                                                                <td key={item.id}> {isCLicked && idForListItem === item.id ? <input type="text" name="name" id={item.id} value={editName} required onChange={(e) => handleChnageEditName(e)} /> : item.name} </td>
                                                                <td key={item.id}>{isCLicked && idForListItem === item.id ? <input name="value" id={item.id} value={editValue} onChange={(e) => handleChangeEditValue(e)} required /> : item.value} </td>
                                                                <td>
                                                                    {isCLicked && idForListItem === item.id ?
                                                                        <span key={item.id} onClick={() => handleSaveItemsList(item)}>Save</span> :
                                                                        <span key={item.id} onClick={() => handleEditItemValue(item)}>Edit</span>}
                                                                    <span onClick={() => handleDeleteClick(item.id)}>Delete</span>
                                                                </td>
                                                            </tr>
                                                        </table>
                                                    )
                                                }) : loading ? <CircularProgress color="inherit" size={20} /> : null
                                                }

                                            </div>
                                        </div>
                                    </div>
                                    <div className="CERulePopup-Footer">
                                        <button
                                            onClick={handleClose}
                                        >Cancel</button>
                                        <button type="submit" class="btn btn-primary btn-sm" onClick={() => handleLookUpListcreateUpdate()}> Save LookUpList</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Fade>
            </Modal>
            {/* <LoadingOverlay showOverlay={loadingForDelete} /> */}
        </>
    )
}

export default LookUpListModal