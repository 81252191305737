import Axios from 'axios';
import React, { Fragment, useEffect, useState } from 'react';

import Permission from 'permission/Permission';
import MenuList from 'gridComponents/MenuList';
import LoadingOverlay from 'components/overlays/LoadingOverlay';
import MessageComponent from '../FunctionalComponents/messageComponent';
import CloseIcon from '@material-ui/icons/Close';
import CreateIcon from '@material-ui/icons/Create';

import { MULTICHANNEL_SETTING } from 'configurations/configApi';

import { handleError } from 'views/FunctionalComponents/ErrorHandle';

import { formatName } from '../../formatterActions';
import { apiEdit } from 'apiActions/action';

export default function McSettingList() {
  const [settingList, setSettingList] = useState([]);
  const [editAttributes, setEditAttribute] = useState({});
  const [responseAttributes, setResponseAttribute] = useState({});

  const [loading, setLoading] = useState(true);
  const [sweetAlert, setSweetAlert] = useState('');

  const headers = {
    Authorization: `Token ${localStorage.getItem('token')}`
  };

  // function to remove sweetalert popups
  const hideAlert = () => setSweetAlert('');

  // function to show/hide loading Overlay
  const loadingAction = action => setLoading(action);

  // function to show Message pops on success/error response
  const fetchMessage = (status, response, stateOfAction) => {
    const message = (
      <MessageComponent
        page="product-configuration"
        action={stateOfAction}
        hideAlert={hideAlert}
        hideOverlay={loadingAction}
        response={response}
        status={status}
      />
    );
    setSweetAlert(message);
  };

  // function to check the error due to 404/500
  const checkFunction = error => {
    if (
      error.response &&
      error.response.status &&
      (error.response.status === 404 ||
        error.response.status === 500)
    ) {
      return true;
    }
  };

  const handleEnterClick = (event, condition, attribute = false) => {
    const { id, value } = event.target;
    if (event.key === 'Enter' && condition) {
      handleUpdate(id, value);
    }
  };

  const handleUpdate = (id, value) => {
    console.log(id, 'id');
    loadingAction(true);
    const data = {
      value
    };
    apiEdit(
      `${MULTICHANNEL_SETTING}${id}/`,
      data,
      'patch',
      ({ status, response }) => {
        if (status) {
          loadingAction(false); // to show loading
          fetchMultiChannelSettings();
        } else {
          loadingAction(false); // to hide input tag for fetching label
          if (
            response.response &&
            response.response.status &&
            response.response.status === 401
          ) {
            handleError(response.response);
          } else if (checkFunction(response)) {
            fetchMessage('redirectTrue');
          } else {
            fetchMessage(false, response.response, 'manualPublish');
          }
        }
      }
    );
  };

  const handleEditAttributesInputChange = ({ target }) => {
    const { id, value } = target;
    setEditAttribute({
      ...editAttributes,
      [id]: {
        ...editAttributes[id],
        editable: value,
        value
      }
    });
  };

  const handleEditAttributesInput = (id, value) => {
    setEditAttribute({
      ...editAttributes,
      [id]: {
        ...editAttributes[id],
        editable: value,
        value:
          (responseAttributes && responseAttributes[id]) ||
          typeof responseAttributes[id] === 'number'
            ? responseAttributes[id].value
            : ''
      }
    });
  };

  const attributesDetail =
    settingList.length > 0 &&
    settingList.map(each =>
      editAttributes && editAttributes[each.id] ? (
        <tr>
          <td>{formatName(each.name)}</td>
          <td>
            {editAttributes[each.id] &&
            editAttributes[each.id].editable ? (
              <div className="editPrice">
                <input
                  type={'number'}
                  id={[each.id]}
                  value={
                    editAttributes[each.id].value
                      ? editAttributes[each.id].value
                      : ''
                  }
                  onKeyPress={event => {
                    handleEnterClick(
                      event,
                      responseAttributes[each.id].value !==
                        editAttributes[each.id].value,
                      true
                    );
                  }}
                  onChange={handleEditAttributesInputChange}
                />
                <CloseIcon
                  alt="Close Edit"
                  onClick={() =>
                    handleEditAttributesInput(each.id, false)
                  }
                  className="on"
                />
              </div>
            ) : (
              <div className="editPrice">
                <b>{each.value}</b>
                <i>
                  <CreateIcon
                    onClick={() =>
                      handleEditAttributesInput(each.id, true)
                    }
                    title="Update Price"
                  />
                </i>
              </div>
            )}
          </td>
        </tr>
      ) : null
    );

  const fetchMultiChannelSettings = () => {
    setLoading(true);
    Axios(MULTICHANNEL_SETTING, {
      method: 'get',
      headers
    })
      .then(response => {
        const _editAttributes = response.data.results.reduce(
          (each, obj) => {
            return {
              ...each,
              [obj.id]: { value: obj.value, editable: false }
            };
          },
          {}
        );
        setSettingList(response.data.results);
        setEditAttribute(_editAttributes);
        setResponseAttribute(_editAttributes);
        setLoading(false);
      })
      .catch(error => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchMultiChannelSettings();
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  return (
    <>
      {sweetAlert}
      <Permission />
      <MenuList />
      <LoadingOverlay showOverlay={loading} />
      <div className="heading">
        <h1>MultiChannel Settings </h1>
      </div>
      <div className="content-warpper">
        <div className="content-body">
          <div className="mc-basicinfo">
            {settingList && settingList.length > 0 ? (
              <table>
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Value</th>
                  </tr>
                </thead>
                <tbody>{attributesDetail}</tbody>
              </table>
            ) : (
              <h4>No Setting</h4>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
