import React, { Component } from "react";
import Tabs from "components/CustomTabs/CustomTabs";
import TaskCreateBusinessRule from "components/Tasks/TaskCreateBusinessRule";

export default class CreateBusinessRuleTab extends Component {

  render() {

    const { handleClickForFunction, handleClickForProductField, handleClickForLookUpList } = this.props;


    return (
      <>
        <Tabs
          headerColor="info"
          tabs={[
            {
              tabName: "Product Fields",
              tabContent: <TaskCreateBusinessRule BusinessRuleProductField handleClickForProductField={handleClickForProductField} productField="Product field" />
            },
            {
              tabName: "Function",
              tabContent: <TaskCreateBusinessRule handleClickForFunction={handleClickForFunction} action="Function" function="function value" />
            },
            {
              tabName: "Lookup List",
              tabContent: <TaskCreateBusinessRule action="LookUpList" lookUpList="Look Up List" handleClickForLookUpList={handleClickForLookUpList} />
            }
          ]}
        />
      </>
    );
  }
}
