import React, { Component } from "react";
import { Link } from "react-router-dom";

export default class RecentOrders extends Component {
  render() {
    const { orders } = this.props;
    const ordersList =
      orders.length > 0 &&
      orders.map(eachOrder => {
        return (
          <tr>
            <td>
              <Link to={`/orders/${eachOrder.order.id}`}>
                {eachOrder.order.order_id}
              </Link>
            </td>
            <td>
              {eachOrder.order.order_status.toUpperCase()[0] +
                eachOrder.order.order_status.substr(
                  1,
                  eachOrder.order.order_status.length
                )}
            </td>
            <td className="text-center">{eachOrder.qty}</td>
            <td>{eachOrder.order.updated_on}</td>
          </tr>
        );
      });
    return (
      <div>
        {orders.length > 0 ? (
          <div className="header-static">
            <div className="table-header">
              <table>
                <thead>
                  <tr>
                    <th>Order ID</th>
                    <th>Status</th>
                    <th className="text-center">Qty</th>
                    <th>Updated On</th>
                  </tr>
                </thead>
                <tbody>{ordersList}</tbody>
              </table>
            </div>
            <div className="tableScroll">
              <table className="table-body">
                <thead>
                  <tr>
                    <th>Order ID</th>
                    <th>Status</th>
                    <th className="text-center">Qty</th>
                    <th>Updated On</th>
                  </tr>
                </thead>
                <tbody>{ordersList}</tbody>
              </table>
            </div>
          </div>
        ) : (
          <p className="noData">
            <span>
              <i className="fas fa-coins" />
              No Recent Orders For This Product
            </span>
          </p>
        )}
      </div>
    );
  }
}
