export const capitalizeString = (string) =>
  string.charAt(0).toUpperCase() + string.slice(1);

export const removeUnderScoreFromString = (string) => {
  const wordArray = string.split("_");
  const capitalizedWordArray = wordArray.map((each) => capitalizeString(each));

  return capitalizedWordArray.join(" ");
};

export const formatName = (string) => {
  const formattedString = string.includes("_")
    ? removeUnderScoreFromString(string)
    : capitalizeString(string);

  return formattedString;
};
