import React from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import tasksStyle from "assets/jss/material-dashboard-pro-react/components/tasksStyle.jsx";
import BusinessRuleProductField from "./BusinessRuleProductFields";
import BusinessRuleFunction from "./BusinessRuleFunction";
import BusinessRuleLookUpList from "./BusinessRuleLookUpList";

class TaskCreateBusinessRule extends React.Component {
    
    render() {
        const {handleClickForProductField, handleClickForFunction, handleClickForLookUpList} = this.props;
        return (
            <>
                {this.props.BusinessRuleProductField ? (
                    <BusinessRuleProductField productField={this.props.productField} handleClickForProductField={handleClickForProductField} />
                )
                    :
                    this.props.action === "Function" ? (
                        <BusinessRuleFunction function={this.props.function} handleClickForFunction={handleClickForFunction} />
                    )
                        : this.props.action === "LookUpList" ? (
                            <BusinessRuleLookUpList function={this.props.lookUpList} handleClickForLookUpList={handleClickForLookUpList}/>
                        ) : null
                }
            </>
        );
    }
}

TaskCreateBusinessRule.propTypes = {
    orders: PropTypes.array,
    productHistory: PropTypes.array,
    mpn: PropTypes.string
};

export default withStyles(tasksStyle)(TaskCreateBusinessRule);
