import React from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import { Link } from "react-router-dom";

import tasksStyle from "assets/jss/material-dashboard-pro-react/components/tasksStyle.jsx";

class Tasks extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      priceHist: []
    };
  }
  componentDidMount() {
    this.props.priceHistory.forEach(each => {
      if (
        each.updated_price !== null &&
        each.updated_price !== each.previous_price
      ) {
        this.state.priceHist.push(each);
        this.setState({
          priceHist: this.state.priceHist
        });
      }
    });
  }
  render() {
    const { priceHist } = this.state;

    const ordersList =
      priceHist.length > 0 &&
      priceHist.map(eachHistory => {
        return (
          <tr>
            <td>
              {eachHistory.order ? (
                <Link to={`/orders/${eachHistory.order}`}>
                  {eachHistory.order_id}
                </Link>
              ) : (
                "N/A"
              )}
            </td>
            <td className="text-center">
              {eachHistory.previous_price === null
                ? "N/A"
                : eachHistory.previous_price}
            </td>
            <td className="text-center">
              {eachHistory.updated_price === null
                ? "N/A"
                : eachHistory.updated_price}
            </td>
            <td>{eachHistory.updated_by}</td>
            <td>{eachHistory.created_on}</td>
          </tr>
        );
      });
    return (
      <>
        {priceHist.length > 0 ? (
          <div className="header-static">
            <div className="table-header">
              <table>
                <thead>
                  <tr>
                    <th>Order ID</th>
                    <th className="text-center">Previous Price</th>
                    <th className="text-center">Updated Price</th>
                    <th>Updated By</th>
                    <th>Created On</th>
                  </tr>
                </thead>
                <tbody>{ordersList}</tbody>
              </table>
            </div>
            <div className="tableScroll">
              <table className="table-body">
                <thead>
                  <tr>
                    <th>Order ID</th>
                    <th className="text-center">Previous Price</th>
                    <th className="text-center">Updated Price</th>
                    <th>Updated By</th>
                    <th>Created On</th>
                  </tr>
                </thead>
                <tbody>{ordersList}</tbody>
              </table>
            </div>
          </div>
        ) : (
          <p className="noData">
            <span>
              <i className="fas fa-coins" />
              No Price History For This Product
            </span>
          </p>
        )}
      </>
    );
  }
}

Tasks.propTypes = {
  orders: PropTypes.array
};

export default withStyles(tasksStyle)(Tasks);
