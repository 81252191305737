import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';

const Auth0validation = () => {
  const { isAuthenticated, isLoading, user } = useAuth0();

  let url = new URL(document.URL);
  let code = url.searchParams.get('code');

  if (isLoading) {
    return <div>Loading ...</div>;
  }

  return (
    isAuthenticated && (
      <>
        <h1 className="text-center mt-5">
          <div>
            {code ? 'UNDER CONSTRUCTION -' : ''} {code}
            <img src={user.picture} alt={user.name} />
            <h2>{user.name}</h2>
            <p>{user.email}</p>
          </div>
        </h1>
      </>
    )
  );
};

export default Auth0validation;
