import React from "react";
import { REDIRECT_TO_LOGIN } from "../configurations/configRoutes.jsx";

class Permission extends React.Component {
  componentDidMount() {
    if (localStorage.getItem("token") === null)
      window.location = REDIRECT_TO_LOGIN;
  }

  render() {
    return null;
  }
}

export default Permission;
