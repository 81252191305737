import React, { useState, useEffect } from "react";
import { BUSINESS_RULE_PRODUCTION_FIELD } from "../../configurations/configApi";
import { fetchUrl } from "../../apiActions/action";
import { makeStyles } from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import errorRedirect from "views/FunctionalComponents/ErrorRedirect";

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',

    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
}));


function TemplateProductFieldValue({ allCellData, handleDoneValueForProductFeild }) {

    const classes = useStyles();

    const [loading, setLoading] = useState(false)

    const [allProductionField, setAllProductionField] = useState([])

    const [allTemplateProductValue, setAllProductTemplateValue] = useState(allCellData && allCellData.mapping_details && allCellData.mapping_details.name === "field" ? allCellData.mapping_details.value: "");

    const [productFeildInputValue, setProductFeildInputValue] = useState("");


    const fetchProductAllField = () => {
        setLoading(true)
        fetchUrl(BUSINESS_RULE_PRODUCTION_FIELD, getVal => {
            if (getVal.status) {
                setAllProductionField(getVal.response.data)
                setLoading(false)
            } else {
                if (getVal.response.response &&
                    getVal.response.response.status &&
                    ((getVal.response.response.status === 401))

                ) {
                    localStorage.removeItem("token");
                    window.location = "/auth/login-page";
                }
                else errorRedirect(getVal.response)
            }
        });
    }

    useEffect(() => {
        fetchProductAllField()
    }, [])


    const fetchSerachFunctionData = (data) => {
        if (data.length >= 2) {
            setLoading(true)
            fetchUrl(`${BUSINESS_RULE_PRODUCTION_FIELD}?query=${data}`, ({ status, response }) => {
                if (status) {
                    setAllProductionField(response.data)
                    setLoading(false)
                }
                else {
                    if (response.response &&
                        response.response.status &&
                        ((response.response.status === 401))

                    ) {
                        localStorage.removeItem("token");
                        window.location = "/auth/login-page";
                    }
                    else errorRedirect(response)
                }
            })
        }
    }

    const handleProductValueChange = (e, value) => {
        setAllProductTemplateValue(value)
    }


    const handleInputProductFieldValueChange = (e) => {
        setProductFeildInputValue(e.target.value)
    }


    // call fecth sku function
    useEffect(() => {
        fetchSerachFunctionData(productFeildInputValue)
    }, [productFeildInputValue])


    const HanldeDoneClick = () => {
        handleDoneValueForProductFeild(allTemplateProductValue)
        fetchProductAllField()
    }

    const handleCancel = () => {
        setProductFeildInputValue("")
        allTemplateProductValue("")
    }


    return (
        <div>
            <div>
                {/* <input type="search" placeholder="Search.." onChange={(e) => handleSearchProductField(e)} /> */}
                <Autocomplete
                    freeSolo
                    id="free-solo-2-demo"
                    Clearable
                    options={allProductionField && allProductionField.map((option) => option.name)}
                    style={{ width: "100%", height: 30 }}
                    value={allTemplateProductValue}
                    onChange={(event, value) => handleProductValueChange(event, value)}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            variant="outlined"
                            value={productFeildInputValue}
                            onChange={handleInputProductFieldValueChange}
                            InputProps={{
                                ...params.InputProps, type: 'search',
                                endAdornment: (
                                    <React.Fragment>
                                        {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                        {params.InputProps.endAdornment}
                                    </React.Fragment>
                                ),
                            }}
                        />
                    )}
                />
            </div>
            {/* <div>
            <input type="checkbox" id="vehicle1" name="vehicle1" value="Bike" />
            <label for="vehicle1"> If this field is blank, then use another value.</label><br />
            <input type="checkbox" id="vehicle1" name="vehicle1" value="Bike" />
             <label for="vehicle1"> If this field is too long, then use another value.</label>
             </div> */}
            <div>
                <button type="button" onClick={() => HanldeDoneClick()} > Done </button>
                <button type="button" onClick={() => handleCancel()}> Cancel </button>
            </div>
        </div>
    )
}

export default TemplateProductFieldValue