import React, { useEffect, useState } from 'react';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import TemplateTab from "../views/FunctionalComponents/TemplateTab"
import { TEMPLATE_MARKETPLACE_FEILD_MAPPING, TEMPLATE_API } from "../configurations/configApi";
import { apiEdit, fetchUrl } from "../apiActions/action";
import LoadingOverlay from "../components/overlays/LoadingOverlay";
import errorRedirect from "views/FunctionalComponents/ErrorRedirect";

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
    textAlign: "center",
    marginBottom: 20,

  }
}));


const DetailCellRenderer = ({ data, params, api, node}) => {
  const classes = useStyles();
  const [allData, setAllData] = useState();
  const [loading, setLoading] = useState(false);

  const marketplaceId = localStorage.getItem("marketplaceId") ? localStorage.getItem("marketplaceId") : ""  ;
  const skuValue = localStorage.getItem("skuPreviewProduct") ? localStorage.getItem("skuPreviewProduct") : "";

  const fetchAllTemplate = () => {
    setLoading(true);
    var soniServe = `${TEMPLATE_API}${marketplaceId}/?sku=${skuValue}&filter=${"[]"}`;
    fetchUrl(soniServe, ({ status, response }) => {
      if (status) {
        let refreshValue = response.data.response[0].mappings.filter(item => data.id === item.id);
        localStorage.setItem("cellRenderId", refreshValue && refreshValue[0]&& refreshValue[0].id )
        setAllData(refreshValue && refreshValue[0])
        setLoading(false)
      } else {
        if (response.response &&
          response.response.status &&
          ((response.response.status === 401))
        ) {
          localStorage.removeItem("token");
          localStorage.removeItem("email");
          window.location = "/auth/login-page";
        }
      }
    });
  }

  useEffect(() => {
    setAllData(data)
  }, data)


// update text value
  const handleDoneValue = (textValue) => {
    const productId = allData && allData.id;
    let value = textValue
    // updating the existing labels
    const urlForSave = `${TEMPLATE_MARKETPLACE_FEILD_MAPPING}${productId}/`;
    const methodOfSave = "put";
    const data = { "mappings": { "text_value": value } }
    apiEdit(urlForSave, data, methodOfSave, ({ status, response }) => {
      if (status) {
        window.location = `/configuration/details/${marketplaceId}`
        fetchAllTemplate();
        // inputMethod === "saveAs" && this.fetchProductDisplay("saveAs");
      } else {
        if (response.response &&
          response.response.status &&
          ((response.response.status === 401))

        ) {
          localStorage.removeItem("token");
          localStorage.removeItem("email");
          window.location = "/auth/login-page";
        }
        else errorRedirect(response)
      }
    });
  }

  // update product feild

  const handleDoneValueForProductFeild = (feild) => {
    const productId = allData && allData.id;
    let value = feild
    // updating the existing labels
    const urlForSave = `${TEMPLATE_MARKETPLACE_FEILD_MAPPING}${productId}/`;
    const methodOfSave = "put";
    const data = { "mappings": { "field": value } }
    apiEdit(urlForSave, data, methodOfSave, ({ status, response }) => {
      if (status) {
        window.location = `/configuration/details/${marketplaceId}`;
        fetchAllTemplate();
      } else {
        if (response.response &&
          response.response.status &&
          ((response.response.status === 401))

        ) {
          localStorage.removeItem("token");
          localStorage.removeItem("email");
          window.location = "/auth/login-page";
        }
        else errorRedirect(response)
      }
    });
  }

  // LookUp list
  const handleDoneLookUpList = (lookuplist) => {
    const productId = allData && allData.id;
    let value = lookuplist && lookuplist[0] && lookuplist[0].id
    console.log("lokuplits", value)
    // updating the existing labels
    const urlForSave = `${TEMPLATE_MARKETPLACE_FEILD_MAPPING}${productId}/`;
    const methodOfSave = "put";
    const data = { "mappings": { "lookup_list": value } }
    apiEdit(urlForSave, data, methodOfSave, ({ status, response }) => {
      if (status) {
        // window.location = `/configuration/details/${marketplaceId}`;
        fetchAllTemplate();
      } else {
        if (response.response &&
          response.response.status &&
          ((response.response.status === 401))
        ) {
          localStorage.removeItem("token");
          localStorage.removeItem("email");
          window.location = "/auth/login-page";
        }
        else errorRedirect(response)
      }
    });
  }

  // update business rule result
  const handleDoneValueForBusinessRuleResult = (BusinessRule) => {
    const productId = allData && allData.id;
    let value = BusinessRule
    // updating the existing labels
    const urlForSave = `${TEMPLATE_MARKETPLACE_FEILD_MAPPING}${productId}/`;
    const methodOfSave = "put";
    const data = { "mappings": { "business_rule": value } }


    apiEdit(urlForSave, data, methodOfSave, ({ status, response }) => {
      if (status) {
        window.location = `/configuration/details/${marketplaceId}`;
        fetchAllTemplate();
      } else {
        if (response.response &&
          response.response.status &&
          ((response.response.status === 401))
        ) {
          localStorage.removeItem("token");
          localStorage.removeItem("email");
          window.location = "/auth/login-page";
        }
        else errorRedirect(response)
      }
    });
  }

  let urlCheck =  allData&&allData.value&&allData.value[0].split(":")
  // console.log("url", urlCheck && urlCheck[0])

  return (
    <div>
      <div className={classes.root}>
        <Grid container style={{ paddingTop: "10px" }} className="rowDrop">
          <Grid item >
            {allData && allData.field ? allData.field : null}
          </Grid>
          <Grid item >
            { allData && allData.mapping_field ? allData.mapping_field : null}
          </Grid>
          <Grid item >
          {urlCheck && urlCheck[0] === "https" ||  urlCheck && urlCheck[0] === "http"? <a href={allData && allData.value} title={allData && allData.value} target="_blank">{allData && allData.value}</a>  : allData && allData.value ? allData.value : null}
            {/* { data && data.value ? data.value : null} */}
          </Grid>
          <Grid item  >
            {allData && allData.status}
          </Grid>
        </Grid>
      </div>
      <div className={classes.root}>
        <Grid container spacing={1}>
          <Grid item xs={4} justify="flex-end" style={{ display: "flex" }}>
            Description
          </Grid>
          <Grid item xs={8} >
            <Paper className="templateBox">
              <span style={{ marginBottom: "20px" }}>What value should we send for each product's "{data.field}"? </span>
              <TemplateTab allCellData={data} handleDoneValue={handleDoneValue}
                handleDoneValueForProductFeild={handleDoneValueForProductFeild}
                handleDoneValueForBusinessRuleResult={handleDoneValueForBusinessRuleResult}
                handleDoneLookUpList={handleDoneLookUpList}
                 />
            </Paper>
          </Grid>
        </Grid>
      </div>
      <LoadingOverlay showOverlay={loading} />
    </div>
  );
};

export default DetailCellRenderer;