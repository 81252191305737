import React from "react";
import { Link } from "react-router-dom";

// Function to split the date and time
const splitTime = (time, index) => {
  var splitted = time.split("|");
  return splitted[index];
};

// component which returns Invoice Details
export const Invoice = (props) => {
  const { allResponse } = props;

  const productDetails =
    allResponse.length > 0 &&
    allResponse[0].items.map((each) => {
      return (
        <tr>
          <td>
            <div className="orderImage">
              {each.image && <img src={each.image} alt="" />}{" "}
              <i className="fas fa-image" />{" "}
            </div>
          </td>
          <td>{each.title}</td>
          <td>
            <Link to={`/catalogs/${each.product_id}`}>{each.sku}</Link>
          </td>
          <td>{each.qty}</td>
          <td>
            {Number(each.published_product_margin)
              ? `${each.published_product_margin} %`
              : each.published_product_margin}{" "}
          </td>
          <td>{each.cost}</td>
        </tr>
      );
    });

  return (
    <div className="voJson">
      <table className="OrderItems">
        <thead>
          <tr>
            <th />
            <th>Title</th>
            <th>SKU</th>
            <th>Qty</th>
            <th>Margin</th>
            <th>Cost</th>
          </tr>
        </thead>
        <tbody>
          {productDetails}
          <tr className="grandTotal">
            <td> </td>
            <td> </td>
            <td> </td>
            <td> </td>
            <td>Amount:</td>

            <td>
              {Number(allResponse[0].amount)
                ? allResponse[0].amount
                : allResponse[0].platform_amount}{" "}
            </td>
          </tr>
          <tr className="grandTotal">
            <td> </td>
            <td> </td>
            <td> </td>
            <td> </td>
            <td>Shipping Fee: </td>
            <td>
              {Number(allResponse[0].amount)
                ? allResponse[0].shipping_fee
                : allResponse[0].platform_shipping_fee}
            </td>
          </tr>
          <tr className="grandTotal">
            <td> </td>
            <td> </td>
            <td> </td>
            <td> </td>
            <td>Grand Total: </td>
            <td>
              {Number(allResponse[0].amount)
                ? Number(allResponse[0].amount) +
                  Number(allResponse[0].shipping_fee)
                : Number(allResponse[0].platform_amount) +
                  Number(allResponse[0].platform_shipping_fee)}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

// component which returns Shipping To Address Details
export const ShippingInfo = (props) => {
  const { allResponse } = props;
  // console.log(allResponse,'alll')
  return (
    <>
      {allResponse[0].ship_to_info ? (
        <>
          <h4>Shipping</h4>
          <p>
            <span>Name:</span>{" "}
            <b>
              {allResponse[0].ship_to_info.firstName +
                "  " +
                allResponse[0].ship_to_info.lastName}{" "}
            </b>
          </p>
          <p>
            <span>Company Name:</span>{" "}
            <b>
              {allResponse[0].ship_to_info.companyName ||
                allResponse[0].ship_to_info.company_name}
            </b>
          </p>
          <p>
            <span> City:</span>{" "}
            <b>
              {allResponse[0].ship_to_info.city
                ? allResponse[0].ship_to_info.city
                : ""}
            </b>
          </p>
          <p>
            <span> Country:</span>{" "}
            <b>
              {allResponse[0].ship_to_info.country
                ? allResponse[0].ship_to_info.country
                : ""}
            </b>
          </p>
          <p>
            <span>Address:</span>{" "}
            <b>
              {allResponse[0].ship_to_info.addressLine1 ||
                allResponse[0].ship_to_info.address_line1}{" "}
              {allResponse[0].ship_to_info.addressLine2 ||
                allResponse[0].ship_to_info.address_line2}{" "}
              {/* {allResponse[0].ship_to_info.postalCode ? allResponse[0].ship_to_info.postalCode : ""} */}
            </b>
          </p>
          <p>
            <span>Email:</span>{" "}
            <b>
              {allResponse[0].ship_to_info.emailAddress ||
                allResponse[0].ship_to_info.email_address}
            </b>
          </p>
          <p>
            <span>Day Time Phone:</span>{" "}
            <b>
              {allResponse[0].ship_to_info.daytimePhone ||
                allResponse[0].ship_to_info.daytime_phone}
            </b>
          </p>
          <p>
            <span>Evening Time Phone:</span>{" "}
            <b>
              {allResponse[0].ship_to_info.eveningPhone ||
                allResponse[0].ship_to_info.evening_phone}
            </b>
          </p>
        </>
      ) : (
        "No Shipping and Billing Info Available"
      )}
    </>
  );
};

// component which returns Shipstationstore list =
export const ShipstationStoreList = (props) => {
  const { shipstationStore } = props;
  // console.log(shipstationStore,'shipstationStore')
  return (
    <>
      {shipstationStore[0] ? (
        shipstationStore[0].map((itm) => (
          <div className="shipstationsRow">
            <p>
              <span>Name:</span> {itm.storeName}
            </p>
            <p>
              <span>Marketplace Name:</span> {itm.marketplaceName}
            </p>
            <p>
              <span>Company Name:</span> {itm.companyName}
            </p>
            <p>
              <span>Store Id:</span> {itm.storeId}
            </p>
            <p>
              <span>Active:</span> {itm.active ? "True" : "False"}
            </p>
            <p>
              <span>CreatedAt:</span> {itm.createDate}
            </p>
            <p>
              <span>ModifiedAt:</span> {itm.modifyDate}
            </p>
            <p>
              <span>RefreshDate:</span> {itm.refreshDate}
            </p>
          </div>
        ))
      ) : (
        <h4>No stores</h4>
      )}
    </>
  );
};

// component which returns Shipstationstore list =
export const ShipstationOrderList = (props) => {
  const { shipstationOrders } = props;
  // console.log(shipstationOrders,'shiporders')
  return (
    <>
      <div className="shipstation-orders">
        <table>
          <tr>
            <th>orderId</th>

            <th>customerEmail</th>
            <th>amountPaid</th>
            <th>phone</th>
            <th>​orderStatus</th>

            <th>order Date</th>
            <th>modify Date</th>
          </tr>
          {shipstationOrders ? (
            shipstationOrders.map((itm) => (
              <tr>
                <td>{itm.id}</td>

                <td>{itm.customer_email}</td>
                <td>{itm.amount_paid}</td>
                <td>{itm.bill_to.phone}</td>
                <td>{itm.order_status}</td>

                <td>{itm.order_date}</td>
                <td>{itm.modify_date}</td>
              </tr>
            ))
          ) : (
            <h1>No orders</h1>
          )}
        </table>
      </div>
    </>
  );
};

// component which returns Billing Details
export const BillingInfo = (props) => {
  const { allResponse } = props;
  return (
    <>
      {allResponse[0].ship_to_bill_info ? (
        <>
          <h4>Billing</h4>
          <p>
            <span>Name:</span>{" "}
            <b>
              {allResponse[0].ship_to_bill_info.firstName +
                "  " +
                allResponse[0].ship_to_bill_info.lastName}
            </b>
          </p>
          <p>
            <span>Company Name:</span>{" "}
            <b>
              {allResponse[0].ship_to_bill_info.companyName ||
                allResponse[0].ship_to_bill_info.company_name}
            </b>
          </p>
          <p>
            <span> City:</span>{" "}
            <b>
              {allResponse[0].ship_to_bill_info.city
                ? allResponse[0].ship_to_bill_info.city
                : ""}
            </b>
          </p>
          <p>
            <span> Country:</span>{" "}
            <b>
              {allResponse[0].ship_to_bill_info.country
                ? allResponse[0].ship_to_bill_info.country
                : ""}
            </b>
          </p>
          <p>
            <span>Address:</span>{" "}
            <b>
              {allResponse[0].ship_to_bill_info.addressLine1 ||
                allResponse[0].ship_to_bill_info.address_line1}{" "}
              {allResponse[0].ship_to_bill_info.addressLine2 ||
                allResponse[0].ship_to_bill_info.address_line2}
            </b>
          </p>
          <p>
            <span>Email:</span>{" "}
            <b>
              {allResponse[0].ship_to_bill_info.emailAddress ||
                allResponse[0].ship_to_bill_info.email_address}
            </b>
          </p>
          <p>
            <span>Day Time Phone:</span>{" "}
            <b>
              {allResponse[0].ship_to_bill_info.daytimePhone ||
                allResponse[0].ship_to_bill_info.daytime_phone}
            </b>
          </p>
          <p>
            <span>Evening Time Phone:</span>{" "}
            <b>
              {allResponse[0].ship_to_bill_info.eveningPhone ||
                allResponse[0].ship_to_bill_info.evening_phone}
            </b>
          </p>
        </>
      ) : (
        ""
      )}
    </>
  );
};

// component which returns Shipment Details
export const ShipmentDetails = (props) => {
  const { allResponse } = props;

  return (
    <>
      {allResponse[0].shipping_items.length > 0 && (
        <>
          <div className="shipping">
            <h4>Shipment Details</h4>
            <div>
              <p>
                <span>Carrier Code:</span>{" "}
                <b>{allResponse[0].shipping_items[0].carrier_code}</b>
              </p>
              <p>
                <span>Tracking Number:</span>{" "}
                <b>{allResponse[0].shipping_items[0].tracking_no}</b>
              </p>
              <p>
                <span>Master Tracking Number:</span>{" "}
                <b>{allResponse[0].shipping_items[0].master_tracking_no}</b>
              </p>
              <p>
                <span>Reference Number:</span>{" "}
                <b>{allResponse[0].shipping_items[0].reference_no}</b>
              </p>
              <p>
                <span>Shipped On:</span>{" "}
                <b>
                  {splitTime(allResponse[0].shipping_items[0].ship_time, 0)}{" "}
                  {splitTime(allResponse[0].shipping_items[0].ship_time, 1)}
                </b>
              </p>
            </div>
          </div>
          <div className="billing">
            <h4>Shipped From:</h4>{" "}
            <p>
              <span>City:</span>{" "}
              <b>{allResponse[0].shipping_items[0].ship_from_info.city}</b>
            </p>
            <p>
              <span>Country:</span>{" "}
              <b>{allResponse[0].shipping_items[0].ship_from_info.country}</b>
            </p>
            <p>
              <span>Address:</span>{" "}
              <b>
                {allResponse[0].shipping_items[0].ship_from_info.addressLine1 ||
                  allResponse[0].shipping_items[0].ship_from_info
                    .address_line1}{" "}
                {allResponse[0].shipping_items[0].ship_from_info.addressLine2 ||
                  allResponse[0].shipping_items[0].ship_from_info.address_line2}
              </b>
            </p>
            <p>
              <span>Email:</span>{" "}
              <b>
                {allResponse[0].shipping_items[0].ship_from_info.emailAddress ||
                  allResponse[0].shipping_items[0].ship_from_info.email_address}
              </b>
            </p>
          </div>
        </>
      )}
    </>
  );
};

// component which returns Order History Tracking
export const OrderHistory = (props) => {
  const { allResponse } = props;

  const historyDetail =
    allResponse.length > 0 &&
    allResponse[0].order_history.length > 0 &&
    allResponse[0].order_history.map((eachHistory, index) => (
      <>
        <li
          className={`added ${
            allResponse[0].order_history.length === index + 1 &&
            (eachHistory.order_status.toLowerCase().startsWith("cancel") ||
              eachHistory.order_status.toLowerCase().startsWith("complete"))
              ? ""
              : `last`
          }`}
        >
          <div>
            <p title="Actual Time">
              {splitTime(eachHistory.order_received_time, 0)}
            </p>
            <span>{splitTime(eachHistory.order_received_time, 1)}</span>
          </div>
          <div>
            <p>
              {eachHistory.order_status.toUpperCase()[0] +
                eachHistory.order_status.substr(
                  1,
                  eachHistory.order_status.length
                )}
            </p>
            {index === 0 && (
              <p>
                Received :{" "}
                <span>
                  {splitTime(eachHistory.order_placed_time, 0)}{" "}
                  {splitTime(eachHistory.order_placed_time, 1)}
                </span>
              </p>
            )}
          </div>
        </li>
      </>
    ));

  return (
    <div className="voJsons">
      {allResponse[0].order_history.length > 0 ? (
        <ul className="timeline">{historyDetail}</ul>
      ) : (
        <p className="noData">
          <span>
            <i className="fas fa-coins" />
            No History to Show
          </span>
        </p>
      )}
    </div>
  );
};

// component which returns Shipping To Address Details
export const WeyBillsInfo = (props) => {
  const { allResponse } = props;
  return (
    <>
      {allResponse[0].waybill && allResponse[0].waybill[0] ? (
        <>
          <h4>WayBills</h4>
          <p>
            <span>Tracking Number:</span>{" "}
            <b>{allResponse[0].waybill[0].trackingNo}</b>
          </p>
          <p>
            <span>Ship label value:</span>{" "}
            <a
              style={{ color: "#02b2e6" }}
              href={
                allResponse && allResponse[0]
                  ? allResponse[0].waybill[0].shipLabelValue
                  : ""
              }
              target="_blank"
            >
              Ship Label Value
            </a>
          </p>
        </>
      ) : (
        ""
      )}
    </>
  );
};
