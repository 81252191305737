import React, { useState, useEffect } from "react";
import { BUSINESS_RULE_PRODUCTION_FIELD } from "../../configurations/configApi";
import { fetchUrl } from "../../apiActions/action";
import { makeStyles } from '@material-ui/core/styles';
import { Accordion, AccordionSummary, AccordionDetails, Typography } from '@material-ui/core/';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CircularProgress from '@material-ui/core/CircularProgress';
import { handleError } from "views/FunctionalComponents/ErrorHandle";
import errorRedirect from "views/FunctionalComponents/ErrorRedirect";

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',

  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));


function BusinessRuleProductField(props) {

  const classes = useStyles();

  const [loading, setLoading] = useState(false)

  const [allProductionField, setAllProductionField] = useState([])

  const [productValue, setProductValue] = useState("")


  const fetchProductAllField = () => {
    setLoading(true)
    fetchUrl(BUSINESS_RULE_PRODUCTION_FIELD, getVal => {
      if (getVal.status) {
        setAllProductionField(getVal.response.data)
        setLoading(false)
      } else {
        if (getVal.response.response &&
          getVal.response.response.status &&
          ((getVal.response.response.status === 401))

        ) {
          handleError(getVal.response.response);
        }
        else errorRedirect(getVal.response)
      }
    });
  }

  useEffect(() => {
    fetchProductAllField()
  }, [])

  const handleClickForProductField = (e) => {
    let data = allProductionField && allProductionField.filter(item => Number(item.id) === Number(e.target.id))
    props.handleClickForProductField(data, props.productField)
  }

  const option = allProductionField && allProductionField.map(item => {
    return (
      <div>
        <div className={classes.root}>
          <Accordion id={item.id}>
            <Typography onDoubleClick={(e) => handleClickForProductField(e)} id={item.id} className={classes.heading}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id={item.id}
              >
              </AccordionSummary>
              {item.name}({item.formatted_name})
            </Typography>
            <AccordionDetails>
              <Typography style={{ fontSize: "10px" }}>
                {item.formatted_name}
              </Typography>
            </AccordionDetails>
          </Accordion>
        </div>
      </div>
    )
  }
  )

  const fetchSerachFunctionData = (data) => {
    setLoading(true)
    if (data.length >= 2) {
      fetchUrl(`${BUSINESS_RULE_PRODUCTION_FIELD}?query=${data}`, ({ status, response }) => {
        if (status) {
          setAllProductionField(response.data)
          setLoading(false)
        }
        else {
          if (response.response &&
            response.response.status &&
            ((response.response.status === 401))

          ) {
            handleError(response.response);
          }
        }
      })
    }
  }

  const handleSearchProductField = (e) => {
    if (e.target.value.length === 0) {
      fetchProductAllField()
    }
    else {
      fetchSerachFunctionData(e.target.value)
    }
  }

  return (
    <div>
      <div>
        <input type="search" placeholder="Search.." onChange={(e) => handleSearchProductField(e)} />
      </div>
      {
        loading ? <CircularProgress color="inherit" size={20} /> :
          <div style={{ maxHeight: "200px", overflowY: "auto" }}>
            {allProductionField && allProductionField.length > 0 ? option : "No data availble"}
          </div>
      }
    </div>
  )
}

export default BusinessRuleProductField