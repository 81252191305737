import React, { useState, useEffect } from "react";
import { BUSINESS_RULE_API } from "../../configurations/configApi";
import { fetchUrl, apiEdit } from "../../apiActions/action";
import { makeStyles } from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import TemplateModal from "../../Modal/Modal";
import LoadingOverlay from "../../components/overlays/LoadingOverlay";
import errorRedirect from "views/FunctionalComponents/ErrorRedirect";

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',

    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
}));


function TemplateBusinessRuleResult({ allCellData, handleDoneValueForBusinessRuleResult }) {

    const classes = useStyles();

    const [loading, setLoading] = useState(false)

    const [allBusinessRuleResult, setAllBusinessRuleResult] = useState([])

    const [businessRuleResult, setBusinessRuleResult] = useState(allCellData && allCellData.mapping_details && allCellData.mapping_details.name == "business_rule" ? allCellData.mapping_details.value: "");

    const [businessInputValue, setBusinessInputValue] = useState("");

    const [templateBusinessRule, setTemplateBusinessRule] = useState("");

    const [stringValue, setStringValue] = useState("");

    const [open, setOpen] = React.useState(false);


    const fetchProductAllField = () => {
        setLoading(true)
        fetchUrl(BUSINESS_RULE_API, getVal => {
            if (getVal.status) {
                setAllBusinessRuleResult(getVal.response.data.results)
                setLoading(false)
            } else {
                if (getVal.response.response &&
                    getVal.response.response.status &&
                    ((getVal.response.response.status === 401))

                ) {
                    localStorage.removeItem("token");
                    window.location = "/auth/login-page";
                }
                else errorRedirect(getVal.response)
            }
        });
    }

    useEffect(() => {
        fetchProductAllField()
    }, [])


    const BusinessRuled = allBusinessRuleResult && allBusinessRuleResult.filter(item => businessRuleResult === item.name)


    const fetchSerachBusinessResultData = (data) => {

        if (data.length >= 2) {
            setLoading(true)
            fetchUrl(`${BUSINESS_RULE_API}?query=${data}`, ({ status, response }) => {
                if (status) {
                    setLoading(false)
                    setAllBusinessRuleResult(response.data.results)

                }
                else {
                    if (response.response &&
                        response.response.status &&
                        ((response.response.status === 401))

                    ) {
                        localStorage.removeItem("token");
                        window.location = "/auth/login-page";
                    }
                    else errorRedirect(response)
                }
            })
        }
    }

    const handleBusinessRuleResultChange = (e, value) => {
        setBusinessRuleResult(value)
    }

    const handleInputBusinessRuleResultChange = (e) => {
        setBusinessInputValue(e.target.value)
    }


    // call fecth sku function
    useEffect(() => {
        fetchSerachBusinessResultData(businessInputValue)
    }, [businessInputValue])


    const HanldeDoneClick = () => {
        handleDoneValueForBusinessRuleResult(BusinessRuled && BusinessRuled[0] && BusinessRuled[0].id)
    }

    const handleCancel = () => {
        setBusinessRuleResult("")
    }

    const HandleCreateBusinessRuleResult = () => {
        setTemplateBusinessRule("")
        setOpen(true)
        setStringValue("Create")
    }

    const handleClose = () => {
        setOpen(false);
        setTemplateBusinessRule("")
    }
    const HandleEditBusinessRuleResult = () => {
        let editValueForBusinessRule =
            allBusinessRuleResult &&
            allBusinessRuleResult.filter(items => items.name === businessRuleResult);

        let data = editValueForBusinessRule && editValueForBusinessRule[0];
        setTemplateBusinessRule(data)
        setOpen(true)
        setStringValue("Edit")
    }

    // Api for Create and update function
    const createUpdateBusinessRuleListPage = (functionValue, name, description, marketplace, sku) => {
        let BusinessRuleId = templateBusinessRule && templateBusinessRule.id
        let urlForSave;
        let methodOfSave;
        let dataOfSave;
        dataOfSave = {
            // marketplace: marketplace,
            formula: functionValue,
            uploaded_by: localStorage.getItem("userId"),
            name: name,
            description: description,
            product_preview_sku: sku,
            marketplace: marketplace
        }
        if (stringValue === "Edit") {
            urlForSave = `${BUSINESS_RULE_API}${BusinessRuleId}/`
            methodOfSave = "put";
        } else {
            urlForSave = BUSINESS_RULE_API
            methodOfSave = "post";
        }
        if (functionValue && name && description) {
            apiEdit(urlForSave, dataOfSave, methodOfSave, ({ status, response }) => {
                if (status) {
                    //    console.log("create business rule", response)
                    setOpen(false)
                    if (stringValue === "Edit")
                      setBusinessRuleResult(name);
                    fetchProductAllField();
                } else {
                    setOpen(true)
                    if (response.response &&
                        response.response.status &&
                        ((response.response.status === 401))

                    ) {
                        localStorage.removeItem("token");
                        window.location = "/auth/login-page";
                    }
                    else errorRedirect(response)
                }
            });
        };
    }

    return (
        <>
            <div>
                <div>
                    {/* <input type="search" placeholder="Search.." onChange={(e) => handleSearchProductField(e)} /> */}
                    <Autocomplete
                        freeSolo
                        id="free-solo-2-demo"
                        Clearable
                        options={allBusinessRuleResult && allBusinessRuleResult.map((option) => option.name)}
                        style={{ width: "100%" }}
                        value={businessRuleResult}
                        onChange={(event, value) => handleBusinessRuleResultChange(event, value)}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="outlined"
                                value={businessInputValue}
                                onChange={handleInputBusinessRuleResultChange}
                                InputProps={{
                                    ...params.InputProps, type: 'search',
                                    endAdornment: (
                                        <React.Fragment>
                                            {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                            {params.InputProps.endAdornment}
                                        </React.Fragment>
                                    ),
                                }}
                            />
                        )}
                    />
                </div>
                <div>
                    <button type="button" onClick={() => HanldeDoneClick()} > Done </button>
                    <button type="button" onClick={() => handleCancel()}> Cancel </button>
                </div>
                <div
                    className="CERule"
                    onClick={() => HandleCreateBusinessRuleResult()}
                >Create Rule</div>
                {
                    businessRuleResult ? <div
                        className="CERule"
                        onClick={() => HandleEditBusinessRuleResult()}
                    > Edit Rule</div> : null
                }
            </div>
            {open ? <TemplateModal
                open={open}
                handleClose={handleClose}
                stringValue={stringValue}
                templateBusinessRule={templateBusinessRule}
                createUpdateBusinessRuleListPage={createUpdateBusinessRuleListPage}
            /> : null}
            <LoadingOverlay showOverlay={loading} />
        </>
    )
}

export default TemplateBusinessRuleResult;