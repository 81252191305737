import React, { Component } from "react";
import { Link } from "react-router-dom";

export default class viewRender extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: this.getCartValue()
    };
  }

  getCartValue() {
    return this.props.value;
  }

  render() {
    return this.props.data && this.props.data.id ? (
      <>
        {
          <Link to={`${window.location.pathname}/${this.props.data.id}`}>
            {this.state.value}{" "}
            {window.location.pathname === "/orders" && "View Detail"}
          </Link>
        }
      </>
    ) : (
      ""
    );
  }
}
