export const numberFields = [
  'multipack_quantity',
  'height',
  'length',
  'width',
  'weight',
  'cost',
  'margin',
  'retail_price',
  'starting_price',
  'reserve_price',
  'buy_it_now_price',
  'store_price',
  'second_chance_price',
  'min_price',
  'max_price',
  'total_available_quantity',
  'estimated_shipping_cost',
  'open_allocated_quantity',
  'open_allocated_quantity_pooled',
  'pending_checkout_quantity',
  'pending_checkout_quantity_pooled',
  'pending_payment_quantity',
  'pending_payment_quantity_pooled',
  'pending_shipment_quantity',
  'pending_shipment_quantity_pooled',
  'total_quantity',
  'total_quantity_pooled',
  'quantity_sold_last7_days',
  'quantity_sold_last14_days',
  'quantity_sold_last30_days',
  'quantity_sold_last60_days',
  'quantity_sold_last90_days',
  'channel_advisor_parent_id',
  'phoenix_quantity',
  'id',
  'channel_advisor_id'
];

export const textFields = [
  'sku',
  'brand',
  'title',
  'mpn',
  'block_comment',
  'asin',
  'ean',
  'description',
  'flag_description',
  'harmonized_code',
  'isbn',
  'manufacturer',
  'short_description',
  'subtitle',
  'tax_product_code',
  'upc',
  'warehouse_location',
  'warranty',
  'supplier_name',
  'supplier_code',
  'supplier_po',
  'classification',
  'store_title',
  'store_description',
  'bundle_type',
  'copy_to_children',
  'parent_sku',
  'relationship_name',
  'goat_sku'
];

export const setFields = [
  'is_published_to_voyageone',
  'product_type',
  'distribution_center',
  'marketplaces',
  'flag',
  'profile_data',
  'label_data',
  'condition',
  'is_available_in_store',
  'is_blocked',
  'is_external_quantity_blocked',
  'is_display_in_store',
  'is_parent',
  'is_in_relationship',
  'is_deleted',
  'marketplaces'
];

export const setProperFields = [
  'is_published_to_voyageone',
  'is_available_in_store',
  'is_blocked',
  'is_external_quantity_blocked',
  'is_display_in_store',
  'is_parent',
  'is_in_relationship',
  'is_deleted'
];

export const setFieldsOrder = ['order_status', 'marketplace'];
