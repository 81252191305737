import React, { Component } from "react";

import Tabs from "components/CustomTabs/CustomTabs";
import Tasks from "components/Tasks/Tasks";

export default class catalogTab extends Component {
  render() {
    const { id, orders, productHistory, mpn, isPublishToVo, isPublishToShopify } = this.props;


    return (
      <>
        <Tabs
          headerColor="info"
          tabs={[
            {
              tabName: "Recent Orders",
              tabContent: <Tasks recentOrders orders={orders} />
            },
            {
              tabName: "Stock History",
              tabContent: (
                <Tasks action="stockHistoryTab" stockHistory={productHistory} />
              )
            },
            {
              tabName: "Price History",
              tabContent: (
                <Tasks action="priceHistoryTab" priceHistory={productHistory} />
              )
            },
            {
              tabName: "Published/Unpublished History",
              tabContent: <Tasks action="pubUnpubHistoryTab" id={id} />
            },
            {
              tabName: "JSON",
              tabContent: <Tasks action="JsonTab" id={id} isPublishToVo={isPublishToVo} isPublishToShopify={isPublishToShopify} />
            }
          ]}
        />
      </>
    );
  }
}
