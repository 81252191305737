
import React from "react";
import SweetAlert from "react-bootstrap-sweetalert";

const BusinessRuleDelete = (props) => {

    let data = {
        product_ids: props.product_ids
    }
    const handleSubmit = () => {
        props.handleBusinessDelete(data);
    };

    return (
        <div>
            <SweetAlert
                style={{ display: "block", marginTop: "-150px" }}
                title="Delete Business Rule"
                onConfirm={() => handleSubmit()}
                onCancel={() => props.hideAlert()}
                confirmBtnText="Submit"
                cancelBtnText="Cancel"
                showCancel
            >
                <h6 className="mt-5 mb-5">Do You Want to Delete Business Rule?</h6>
            </SweetAlert>
        </div>
    )
}

export default BusinessRuleDelete