import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CreateBusinessRuleTab from "views/FunctionalComponents/CreateBusinessRuleTab";
import { AUTOCOMPLETE_SKU_BUSINESS_RULE, FELD_FORMATED_VIEW, BUSINESS_RULE_API } from "../configurations/configApi";
import { fetchUrl, apiPost } from "../apiActions/action";
import {TextField, CircularProgress, Modal, Backdrop, Fade } from '@material-ui/core/';
import Autocomplete from '@material-ui/lab/Autocomplete';
import RedoIcon from '@material-ui/icons/Redo';
import UndoIcon from '@material-ui/icons/Undo';


const marketplaceOptions = localStorage.getItem("marketplaces_list") ? localStorage.getItem("marketplaces_list") : [];

const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: '1px solid #eee',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        maxWidth : "1200px",
        position : "relative"
    },
}));


const TemplateModal = ({ open, handleClose, stringValue, templateBusinessRule, createUpdateBusinessRuleListPage }) => {

    const classes = useStyles();

    // const { data: passedItems, isClicked: clicked, handleClick: handleClicks } = props;

    const [skuAll, setSkuAll] = useState([]);

    const [isSkuInputValue, setIsSkuInputValue] = useState("");

    const [loading, setLoading] = useState(false);

    const [functionValue, setFunctionValue] = useState(templateBusinessRule && templateBusinessRule ? templateBusinessRule.formula : []);

    const [description, setDescription] = useState(templateBusinessRule && templateBusinessRule ? templateBusinessRule.description : "");

    const [loadingForFormatted, setLoadingForFormatted] = useState(false);

    const [sku, setSku] = useState(templateBusinessRule && templateBusinessRule ? templateBusinessRule.product_preview_sku : "")

    const [name, setName] = useState(templateBusinessRule && templateBusinessRule ? templateBusinessRule.name : "");

    const [marketplace, setMarketplace] = useState(templateBusinessRule && templateBusinessRule && templateBusinessRule.marketplace ? templateBusinessRule.marketplace.name : "");

    const [formattedValue, setFormatedValue] = useState("");

    const [cursorPos, setCursorPos] = useState(null);

    const [isOnclick, setIsOnClick] = useState(false);

    const [allBusinessValue, setAllBusinessValue] = useState();

    const [undoData, setUndoData] = useState([functionValue]);

    const [redoData, setRedoData] = useState([functionValue]);

    const [isClose, setIsClose] = useState(true);


    // fetch all recent sku
    const fetchRecentSku = () => {
        fetchUrl(AUTOCOMPLETE_SKU_BUSINESS_RULE, ({ status, response }) => {
            if (status) {
                setSkuAll(response.data.message)
            }
            else {
            if (response.response &&
                response.response.status &&
                ((response.response.status === 401))
              ) {
                localStorage.removeItem("token");
                localStorage.removeItem("email");
                window.location = "/auth/login-page";
              }
            }
        });
    }
    useEffect(() => {
        fetchRecentSku()
        fetchUrl(BUSINESS_RULE_API, ({ status, response }) => {
            if (status) {
                setAllBusinessValue(response.data.results)
            }
            else {
            if (response.response &&
                response.response.status &&
                ((response.response.status === 401))
              ) {
                localStorage.removeItem("token");
                localStorage.removeItem("email");
                window.location = "/auth/login-page";
              }
            }
        });
    }, [])


    const handleSkuChange = (e, value) => {
        if (value) {
            let values = value && value.split(":")[0]
            setSku(values)
        }
    }

    // fetch sku for preview
    const fetchSKus = (data) => {
        if (data) {
            fetchUrl(`${AUTOCOMPLETE_SKU_BUSINESS_RULE}?query=${data}`, ({ status, response }) => {
                if (status) {
                    setSkuAll(response.data.message)
                    setLoading(false)
                }
                else {
                if (response.response &&
                    response.response.status &&
                    ((response.response.status === 401))
                  ) {
                    localStorage.removeItem("token");
                    localStorage.removeItem("email");
                    window.location = "/auth/login-page";
                  }
                }
            })
        }
    }

    // handle change for search field sku for prevew
    const handleInputSkuChange = (e) => {
        if (e.target.value.length > 0) {
            setLoading(true)
        } else {
            setLoading(false)
            fetchRecentSku()
        }
        setIsSkuInputValue(e.target.value)
    }

    // call skuFecth api each input feild
    useEffect(() => {
        fetchSKus(isSkuInputValue)
    }, [isSkuInputValue])

    // handle change for set functiion value in text editor
    const handleClickForFunction = (data) => {
        let value = data && data[0].name
        handleOnafocus()
        if (isOnclick) {
            let firstHalfValue = functionValue.slice(0, cursorPos)
            let secHalfValue = functionValue.slice(cursorPos, functionValue.length)
            setFunctionValue([...firstHalfValue, value, ...secHalfValue].join(""));

            let uddata = [...undoData, functionValue + value];
            setUndoData(uddata);
            let rddata = [...redoData, functionValue + value];
            setRedoData(rddata);
        }
        else {
            setFunctionValue([...functionValue, value].join(""));
            let uddata = [...undoData, functionValue + value]
            setUndoData(uddata);
            let rddata = [...redoData, functionValue + value]
            setRedoData(rddata);
        }
        if (functionValue) {
            document.getElementById("text").style.borderColor = "blue";
        }
        if(name.length === 0){
            document.getElementById("name").style.borderColor = "#D10000";
        }
        if(description.length ===0){
            document.getElementById("description").style.borderColor = "#D10000";
        }
    }

    // handle change for set product field value in text editor
    const handleClickForProductField = (data, action) => {
        handleOnafocus();
        let value = data && data[0].formatted_name
        if (isOnclick) {
            let firstHalfValue = functionValue.slice(0, cursorPos)
            let secHalfValue = functionValue.slice(cursorPos, functionValue.length)
            setFunctionValue([...firstHalfValue, value, ...secHalfValue].join(""));
            let uddata = [...undoData, functionValue + value];
            setUndoData(uddata);
            let rddata = [...redoData, functionValue + value];
            setRedoData(rddata);
        }
        else {
            setFunctionValue([...functionValue, value].join(""));
            let uddata = [...undoData, functionValue + value]
            setUndoData(uddata)
            let rddata = [...redoData, functionValue + value]
            setRedoData(rddata)
        }
        if (functionValue) {
            document.getElementById("text").style.borderColor = "blue";
        }
        if(name.length === 0){
            document.getElementById("name").style.borderColor = "#D10000";
        }
        if(description.length ===0){
            document.getElementById("description").style.borderColor = "#D10000";
        }
    }

     // handle change for set LookUPlist value in text editor
     const handleClickForLookUpList = (data, action) => {
        handleOnafocus();
        let value = data && data[0].name
        if (isOnclick) {
            let firstHalfValue = functionValue.slice(0, cursorPos)
            let secHalfValue = functionValue.slice(cursorPos, functionValue.length)
            setFunctionValue([...firstHalfValue, value, ...secHalfValue].join(""));
            let uddata = [...undoData, functionValue + value];
            setUndoData(uddata);
            let rddata = [...redoData, functionValue + value];
            setRedoData(rddata);
        }
        else {
            setFunctionValue([...functionValue, value].join(""));
            let uddata = [...undoData, functionValue + value]
            setUndoData(uddata)
            let rddata = [...redoData, functionValue + value]
            setRedoData(rddata)
        }
        if (functionValue) {
            document.getElementById("text").style.borderColor = "blue";
        }
        if(name.length === 0){
            document.getElementById("name").style.borderColor = "#D10000";
        }
        if(description.length ===0){
            document.getElementById("description").style.borderColor = "#D10000";
        }
    }

    // function for call api to show the formatted value 
    const fetchPostformattedValue = () => {
        if (sku && marketplace) {
            setLoadingForFormatted(true)
            const data = {
                sku: sku,
                marketplace: marketplace,
                formula: functionValue
            };
            apiPost(FELD_FORMATED_VIEW, data, ({ status, response }) => {
                if (status) {
                    setFormatedValue(response.data.message)
                    setLoadingForFormatted(false)
                } 
                else {
                if (response.response &&
                    response.response.status &&
                    ((response.response.status === 401))
                  ) {
                    localStorage.removeItem("token");
                    localStorage.removeItem("email");
                    window.location = "/auth/login-page";
                  }
                }
            });
        }
    }

    // handle change function for text editor 
    const handleChangeTextArea = (e) => {
        let data = [...undoData, e.target.value]
        setUndoData(data)
        let rddata = [...redoData, e.target.value]
        setRedoData(rddata)
        setFunctionValue(e.target.value)
        if (e.target.value || functionValue) {
            document.getElementById("text").style.borderColor = "blue";
        }
        if(e.target.value || functionValue) {
            document.getElementById("text").style.borderColor = "blue";
        }
        if(name.length === 0){
            document.getElementById("name").style.borderColor = "#D10000";
        }
        if(description.length ===0){
            document.getElementById("description").style.borderColor = "#D10000";
        }
    }

    // Undo function for formule editor
    const undoFunction = () => {
        let data = undoData
        if (data.length > 1) {
            data.pop()
            setFunctionValue(data[undoData.length - 1])
            setUndoData(data)
        }
    }

    //Redo function for formula editor
    const redoFunction = () => {
        let data = redoData
        if (redoData.length > undoData.length) {
            setFunctionValue(data[undoData.length])
            let newUndoData = [...undoData, data[undoData.length]]
            setUndoData(newUndoData)
        }
    }

    // calling formattng api for each word in texteditor
    useEffect(() => {
        if (functionValue != "" && marketplace != "" && sku != "") {
            fetchPostformattedValue();
        } else if (functionValue.length === 0) {
            setFormatedValue("")
        }
        else {
            setFormatedValue("")
        }
    }, [functionValue, marketplace, sku])

    // calculate the start and end  cursor point in text editor
    const handleOnafocus = () => {
        setIsOnClick(true)
        var txtarea = document.getElementById("text");
        var start = txtarea.selectionStart;
        var end = txtarea.selectionEnd;
        setCursorPos(start)
    }

    // function for onkeyUp and call the focus api to restore the recent cursor point on keyUp
    const handleOnKeyUp = () => {
        handleOnafocus();
    }

    // Handle change for name
    const handleNameChane = (e) => {
        setName(e.target.value)
        if (e.target.value) {
            document.getElementById("name").style.borderColor = "blue";
        }

    }

    // handle change for description
    const handleDescriptionChange = (e) => {
        setDescription(e.target.value);
        if (e.target.value) {
            document.getElementById("description").style.borderColor = "blue";
        }
    }

    const clearAllstateValue = () => {
        setName("");
        setDescription("");
        setFunctionValue("");
        setSku("");
        setMarketplace("");

    }

    //Edit business rule 
    const handleEditBusinessRuleSubmit = () => {
        if (functionValue && name && description) {
            createUpdateBusinessRuleListPage(functionValue, name, description, marketplace, sku);
            clearAllstateValue();
        }
        else {
            // alert("please fill all fields")
            if (name.length === 0) {
                document.getElementById("name").style.borderColor = "#D10000";
            }
            if (functionValue.length === 0) {
                document.getElementById("text").style.borderColor = "#D10000";
            } if (description.length === 0) {
                document.getElementById("description").style.borderColor = "#D10000";
            }
        }

    }

    const handleMarketPlaceChange = (e, value) => {
        if (value) {
            setMarketplace(value)
        }
    }

    const marketplaceOption = JSON.parse(marketplaceOptions);
    return (
        <>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <div className={classes.paper}>
                        <div className="CERulePopup">
                            <div >
                                <div >
                                    <div >
                                        <h3 > {stringValue} Rule</h3>
                                        <button type="button"
                                            onClick={handleClose}
                                        >
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <div>
                                        <div class="row">
                                            <div class="col-4 CbrL">
                                                <label >Name*</label>
                                                <input type="text" name="fname" id="name" value={name} required onChange={(e) => handleNameChane(e)} />
                                                <label >Description*</label>
                                                <textarea name="description" id="description" value={description} onChange={(e) => handleDescriptionChange(e)} required >
                                                    description....
                                                </textarea>
                                                <div className="CbrTabs" style={{ maxHeight: "350px" }} >
                                                    <CreateBusinessRuleTab
                                                        handleClickForFunction={handleClickForFunction}
                                                        handleClickForProductField={handleClickForProductField}
                                                        handleClickForLookUpList={handleClickForLookUpList}
                                                    />
                                                </div>
                                            </div>
                                            <div class="col-8 CbrR">
                                                <div className="CbrRow" >
                                                    <label style={{ float: "left" }} >Results Preview for SKU</label>
                                                    <div>
                                                        <Autocomplete
                                                            freeSolo
                                                            id="free-solo-2-demo"
                                                            Clearable
                                                            options={skuAll && skuAll.map((option) => option.sku + ": " + option.title)}
                                                            style={{ width: 300, height: 30, float: "left" }}
                                                            value={sku}
                                                            onChange={(event, value) => handleSkuChange(event, value)}
                                                            renderInput={(params) => (
                                                                <TextField
                                                                    {...params}
                                                                    variant="outlined"
                                                                    value={isSkuInputValue}
                                                                    onChange={handleInputSkuChange}
                                                                    InputProps={{
                                                                        ...params.InputProps, type: 'search',
                                                                        endAdornment: (
                                                                            <React.Fragment>
                                                                                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                                                                {params.InputProps.endAdornment}
                                                                            </React.Fragment>
                                                                        ),
                                                                    }}
                                                                />
                                                            )}
                                                        />
                                                        <label style={{ float: "left" }}>On</label>
                                                        <Autocomplete
                                                            freeSolo
                                                            id="free-solo-2-demo"
                                                            Clearable
                                                            options={marketplaceOption && marketplaceOption.map((option) => option.name)}
                                                            value={marketplace}
                                                            style={{ width: 175, height: 30, float: "left" }}
                                                            onChange={(event, value) => handleMarketPlaceChange(event, value)}
                                                            renderInput={(params) => <TextField {...params} variant="outlined" />}
                                                        />
                                                    </div>
                                                </div>
                                                <div>{loadingForFormatted ? <CircularProgress color="inherit" size={20} /> : formattedValue}</div>
                                                <div className="CBREditor">
                                                    <label > Formula editor
                                                        <span style={{ float: "right" }} className="udoBtn">
                                                            <UndoIcon size={16} color="inherit" onClick={undoFunction} style={{ cursor: "pointer" }} />
                                                            <RedoIcon size={16} color="inherit" onClick={redoFunction} style={{ cursor: "pointer" }} />
                                                        </span>
                                                        <span style={{ float: "right" }}> {functionValue && functionValue.length}/10000</span>
                                                    </label>
                                                    <textarea
                                                        id="text"
                                                        autoCorrect="off"
                                                        autoCapitalize="off"
                                                        maxLength="10000"
                                                        name="textarea"
                                                        spellCheck="false"
                                                        value={functionValue}
                                                        onClick={() => handleOnafocus()}
                                                        onKeyPress={() => handleOnafocus()}
                                                        onKeyUp={() => handleOnKeyUp()}
                                                        tabIndex="0" onChange={(e) => handleChangeTextArea(e)} > </textarea>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="CERulePopup-Footer">
                                        <button
                                            onClick={handleClose}
                                        >Cancel</button>
                                        <button type="submit" class="btn btn-primary btn-sm" onClick={() => handleEditBusinessRuleSubmit()}> Save Rule</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Fade>
            </Modal>
        </>
    )
}

export default TemplateModal