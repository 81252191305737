import React, { Component } from "react";

export default class publishedRender extends Component {
  render() {
    return this.props.data.id ? (
      <p style={{ textAlign: "center" }}>
        {this.props.value ? (
          <i className="fas fa-check" aria-hidden="true" title="Published" />
        ) : (
          <i
            className="fas fa-times-circle"
            aria-hidden="true"
            title="Not Published"
          />
        )}{" "}
      </p>
    ) : (
      ""
    );
  }
}
