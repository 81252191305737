import Axios from "axios";
import React, { Fragment, useEffect, useState } from "react";

import Permission from "permission/Permission";
import MenuList from "gridComponents/MenuList";
import LoadingOverlay from "components/overlays/LoadingOverlay";
import MessageComponent from "../FunctionalComponents/messageComponent";

import { PRODUCT_FIELD_CONFIGURATION } from "configurations/configApi";

import { handleError } from "views/FunctionalComponents/ErrorHandle";
import errorRedirect from "views/FunctionalComponents/ErrorRedirect";

import { formatName } from "../../formatterActions";

export default function ProductConfiguration() {
  const [productFieldConfiguration, setProductFieldConfiguration] = useState({
    attribute_configuration: [],
    field_configuration: [],
  });
  const [loading, setLoading] = useState(true);
  const [sweetAlert, setSweetAlert] = useState("");

  const headers = {
    Authorization: `Token ${localStorage.getItem("token")}`,
  };

  // function to remove sweetalert popups
  const hideAlert = () => setSweetAlert("");

  // function to show/hide loading Overlay
  const loadingAction = (action) => setLoading(action);

  // function to show Message pops on success/error response
  const fetchMessage = (status, response, stateOfAction) => {
    const message = (
      <MessageComponent
        page="product-configuration"
        action={stateOfAction}
        hideAlert={hideAlert}
        hideOverlay={loadingAction}
        response={response}
        status={status}
      />
    );
    setSweetAlert(message);
  };

  // function to check the error due to 404/500
  const checkFunction = (error) => {
    if (
      error.response &&
      error.response.status &&
      (error.response.status === 404 || error.response.status === 500)
    ) {
      return true;
    }
  };

  const fetchProductFieldConfiguration = () => {
    setLoading(true);
    Axios(PRODUCT_FIELD_CONFIGURATION, {
      method: "get",
      headers,
    })
      .then((response) => {
        setProductFieldConfiguration(response.data);
        console.log(response.data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        // if (
        //   error.response &&
        //   error.response.status &&
        //   error.response.status === 401
        // ) {
        //   handleError(error.response);
        // } else errorRedirect(error.response);
      });
  };

  const handleConfigurationEdit = () => {
    setLoading(true);
    Axios(PRODUCT_FIELD_CONFIGURATION, {
      method: "post",
      headers,
      data: productFieldConfiguration,
    })
      .then((response) => {
        fetchMessage(true, response, "publish");
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        if (
          error.response &&
          error.response.status &&
          error.response.status === 401
        ) {
          handleError(error.response);
        } else if (checkFunction(error)) {
          fetchMessage("redirectTrue");
        } else {
          fetchMessage(false, error.response, "publish");
        }
      });
  };

  const handleCheckBoxChange = ({ target }) => {
    const { id, checked } = target;
    const [configArray, configName] = id.split("-");

    setProductFieldConfiguration((prevState) => ({
      ...prevState,
      [configArray]: prevState[configArray].map((each) =>
        each.name === configName ? { ...each, is_active: checked } : each
      ),
    }));
  };

  useEffect(() => {
    fetchProductFieldConfiguration();
  }, []);

  return (
    <>
      {sweetAlert}
      <Permission />
      <MenuList />
      <LoadingOverlay showOverlay={loading} />
      <div className="heading">
        <h1>Product Configuration</h1>
        <button onClick={handleConfigurationEdit} className="configSave">
          Save
        </button>
      </div>

      <div className="content-warpper">
        <div className="content-body">
          <div className="catlog-basicinfo">
            <h5>Attribute Configuration</h5>
            <div className="checkBoxDivs">
              {productFieldConfiguration.attribute_configuration.map((each) => (
                <Fragment key={"attribute_configuration-" + each.name}>
                  <div>
                    <input
                      type="checkbox"
                      id={"attribute_configuration-" + each.name}
                      checked={each.is_active}
                      onChange={handleCheckBoxChange}
                    />
                    <p>{formatName(each.name)}</p>
                  </div>
                </Fragment>
              ))}
            </div>
          </div>
          <div className="otherInfo">
            <h5>Field Configuration</h5>
            <div className="checkBoxDivs">
              {productFieldConfiguration.field_configuration.map((each) => (
                <Fragment key={"field_configuration-" + each.name}>
                  <div>
                    <input
                      type="checkbox"
                      id={"field_configuration-" + each.name}
                      checked={each.is_active}
                      onChange={handleCheckBoxChange}
                    />
                    <p>{formatName(each.name)}</p>
                  </div>
                </Fragment>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
