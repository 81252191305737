import React from 'react';
import CardFooter from 'components/Card/CardFooter.jsx';
import Button from 'components/CustomButtons/Button.jsx';

import { useAuth0 } from '@auth0/auth0-react';

function LoginStockXButton() {
  const { isAuthenticated, loginWithRedirect } = useAuth0();

  return (
    !isAuthenticated && (
      <CardFooter style={{ display: 'none' }}>
        <Button
          color="info"
          simple
          size="lg"
          block
          onClick={() => loginWithRedirect()}
        >
          Login StockX
        </Button>
      </CardFooter>
    )
  );
}

export default LoginStockXButton;
