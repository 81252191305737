import React, {useState } from "react";


export default function BusinessRuleRender(props) {

    const [id, setId] = useState(null)
    const [isClicked, setIsClicked] = useState(false)

  const handleClick = (id) => {
    setId(id);
    setIsClicked(true);
  }
    return props.data && props.data.id ? (
      <>
        <div 
        data-toggle="modal"
        data-target="#exampleModal"
        style={{ color: "blue" }}
        onClick={() => handleClick(props.data.id)} 
        >
        {props.data.name}</div>
      </>
    ) : (
      ""
    );
  }

