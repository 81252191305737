import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "none",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

export default function CopyProduct({
  open,
  closeCopyProduct,
  sku,
  title,
  quantity,
  copyProductWithoutSibling,
  product_ids,
}) {
  const classes = useStyles();
  const [productTitle, setProductTitle] = useState(title ? title : "");
  const [productSku, setProductSku] = useState(sku ? sku : "");
  const [productQuantity, setProductQuantity] = useState(quantity);

  const [productIds, setProductIds] = useState(
    product_ids ? product_ids : null
  );

  const handleClickCopyProduct = () => {
    console.log("hiii copy product");
    copyProductWithoutSibling(
      productSku,
      productTitle,
      productIds,
      productQuantity
    );
  };

  const handleChangeProductTitle = (e) => {
    setProductTitle(e.target.value);
  };

  const handleChangeProductSku = (e) => {
    setProductSku(e.target.value);
  };

  const handleChangeProductQuantity = ({ target }) => {
    const { value } = target;
    if (isNaN(Number(value))) {
      return;
    } else {
      setProductQuantity(value);
    }
  };

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className="modalPopup"
        open={open}
        onClose={closeCopyProduct}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <h4 id="transition-modal-title">Copy This Product</h4>
            <div className="popupRow">
              <label id="transition-modal-description" required>
                Product Title*
              </label>
              <input
                id="transition-modal-description"
                name="product_title"
                value={productTitle}
                onChange={(e) => handleChangeProductTitle(e)}
              />
            </div>
            <div className="popupRow">
              <label id="transition-modal-description"> SKU*</label>
              <input
                id="transition-modal-description"
                name="sku"
                value={productSku}
                onChange={(e) => handleChangeProductSku(e)}
                required
              />
            </div>
            <div className="popupRow">
              <label id="transition-modal-description"> Quantity*</label>
              <input
                id="transition-modal-description"
                name="quantity"
                type="text"
                value={productQuantity}
                onChange={handleChangeProductQuantity}
                required
              />
            </div>
            <div className="popupRow">
              <button
                type="button"
                onClick={handleClickCopyProduct}
                disabled={[productSku, productTitle, productQuantity].includes(
                  ""
                )}
              >
                <i className="fa fa-copy"> </i> Copy Product
              </button>
              <button type="button" onClick={closeCopyProduct}>
                Cancel
              </button>
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}
