export default function errorRedirect(response) {
  if (
    (response &&
      response.status &&
      (response.status === 404 || response.status === 500)) ||
    !response
  ) {
    window.location = "/error";
  } else {
    // to show some popups
    window.location = "/error";
  }
}
