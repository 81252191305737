
export const handleError = (response) => {
    if (response &&
        response.status &&
        ((response.status === 401))
        
      ) {
        localStorage.removeItem("email");
        localStorage.removeItem("token");
        localStorage.removeItem("userName");
        localStorage.removeItem("userId");
        window.location = "/auth/login-page";
      }
      // console.log("handleerror", response)
}