import React from "react";
import { Redirect } from "react-router-dom";
import classNames from "classnames";
import PropTypes from "prop-types";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Hidden from "@material-ui/core/Hidden";

// @material-ui/icons
import Person from "@material-ui/icons/ExitToApp";

// core components
import Button from "components/CustomButtons/Button.jsx";

import adminNavbarLinksStyle from "assets/jss/material-dashboard-pro-react/components/adminNavbarLinksStyle.jsx";

class HeaderLinks extends React.Component {
  state = {
    open: false,
    logout: false
  };
  handleClick = () => {
    this.setState({ open: !this.state.open });
  };
  handleClose = () => {
    this.setState({ open: false });
  };

  handleLogout = () => {
    localStorage.clear();
    this.setState({ logout: true });
  };

  render() {
    const { classes, rtlActive } = this.props;
    const { logout } = this.state;

    if (logout) {
      return <Redirect to="/auth/login-page" />
    }

    const wrapper = classNames({
      [classes.wrapperRTL]: rtlActive
    });

    return (
      <div className={wrapper}>
        <Button
          color="transparent"
          aria-label="Person"
          title="Logout"
          onClick={() => this.handleLogout()}
          justIcon
          className={rtlActive ? classes.buttonLinkRTL : classes.buttonLink}
          muiClasses={{
            label: rtlActive ? classes.labelRTL : ""
          }}
        >
          <Person
            className={
              classes.headerLinksSvg +
              " " +
              (rtlActive
                ? classes.links + " " + classes.linksRTL
                : classes.links)
            }
          />
          <Hidden mdUp implementation="css">
            <span className={classes.linkText}>
              {rtlActive ? "الملف الشخصي" : "Logout"}
            </span>
          </Hidden>
        </Button>
      </div>
    );
  }
}

HeaderLinks.propTypes = {
  classes: PropTypes.object.isRequired,
  rtlActive: PropTypes.bool
};

export default withStyles(adminNavbarLinksStyle)(HeaderLinks);
