import React, { Component } from "react"
import { Link } from "react-router-dom";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import { fetchUrl } from "../../apiActions/action";
import Permission from "../../permission/Permission";
import MenuList from "../../gridComponents/MenuList";
import { BULK_PUBLISH_DETAILS } from "../../configurations/configApi";
import errorRedirect from "views/FunctionalComponents/ErrorRedirect";

export default class BulkPublishDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      columnDefs: [
        {
          headerName: 'SKU', field: 'sku',
          cellRendererFramework: (params) =>
            <Link to={`/catalogs/${params.data.product_id}`}>{params.value}</Link> //  To add  icon or link  in cell etc
        },
        { headerName: 'Marketplace', field: 'marketplace' },
        {
          headerName: "Status",
          field: "status",
          //minWidth: 100,
          filter: "agSetColumnFilter",
          filterParams: {
            values: function (params) {
              // simulating async delay
              setTimeout(function () {
                params.success(["true", "false"]);
              }, 500);
            }
          },
          // cellRenderer: params => console.log(params)
          cellRendererFramework: (params) =>
            <div>
              {params.value === true ? <i className="fas fa-check" aria-hidden="true" title="true" /> : <i
                className="fas fa-times-circle"
                aria-hidden="true"
                title="false"
              />}
            </div>
        },
        { headerName: 'UPDATED ON', field: 'updated_on' },
        { headerName: 'ERROR MESSAGE', field: 'error_message' }

      ],
      loadingOverlayComponent: 'customLoadingOverlay',
      loadingOverlayComponentParams: {
        loadingMessage: 'One moment please...',
      },
      noRowsOverlayComponent: 'customNoRowsOverlay',
      noRowsOverlayComponentParams: {
        noRowsMessageFunc: function () {
          return 'Sorry - no rows';
        },
      },
      loadingCellRenderer: "loadingRender",
      defaultColDef: {
        resizable: true,
        width: 360
      }
    }
  }

  onGridReady = (params) => {
    fetchUrl(`${BULK_PUBLISH_DETAILS}${this.props.match.params.id}/`, getVal => {
      if (getVal.status) {
        params.api.applyTransaction({ add: getVal.response.data })
      }
      else {
        if (getVal.response.response &&
          getVal.response.response.status &&
          ((getVal.response.response.status === 401))

        ) {
          localStorage.removeItem("token");
          window.location = "/auth/login-page";
        }
        else errorRedirect(getVal.response)
      }
    });
  }

  render() {

    const {
      loadingCellRenderer,
      defaultColDef,
      columnDefs,
      loadingCellRendererParams,
      noRowsOverlayComponentParams,
      loadingOverlayComponentParams
    } = this.state

    return (
      <>
        <Permission />
        <MenuList />
        <div className="heading">
          <h1 id="dashboard-title">Bulk Publish Details </h1>
        </div>
        <div className="ag-theme-alpine" style={{ height: "100%", width: "100%" }}>
          <AgGridReact
            // enableSorting={true}
            onGridReady={this.onGridReady} // when grid gets ready it invokes
            columnDefs={columnDefs} // to show all column headers and fields
            defaultColDef={defaultColDef} // to enable common props for each fields
            loadingCellRendererParams={loadingCellRendererParams} // renderer Params to load msg
            noRowsOverlayComponentParams={noRowsOverlayComponentParams} // default no rows overlay component
            loadingOverlayComponentParam={loadingOverlayComponentParams}
            loadingCellRenderer={loadingCellRenderer}
          >
          </AgGridReact>
        </div>
      </>
    )
  }
}