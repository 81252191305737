import React from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';

// import { withAuth0 } from '@auth0/auth0-react';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import InputAdornment from '@material-ui/core/InputAdornment';
import Icon from '@material-ui/core/Icon';

// @material-ui/icons
import Email from '@material-ui/icons/AccountCircle';
// import LockOutline from "@material-ui/icons/LockOutline";

// core components
import { LOGIN_API_URL } from 'configurations/configApi.jsx';
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import CustomInput from 'components/CustomInput/CustomInput.jsx';
import Button from 'components/CustomButtons/Button.jsx';
import Card from 'components/Card/Card.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import CardHeader from 'components/Card/CardHeader.jsx';
import CardFooter from 'components/Card/CardFooter.jsx';
import LoadingOverlay from 'components/overlays/LoadingOverlay.jsx';
import SnackbarContent from 'components/Snackbar/SnackbarContent.jsx';
import loginPageStyle from 'assets/jss/material-dashboard-pro-react/views/loginPageStyle.jsx';

import LoginStockXButton from 'components/CustomButtons/LoginStockXBtn';
class LoginPage extends React.Component {
  constructor(props) {
    super(props);
    // we use this to make the card to appear after the page has been rendered
    this.state = {
      cardAnimaton: 'cardHidden',
      message: '',
      loginUsername: '',
      loginUsernameState: '',
      loginPassword: '',
      loginPasswordState: '',
      redirectToDashboard: false,
      showOverlay: false,
      showPassword: false
    };
    this.loginClick = this.loginClick.bind(this);
    this.change = this.change.bind(this);
    this.verifyEmail = this.verifyEmail.bind(this);
    this.verifyLength = this.verifyLength.bind(this);
    this.handleTrigger = this.handleTrigger.bind(this);
  }

  componentDidMount() {
    // we add a hidden class to the card and after 700 ms we delete it and the transition appears
    this.timeOutFunction = setTimeout(
      function() {
        this.setState({ cardAnimaton: '' });
      }.bind(this),
      700
    );
  }

  componentWillUnmount() {
    clearTimeout(this.timeOutFunction);
    this.timeOutFunction = null;
  }

  // function to setup Api call for Login
  loginClick() {
    this.setState({ showOverlay: true });
    if (this.state.loginUsernameState === '') {
      this.setState({ loginUsernameState: 'error' });
    }
    if (this.state.loginPasswordState === '') {
      this.setState({ loginPasswordState: 'error' });
    }

    const data = {
      username: this.state.loginUsername,
      password: this.state.loginPassword
    };
    axios(LOGIN_API_URL, {
      method: 'post',
      data
    })
      .then(response => {
        localStorage.setItem('email', response.data.email);
        localStorage.setItem('token', response.data.token);
        localStorage.setItem('userName', response.data.username);
        localStorage.setItem('userId', response.data.user_id);
        localStorage.setItem(
          'productConfigId',
          response.data.products_page_config_id
        );
        localStorage.setItem(
          'orderConfigId',
          response.data.orders_page_config_id
        );
        this.setState({
          showOverlay: false,
          redirectToDashboard: true
        });
      })
      .catch(error => {
        if (
          error.response &&
          error.response.status &&
          error.response.status === 401
        ) {
          localStorage.removeItem('token');
          window.location = '/auth/login-page';
        } else if (
          (error.response &&
            error.response.status &&
            (error.response.status === 404 ||
              error.response.status === 500)) ||
          !error.response
        ) {
          window.location = '/error';
        } else {
          this.setState({
            message: error.response.data.message,
            showOverlay: false
          });
        }
      });
  }

  // Function to set input color change based on Validation
  change(event, type) {
    this.setState({ message: '' });
    switch (type) {
      case 'username':
        if (this.verifyLength(event.target.value, 1)) {
          this.setState({
            loginUsernameState: 'success',
            loginUsername: event.target.value
          });
        } else {
          this.setState({ loginUsernameState: 'error' });
        }
        break;
      case 'password':
        if (this.verifyLength(event.target.value, 1)) {
          this.setState({
            loginPasswordState: 'success',
            loginPassword: event.target.value
          });
        } else {
          this.setState({ loginPasswordState: 'error' });
        }
        break;
      default:
      // todo
    }
  }
  // function that returns true if value is email, false otherwise
  verifyEmail(value) {
    var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (emailRex.test(value)) {
      return true;
    }
    return false;
  }

  // function that verifies if a string has a given length or not
  verifyLength(value, length) {
    if (value.length >= length) {
      return true;
    }
    return false;
  }

  // Function to show/hide Password
  handleTrigger() {
    this.setState({ showPassword: !this.state.showPassword });
  }

  render() {
    const { classes } = this.props;
    const { redirectToDashboard } = this.state;
    if (redirectToDashboard) {
      return <Redirect to="/" />;
    }

    return (
      <div className={classes.container}>
        <form>
          <GridContainer justify="center">
            <GridItem xs={12} sm={6} md={4}>
              {this.state.message && (
                <SnackbarContent
                  message={this.state.message}
                  color="danger"
                />
              )}
              <Card
                login
                className={classes[this.state.cardAnimaton]}
              >
                <CardHeader
                  className={`${classes.cardHeader} ${
                    classes.textCenter
                  }`}
                  color="info"
                >
                  <h4 className={classes.cardTitle}>Log in</h4>
                </CardHeader>
                <CardBody>
                  <CustomInput
                    success={
                      this.state.loginUsernameState === 'success'
                    }
                    error={this.state.loginUsernameState === 'error'}
                    labelText="Username"
                    id="loginUsername"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      onChange: event =>
                        this.change(event, 'username'),
                      type: 'text',
                      endAdornment: (
                        <InputAdornment position="end">
                          <Email
                            className={classes.inputAdornmentIcon}
                          />
                        </InputAdornment>
                      )
                    }}
                  />
                  <CustomInput
                    success={
                      this.state.loginPasswordState === 'success'
                    }
                    error={this.state.loginPasswordState === 'error'}
                    labelText="Password"
                    id="loginPassword"
                    className="passWordDiv"
                    formControlProps={{
                      fullWidth: true
                    }}
                    title={this.state.showPassword ? 'Hide' : 'Show'}
                    inputProps={{
                      onChange: event =>
                        this.change(event, 'password'),
                      type: this.state.showPassword
                        ? 'text'
                        : 'password',
                      endAdornment: (
                        <InputAdornment position="end">
                          <Icon
                            className={classes.inputAdornmentIcon}
                            onClick={() => this.handleTrigger()}
                          >
                            {this.state.showPassword
                              ? 'visibility'
                              : 'visibility_off'}
                          </Icon>
                        </InputAdornment>
                      )
                    }}
                  />
                </CardBody>
                <CardFooter className={classes.justifyContentCenter}>
                  <Button
                    color="info"
                    // id="loginBtn"
                    simple
                    size="lg"
                    block
                    onClick={this.loginClick}
                  >
                    Let's Go
                  </Button>
                </CardFooter>
                <LoginStockXButton />
              </Card>
            </GridItem>
          </GridContainer>
        </form>
        <LoadingOverlay showOverlay={this.state.showOverlay} />
      </div>
    );
  }
}

LoginPage.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(loginPageStyle)(LoginPage);
