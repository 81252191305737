import React, { Component } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import GridItem from 'components/Grid/GridItem';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';

const animatedComponents = makeAnimated();

const options = [
  { value: 'VoyageOne', label: 'VoyageOne' },
  { value: 'Shopify', label: 'Shopify' },
  { value: 'StockX', label: 'StockX' }
];

export default class UnpublishComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      product_list: this.props.product_ids,
      market_place: []
    };
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  handleInputChange = event => {
    // remove h6 tab, so that it will hide the text from user
    // console.log("18", this.state.market_place)
    var child = document.getElementsByTagName('h6')[0];
    child.classList.remove('h6ClassToShow');
    let value =
      event && event.length > 0 && event.map(item => item.value);
    if (value) {
      this.setState({ market_place: value });
    } else {
      this.setState({ market_place: [] });
    }
    console.log('23', value);
    // const { name, value } = event.target;
    // this.setState({ market_place: value })
    // if (value) {
    //   this.setState({ [name]: value });
    // } else {
    //   delete this.state[name];
    // }
  };

  handleSubmit = () => {
    // console.log(Object.keys(this.state).length)
    if (
      this.state.market_place &&
      this.state.market_place.length > 0
    ) {
      this.props.handleUnpublish(this.state);
    } else {
      // to show the error message inside popup
      var para = document.getElementsByTagName('h6')[0];
      para.className += ' h6ClassToShow';
    }
  };

  render() {
    console.log('60', this.state.market_place);
    return (
      <>
        <SweetAlert
          style={{ display: 'block', marginTop: '-150px' }}
          title="Unpublish Products"
          onConfirm={() => this.handleSubmit()}
          onCancel={() => this.props.hideAlert()}
          confirmBtnText="Submit"
          cancelBtnText="Cancel"
          showCancel
        >
          <GridItem xs={12} sm={12} md={12} id="gridSweetId">
            <h6 className="errorH6">
              Please provide input for all fields
            </h6>
            <div className="row">
              <table className="col-md-12" cellspacing="5">
                <tr>
                  <td>Market Place</td>
                  <td>
                    <Select
                      closeMenuOnSelect={false}
                      components={animatedComponents}
                      onChange={this.handleInputChange}
                      isMulti
                      options={options}
                    />
                  </td>
                </tr>
              </table>
            </div>
          </GridItem>
        </SweetAlert>
      </>
    );
  }
}

// const { name, value } = event.target;
// this.setState({ market_place: value })
// if (value) {
//   this.setState({ [name]: value });
// } else {
//   delete this.state[name];
// }
