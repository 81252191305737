import React from "react";

import { fetchUrl } from "../../apiActions/action";
import { PUB_UNPUB_API_URL } from "../../configurations/configApi";
import Icon from "@material-ui/core/Icon";

class PublishUnpublishHistory extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: "",
      responseData: {}
    };
  }

  fetchPublishUnpublishHistory = id => {
    fetchUrl(`${PUB_UNPUB_API_URL}${id}/`, ({ status, response }) => {
      if (status) {
        this.setState({ loading: false, responseData: response.data });
      }
    });
  };

  componentDidMount() {
    const { id } = this.props;
    this.setState({ loading: true });
    this.fetchPublishUnpublishHistory(id);
  }
  render() {
    const { loading, responseData } = this.state;

    const ordersList =
      responseData.length > 0 &&
      responseData.map(eachHistory => {
        return (
          <tr>
            <td>{eachHistory.action_type}</td>
            <td>{eachHistory.platform}</td>
            <td>{eachHistory.updated_on}</td>
            <td>{eachHistory.created_on}</td>
          </tr>
        );
      });
    return (
      <>
        {responseData.length > 0 ? (
          <div className="header-static">
            <div className="table-header">
              <table>
                <thead>
                  <tr>
                    <th>Action Type</th>
                    <th>Platform</th>
                    <th>Updated On</th>
                    <th>Created On</th>
                  </tr>
                </thead>
                <tbody>{ordersList}</tbody>
              </table>
            </div>
            <div className="tableScroll">
              <table className="table-body">
                <thead>
                  <tr>
                    <th>Action Type</th>
                    <th>Platform</th>
                    <th>Updated On</th>
                    <th>Created On</th>
                  </tr>
                </thead>
                <tbody>{ordersList}</tbody>
              </table>
            </div>
          </div>
        ) : loading === false ? (
          <p className="noData">
            <span>
              <i className="fas fa-coins" />
              No History For This Product
            </span>
          </p>
        ) : (
          <p className="loader">
            <Icon className="rotate">cached</Icon>
            <br />
            Loading ....
          </p>
        )}
      </>
    );
  }
}

export default PublishUnpublishHistory;
