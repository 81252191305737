import React, { Component } from "react";

export default class CustomLoadingCellRenderer extends Component {
  render() {
    return (
      <div
        className="ag-custom-loading-cell"
        style={{ paddingLeft: "50px", lineHeight: "55px" }}
      >
        <i className="fas fa-spinner fa-pulse" />{" "}
      </div>
    );
  }
}
