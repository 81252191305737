
import React from "react";
import SweetAlert from "react-bootstrap-sweetalert";

const StaleProductDelete = (props) => {
    
    let data = {
        product_ids: props.product_ids
    } 
    
   const handleSubmit = () => {
          props.handleDeleteStaleProduct(data);
      };


    return (
        <div>
            <SweetAlert
                style={{ display: "block", marginTop: "-150px"}}
                title="Delete Stale Product"
                onConfirm={() => handleSubmit()}
                onCancel={() => props.hideAlert()}
                confirmBtnText="Submit"
                cancelBtnText="Cancel"
                showCancel
            >
                <h5>Do You Want to Delete Products?</h5>
            </SweetAlert>
        </div>
    )
}

export default StaleProductDelete