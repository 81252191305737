import React, { Component } from 'react';
import axios from 'axios';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-enterprise';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import _ from 'lodash';
import {
  CATALOG_API_URL,
  CATALOG_SEARCH_API_URL,
  CUSTOMIZATION_GRID_API_URL,
  PRODUCT_PROPERTIES_API_URL,
  PUBLISH_API_URL,
  SYNC_API_URL,
  UNPUBLISH_API_URL,
  BULK_DELETE_PRODUCT,
  DELETE_PRODUCT
} from '../../configurations/configApi';
import Permission from '../../permission/Permission';
import CustomNoRowsOverlay from '../../formatters/customNoRowsOverlay';
import CustomTooltip from '../../formatters/customTooltip.jsx';
import BooleanRender from '../../formatters/BooleanRender';
import publishedRender from '../../formatters/publishedRender';
import imageRender from '../../formatters/imageRender';
import loadingRender from '../../formatters/loadingRender';
import MenuList from '../../gridComponents/MenuList';
import viewRender from '../../formatters/viewRender';
import DateTimeRender from '../../formatters/DateTimeRender';

import {
  apiEdit,
  apiPost,
  fetchUrl,
  apiDelete
} from '../../apiActions/action';
import { saveAction } from '../../apiActions/checkFunctions';
import MessageComponent from '../FunctionalComponents/messageComponent';
import PublishComponent from '../FunctionalComponents/publishComponent';
import {
  numberFields,
  setProperFields,
  textFields,
  setFields
} from '../../constants/columnConstants';
import UnpublishComponent from 'views/FunctionalComponents/UnpublishComponent';
import StaleProductDelete from 'views/FunctionalComponents/staleProductDelete';
import { handleError } from '../FunctionalComponents/ErrorHandle';
import { Link } from 'react-router-dom';
import errorRedirect from 'views/FunctionalComponents/ErrorRedirect';

let productTypes = [];
var distributionCenters = [];
let conditionList = [];
var flagList = [];
var labelList = [];
var profileList = {};
var publishIds = [];
var displayColumns = [];
var firstComp = false;
var load = false;
var responseOnGrid = '';
var gridParams = '';
var pageParams = '';
var gridParamGrid = '';
var urlParams = '';
var configLable = '';
var pageType = '';
var toCheckPublish = '';
var marketplaces = [];

class Catalogs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      columnDefs: [
        {
          headerName: '',
          field: 'created_on',
          cellRenderer: 'agGroupCellRenderer',
          cellRendererParams: {
            checkbox: true
          },
          lockVisible: true
        },
        {
          headerName: 'Image',
          field: 'image',
          // minWidth: 100,
          cellRenderer: 'imageRender',
          lockVisible: true
        },
        {
          headerName: 'MPN',
          field: 'mpn',
          // minWidth: 100,
          sortable: true,
          filter: 'agTextColumnFilter',
          filterParams: {
            buttons: ['reset'],
            debounceMs: 1000,
            suppressAndOrCondition: true, // it will remove AND/OR conditions
            filterOptions: [
              'contains',
              'notContains',
              'equals',
              'notEqual',
              'startsWith',
              'endsWith',
              'In List',
              'Not In List'
            ]
          }
        },
        {
          headerName: 'SKU',
          field: 'sku',
          // minWidth: 100,
          sortable: true,
          lockVisible: true,
          filter: 'agTextColumnFilter',
          filterParams: {
            buttons: ['reset'],
            debounceMs: 1000,
            suppressAndOrCondition: true, // it will remove AND/OR conditions
            filterOptions: [
              'contains',
              'notContains',
              'equals',
              'notEqual',
              'startsWith',
              'endsWith',
              'In List',
              'Not In List'
            ]
          },
          cellRenderer: 'viewRender'
        },
        {
          headerName: 'GOAT SKU',
          field: 'goat_sku',
          minWidth: 100,
          editable: true,
          sortable: true,
          lockVisible: false,
          filter: 'agTextColumnFilter',
          filterParams: {
            buttons: ['reset'],
            debounceMs: 1000,
            suppressAndOrCondition: true, // it will remove AND/OR conditions
            filterOptions: [
              'contains',
              'notContains',
              'equals',
              'notEqual',
              'startsWith',
              'endsWith',
              'In List',
              'Not In List'
            ]
          }
        },
        {
          headerName: 'Title',
          field: 'title',
          // minWidth: 200,
          sortable: true,
          filter: 'agTextColumnFilter',
          filterParams: {
            buttons: ['reset'],
            debounceMs: 1000,
            suppressAndOrCondition: true, // it will remove AND/OR conditions
            filterOptions: [
              'contains',
              'notContains',
              'equals',
              'notEqual',
              'startsWith',
              'endsWith',
              'In List',
              'Not In List'
              // "Is Empty",
              // "Is Not Empty"
            ]
          }
        },
        {
          headerName: 'Brand',
          field: 'brand',
          // minWidth: 100,
          sortable: true,
          filter: 'agTextColumnFilter',
          filterParams: {
            buttons: ['reset'],
            debounceMs: 1000,
            suppressAndOrCondition: true, // it will remove AND/OR conditions
            filterOptions: [
              'contains',
              'notContains',
              'equals',
              'notEqual',
              'startsWith',
              'endsWith',
              'In List',
              'Not In List'
              // "Is Empty",
              // "Is Not Empty"
            ]
          }
        },
        {
          headerName: 'Type',
          //minWidth: 100,
          field: 'product_type',
          filter: 'agSetColumnFilter',
          filterParams: {
            values: function(params) {
              // simulating async delay
              setTimeout(function() {
                params.success(productTypes);
              }, 500);
            }
          }
        },
        {
          headerName: 'Distribution',
          field: 'distribution_center',
          //minWidth: 100,
          tooltipField: 'distribution_center',
          sortable: true,
          tooltipComponentParams: { color: '#ececec' },
          filter: 'agSetColumnFilter',
          filterParams: {
            values: function(params) {
              // simulating async delay
              setTimeout(function() {
                params.success(distributionCenters);
              }, 500);
            }
          }
        },
        {
          headerName: 'Phoenix Qty',
          field: 'phoenix_quantity',
          //minWidth: 100,
          editable: true,
          sortable: true,
          filter: 'agNumberColumnFilter',
          filterParams: {
            buttons: ['reset'],
            debounceMs: 1000,
            suppressAndOrCondition: true
          }
        },
        // {
        //   headerName: "Published To Voyageone",
        //   field: "is_published_to_voyageone",
        //   //minWidth: 100,
        //   filter: "agSetColumnFilter",
        //   cellRenderer: "publishedRender",
        //   filterParams: {
        //     values: function(params) {
        //       // simulating async delay
        //       setTimeout(function() {
        //         params.success(["True", "False"]);
        //       }, 500);
        //     }
        //   }
        // },
        {
          headerName: 'Published',
          field: 'marketplaces',
          //minWidth: 100,
          tooltipField: 'marketplaces',
          sortable: true,
          tooltipComponentParams: { color: '#ececec' },
          filter: 'agSetColumnFilter',
          filterParams: {
            // defaultToNothingSelected: true,
            values: function(params) {
              // simulating async delay
              setTimeout(function() {
                params.success(marketplaces);
              }, 500);
            }
          }
        },
        {
          headerName: 'Online',
          field: 'online',
          //minWidth: 100,
          filter: 'agSetColumnFilter',
          cellRenderer: 'BooleanRender',
          filterParams: {
            values: function(params) {
              // simulating async delay
              setTimeout(function() {
                params.success(['True', 'False']);
              }, 500);
            }
          }
        },
        {
          headerName: 'Stale',
          field: 'is_stale',
          //minWidth: 100,
          filter: 'agSetColumnFilter',
          cellRenderer: 'BooleanRender',
          filterParams: {
            values: function(params) {
              // simulating async delay
              setTimeout(function() {
                params.success(['True', 'False']);
              }, 500);
            }
          }
        },
        {
          headerName: 'Qty Total',
          field: 'total_quantity',
          //minWidth: 100,
          sortable: true,
          filter: 'agNumberColumnFilter',
          filterParams: {
            buttons: ['reset'],
            debounceMs: 1000,
            suppressAndOrCondition: true
          }
        },
        {
          headerName: 'Release Year',
          field: 'release_year',
          //minWidth: 100,
          sortable: true,
          filter: 'agNumberColumnFilter',
          filterParams: {
            buttons: ['reset'],
            debounceMs: 1000,
            suppressAndOrCondition: true
          }
        },
        {
          headerName: 'Qty Total Avail',
          field: 'total_available_quantity',
          //minWidth: 100,
          sortable: true,
          filter: 'agNumberColumnFilter',
          filterParams: {
            buttons: ['reset'],
            debounceMs: 1000,
            suppressAndOrCondition: true
          }
        },
        {
          headerName: 'Qty Pending Payment',
          field: 'pending_payment_quantity',
          sortable: true,
          //minWidth: 100,
          filter: 'agNumberColumnFilter',
          filterParams: {
            buttons: ['reset'],
            debounceMs: 1000,
            suppressAndOrCondition: true
          }
        },
        {
          headerName: 'Qty Pending Shipment',
          field: 'pending_shipment_quantity',
          sortable: true,
          //minWidth: 100,
          filter: 'agNumberColumnFilter',
          filterParams: {
            buttons: ['reset'],
            debounceMs: 1000,
            suppressAndOrCondition: true
          }
        },
        {
          headerName: 'Buy Now Price',
          field: 'buy_it_now_price',
          //minWidth: 100,
          valueFormatter: function(params) {
            return params.value && `${params.value}`;
          },
          sortable: true,
          filter: 'agNumberColumnFilter',
          filterParams: {
            buttons: ['reset'],
            debounceMs: 1000,
            suppressAndOrCondition: true
          }
        },
        {
          headerName: 'Retail Price',
          field: 'retail_price',
          //minWidth: 100,
          editable: true,
          sortable: true,
          filter: 'agNumberColumnFilter',
          filterParams: {
            buttons: ['reset'],
            debounceMs: 1000,
            suppressAndOrCondition: true
          }
        },
        {
          headerName: 'Price Manually Updated',
          field: 'retail_price_manually_updated',
          //minWidth: 100,
          filter: 'agSetColumnFilter',
          cellRenderer: 'BooleanRender',
          filterParams: {
            values: function(params) {
              // simulating async delay
              setTimeout(function() {
                params.success(['True', 'False']);
              }, 500);
            }
          }
        },
        {
          headerName: 'ID',
          field: 'id',
          //minWidth: 100,
          sortable: true,
          filter: 'agNumberColumnFilter',
          filterParams: {
            buttons: ['reset'],
            debounceMs: 1000,
            suppressAndOrCondition: true
          }
        },
        {
          headerName: 'Channel Advisor ID',
          field: 'channel_advisor_id',
          //minWidth: 100,
          sortable: true,
          filter: 'agNumberColumnFilter',
          filterParams: {
            buttons: ['reset'],
            debounceMs: 1000,
            suppressAndOrCondition: true
          }
        },
        {
          headerName: 'Available in Store',
          field: 'is_available_in_store',
          //minWidth: 100,
          filter: 'agSetColumnFilter',
          cellRenderer: 'BooleanRender',
          filterParams: {
            values: function(params) {
              // simulating async delay
              setTimeout(function() {
                params.success(['True', 'False']);
              }, 500);
            }
          }
        },
        {
          headerName: 'Blocked',
          field: 'is_blocked',
          //minWidth: 100,
          filter: 'agSetColumnFilter',
          cellRenderer: 'BooleanRender',
          filterParams: {
            values: function(params) {
              // simulating async delay
              setTimeout(function() {
                params.success(['True', 'False']);
              }, 500);
            }
          }
        },
        {
          headerName: 'External Qty Blocked',
          field: 'is_external_quantity_blocked',
          //minWidth: 100,
          filter: 'agSetColumnFilter',
          cellRenderer: 'BooleanRender',
          filterParams: {
            values: function(params) {
              // simulating async delay
              setTimeout(function() {
                params.success(['True', 'False']);
              }, 500);
            }
          }
        },
        {
          headerName: 'Display in Store',
          field: 'is_display_in_store',
          //minWidth: 100,
          filter: 'agSetColumnFilter',
          cellRenderer: 'BooleanRender',
          filterParams: {
            values: function(params) {
              // simulating async delay
              setTimeout(function() {
                params.success(['True', 'False']);
              }, 500);
            }
          }
        },
        {
          headerName: 'Parent',
          field: 'is_parent',
          //minWidth: 100,
          filter: 'agSetColumnFilter',
          cellRenderer: 'BooleanRender',
          filterParams: {
            values: function(params) {
              // simulating async delay
              setTimeout(function() {
                params.success(['True', 'False']);
              }, 500);
            }
          }
        },
        {
          headerName: 'RelationShip',
          field: 'is_in_relationship',
          //minWidth: 100,
          filter: 'agSetColumnFilter',
          cellRenderer: 'BooleanRender',
          filterParams: {
            values: function(params) {
              // simulating async delay
              setTimeout(function() {
                params.success(['True', 'False']);
              }, 500);
            }
          }
        },
        {
          headerName: 'Deleted',
          field: 'is_deleted',
          //minWidth: 100,
          filter: 'agSetColumnFilter',
          cellRenderer: 'BooleanRender',
          filterParams: {
            values: function(params) {
              // simulating async delay
              setTimeout(function() {
                params.success(['True', 'False']);
              }, 500);
            }
          }
        },
        {
          headerName: 'Block Comment',
          field: 'block_comment',
          //minWidth: 100,
          sortable: true,
          filter: 'agTextColumnFilter',
          filterParams: {
            buttons: ['reset'],
            debounceMs: 1000,
            suppressAndOrCondition: true, // it will remove AND/OR conditions
            filterOptions: [
              'contains',
              'notContains',
              'equals',
              'notEqual',
              'startsWith',
              'endsWith',
              'In List',
              'Not In List'
              // "Is Empty",
              // "Is Not Empty"
            ]
          }
        },
        {
          headerName: 'Asin',
          field: 'asin',
          //minWidth: 100,
          sortable: true,
          filter: 'agTextColumnFilter',
          filterParams: {
            buttons: ['reset'],
            debounceMs: 1000,
            suppressAndOrCondition: true, // it will remove AND/OR conditions
            filterOptions: [
              'contains',
              'notContains',
              'equals',
              'notEqual',
              'startsWith',
              'endsWith',
              'In List',
              'Not In List'
              // "Is Empty",
              // "Is Not Empty"
            ]
          }
        },
        {
          headerName: 'EAN',
          field: 'ean',
          //minWidth: 100,
          sortable: true,
          filter: 'agTextColumnFilter',
          filterParams: {
            buttons: ['reset'],
            debounceMs: 1000,
            suppressAndOrCondition: true, // it will remove AND/OR conditions
            filterOptions: [
              'contains',
              'notContains',
              'equals',
              'notEqual',
              'startsWith',
              'endsWith',
              'In List',
              'Not In List'
              // "Is Empty",
              // "Is Not Empty"
            ]
          }
        },
        {
          headerName: 'Condition',
          field: 'condition',
          //minWidth: 100,
          sortable: true,
          filter: 'agSetColumnFilter',
          filterParams: {
            values: function(params) {
              // simulating async delay
              setTimeout(function() {
                params.success(conditionList);
              }, 500);
            }
          }
        },
        {
          headerName: 'Description',
          field: 'description',
          //minWidth: 100,
          sortable: true,
          filter: 'agTextColumnFilter',
          filterParams: {
            buttons: ['reset'],
            debounceMs: 1000,
            suppressAndOrCondition: true, // it will remove AND/OR conditions
            filterOptions: [
              'contains',
              'notContains',
              'equals',
              'notEqual',
              'startsWith',
              'endsWith',
              'In List',
              'Not In List'
              // "Is Empty",
              // "Is Not Empty"
            ]
          }
        },
        {
          headerName: 'Flag Description',
          field: 'flag_description',
          //minWidth: 100,
          sortable: true,
          filter: 'agTextColumnFilter',
          filterParams: {
            buttons: ['reset'],
            debounceMs: 1000,
            suppressAndOrCondition: true, // it will remove AND/OR conditions
            filterOptions: [
              'contains',
              'notContains',
              'equals',
              'notEqual',
              'startsWith',
              'endsWith',
              'In List',
              'Not In List'
              // "Is Empty",
              // "Is Not Empty"
            ]
          }
        },
        {
          headerName: 'Flag',
          field: 'flag',
          //minWidth: 100,
          sortable: true,
          filter: 'agSetColumnFilter',
          filterParams: {
            values: function(params) {
              // simulating async delay
              setTimeout(function() {
                params.success(flagList);
              }, 500);
            }
          }
        },
        {
          headerName: 'Harmonized Code',
          field: 'harmonized_code',
          //minWidth: 100,
          sortable: true,
          filter: 'agTextColumnFilter',
          filterParams: {
            buttons: ['reset'],
            debounceMs: 1000,
            suppressAndOrCondition: true, // it will remove AND/OR conditions
            filterOptions: [
              'contains',
              'notContains',
              'equals',
              'notEqual',
              'startsWith',
              'endsWith',
              'In List',
              'Not In List'
              // "Is Empty",
              // "Is Not Empty"
            ]
          }
        },
        {
          headerName: 'ISBN',
          field: 'isbn',
          //minWidth: 100,
          sortable: true,
          filter: 'agTextColumnFilter',
          filterParams: {
            buttons: ['reset'],
            debounceMs: 1000,
            suppressAndOrCondition: true, // it will remove AND/OR conditions
            filterOptions: [
              'contains',
              'notContains',
              'equals',
              'notEqual',
              'startsWith',
              'endsWith',
              'In List',
              'Not In List'
              // "Is Empty",
              // "Is Not Empty"
            ]
          }
        },
        {
          headerName: 'Manufacturer',
          field: 'manufacturer',
          //minWidth: 100,
          sortable: true,
          filter: 'agTextColumnFilter',
          filterParams: {
            buttons: ['reset'],
            debounceMs: 1000,
            suppressAndOrCondition: true, // it will remove AND/OR conditions
            filterOptions: [
              'contains',
              'notContains',
              'equals',
              'notEqual',
              'startsWith',
              'endsWith',
              'In List',
              'Not In List'
              // "Is Empty",
              // "Is Not Empty"
            ]
          }
        },
        {
          headerName: 'Short Description',
          field: 'short_description',
          //minWidth: 100,
          sortable: true,
          filter: 'agTextColumnFilter',
          filterParams: {
            buttons: ['reset'],
            debounceMs: 1000,
            suppressAndOrCondition: true, // it will remove AND/OR conditions
            filterOptions: [
              'contains',
              'notContains',
              'equals',
              'notEqual',
              'startsWith',
              'endsWith',
              'In List',
              'Not In List'
              // "Is Empty",
              // "Is Not Empty"
            ]
          }
        },
        {
          headerName: 'Subtitle',
          field: 'subtitle',
          //minWidth: 100,
          sortable: true,
          filter: 'agTextColumnFilter',
          filterParams: {
            buttons: ['reset'],
            debounceMs: 1000,
            suppressAndOrCondition: true, // it will remove AND/OR conditions
            filterOptions: [
              'contains',
              'notContains',
              'equals',
              'notEqual',
              'startsWith',
              'endsWith',
              'In List',
              'Not In List'
              // "Is Empty",
              // "Is Not Empty"
            ]
          }
        },
        {
          headerName: 'Tax Product Code',
          field: 'tax_product_code',
          //minWidth: 100,
          sortable: true,
          filter: 'agTextColumnFilter',
          filterParams: {
            buttons: ['reset'],
            debounceMs: 1000,
            suppressAndOrCondition: true, // it will remove AND/OR conditions
            filterOptions: [
              'contains',
              'notContains',
              'equals',
              'notEqual',
              'startsWith',
              'endsWith',
              'In List',
              'Not In List'
              // "Is Empty",
              // "Is Not Empty"
            ]
          }
        },
        {
          headerName: 'UPC',
          field: 'upc',
          //minWidth: 100,
          sortable: true,
          filter: 'agTextColumnFilter',
          filterParams: {
            buttons: ['reset'],
            debounceMs: 1000,
            suppressAndOrCondition: true, // it will remove AND/OR conditions
            filterOptions: [
              'contains',
              'notContains',
              'equals',
              'notEqual',
              'startsWith',
              'endsWith',
              'In List',
              'Not In List'
              // "Is Empty",
              // "Is Not Empty"
            ]
          }
        },
        {
          headerName: 'Warehouse Location',
          field: 'warehouse_location',
          //minWidth: 100,
          sortable: true,
          filter: 'agTextColumnFilter',
          filterParams: {
            buttons: ['reset'],
            debounceMs: 1000,
            suppressAndOrCondition: true, // it will remove AND/OR conditions
            filterOptions: [
              'contains',
              'notContains',
              'equals',
              'notEqual',
              'startsWith',
              'endsWith',
              'In List',
              'Not In List'
              // "Is Empty",
              // "Is Not Empty"
            ]
          }
        },
        {
          headerName: 'Warranty',
          field: 'warranty',
          //minWidth: 100,
          sortable: true,
          filter: 'agTextColumnFilter',
          filterParams: {
            buttons: ['reset'],
            debounceMs: 1000,
            suppressAndOrCondition: true, // it will remove AND/OR conditions
            filterOptions: [
              'contains',
              'notContains',
              'equals',
              'notEqual',
              'startsWith',
              'endsWith',
              'In List',
              'Not In List'
              // "Is Empty",
              // "Is Not Empty"
            ]
          }
        },
        {
          headerName: 'Multipack Qty',
          field: 'multipack_quantity',
          //minWidth: 100,
          sortable: true,
          filter: 'agNumberColumnFilter',
          filterParams: {
            buttons: ['reset'],
            debounceMs: 1000,
            suppressAndOrCondition: true
          }
        },
        {
          headerName: 'Height',
          field: 'height',
          //minWidth: 100,
          sortable: true,
          filter: 'agNumberColumnFilter',
          filterParams: {
            buttons: ['reset'],
            debounceMs: 1000,
            suppressAndOrCondition: true
          }
        },
        {
          headerName: 'Length',
          field: 'length',
          //minWidth: 100,
          sortable: true,
          filter: 'agNumberColumnFilter',
          filterParams: {
            buttons: ['reset'],
            debounceMs: 1000,
            suppressAndOrCondition: true
          }
        },
        {
          headerName: 'Width',
          field: 'width',
          //minWidth: 100,
          sortable: true,
          filter: 'agNumberColumnFilter',
          filterParams: {
            buttons: ['reset'],
            debounceMs: 1000,
            suppressAndOrCondition: true
          }
        },
        {
          headerName: 'Weight',
          field: 'weight',
          //minWidth: 100,
          sortable: true,
          filter: 'agNumberColumnFilter',
          filterParams: {
            buttons: ['reset'],
            debounceMs: 1000,
            suppressAndOrCondition: true
          }
        },
        {
          headerName: 'Cost',
          field: 'cost',
          //minWidth: 100,
          editable: true,
          sortable: true,
          filter: 'agNumberColumnFilter',
          filterParams: {
            buttons: ['reset'],
            debounceMs: 1000,
            suppressAndOrCondition: true
          }
        },
        {
          headerName: 'Margin',
          field: 'margin',
          //minWidth: 100,
          sortable: true,
          filter: 'agNumberColumnFilter',
          filterParams: {
            buttons: ['reset'],
            debounceMs: 1000,
            suppressAndOrCondition: true
          }
        },
        {
          headerName: 'Starting Price',
          field: 'starting_price',
          //minWidth: 100,
          sortable: true,
          filter: 'agNumberColumnFilter',
          filterParams: {
            buttons: ['reset'],
            debounceMs: 1000,
            suppressAndOrCondition: true
          }
        },
        {
          headerName: 'Reserve Price',
          field: 'reserve_price',
          //minWidth: 100,
          sortable: true,
          filter: 'agNumberColumnFilter',
          filterParams: {
            buttons: ['reset'],
            debounceMs: 1000,
            suppressAndOrCondition: true
          }
        },
        {
          headerName: 'Store Price',
          field: 'store_price',
          //minWidth: 100,
          sortable: true,
          filter: 'agNumberColumnFilter',
          filterParams: {
            buttons: ['reset'],
            debounceMs: 1000,
            suppressAndOrCondition: true
          }
        },
        {
          headerName: 'Second Chance Price',
          field: 'second_chance_price',
          //minWidth: 100,
          sortable: true,
          filter: 'agNumberColumnFilter',
          filterParams: {
            buttons: ['reset'],
            debounceMs: 1000,
            suppressAndOrCondition: true
          }
        },
        {
          headerName: 'Min Price',
          field: 'min_price',
          //minWidth: 100,
          sortable: true,
          filter: 'agNumberColumnFilter',
          filterParams: {
            buttons: ['reset'],
            debounceMs: 1000,
            suppressAndOrCondition: true
          }
        },
        {
          headerName: 'Max Price',
          field: 'max_price',
          //minWidth: 100,
          sortable: true,
          filter: 'agNumberColumnFilter',
          filterParams: {
            buttons: ['reset'],
            debounceMs: 1000,
            suppressAndOrCondition: true
          }
        },
        {
          headerName: 'Estimated Shipping Cost',
          field: 'estimated_shipping_cost',
          //minWidth: 100,
          sortable: true,
          filter: 'agNumberColumnFilter',
          filterParams: {
            buttons: ['reset'],
            debounceMs: 1000,
            suppressAndOrCondition: true
          }
        },
        {
          headerName: 'Supplier Name',
          field: 'supplier_name',
          //minWidth: 100,
          sortable: true,
          filter: 'agTextColumnFilter',
          filterParams: {
            buttons: ['reset'],
            debounceMs: 1000,
            suppressAndOrCondition: true, // it will remove AND/OR conditions
            filterOptions: [
              'contains',
              'notContains',
              'equals',
              'notEqual',
              'startsWith',
              'endsWith',
              'In List',
              'Not In List'
              // "Is Empty",
              // "Is Not Empty"
            ]
          }
        },
        {
          headerName: 'Supplier Code',
          field: 'supplier_code',
          //minWidth: 100,
          sortable: true,
          filter: 'agTextColumnFilter',
          filterParams: {
            buttons: ['reset'],
            debounceMs: 1000,
            suppressAndOrCondition: true, // it will remove AND/OR conditions
            filterOptions: [
              'contains',
              'notContains',
              'equals',
              'notEqual',
              'startsWith',
              'endsWith',
              'In List',
              'Not In List'
              // "Is Empty",
              // "Is Not Empty"
            ]
          }
        },
        {
          headerName: 'Supplier Po',
          field: 'supplier_po',
          //minWidth: 100,
          sortable: true,
          filter: 'agTextColumnFilter',
          filterParams: {
            buttons: ['reset'],
            debounceMs: 1000,
            suppressAndOrCondition: true, // it will remove AND/OR conditions
            filterOptions: [
              'contains',
              'notContains',
              'equals',
              'notEqual',
              'startsWith',
              'endsWith',
              'In List',
              'Not In List'
              // "Is Empty",
              // "Is Not Empty"
            ]
          }
        },
        {
          headerName: 'Classification',
          field: 'classification',
          //minWidth: 100,
          sortable: true,
          filter: 'agTextColumnFilter',
          filterParams: {
            buttons: ['reset'],
            debounceMs: 1000,
            suppressAndOrCondition: true, // it will remove AND/OR conditions
            filterOptions: [
              'contains',
              'notContains',
              'equals',
              'notEqual',
              'startsWith',
              'endsWith',
              'In List',
              'Not In List'
              // "Is Empty",
              // "Is Not Empty"
            ]
          }
        },
        {
          headerName: 'Store Title',
          field: 'store_title',
          //minWidth: 100,
          sortable: true,
          filter: 'agTextColumnFilter',
          filterParams: {
            buttons: ['reset'],
            debounceMs: 1000,
            suppressAndOrCondition: true, // it will remove AND/OR conditions
            filterOptions: [
              'contains',
              'notContains',
              'equals',
              'notEqual',
              'startsWith',
              'endsWith',
              'In List',
              'Not In List'
              // "Is Empty",
              // "Is Not Empty"
            ]
          }
        },
        {
          headerName: 'Store Description',
          field: 'store_description',
          //minWidth: 100,
          sortable: true,
          filter: 'agTextColumnFilter',
          filterParams: {
            buttons: ['reset'],
            debounceMs: 1000,
            suppressAndOrCondition: true, // it will remove AND/OR conditions
            filterOptions: [
              'contains',
              'notContains',
              'equals',
              'notEqual',
              'startsWith',
              'endsWith',
              'In List',
              'Not In List'
              // "Is Empty",
              // "Is Not Empty"
            ]
          }
        },
        {
          headerName: 'Bundle Type',
          field: 'bundle_type',
          //minWidth: 100,
          sortable: true,
          filter: 'agTextColumnFilter',
          filterParams: {
            buttons: ['reset'],
            debounceMs: 1000,
            suppressAndOrCondition: true, // it will remove AND/OR conditions
            filterOptions: [
              'contains',
              'notContains',
              'equals',
              'notEqual',
              'startsWith',
              'endsWith',
              'In List',
              'Not In List'
              // "Is Empty",
              // "Is Not Empty"
            ]
          }
        },
        {
          headerName: 'Allocated Qty',
          field: 'open_allocated_quantity',
          //minWidth: 100,
          sortable: true,
          filter: 'agNumberColumnFilter',
          filterParams: {
            buttons: ['reset'],
            debounceMs: 1000,
            suppressAndOrCondition: true
          }
        },
        {
          headerName: 'Allocated Qty Pooled',
          field: 'open_allocated_quantity_pooled',
          //minWidth: 100,
          sortable: true,
          filter: 'agNumberColumnFilter',
          filterParams: {
            buttons: ['reset'],
            debounceMs: 1000,
            suppressAndOrCondition: true
          }
        },
        {
          headerName: 'Pending Checkout Qty',
          field: 'pending_checkout_quantity',
          //minWidth: 100,
          sortable: true,
          filter: 'agNumberColumnFilter',
          filterParams: {
            buttons: ['reset'],
            debounceMs: 1000,
            suppressAndOrCondition: true
          }
        },
        {
          headerName: 'Pending Checkout Qty Pooled',
          field: 'pending_checkout_quantity_pooled',
          //minWidth: 100,
          sortable: true,
          filter: 'agNumberColumnFilter',
          filterParams: {
            buttons: ['reset'],
            debounceMs: 1000,
            suppressAndOrCondition: true
          }
        },
        {
          headerName: 'Pending Qty Pooled',
          field: 'pending_payment_quantity_pooled',
          //minWidth: 100,
          sortable: true,
          filter: 'agNumberColumnFilter',
          filterParams: {
            buttons: ['reset'],
            debounceMs: 1000,
            suppressAndOrCondition: true
          }
        },
        {
          headerName: 'Pending Shipment Qty Pooled',
          field: 'pending_shipment_quantity_pooled',
          //minWidth: 100,
          sortable: true,
          filter: 'agNumberColumnFilter',
          filterParams: {
            buttons: ['reset'],
            debounceMs: 1000,
            suppressAndOrCondition: true
          }
        },
        {
          headerName: 'Total Qty Pooled',
          field: 'total_quantity_pooled',
          //minWidth: 100,
          sortable: true,
          filter: 'agNumberColumnFilter',
          filterParams: {
            buttons: ['reset'],
            debounceMs: 1000,
            suppressAndOrCondition: true
          }
        },
        {
          headerName: 'Qty Sold 7 Days',
          field: 'quantity_sold_last7_days',
          //minWidth: 100,
          sortable: true,
          filter: 'agNumberColumnFilter',
          filterParams: {
            buttons: ['reset'],
            debounceMs: 1000,
            suppressAndOrCondition: true
          }
        },
        {
          headerName: 'Qty Sold 14 Days',
          field: 'quantity_sold_last14_days',
          //minWidth: 100,
          sortable: true,
          filter: 'agNumberColumnFilter',
          filterParams: {
            buttons: ['reset'],
            debounceMs: 1000,
            suppressAndOrCondition: true
          }
        },
        {
          headerName: 'Qty Sold 30 Days',
          field: 'quantity_sold_last30_days',
          //minWidth: 100,
          sortable: true,
          filter: 'agNumberColumnFilter',
          filterParams: {
            buttons: ['reset'],
            debounceMs: 1000,
            suppressAndOrCondition: true
          }
        },
        {
          headerName: 'Qty Sold 60 Days',
          field: 'quantity_sold_last60_days',
          //minWidth: 100,
          sortable: true,
          filter: 'agNumberColumnFilter',
          filterParams: {
            buttons: ['reset'],
            debounceMs: 1000,
            suppressAndOrCondition: true
          }
        },
        {
          headerName: 'Qty Sold 90 Days',
          field: 'quantity_sold_last90_days',
          //minWidth: 100,
          sortable: true,
          filter: 'agNumberColumnFilter',
          filterParams: {
            buttons: ['reset'],
            debounceMs: 1000,
            suppressAndOrCondition: true
          }
        },
        {
          headerName: 'Copy To Children',
          field: 'copy_to_children',
          //minWidth: 100,
          sortable: true,
          filter: 'agTextColumnFilter',
          filterParams: {
            buttons: ['reset'],
            debounceMs: 1000,
            suppressAndOrCondition: true, // it will remove AND/OR conditions
            filterOptions: [
              'contains',
              'notContains',
              'equals',
              'notEqual',
              'startsWith',
              'endsWith',
              'In List',
              'Not In List'
              // "Is Empty",
              // "Is Not Empty"
            ]
          }
        },
        {
          headerName: 'Parent SKU',
          field: 'parent_sku',
          //minWidth: 100,
          sortable: true,
          filter: 'agTextColumnFilter',
          filterParams: {
            buttons: ['reset'],
            debounceMs: 1000,
            suppressAndOrCondition: true, // it will remove AND/OR conditions
            filterOptions: [
              'contains',
              'notContains',
              'equals',
              'notEqual',
              'startsWith',
              'endsWith',
              'In List',
              'Not In List'
              // "Is Empty",
              // "Is Not Empty"
            ]
          }
        },
        {
          headerName: 'Channel Advisor Parent ID',
          field: 'channel_advisor_parent_id',
          //minWidth: 100,
          sortable: true,
          filter: 'agNumberColumnFilter',
          filterParams: {
            buttons: ['reset'],
            debounceMs: 1000,
            suppressAndOrCondition: true
          }
        },
        {
          headerName: 'Relationship Name',
          field: 'relationship_name',
          //minWidth: 100,
          sortable: true,
          filter: 'agTextColumnFilter',
          filterParams: {
            buttons: ['reset'],
            debounceMs: 1000,
            suppressAndOrCondition: true, // it will remove AND/OR conditions
            filterOptions: [
              'contains',
              'notContains',
              'equals',
              'notEqual',
              'startsWith',
              'endsWith',
              'In List',
              'Not In List'
              // "Is Empty",
              // "Is Not Empty"
            ]
          }
        },
        {
          headerName: 'Profile',
          field: 'profile_data',
          //minWidth: 100,
          sortable: true,
          filter: 'agSetColumnFilter',
          filterParams: {
            values: function(params) {
              // simulating async delay
              setTimeout(function() {
                params.success(profileList);
              }, 500);
            }
          }
        },
        {
          headerName: 'Labels',
          field: 'label_data',
          //minWidth: 100,
          sortable: true,
          filter: 'agSetColumnFilter',
          filterParams: {
            values: function(params) {
              // simulating async delay
              setTimeout(function() {
                params.success(labelList);
              }, 500);
            }
          }
        },
        {
          headerName: 'Last Updated',
          field: 'updated_on',
          sortable: true,
          cellRenderer: 'DateTimeRender'
        },
        {
          headerName: 'Last Quantity Updated At',
          field: 'quantity_update_date_utc',
          sortable: true,
          minWidth: 100,
          cellRenderer: 'DateTimeRender'
        },
        {
          headerName: 'StockX Product Id',
          field: 'stockx_product_id',
          sortable: true,
          editable: true,
          suppressPaste: true,
          minWidth: 100
        },
        {
          headerName: 'StockX Variant Id',
          field: 'stockx_variant_id',
          sortable: true,
          editable: true,
          suppressPaste: true,
          minWidth: 100
        }
      ],
      defaultColDef: {
        flex: 1,
        resizable: true,
        tooltipComponent: 'customTooltip',
        floatingFilter: true
      },
      rowModelType: 'infinite',
      frameworkComponents: {
        customNoRowsOverlay: CustomNoRowsOverlay,
        customTooltip: CustomTooltip,
        imageRender: imageRender,
        loadingRender: loadingRender,
        publishedRender: publishedRender,
        BooleanRender: BooleanRender,
        viewRender: viewRender,
        DateTimeRender: DateTimeRender
      },
      loadingCellRenderer: 'loadingRender',
      loadingCellRendererParams: {
        loadingMessage: 'One moment please...'
      },
      loadingOverlayComponent: 'loadingRender',
      totalProductsCount: 0,
      catalogfilterCount: 0,
      noRowsOverlayComponent: 'customNoRowsOverlay',
      noRowsOverlayComponentParams: {
        noRowsMessageFunc: function() {
          return 'Sorry - No Products!';
        }
      },
      rowSelection: 'multiple',
      tooltipShowDelay: 0,
      filterStatus: false,
      product_ids: [],
      showAction: false,
      saveBtn: false,
      countNill: true,
      paginationPageSize: 50,
      cacheBlockSize: 50,
      context: { componentParent: this },
      saveInput: false, // to show the input tag, when save as is given
      displayDropDownConfig: [], // to get multiple-labels response
      activeId: '', // to show active Label in dropdown
      saveCheck: true,
      loading: false,
      export_loading: false
    };
    this.hideAlert = this.hideAlert.bind(this);
    this.handlePublish = this.handlePublish.bind(this);
    this.onSelectionChanged = this.onSelectionChanged.bind(this);
    this.onFilterCount = this.onFilterCount.bind(this);
    this.onShowAction = this.onShowAction.bind(this);
    this.fetchSync = this.fetchSync.bind(this);
    this.setDefaultWidth = this.setDefaultWidth.bind(this);
    this.deleteStaleProduct = this.deleteStaleProduct.bind(this);
    this.handleDeleteStaleProduct = this.handleDeleteStaleProduct.bind(
      this
    );
  }

  // used to get the data of selected rows
  onSelectionChanged = () => {
    // remove boolean values after filtering published to ids
    let product_ids = this.gridApi
      .getSelectedRows()
      .map(function(eachRow) {
        return {
          id: eachRow.id,
          published: eachRow.is_published_to_voyageone
        };
      });
    this.setState({
      product_ids: product_ids,
      showAction: true
    });
  };

  // function to show no rows overlay
  onBtShowNoRows = display => {
    if (display) this.gridApi.showNoRowsOverlay();
    else this.gridApi.hideOverlay();
  };

  // function to set the total and filtered count
  onFilterCount = (
    status,
    catalogfilterCount,
    totalProductsCount
  ) => {
    status
      ? this.setState({ filterStatus: true, countNill: true })
      : this.setState({ filterStatus: false, countNill: true });
    this.setState({ catalogfilterCount, totalProductsCount });
  };

  // function to hide count
  onCountNill = () => {
    this.setState({ countNill: false });
  };

  // function to get the label name
  handleInputOfSave = event => {
    this.setState({ labelOfSaved: event.target.value });
  };

  // function to get the label's field visiblity & width
  handleDisplayChange = event => {
    let selectedId = event.target.value;
    this.setState({ saveBtn: true, saveCheck: true, loading: true });
    this.onBtShowHideLoading(true); // to show loading

    fetchUrl(
      `${CUSTOMIZATION_GRID_API_URL}${selectedId}`,
      ({ status, response }) => {
        this.onBtShowHideLoading(false); // to show loading
        this.setState({ loading: false });

        if (status) {
          this.setState({ activeId: selectedId });
          if (response.data) {
            configLable = response.data.label;
            const newDisplayDropDownConfig = this.state.displayDropDownConfig.map(
              each => ({
                ...each,
                is_active: each.label === response.data.label
              })
            );
            this.setState({
              displayDropDownConfig: newDisplayDropDownConfig
            });
            Object.keys(response.data.fields).forEach(eachTo => {
              if (eachTo === 'image' || eachTo === 'created_on') {
                // setting default width to img and created_on fields
                this.gridColumnApi.setColumnWidth(eachTo, 75);
              } else {
                /* setting column Width & Visiblity by
                negative the hide value getting from BE */
                this.gridColumnApi.setColumnWidth(
                  eachTo,
                  response.data.field_widths[eachTo]
                );
                this.gridColumnApi.setColumnVisible(
                  eachTo,
                  !response.data.fields[eachTo]
                );
                // this.gridApi.setFilterModel(response.data.grid_params);
                // this.callSetFilterModel(response.data.grid_params)
              }
            });
            this.gridApi.setFilterModel(response.data.grid_params);
          }
        } else {
          if (
            response.response &&
            response.response.status &&
            response.response.status === 401
          ) {
            handleError(response.response);
          }
        }
      }
    );
  };

  // function to show Action dropdown
  onShowAction = showActionBool => {
    this.setState({ showAction: showActionBool });
  };

  // function to enable/disable input tag to get label config name
  saveAsState = getValueOfSave => {
    this.setState({ saveInput: getValueOfSave });
  };

  clearFilters = () => {
    this.gridApi.setFilterModel(null);
  };

  paginationSetUp = setUpPage => {
    localStorage.setItem('page', setUpPage);
    window.location = '/catalogs';
  };

  deleteFilter = () => {
    if (this.state.activeId) {
      apiDelete(
        `${CUSTOMIZATION_GRID_API_URL}${this.state.activeId}/`,
        'delete',
        ({ status, response }) => {
          console.log(status);
          console.log(response);
          if (status) {
            this.fetchMessage(true, response, 'delete');
            this.fetchProductDisplay(''); // to fetch product properties
          } else {
            this.fetchMessage(null, response, 'delete');
          }
        }
      );
    }
  };
  // Function to save the configured field and api integration
  saveState = (inputMethod, prodId) => {
    let urlForSave;
    let methodOfSave;
    let paramsOfSave;

    // to get the hide status from grid for each fields
    let saveFieldsVisiblity = saveAction(
      this.gridColumnApi,
      'visiblity'
    );
    // to get the width number from grid for each fields
    let saveFieldsWidth = saveAction(this.gridColumnApi, 'width');

    this.setState({ saveBtn: false, saveCheck: false }); // to hide Save Btn
    this.onBtShowHideLoading(true); // to show loading

    let queryData = urlParams.split('?')[1];
    // queryData = `&{queryData}`

    // updating the existing labels
    if (inputMethod === 'save') {
      urlForSave = `${CUSTOMIZATION_GRID_API_URL}${prodId}/?${queryData}`;
      methodOfSave = 'put';
      paramsOfSave = {
        fields: saveFieldsVisiblity,
        field_widths: saveFieldsWidth,
        grid_params: gridParamGrid
      };
    } else {
      // creating new label
      // when saveAs method called
      urlForSave = `${CUSTOMIZATION_GRID_API_URL}?${queryData}`;
      methodOfSave = 'post';
      paramsOfSave = {
        fields: saveFieldsVisiblity,
        field_widths: saveFieldsWidth,
        label: this.state.labelOfSaved,
        grid_params: gridParamGrid,
        page_type: pageType,
        user: localStorage.getItem('userId')
      };
    }
    apiEdit(
      urlForSave,
      paramsOfSave,
      methodOfSave,
      ({ status, response }) => {
        if (status) {
          this.onBtShowHideLoading(false); // to show loading
          /* after creation is completed, getting all
        the labels and setting the created label as active */
          inputMethod === 'saveAs' &&
            this.fetchProductDisplay('saveAs');
        } else {
          this.saveAsState(false); // to hide input tag for fetching label
          if (
            response.response &&
            response.response.status &&
            response.response.status === 401
          ) {
            handleError(response.response);
          } else if (this.checkFunction(response)) {
            this.fetchMessage('redirectTrue');
          } else {
            this.fetchMessage(
              false,
              response.response,
              'cancelVisiblity'
            );
          }
        }
      }
    );
  };

  // this function will trigger whenever column width is changed
  onColumnMoved = event => {
    this.setState({ saveBtn: load ? true : false });
  };

  // this function will trigger whenever column visiblity is changed
  onColumnVisible = event => {
    responseOnGrid &&
      load &&
      this.setState({ saveBtn: load ? true : false });
  };

  // function to set width for columns visibled
  setDefaultWidth = () => {
    displayColumns.forEach(each => {
      if (each.is_active) {
        Object.keys(each.field_widths).forEach(eachKey => {
          if (eachKey === 'image' || eachKey === 'created_on') {
            this.gridColumnApi.setColumnWidth(eachKey, 75);
          } else {
            this.gridColumnApi.setColumnWidth(
              eachKey,
              each.field_widths[eachKey]
            );
          }
        });
      }
    });
  };

  cellValueChanged(event) {
    const colIdValue = { [event.column.colId]: event.newValue };
    this.onBtShowHideLoading(true); // to show loading
    // when cell event is having no value initially
    if (typeof event.newValue !== 'undefined') {
      apiEdit(
        `${CATALOG_API_URL}${event.data.id}/`,
        colIdValue,
        'patch',
        ({ status, response }) => {
          if (status) {
            this.onBtShowHideLoading(false); // to hide loading
            this.fetchMessage(true, response, 'retailPublish');
          } else {
            this.onBtShowHideLoading(false); // to hide input tag for fetching label
            if (
              response.response &&
              response.response.status &&
              response.response.status === 401
            ) {
              handleError(response.response);
            } else if (this.checkFunction(response)) {
              this.fetchMessage('redirectTrue');
            } else {
              this.gridApi.forEachNode(function(rowNode) {
                // if (idsToUpdate.includes(rowNode.data.id)) {
                if (event.data.id === rowNode.data.id) {
                  var updated = JSON.parse(
                    JSON.stringify(rowNode.data)
                  );
                  updated[event.column.colId] = event.oldValue;
                  rowNode.setData(updated);
                }
              });
              this.fetchMessage(
                false,
                response.response,
                'retailPublish'
              );
            }
          }
        }
      );
    } else {
      this.onBtShowHideLoading(false); // to hide loading
    }
  }

  // fn triggers, whenever filter value is changed
  filterChanged(event) {
    // console.log("filterchanged")
    setTimeout(() => {
      this.setState({ saveBtn: load ? true : false });
      pageParams =
        Object.keys(this.gridApi.getFilterModel()).length > 0
          ? true
          : false;
      this.state.columnDefs.forEach(each => {
        if (
          Object.keys(this.gridApi.getFilterModel()).includes(
            each.field
          )
        ) {
          Array.from(
            document.getElementsByClassName(`ag-input-field-input`)
          ).forEach(o => {
            if (
              o.getAttribute('aria-label') ===
              `${each.headerName} Filter Input`
            )
              o.className += ' yellowFilterInput';
          });
        } else {
          Array.from(
            document.getElementsByClassName(`yellowFilterInput`)
          ).forEach(o => {
            if (
              o.getAttribute('aria-label') ===
              `${each.headerName} Filter Input`
            ) {
              o.classList.remove('yellowFilterInput');
            }
          });
        }
      });
    }, 5000);
  }

  // to set the initially filtered param values
  callSetFilterModel = value => {
    // console.log("callSetFilter")
    this.gridApi.setFilterModel(gridParams);
    pageParams = Object.keys(gridParams).length > 0 ? true : false;

    if (Object.keys(gridParams).length > 0) {
      this.state.columnDefs.forEach(each => {
        if (Object.keys(gridParams).includes(each.field)) {
          Array.from(
            document.getElementsByClassName(`ag-input-field-input`)
          ).forEach(o => {
            if (
              o.getAttribute('aria-label') ===
              `${each.headerName} Filter Input`
            )
              o.className += ' yellowFilterInput';
          });
        }
      });
    }
    Object.keys(gridParams).length === 0 &&
      this.gridApi.onFilterChanged();
  };

  onGridReady = params => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;

    // console
    this.setState({ params: params });
    // this.autoSizeAll(false);

    var datasource = createServerSideDatasource(
      this.onBtShowNoRows,
      this.onFilterCount,
      this.onShowAction,
      this.onCountNill,
      params
    );

    params.api.setDatasource(datasource);

    params.api.addGlobalListener(function(type, event) {
      if (type === 'componentStateChanged' && !firstComp) {
        if (displayColumns.length > 0) {
          firstComp = true;
          params.api.gridOptionsWrapper.gridOptions.context.componentParent.setDefaultWidth(); // to set the width when grid loads
          params.api.gridOptionsWrapper.gridOptions.context.componentParent.callSetFilterModel(); // to set Filter Params in Grid Columns
        }
        displayColumns.length > 0 &&
          displayColumns.forEach(each => {
            // if is_active true, configuration is setup
            if (each.is_active) {
              Object.keys(each.fields).forEach(eachTo => {
                // negating the values of field to make the field visible
                return params.columnApi.setColumnVisible(
                  eachTo,
                  !each.fields[eachTo]
                );
              });
            }
          });
      }
      if (type === 'columnHoverChanged' && responseOnGrid) {
        load = true;
      }
    });
  };

  // function to remove sweetalert popups
  hideAlert = (mode, modeAction) => {
    this.setState({ sweetAlert: '' });

    /*if modeAction is cancelVisiblity,
    then shows reset Action, else calling grid and deselecting all rows */
    if (
      modeAction !== 'cancelVisiblity' &&
      modeAction !== 'retailPublish'
    ) {
      // load grid when shipment got success
      (mode === 'success' || mode === 'error') &&
        this.onGridReady(this.state.params);
      // deselect all rows when api got success or error
      (mode === 'success' || mode === 'error') &&
        this.state.params.api.deselectAll();
    } else {
      this.setState({ saveBtn: true });
    }
  };

  // function to show/hide loading overlay components
  onBtShowHideLoading = showHideState => {
    //  if true: show loading component
    // else hide loading component
    showHideState
      ? this.gridApi.showLoadingOverlay()
      : this.gridApi.hideOverlay();
  };

  // function to show Message pops on success/error response
  fetchMessage = (status, response, stateOfAction) => {
    const message = (
      <MessageComponent
        page="catalogs"
        action={stateOfAction}
        hideAlert={this.hideAlert}
        hideOverlay={this.onBtShowHideLoading}
        response={response}
        status={status}
      />
    );
    this.setState({ sweetAlert: message });
  };

  // function to check the error due to 404/500
  checkFunction = error => {
    if (
      error.response &&
      error.response.status &&
      (error.response.status === 404 ||
        error.response.status === 500)
      //  || !error.response
    ) {
      return true;
    }
  };

  // function to call api for bulk shipment
  handlePublish = getState => {
    this.setState({ sweetAlert: '' }); // to hide input pop up of shipment
    this.onBtShowHideLoading(true); // to show loading
    apiPost(PUBLISH_API_URL, getState, ({ status, response }) => {
      if (status) {
        this.fetchMessage(true, response, 'publish');
      } else {
        if (
          response.response &&
          response.response.status &&
          response.response.status === 401
        ) {
          handleError(response.response);
        } else if (this.checkFunction(response)) {
          this.fetchMessage('redirectTrue');
        } else {
          this.fetchMessage(false, response.response, 'publish');
        }
      }
    });
  };

  // Function to filter product_ids based on published or not
  filterSelection = modeOfAction => {
    // console.log("filterSelection")
    if (modeOfAction === 'sync') {
      // function to return list of ids
      publishIds = this.state.product_ids.map(each => each.id);
      return publishIds;
    } else {
      /* used lodash to filter the array of product_ids
    with field name published and modeOfaction */
      publishIds = _.filter(this.state.product_ids).map(
        each => each.id
      );
      return publishIds;
    }
  };

  // function to fetch publish popups
  fetchPublish = () => {
    this.filterSelection(false);
    const sweet = (
      <PublishComponent
        handlePublish={this.handlePublish}
        product_ids={publishIds}
        toCheckPublish={toCheckPublish}
        hideAlert={this.hideAlert}
      />
    );
    this.setState({ sweetAlert: sweet });
  };

  handleUnpublish = getState => {
    this.setState({ sweetAlert: '' }); // to hide input pop up of shipment
    this.onBtShowHideLoading(true); // to show loading
    // const data = { product_list: publishIds };
    apiPost(UNPUBLISH_API_URL, getState, ({ status, response }) => {
      if (status) {
        this.fetchMessage(true, response, 'unpublish');
      } else {
        if (
          response.response &&
          response.response.status &&
          response.response.status === 401
        ) {
          handleError(response.response);
        } else if (this.checkFunction(response)) {
          this.fetchMessage('redirectTrue');
        } else {
          this.fetchMessage(false, response.response, 'unpublish');
        }
      }
    });
  };

  // function to call unpublish api
  fetchUnPublish = () => {
    this.filterSelection(true);
    const sweet = (
      <UnpublishComponent
        handleUnpublish={this.handleUnpublish}
        product_ids={publishIds}
        hideAlert={this.hideAlert}
        toCheckPublish={toCheckPublish}
      />
    );
    this.setState({ sweetAlert: sweet });
  };

  // function to call sync api
  fetchSync = () => {
    this.onBtShowHideLoading(true); // to show loading
    this.filterSelection('sync');
    const data = { product_list: publishIds };
    apiPost(SYNC_API_URL, data, ({ status, response }) => {
      if (status) {
        this.fetchMessage(true, response, 'sync');
      } else {
        if (
          response.response &&
          response.response.status &&
          response.response.status === 401
        ) {
          handleError(response.response);
        } else if (this.checkFunction(response)) {
          this.fetchMessage('redirectTrue');
        } else {
          this.fetchMessage(false, response.response, 'sync');
        }
      }
    });
  };

  // function to get product properties
  fetchProductDisplay = getValue => {
    fetchUrl(PRODUCT_PROPERTIES_API_URL, ({ status, response }) => {
      if (status) {
        this.setState({ response: response.data });
        let value = response.data.display_config.filter(
          value => value.is_active === true
        );
        let configLables = response.data.display_config.filter(
          value => value.is_active === true
        );
        let pageTypes = response.data.display_config.filter(
          value => value.is_active === true
        );
        configLable =
          configLables && configLables[0]
            ? configLables[0].label
            : null;
        productTypes = response.data.product_types;
        distributionCenters = response.data.distribution_centers;
        marketplaces = response.data.marketplaces;
        conditionList = response.data.condition;
        flagList = response.data.flag;
        profileList = response.data.profile;
        labelList = response.data.labels;
        displayColumns = response.data.display_config;
        // gridParams = response.data.page_params[0].grid_params;
        gridParams =
          value && value[0].grid_params ? value[0].grid_params : {};
        pageType =
          pageTypes && pageTypes[0] ? pageTypes[0].page_type : '';
        this.setState({
          displayDropDownConfig: response.data.display_config
        });
        let activeProduct =
          response.data.display_config.length > 0 &&
          _.find(response.data.display_config, { is_active: true });
        activeProduct &&
          this.setState({
            activeId: activeProduct.id
          });
        getValue &&
          this.setState({ saveInput: false, saveBtn: false });
      } else {
        setTimeout(() => {
          if (
            response.response &&
            response.response.status &&
            response.response.status === 401
          ) {
            handleError(response.response);
          }
        }, 1000);
      }
    });
  };

  handleDeleteStaleProduct = data => {
    this.setState({ sweetAlert: '' }); // to hide input pop up of shipment
    this.onBtShowHideLoading(true); // to show loading
    // console.log("product id length", data.product_ids.length)
    let id = data.product_ids;
    if (data.product_ids.length === 1) {
      apiDelete(
        `${DELETE_PRODUCT}${id}/`,
        'delete',
        ({ status, response }) => {
          if (status) {
            this.fetchMessage(true, response, 'delete');
          } else {
            if (
              response.response &&
              response.response.status &&
              response.response.status === 401
            ) {
              handleError(response.response);
            } else if (this.checkFunction(response)) {
              this.fetchMessage('redirectTrue');
            } else {
              this.fetchMessage(false, response.response, 'delete');
            }
          }
        }
      );
    } else {
      apiPost(BULK_DELETE_PRODUCT, data, ({ status, response }) => {
        if (status) {
          this.fetchMessage(true, response, 'delete');
        } else {
          if (
            response.response &&
            response.response.status &&
            response.response.status === 401
          ) {
            handleError(response.response);
          } else if (this.checkFunction(response)) {
            this.fetchMessage('redirectTrue');
          } else {
            this.fetchMessage(false, response.response, 'delete');
          }
        }
      });
    }
  };

  downloadFile = ({ data, fileName, fileType }) => {
    // Create a blob with the data we want to download as a file
    const blob = new Blob([data], { type: fileType });
    // Create an anchor element and dispatch a click event on it
    // to trigger a download
    const a = document.createElement('a');
    a.download = fileName;
    a.href = window.URL.createObjectURL(blob);
    const clickEvt = new MouseEvent('click', {
      view: window,
      bubbles: true,
      cancelable: true
    });
    a.dispatchEvent(clickEvt);
    a.remove();
  };

  // function to handle export
  handleExport = () => {
    this.setState({ export_loading: true });
    const headers = {
      Authorization: `Token ${localStorage.getItem('token')}`
    };

    axios(urlParams + '&export=true', {
      method: 'post',
      headers,
      responseType: 'blob', //important
      data: { fields: saveAction(this.gridColumnApi, 'visiblity') }
    })
      .then(({ data }) => {
        this.setState({ export_loading: false });
        this.fetchMessage(
          true,
          { data: { message: 'Export Initiated Successfully' } },
          'retailPublish'
        );
      })
      .catch(error => {
        this.setState({ export_loading: false });
        console.log(error);
      });
  };

  // function to  delete stale product
  deleteStaleProduct = () => {
    // console.log("nitu")
    this.filterSelection(false);
    const sweet = (
      <StaleProductDelete
        handleDeleteStaleProduct={this.handleDeleteStaleProduct}
        product_ids={publishIds}
        toCheckPublish={toCheckPublish}
        hideAlert={this.hideAlert}
      />
    );
    this.setState({ sweetAlert: sweet });
  };

  componentDidMount() {
    // localStorage.setItem("page", false); // to set pagination
    firstComp = false;
    load = false;
    productTypes = [];
    distributionCenters = [];
    conditionList = [];
    flagList = [];
    labelList = [];
    profileList = {};
    publishIds = [];
    displayColumns = [];
    responseOnGrid = '';
    gridParams = '';
    pageParams = '';
    this.fetchProductDisplay(''); // to fetch product properties
    localStorage.setItem('allProductDataPerId', JSON.stringify({}));
  }

  render() {
    const {
      columnDefs,
      defaultColDef,
      frameworkComponents,
      loadingOverlayComponent,
      loadingCellRenderer,
      loadingCellRendererParams,
      noRowsOverlayComponent,
      noRowsOverlayComponentParams,
      product_ids,
      rowModelType,
      rowSelection,
      saveBtn,
      showAction,
      sweetAlert,
      tooltipShowDelay,
      countNill,
      saveInput,
      displayDropDownConfig,
      activeId,
      saveCheck
    } = this.state;

    const displayConfigLabels =
      displayDropDownConfig.length > 1 &&
      displayDropDownConfig.map(each => (
        <>
          {!each.is_active && (
            <option id={each.id} value={each.id} key={each.id}>
              {each.label}
            </option>
          )}
        </>
      ));

    const firstLabel =
      displayDropDownConfig.length > 0 &&
      displayDropDownConfig.map(each => (
        <>
          {each.is_active && (
            <option id={each.id} value={each.id} key={each.id}>
              {each.label}
            </option>
          )}
        </>
      ));

    const deleteFilterId = displayDropDownConfig
      .filter(each => each.is_active)
      .map(each => each.label)[0];
    const filterDelete =
      deleteFilterId === 'Default' ? (
        ''
      ) : (
        <p onClick={() => this.deleteFilter()}>
          <i className="fas fa-trash" aria-hidden="true" />
          Delete Filter
        </p>
      );

    return (
      <>
        <Permission />
        <MenuList />
        <div className="heading">
          <h1 id="catalog-title">
            Product Catalog{' '}
            <span id="catalog-count" class="es-catalog">
              {/* to show total and filtered count  */}
              {countNill
                ? this.state.filterStatus
                  ? `${this.state.catalogfilterCount} of ${
                      this.state.totalProductsCount
                    }`
                  : this.state.totalProductsCount === 0
                  ? ''
                  : this.state.totalProductsCount
                : ''}
            </span>
          </h1>
          {/* to show actions like publish/unpublish/sync */}
          {showAction && (
            <div className="dropdown auction">
              <button>
                <i className="fas fa-gavel" aria-hidden="true" />{' '}
                Actions
              </button>

              <div id="myDropdown" className="dropdown-content">
                {product_ids.length > 0 && showAction ? (
                  <div>
                    <p onClick={this.fetchPublish}>
                      <i className="fas fa-eye" aria-hidden="true" />{' '}
                      Publish
                    </p>
                    <p onClick={this.fetchUnPublish}>
                      <i
                        className="fas fa-eye-slash"
                        aria-hidden="true"
                      />{' '}
                      Unpublish
                    </p>
                    {/* <p onClick={this.fetchSync}>
                        <i className="fas fa-sync-alt" aria-hidden="true" /> Sync
                      </p> */}
                    <p onClick={this.deleteStaleProduct}>
                      <i
                        className="fa fa-trash"
                        aria-hidden="true"
                      />{' '}
                      Delete
                    </p>
                    <Link to="/create_basic_product">
                      {' '}
                      <i
                        className="fa fa-shopping-cart"
                        aria-hidden="true"
                      />{' '}
                      Basic Product
                    </Link>
                    <Link to="/create_parent_product">
                      {' '}
                      <i
                        className="fa fa-cart-plus"
                        aria-hidden="true"
                      />{' '}
                      Parent Product
                    </Link>
                    <Link to="/create_master_product">
                      {' '}
                      <i
                        className="fa fa-cart-arrow-down"
                        aria-hidden="true"
                      />{' '}
                      Master Product
                    </Link>
                  </div>
                ) : (
                  <div>
                    <Link to="/create_basic_product">
                      {' '}
                      <i
                        className="fa fa-shopping-cart"
                        aria-hidden="true"
                      />{' '}
                      Basic Product
                    </Link>
                    <Link to="/create_parent_product">
                      {' '}
                      <i
                        className="fa fa-cart-plus"
                        aria-hidden="true"
                      />{' '}
                      Parent Product
                    </Link>
                    <Link to="/create_master_product">
                      {' '}
                      <i
                        className="fa fa-cart-arrow-down"
                        aria-hidden="true"
                      />{' '}
                      Master Product
                    </Link>
                  </div>
                )}
              </div>
            </div>
          )}
          {urlParams ? (
            <div className="dropdown auction">
              <button onClick={this.handleExport}>
                <i className="fas fa-export" />
                {this.state.export_loading ? 'Loading' : 'Export'}
              </button>
            </div>
          ) : (
            ''
          )}
          {/* Below code defines, bind labels, save, saveAs btns and actions */}
          {saveInput ? (
            <div className="profileConfig">
              <input
                type="text"
                id="labelToSave"
                name="labelToSave"
                onChange={event => this.handleInputOfSave(event)}
              />{' '}
              <button
                onClick={() => this.saveState('saveAs')}
                className="prosave"
              >
                Save
              </button>
              <button onClick={() => this.saveAsState(false)}>
                Cancel
              </button>
            </div>
          ) : (
            displayDropDownConfig.length > 0 &&
            responseOnGrid && (
              <div className="profileConfig">
                <div className="profiles">
                  <select
                    name="config_profile"
                    id="config_profile"
                    multiple={false}
                    onChange={event =>
                      this.handleDisplayChange(event)
                    }
                  >
                    {firstLabel}
                    {displayConfigLabels}
                  </select>
                </div>

                {/* {(saveBtn || saveCheck) && ( */}
                <div className="dropdown auction">
                  <button>
                    <i className="fas fa-undo" /> Reset
                  </button>
                  <div id="myDropdown" className="dropdown-content">
                    {(saveBtn || saveCheck) && (
                      <>
                        <p
                          onClick={() =>
                            this.saveState('save', activeId)
                          }
                        >
                          <i
                            className="fas fa-save"
                            aria-hidden="true"
                          />{' '}
                          Save
                        </p>
                        <p onClick={() => this.saveAsState(true)}>
                          <i
                            className="fas fa-save"
                            aria-hidden="true"
                          />{' '}
                          Save As
                        </p>
                      </>
                    )}
                    <p onClick={() => this.clearFilters()}>
                      <i
                        className="fas fa-trash-alt"
                        aria-hidden="true"
                      />{' '}
                      Clear All Filters
                    </p>
                    <p onClick={() => this.paginationSetUp(false)}>
                      <i
                        className="fas fa-level-down-alt"
                        aria-hidden="true"
                      />{' '}
                      Enable Infinite Scrolling
                    </p>
                    <p onClick={() => this.paginationSetUp(true)}>
                      <i
                        className="fas fa-angle-double-right"
                        aria-hidden="true"
                      />
                      Enable Pagination
                    </p>
                    {!this.state.loading && filterDelete}
                  </div>
                </div>
                {/* )} */}
              </div>
            )
          )}
        </div>
        {sweetAlert}
        <div
          id="myGrid"
          style={{
            height: '100%',
            width: '100%'
          }}
          className="ag-theme-alpine catalogs"
        >
          <AgGridReact
            columnDefs={columnDefs} // to show all column headers and fields
            defaultColDef={defaultColDef} // to enable common props for each fields
            rowModelType={rowModelType} // type of row to be served
            onGridReady={this.onGridReady} // when grid gets ready it invokes
            onColumnVisible={this.onColumnVisible.bind(this)}
            frameworkComponents={frameworkComponents} // to get all renderer components
            loadingOverlayComponent={loadingOverlayComponent}
            loadingCellRenderer={loadingCellRenderer} // default loading renderer
            loadingCellRendererParams={loadingCellRendererParams} // renderer Params to load msg
            noRowsOverlayComponent={noRowsOverlayComponent} // default no rows overlay component
            noRowsOverlayComponentParams={
              noRowsOverlayComponentParams
            } // to show default no rows message
            tooltipShowDelay={tooltipShowDelay} // to show tooltip action with delay of time
            rowSelection={rowSelection}
            onSelectionChanged={this.onSelectionChanged.bind(this)} // handle selected rows
            rowMultiSelectWithClick={true} // enable multiple row selection with clicks
            onColumnResized={this.onColumnMoved.bind(this)}
            context={this.state.context} // to pass the fns via this obj to other components
            onFilterChanged={this.filterChanged.bind(this)} // whenever filter is called, it triggers
            onCellValueChanged={this.cellValueChanged.bind(this)} // when cell values changed
            singleClickEdit={true}
            // pagination={localStorage.getItem("page")} // if true, it enables pagination otherwise infinite scrolling
            // paginationPageSize={this.state.paginationPageSize} // pagination page size
            cacheBlockSize={this.state.cacheBlockSize} // number of rows returned per request
            // suppressChangeDetection={true}
            suppressClipboardPaste={true}
          />
        </div>
      </>
    );
  }
}

// function to return the name
const getColumnName = (order, name) => {
  return order === 'asc'
    ? name === 'dc_phoenix'
      ? 'distribution_center_quantity__available_quantity'
      : name
    : name === 'dc_phoenix'
    ? '-distribution_center_quantity__available_quantity'
    : `-${name}`;
};

// Function to return sort params
const getSort = params => {
  // console.log("getsort")
  return params.sortModel[0].colId &&
    params.sortModel[0].sort === 'asc'
    ? getColumnName('asc', params.sortModel[0].colId)
    : getColumnName('desc', params.sortModel[0].colId);
};

// Function to return filter condition key
const getCondition = (name, condition, data) => {
  // console.log('getConditon', name, condition);
  if (setFields.includes(name)) {
    return setProperFields.includes(name)
      ? `${name}`
      : [
          'product_type',
          'profile_data',
          'label_data',
          'flag',
          'condition'
        ].includes(name)
      ? `${name}__in`
      : name === 'marketplaces'
      ? `${name}_list`
      : `${name}__contains`;
  } else if (numberFields.includes(name)) {
    if (condition === 'greaterThan') return `${name}__gt`;
    else if (condition === 'greaterThanOrEqual')
      return `${name}__gte`;
    else if (condition === 'lessThan') return `${name}__lt`;
    else if (condition === 'lessThanOrEqual') return `${name}__lte`;
    else if (condition === 'notEqual') return `${name}__exclude`;
    else if (condition === 'inRange') return `${name}__range`;
    else return `${name}`;
  } else if (textFields.includes(name)) {
    if (condition === 'contains') return `${name}__contains`;
    else if (condition === 'notContains') return `${name}__exclude`;
    else if (condition === 'startsWith')
      return `${name}__startswith`;
    else if (condition === 'endsWith') return `${name}__endswith`;
    else if (condition === 'equals') return `${name}`;
    else if (condition === 'notEqual') return `${name}__exclude`;
    else if (condition === 'In List') return `${name}__in`;
    else if (condition === 'Not In List') return `${name}__exclude`;
    else if (
      condition === 'Is Empty' ||
      condition === 'Is Not Empty'
    )
      return `${name}__isnull`;
    else return `${name}`;
  } else {
    return `${name}`;
  }
};

const getData = data => {
  /* this is our initial value i.e. the starting point*/
  let initialValue = '';

  /* numbers array */
  let dataList = data;

  /* reducer method that takes in the accumulator and next item */
  let reducer = (accumulator, item) => {
    if (accumulator === '') return item;
    else return accumulator + '__' + item;
  };

  /* we give the reduce method our reducer function
    and our initial value */
  let totalInList = dataList.reduce(reducer, initialValue);
  return totalInList;
};

const getInData = (data, each) => {
  /* this is our initial value i.e. the starting point*/
  let initialValue = '';

  /* numbers array */
  let dataList = data;
  /* reducer method that takes in the accumulator and next item */
  let reducer = (accumulator, item) => {
    if (accumulator === '') return item;
    else return accumulator + '&' + each + '=' + item;
  };

  /* we give the reduce method our reducer function
    and our initial value */
  let totalInList = dataList.reduce(reducer, initialValue);
  return totalInList;
};

const getInDataMarketPlace = data => {
  var marketplaceList = [];
  for (let i = 0; i < data.length; i++) {
    switch (data[i]) {
      case 'Shopify(Pub)':
        marketplaceList.push('Shopify_contains');
        break;
      case 'StockX(Pub)':
        marketplaceList.push('StockX_contains');
        break;
      case 'StockX(Unpub)':
        marketplaceList.push('StockX_exclude');
        break;
      case 'VoyageOne(Pub)':
        marketplaceList.push('VoyageOne_contains');
        break;
      case 'VoyageOne(Unpub)':
        marketplaceList.push('VoyageOne_exclude');
        break;
      default:
        marketplaceList.push('Shopify_exclude');
        break;
    }
  }
  return marketplaceList;
};

// Function to return filter value params
const getFilter = params => {
  // console.log("getFil")
  var filterData;
  var data;
  var mapObj = Object.keys(params.filterModel).map(each => {
    let eachName = each === 'dc_phoenix' ? 'phoenix_quantity' : each;
    data = getCondition(
      eachName,
      params.filterModel[each].type,
      params.filterModel[each].values
    );
    filterData = {};
    // to return range list for number fields
    if (params.filterModel[each].type === 'inRange') {
      let newRange = [];
      newRange.push(
        params.filterModel[each].filter,
        params.filterModel[each].filterTo
      );

      filterData[data] = getData(newRange);
    } else if (params.filterModel[each].filterType === 'set') {
      // to return checkbox values
      if (
        each === 'marketplaces' &&
        params.filterModel[each].values.length === 0
      ) {
        filterData[data] = ['null'];
      }
      if (params.filterModel[each].values.length > 0) {
        getInData(params.filterModel[each].values, data);
        filterData[data] = [
          'product_type',
          'profile_data',
          'label_data',
          'flag',
          'condition'
        ].includes(each)
          ? getData(params.filterModel[each].values)
          : each === 'marketplaces'
          ? getInDataMarketPlace(params.filterModel[each].values)
          : each === 'distribution_center'
          ? getInData(params.filterModel[each].values, data)
          : params.filterModel[each].values[0] === 'True'
          ? 'true'
          : 'false';
      }
    } else if (
      params.filterModel[each].type === 'In List' ||
      params.filterModel[each].type === 'Not In List'
    ) {
      if (params.filterModel[each].type === 'In List') {
        let splitValues = params.filterModel[each].filter.split(',');
        let listNon = [];
        filterData[data] = getData(splitValues);
      } else {
        let splitValues = params.filterModel[each].filter.split(',');
        filterData[data] = getInData(splitValues, data);
      }
    } else if (
      params.filterModel[each].type === 'Is Empty' ||
      params.filterModel[each].type === 'Is Not Empty'
    ) {
      filterData[data] =
        params.filterModel[each].type === 'Is Empty'
          ? 'True'
          : 'False';
    } else {
      filterData[data] = encodeURIComponent(
        params.filterModel[each].filter
      );
    }
    return filterData;
  });
  // Object.keys(rangeData).length > 0 && mapObj.push(rangeData);
  return mapObj;
};

function createServerSideDatasource(
  onBtShowNoRows,
  onFilterCount,
  onShowAction,
  onCountNill,
  gridParams
) {
  return {
    getRows: function(params) {
      // console.log(params)
      let sortId = '';
      let page = params.endRow / 50;

      let fill = [];
      var esFilter = '';
      if (
        params.sortModel.length > 0 ||
        Object.keys(params.filterModel).length > 0
        // ||
        // params.groupKeys.length > 0
      ) {
        fill = getFilter(params).filter(
          e => Object.keys(e).length > 0
        );
        fill.forEach((each, index) => {
          Object.keys(each).forEach(e => {
            esFilter = esFilter
              ? `${esFilter}&${e}=${each[e]}`
              : `&${e}=${each[e]}`;
          });
        });

        if (params.sortModel.length) {
          sortId = `&ordering=${getSort(params)}`;
        }
      } else {
        sortId = '';
      }
      if ([true, false].includes(pageParams)) {
        var soniServe = `${CATALOG_SEARCH_API_URL}?page=${page}${sortId}${esFilter}`;
      }

      urlParams = soniServe;
      const headers = {
        Authorization: `Token ${localStorage.getItem('token')}`
      };

      onCountNill();
      // to get the hide status from grid for each fields
      let saveFieldsVisiblity = saveAction(
        gridParams.columnApi,
        'visiblity'
      );
      // to get the width number from grid for each fields
      let saveFieldsWidth = saveAction(
        gridParams.columnApi,
        'width'
      );
      const data = {
        grid_params: params.filterModel,
        fields: saveFieldsVisiblity,
        field_widths: saveFieldsWidth,
        label: configLable
      };
      gridParamGrid = params.filterModel;

      if ([true, false].includes(pageParams)) {
        axios(soniServe, {
          method: 'post',
          headers,
          data
        })
          .then(response => {
            toCheckPublish = response.data.results;
            Object.keys(params.filterModel).length === 0
              ? onFilterCount(false, 0, response.data.total_products)
              : onFilterCount(
                  true,
                  response.data.count,
                  response.data.total_products
                );
            responseOnGrid = response.data.total_products;
            if (response.data.results.length > 0) {
              onShowAction(true); // to enable Action Dropdown
              let lastRow = (results, request) => {
                if (!results) return undefined;
                var currentLastRow =
                  request.startRow + results.length;

                return currentLastRow < request.endRow
                  ? currentLastRow
                  : undefined;
              };
              onBtShowNoRows(false);
              params.successCallback(
                response.data.results,
                lastRow(response.data.results, params)
              );
            } else {
              onBtShowNoRows(true);
              onShowAction(false); // to disable Action Dropdown
              params.successCallback(
                [{ columnNameField: 'No Data' }],
                0
              );
            }
          })
          .catch(error => {
            responseOnGrid = '';
            if (
              error.response &&
              error.response.status &&
              error.response.status === 401
            ) {
              handleError(error.response);
            } else errorRedirect(error.response);
          });
      }
    }
  };
}

export default Catalogs;
