import React from "react";
import loader from "../../assets/img/loader.svg";

class LoadingOverlay extends React.Component {
  render() {
    let loaderStyles = {
      position: "fixed",
      top: "0px",
      left: "0px",
      zIndex: 9999,
      width: "100%",
      height: "100%",
      background: "rgba(255,255,255,.6)",
      textAlign: "center",
      display: this.props.showOverlay ? "block" : "none"
    };

    const imageStyle = {
      height: "150px",
      top: "calc(50% - 50px)",
      position: "relative"
    };

    return (
      <div style={loaderStyles}>
        <img src={loader} style={imageStyle} alt="" />
      </div>
    );
  }
}

export default LoadingOverlay;
