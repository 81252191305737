import React, { useEffect, useState } from "react";
import Icon from "@material-ui/core/Icon";
import { Link } from "react-router-dom";
import LoadingOverlay from "components/overlays/LoadingOverlay.jsx";
import MenuList from "../../gridComponents/MenuList";
import Permission from "../../permission/Permission";
import { fetchUrl } from "../../apiActions/action";
import { handleNegative } from "../../apiActions/checkFunctions";
import { DASHBOARD_API_URL, MARKETPLACE_PERSISANCE } from "../../configurations/configApi";
import { handleError } from "../FunctionalComponents/ErrorHandle";
import CircularProgress from '@material-ui/core/CircularProgress';
import errorRedirect from "views/FunctionalComponents/ErrorRedirect";

let user_marketplace = localStorage.getItem("marketplace") && localStorage.getItem("marketplace") ? localStorage.getItem("marketplace"): "" ;
var marketPlaces = "";
var set_marketplaces = "";
var marketPlacesForOption = "";
export default function Dashboard() {

  const [allDashBoardResponse, setAllDashBoardResponse] = useState("");
  const [marketPlacesForOptions, setMarketPlacesForOption] = useState("");
  const [showOverlay, setShowOverlay] = useState(false);
  const [inputVal, setInputVal] = useState("");
  const [isOnChange, setIsOnChange] = useState(false);
  // const [marketPlaces, setMarketPlaces] = useState("")



  const handleInputSearch = event => {
    setInputVal(event.target.value)
  };

  const handleKeyCode = e => {
    handleNegative(e);
    if (e.keyCode === 13) handleSearch();
  };

  const handleSearch = () => {
    fetchDashboard();
  };

  const handleMarketPlace = (event) => {
    marketPlaces = event.target.value
    setIsOnChange(true)
    fetchDashboard();
  }

  const fecthMarketplace = () => {
    let url = `${MARKETPLACE_PERSISANCE}`
    fetchUrl(url, getVal => {
      setShowOverlay(false)
      if (getVal.status) {
        let userId = localStorage.getItem("userId") ? localStorage.getItem("userId") : null ;

        if(userId) {
          set_marketplaces = getVal && getVal.response.data.results ? getVal.response.data.results.filter(item => item.user == userId) : "";
        }

          localStorage.setItem("marketplace", JSON.stringify(set_marketplaces));
      } else {
        console.log("rep2",getVal.response.response)
        if (getVal.response.response &&
          getVal.response.response.status &&
          ((getVal.response.response.status === 401))

        ) {
          handleError(getVal.response.response);
        }
        else errorRedirect(getVal.response)
      }
    });
  }

  const fetchDashboard = () => {
    setShowOverlay(true)
    let marketplace = marketPlaces ? marketPlaces : user_marketplace &&user_marketplace ? JSON.parse(user_marketplace)[0].marketplace : "";
    let url = inputVal
      ? `${DASHBOARD_API_URL}?hours=${inputVal}&marketplace=${marketplace}`
      : `${DASHBOARD_API_URL}?marketplace=${marketplace}`;
    fetchUrl(url, getVal => {
      setShowOverlay(false)
      if (getVal.status) {
        setAllDashBoardResponse(getVal.response.data)
        marketPlacesForOption = getVal.response.data.marketplaces
        setMarketPlacesForOption(getVal.response.data.marketplaces)
        // localStorage.setItem("marketplace", JSON.stringify(set_marketplaces));
        if (marketPlacesForOption) {
          localStorage.setItem("marketplaces_list", JSON.stringify(marketPlacesForOption));
        }
      } else {
        console.log("respo1", getVal.response.response )
        setTimeout(() => {
          if (getVal.response.response &&
            getVal.response.response.status &&
            ((getVal.response.response.status === 401))

          ) {
            handleError(getVal.response.response);
          }
          else errorRedirect(getVal.response)
        }, 1000)

      }
    });
  };

  useEffect(() => {
    fecthMarketplace()
    localStorage.setItem("allProductDataPerId", JSON.stringify({}))
  }, [])


  useEffect(() => {
    if (isOnChange) {
      fecthMarketplace();
      setIsOnChange(false)
    }
    if (user_marketplace) {
      user_marketplace = localStorage.getItem("marketplace") ? localStorage.getItem("marketplace") : "";
      fetchDashboard();
    } else {
      setTimeout(() => {
        user_marketplace = localStorage.getItem("marketplace") ? localStorage.getItem("marketplace") : "";
        fetchDashboard();
      }, 1000)
    }
  }, [user_marketplace, isOnChange])


  const blinkIcon = param =>
    allDashBoardResponse &&
    allDashBoardResponse.celery_status.filter(each => {
      return each.process_name === param && each;
    });

  const formattedOne = param =>
    allDashBoardResponse &&
    allDashBoardResponse.celery_status.filter(each => {
      return each.process_name === param && each;
    });


  const marketplaceLabel = marketPlacesForOptions && marketPlacesForOptions.map(each => (
    <>
      {
        user_marketplace && JSON.parse(user_marketplace)[0].marketplace !== each.name ?
          <option id={each.id} value={each.name} key={each.id}>
            {each.name}
          </option>
          : ""
      }
    </>
  ))

  const setMarketplaceLabel = user_marketplace && JSON.parse(user_marketplace).map(each => (
    <>
      <option id={each.id} value={each.marketplace} key={each.id}>
        {each.marketplace}
      </option>
    </>
  ))


  return (
    <>
      <Permission />
      <MenuList />
      <div className="heading">
        <h1 id="dashboard-title">Dashboard </h1>
        <div className="dashFilter">
          <input
            type="text"
            placeholder="24 Hours"
            onChange={e => handleInputSearch(e)}
            onKeyDown={e => handleKeyCode(e)}
          />
          <button onClick={() => handleSearch()}>
            <i className="fas fa-filter" />
          </button>
        </div>
        <div className="dashDropdwn">
          <select name={marketPlaces} id={marketPlaces} onChange={handleMarketPlace}>
            {setMarketplaceLabel}
            {marketplaceLabel}
          </select>
        </div>
      </div>
      <div className="dashboard-content">
        <div className="myDash">
          <div className="productsDash">
            <span className="headePannel">
              <i class="fas fa-box-open" aria-hidden="true" /> Products
            </span>
            <div className="widgetBox totals">
              <div className="widgetIcon blue">
                <i class="fas fa-gifts" aria-hidden="true" />
              </div>
              <p>
                Total{" "}
                <b>
                  {allDashBoardResponse &&
                    allDashBoardResponse.total_products ? allDashBoardResponse.total_products : <CircularProgress size={25} />}
                </b>
              </p>
              {/* <span>
                Out Of Sync:{" "}
                {allDashBoardResponse &&
                  allDashBoardResponse.products_out_of_sync}
              </span> */}
              <p className="times">
                <span>
                  Manually Updated:{" "}
                  {allDashBoardResponse &&
                    allDashBoardResponse.price_manually_changed}
                </span>
              </p>
              <div className="widgetFooter" />
            </div>

            {/* <div className="widgetBox">
              <div className="widgetIcon red">
                <i class="fas fa-sync-alt" aria-hidden="true" />
              </div>
              <p>
                <Icon
                  className={
                    blinkIcon("published_product_fetch")[0] &&
                      blinkIcon("published_product_fetch")[0].is_active
                      ? "rotate"
                      : ""
                  }
                >
                  autorenew
                </Icon>
                Synced (CA){" "}
                <b>
                  {allDashBoardResponse &&
                    allDashBoardResponse.total_products_synced}
                </b>
              </p>
              <p className="times">
                <span>
                  {blinkIcon("published_product_fetch")[0] &&
                    blinkIcon("published_product_fetch")[0].is_active ? (
                    formattedOne("published_product_fetch")[0].started_on
                  ) : (
                    <>
                      {formattedOne("published_product_fetch") &&
                        formattedOne("published_product_fetch")[0].ended_on}
                    </>
                  )}
                </span>
              </p>
              <div className="widgetFooter" />
            </div> */}

            <div className="widgetBox newpdts">
              <div className="widgetIcon yellow">
                <i class="fas fa-random" aria-hidden="true" />
              </div>
              <p>
                <Icon
                  className={
                    blinkIcon("ca_product_fetch")[0] &&
                      blinkIcon("ca_product_fetch")[0].is_active
                      ? "rotate"
                      : ""
                  }
                >
                  autorenew
                </Icon>
                New Product{" "}
                <b>
                  {allDashBoardResponse &&
                    allDashBoardResponse.total_products_created}
                </b>
              </p>
              <p className="times">
                <span>
                  {blinkIcon("ca_product_fetch")[0] &&
                    blinkIcon("ca_product_fetch")[0].is_active ? (
                    formattedOne("ca_product_fetch")[0].started_on
                  ) : (
                    <>
                      {formattedOne("ca_product_fetch") &&
                        formattedOne("ca_product_fetch")[0].ended_on}
                    </>
                  )}
                </span>
              </p>
              <div className="widgetFooter" />
            </div>

            <div className="widgetBox">
              <div className="widgetIcon red">
                <i class="fas fa-eye" aria-hidden="true" />
              </div>
              <p>
                Published{" "}
                <b>
                  {allDashBoardResponse &&
                    allDashBoardResponse.published_products}
                </b>
              </p>
              <div className="widgetFooter" />
            </div>
            <div className="widgetBox bulk">
              <div className="widgetIcon red">
                <i class="fas fa-eye" aria-hidden="true" />
              </div>
              <p>
                <Icon className={allDashBoardResponse && allDashBoardResponse.bulk_published.status === "IN PROGRESS"
                  ? "rotate"
                  : ""}>autorenew</Icon>
                Bulk Publish{" "}
                <b>
                  <Link to={`bulkpublish/${allDashBoardResponse && allDashBoardResponse.bulk_published && allDashBoardResponse.bulk_published.id}`} style={{ color: "black" }}>{allDashBoardResponse && allDashBoardResponse.bulk_published &&
                    allDashBoardResponse.bulk_published.total_products}</Link>
                </b>
              </p>
              <span> Success: {allDashBoardResponse && allDashBoardResponse.bulk_published &&
                allDashBoardResponse.bulk_published.total_succeeded}</span>
              <span> Failed: {allDashBoardResponse && allDashBoardResponse.bulk_published &&
                allDashBoardResponse.bulk_published.total_failed}</span>
              <p className="times">
                <span>
                  {allDashBoardResponse && allDashBoardResponse.bulk_published &&
                    allDashBoardResponse.bulk_published.last_triggered}
                </span>
              </p>
              <div className="widgetFooter" />
            </div>
            <div className="widgetBox bulk inventry">
              <div className="widgetIcon red">
                <i class="fas fa-eye" aria-hidden="true" />
              </div>
              <p>
                <Icon className={allDashBoardResponse && allDashBoardResponse.bulk_delete && allDashBoardResponse.bulk_delete.status === true
                  ? "rotate"
                  : ""}>autorenew</Icon>
                Bulk Delete{" "}
                <b>
                  <Link to={`bulk_delete_stale_product/${allDashBoardResponse && allDashBoardResponse.bulk_delete && allDashBoardResponse.bulk_delete.id}`} style={{ color: "black" }}>{allDashBoardResponse && allDashBoardResponse.bulk_delete &&
                    allDashBoardResponse.bulk_delete.total_products}</Link>
                </b>
              </p>
              <span> Success: {allDashBoardResponse && allDashBoardResponse.bulk_delete &&
                allDashBoardResponse.bulk_delete.total_succeeded}</span>
              <span> Failed: {allDashBoardResponse && allDashBoardResponse.bulk_delete &&
                allDashBoardResponse.bulk_delete.total_failed}</span>
              <p className="times">
                <span>
                  {allDashBoardResponse && allDashBoardResponse.bulk_delete &&
                    allDashBoardResponse.bulk_delete.last_triggered}
                </span>
              </p>
              <div className="widgetFooter" />
            </div>
            {/* <div className="widgetBox bulk inventry">
              <div className="widgetIcon red">
                <i class="fas fa-eye" aria-hidden="true" />
              </div>
              <p>
                <Icon className={allDashBoardResponse && allDashBoardResponse.product_import.status === true
                  ? "rotate"
                  : ""}>autorenew</Icon>
                Inventory Synced {" "}
                <b>{allDashBoardResponse && allDashBoardResponse.product_import &&
                  allDashBoardResponse.product_import.total_last_synced ? allDashBoardResponse.product_import.total_last_synced : <CircularProgress size={25} />}
                </b>
              </p>
              <span> Last Synced: {allDashBoardResponse && allDashBoardResponse.product_import &&
                allDashBoardResponse.product_import.total_last_synced}</span>
              <p className="times">
                <span>
                  {allDashBoardResponse && allDashBoardResponse.product_import &&
                    allDashBoardResponse.product_import.last_synced}
                </span>
              </p>
              <div className="widgetFooter" />
            </div> */}
          </div>
          <div className="ordersDash">
            <span className="headePannel">
              <i class="fas fa-file-alt" aria-hidden="true" /> Orders
            </span>

            <div className="widgetBox">
              <div className="widgetIcon red">
                <i class="fas fa-sync-alt" aria-hidden="true" />
              </div>
              <p>
                <Icon
                  className={
                    blinkIcon("order_fetch")[0] &&
                      blinkIcon("order_fetch")[0].is_active
                      ? "rotate"
                      : ""
                  }
                >
                autorenew
                </Icon>
                Synced {" "}
                <b>
                  {allDashBoardResponse &&
                    allDashBoardResponse.total_orders_synced ? allDashBoardResponse.total_orders_synced : <CircularProgress size={25} />}
                </b>
              </p>
              <p className="times">
                <span>
                  {blinkIcon("order_fetch")[0] &&
                    blinkIcon("order_fetch")[0].is_active ? (
                    formattedOne("order_fetch")[0].started_on
                  ) : (
                    <>
                      {formattedOne("order_fetch") &&
                        formattedOne("order_fetch")[0].ended_on}
                    </>
                  )}
                </span>
              </p>
              <div className="widgetFooter" />
            </div>

            <div className="widgetBox">
              <div className="widgetIcon yellow">
                <i class="fas fa-file-invoice-dollar" aria-hidden="true" />
              </div>
              <p>
                Total{" "}
                <b>
                  {allDashBoardResponse && allDashBoardResponse.total_orders ? allDashBoardResponse.total_orders : <CircularProgress size={25} />}
                </b>
              </p>
              <div className="widgetFooter" />
            </div>
            <div className="widgetBox">
              <div className="widgetIcon green">
                <i class="fas fa-check-double" aria-hidden="true" />
              </div>
              <p>
                Completed{" "}
                <b>
                  {allDashBoardResponse &&
                    allDashBoardResponse.total_orders_completed}
                </b>
              </p>
              <p className="times">
                <span>
                  {allDashBoardResponse &&
                    allDashBoardResponse.latest_order_completed_time}
                </span>
              </p>
              <div className="widgetFooter" />
            </div>
            <div className="widgetBox">
              <div className="widgetIcon darkBlue">
                <i class="fas fa-shipping-fast" aria-hidden="true" />
              </div>
              <p>
                Shipped{" "}
                <b>
                  {allDashBoardResponse &&
                    allDashBoardResponse.total_orders_shipped ? allDashBoardResponse.total_orders_shipped : <CircularProgress size={25} />}
                </b>
              </p>
              <p className="times">
                <span>
                  {allDashBoardResponse &&
                    allDashBoardResponse.latest_order_shipped_time}
                </span>
              </p>
              <div className="widgetFooter" />
            </div>
            <div className="widgetBox">
              <div className="widgetIcon violet">
                <i class="fas fa-file-signature" aria-hidden="true" />
              </div>
              <p>
                Acknowledged{" "}
                <b>
                  {allDashBoardResponse &&
                    allDashBoardResponse.total_orders_acknowledged}
                </b>
              </p>
              <p className="times">
                <span>
                  {allDashBoardResponse &&
                    allDashBoardResponse.latest_order_acknowledged_time}
                </span>
              </p>
              <div className="widgetFooter" />
            </div>
            <div className="widgetBox">
              <div className="widgetIcon grey">
                <i class="fas fa-ban" aria-hidden="true" />
              </div>
              <p>
                Cancelled{" "}
                <b>
                  {allDashBoardResponse &&
                    allDashBoardResponse.total_orders_cancelled}
                </b>
              </p>
              <p className="times">
                <span>
                  {allDashBoardResponse &&
                    allDashBoardResponse.latest_order_cancelled_time}
                </span>
              </p>
              <div className="widgetFooter" />
            </div>
          </div>
        </div>
        <LoadingOverlay showOverlay={showOverlay} />
      </div>
    </>
  );
}