// Function to handle negative values
export const handleNegative = event => {
  const characterCode = event.key;

  const characterNumber = Number(characterCode);
  if (characterNumber >= 0 && characterNumber <= 9) {
    if (event.currentTarget.value && event.currentTarget.value.length) {
      return;
    }
  } else {
    event.preventDefault();
  }
};

export const saveAction = (gridColumnApi, fields) => {
  let saveFields = {};
  /* this.gridColumnApi.getColumnState() will return 
    the defined columns with hide field */
  gridColumnApi.getColumnState().forEach(eachColumns => {
    // console.log(eachColumns.hide, eachColumns.width, fields)
    saveFields[eachColumns.colId] =
      fields === "visiblity" ? eachColumns.hide : eachColumns.width;
    return saveFields;
  });
  return saveFields;
};
