import React, { Component } from "react";
import Tabs from "components/CustomTabs/CustomTabs";
import TaskTemplate from "components/Tasks/TaskTemplate";



export default class TemplateTab extends Component {


  render() {
    const { allCellData, handleDoneValue, handleDoneValueForProductFeild, handleDoneValueForBusinessRuleResult, handleDoneLookUpList } = this.props
    return (
      <>
        <Tabs
          headerColor="info"
          tabs={[
            {
                tabName: "Text Value",
                tabContent: <TaskTemplate TemplateTextValue templateTextValue="Text Value" allCellData={allCellData} handleDoneValue={handleDoneValue} />
            },
            {
              tabName: "Product Fields Value", 
              tabContent: <TaskTemplate  productFieldValue="Product field Value" action="ProductFieldValue" allCellData={allCellData} handleDoneValueForProductFeild={handleDoneValueForProductFeild} />
            },
            {
              tabName: "Lookup List Result",
              tabContent: <TaskTemplate action="LookupListResult" templateLookUpList="function value" allCellData={allCellData}  handleDoneLookUpList={handleDoneLookUpList} />
            },
            {
              tabName: "Business Rule Result",
              tabContent: <TaskTemplate action="BusinessRuleResult" businessRuleResult="Business Rule Result" allCellData={allCellData}  handleDoneValueForBusinessRuleResult={handleDoneValueForBusinessRuleResult} />
            }
          ]}
        />
      </>
    );
  }
}
