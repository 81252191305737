import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import Icon from "@material-ui/core/Icon";

import logo from "assets/img/logoMC.png";

export default class Navigation extends Component {
  state = {
    logout: false
  };

  handleLogout = () => {
    localStorage.clear();
    this.setState({ logout: true });
  };

  render() {
    const { logout } = this.state;

    if (logout) {
      return <Redirect to="/auth/login-page" />;
    }
    return (
      <div className="nav">
        <div className="brandLogo">
          <Link to="/">
            <img src={logo} alt="Logo" />
          </Link>
        </div>
        {/* <div className="menu-left">
          <ul>
            <li>
              <a href="#"> Home </a>{" "}
            </li>
            <li>
              <a href="#"> Products </a>{" "}
            </li>
            <li>
              <a href="#"> Sell </a>{" "}
            </li>
            <li>
              <a href="#"> Fullfill </a>{" "}
            </li>
            <li>
              <a href="#"> Marketing </a>{" "}
            </li>
            <li>
              <a href="#"> Help </a>{" "}
            </li>
          </ul>
        </div> */}
        <div className="menu-right">
          <ul>
            {/* <li>
              <a href="#">
                {" "}
                <Icon>notifications</Icon>{" "}
              </a>{" "}
            </li> */}
            <li id="account-id-circle">
              <a href=" ">
                <b>
                  {localStorage.getItem("userName") &&
                    localStorage.getItem("userName").toUpperCase()[0] +
                      localStorage.getItem("userName").substr(1)}
                </b>
                <Icon>account_circle</Icon>{" "}
              </a>
              <ul>
                <li id="logout-id">
                  <a href=" " onClick={this.handleLogout}>
                    <i className="fas fa-sign-out-alt" aria-hidden="true" />{" "}
                    Logout
                  </a>
                </li>
              </ul>
            </li>
            {/* <li>
              <a href="#"> Account </a>{" "}
            </li> */}
          </ul>
        </div>
        {/* <div className="dropDwn">
          <select>
            <option>Amazing Sneakers , LLC, USA</option>
          </select>
        </div> */}
      </div>
    );
  }
}
