import React, { Component } from "react";

export default class imageRender extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: this.getIdValue()
    };
    this.getAllOrderId = this.getAllOrderId.bind(this);
  }

  getIdValue() {
    return this.props.value;
  }

  getAllOrderId() {
    this.props.context.componentParent.onGetList(this.state.value);
  }

  render() {
    return this.props.data.id ? (
      <>
        <input
          type="checkbox"
          value={this.state.value}
          onClick={this.getAllOrderId}
        />
        <p>{this.state.value}</p>
      </>
    ) : (
      ""
    );
  }
}
