import React from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import tasksStyle from "assets/jss/material-dashboard-pro-react/components/tasksStyle.jsx";
import TemplateTextValue from "./TemplateTextValue";
import TemplateProductFieldValue from "./TemplateProductFieldValue";
// import BusinessRuleLookUpList from "./BusinessRuleLookUpList";
import TemplateBusinessRuleResult from "./TemplateBusinessRuleResult";
import TemplateLookUpList from "./TemplateLookUpList";

class TaskTemplate extends React.Component {

    render() {
        const { handleDoneValue, allCellData, handleDoneValueForProductFeild, handleDoneValueForBusinessRuleResult, handleDoneLookUpList} = this.props;
        return (
            <>
                {this.props.TemplateTextValue ? (
                    <TemplateTextValue allCellData={allCellData} handleDoneValue={handleDoneValue}  />
                )
                    :
                    this.props.action === "ProductFieldValue" ? (
                        <TemplateProductFieldValue  allCellData={allCellData} handleDoneValueForProductFeild={handleDoneValueForProductFeild} />
                    )
                        : this.props.action === "BusinessRuleResult" ? (
                            <TemplateBusinessRuleResult  allCellData={allCellData}  handleDoneValueForBusinessRuleResult={handleDoneValueForBusinessRuleResult} />
                        ) : this.props.action === "LookupListResult" ? (
                            <TemplateLookUpList allCellData={allCellData}  handleDoneLookUpList={handleDoneLookUpList} />
                        ) : null
                }
            </>
        );
    }
}

TaskTemplate.propTypes = {
    orders: PropTypes.array,
    productHistory: PropTypes.array,
    mpn: PropTypes.string
};

export default withStyles(tasksStyle)(TaskTemplate);
