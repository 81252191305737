import React, { Component } from "react";
import axios from "axios";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import _ from "lodash";

import {
  CATALOG_API_URL,
  CUSTOMIZATION_GRID_API_URL,
  PRODUCT_PROPERTIES_API_URL,
  PUBLISH_API_URL,
  SYNC_API_URL,
  UNPUBLISH_API_URL
} from "../../configurations/configApi";
import Permission from "../../permission/Permission";
import CustomNoRowsOverlay from "../../formatters/customNoRowsOverlay";
import CustomTooltip from "../../formatters/customTooltip.jsx";
import BooleanRender from "../../formatters/BooleanRender";
import publishedRender from "../../formatters/publishedRender";
import imageRender from "../../formatters/imageRender";
import loadingRender from "../../formatters/loadingRender";
import MenuList from "../../gridComponents/MenuList";
import viewRender from "../../formatters/viewRender";
import { apiEdit, apiPost, fetchUrl } from "../../apiActions/action";
import { saveAction } from "../../apiActions/checkFunctions";
import MessageComponent from "../FunctionalComponents/messageComponent";
import PublishComponent from "../FunctionalComponents/publishComponent";
import {
  numberFields,
  setProperFields,
  textFields,
  setFields
} from "../../constants/columnConstants";
import QueryBuilder from "react-querybuilder";
import errorRedirect from "views/FunctionalComponents/ErrorRedirect";

const fields = [
  { name: "mpn", label: "MPN" },
  { name: "sku", label: "SKU" },
  { name: "title", label: "Title" },
  { name: "brand", label: "Brand" },
  { name: "product_type", label: "Type" }
  // { name: "email", label: "Email" },
  // { name: "twitter", label: "Twitter" },
  // { name: "isDev", label: "Is a Developer?", value: false }
];

var productTypes = [];
var distributionCenters = [];
var conditionList = [];
var flagList = [];
var labelList = [];
var profileList = {};
var publishIds = [];
var displayColumns = [];
var firstComp = false;
var load = false;
var responseOnGrid = "";
var gridParams = "";
var queryParams = [];
var pageParams = "";

class Catalogs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      advanceApply: false,
      columnDefs: [
        {
          headerName: "",
          field: "created_on",
          cellRenderer: "agGroupCellRenderer",
          cellRendererParams: {
            checkbox: true
          },
          lockVisible: true
        },
        {
          headerName: "Image",
          field: "image",
          // minWidth: 100,
          cellRenderer: "imageRender",
          lockVisible: true
        },
        {
          headerName: "MPN",
          field: "mpn",
          // minWidth: 100,
          sortable: true,
          filter: "agTextColumnFilter",
          filterParams: {
            buttons: ["reset"],
            debounceMs: 1000,
            suppressAndOrCondition: true, // it will remove AND/OR conditions
            filterOptions: [
              "contains",
              "notContains",
              "equals",
              "notEqual",
              "startsWith",
              "endsWith",
              "In List",
              "Not In List"
            ]
          }
        },
        {
          headerName: "SKU",
          field: "sku",
          // minWidth: 100,
          sortable: true,
          lockVisible: true,
          filter: "agTextColumnFilter",
          filterParams: {
            buttons: ["reset"],
            debounceMs: 1000,
            suppressAndOrCondition: true, // it will remove AND/OR conditions
            filterOptions: [
              "contains",
              "notContains",
              "equals",
              "notEqual",
              "startsWith",
              "endsWith",
              "In List",
              "Not In List"
            ]
          },
          cellRenderer: "viewRender"
        },
        {
          headerName: "Title",
          field: "title",
          // minWidth: 200,
          sortable: true,
          filter: "agTextColumnFilter",
          filterParams: {
            buttons: ["reset"],
            debounceMs: 1000,
            suppressAndOrCondition: true, // it will remove AND/OR conditions
            filterOptions: [
              "contains",
              "notContains",
              "equals",
              "notEqual",
              "startsWith",
              "endsWith",
              "In List",
              "Not In List"
              // "Is Empty",
              // "Is Not Empty"
            ]
          }
        },
        {
          headerName: "Brand",
          field: "brand",
          // minWidth: 100,
          sortable: true,
          filter: "agTextColumnFilter",
          filterParams: {
            buttons: ["reset"],
            debounceMs: 1000,
            suppressAndOrCondition: true, // it will remove AND/OR conditions
            filterOptions: [
              "contains",
              "notContains",
              "equals",
              "notEqual",
              "startsWith",
              "endsWith",
              "In List",
              "Not In List"
              // "Is Empty",
              // "Is Not Empty"
            ]
          }
        },
        {
          headerName: "Type",
          //minWidth: 100,
          field: "product_type",
          filter: "agSetColumnFilter",
          filterParams: {
            values: function(params) {
              // simulating async delay
              setTimeout(function() {
                params.success(productTypes);
              }, 500);
            }
          }
        },
        {
          headerName: "Distribution",
          field: "distribution_center",
          //minWidth: 100,
          tooltipField: "distribution_center",
          sortable: true,
          tooltipComponentParams: { color: "#ececec" },
          filter: "agSetColumnFilter",
          filterParams: {
            values: function(params) {
              // simulating async delay
              setTimeout(function() {
                params.success(distributionCenters);
              }, 500);
            }
          }
        },
        {
          headerName: "Phoenix Qty",
          field: "dc_phoenix",
          //minWidth: 100,
          sortable: true,
          filter: "agNumberColumnFilter",
          filterParams: {
            buttons: ["reset"],
            debounceMs: 1000,
            suppressAndOrCondition: true
          }
        },
        {
          headerName: "Published To Voyageone",
          field: "is_published_to_voyageone",
          //minWidth: 100,
          filter: "agSetColumnFilter",
          cellRenderer: "publishedRender",
          filterParams: {
            values: function(params) {
              // simulating async delay
              setTimeout(function() {
                params.success(["True", "False"]);
              }, 500);
            }
          }
        },
        {
          headerName: "Qty Total",
          field: "total_quantity",
          //minWidth: 100,
          sortable: true,
          filter: "agNumberColumnFilter",
          filterParams: {
            buttons: ["reset"],
            debounceMs: 1000,
            suppressAndOrCondition: true
          }
        },
        {
          headerName: "Qty Total Avail",
          field: "total_available_quantity",
          //minWidth: 100,
          sortable: true,
          filter: "agNumberColumnFilter",
          filterParams: {
            buttons: ["reset"],
            debounceMs: 1000,
            suppressAndOrCondition: true
          }
        },
        {
          headerName: "Qty Pending Payment",
          field: "pending_payment_quantity",
          sortable: true,
          //minWidth: 100,
          filter: "agNumberColumnFilter",
          filterParams: {
            buttons: ["reset"],
            debounceMs: 1000,
            suppressAndOrCondition: true
          }
        },
        {
          headerName: "Qty Pending Shipment",
          field: "pending_shipment_quantity",
          sortable: true,
          //minWidth: 100,
          filter: "agNumberColumnFilter",
          filterParams: {
            buttons: ["reset"],
            debounceMs: 1000,
            suppressAndOrCondition: true
          }
        },
        {
          headerName: "Buy Now Price",
          field: "buy_it_now_price",
          //minWidth: 100,
          valueFormatter: function(params) {
            return params.value && `${params.value}`;
          },
          sortable: true,
          filter: "agNumberColumnFilter",
          filterParams: {
            buttons: ["reset"],
            debounceMs: 1000,
            suppressAndOrCondition: true
          }
        },
        {
          headerName: "Retail Price",
          field: "retail_price",
          //minWidth: 100,
          editable: true,
          sortable: true,
          filter: "agNumberColumnFilter",
          filterParams: {
            buttons: ["reset"],
            debounceMs: 1000,
            suppressAndOrCondition: true
          }
        },
        {
          headerName: "Price Manually Updated",
          field: "retail_price_manually_updated",
          //minWidth: 100,
          filter: "agSetColumnFilter",
          cellRenderer: "BooleanRender",
          filterParams: {
            values: function(params) {
              // simulating async delay
              setTimeout(function() {
                params.success(["True", "False"]);
              }, 500);
            }
          }
        },
        {
          headerName: "ID",
          field: "id",
          //minWidth: 100,
          sortable: true,
          filter: "agTextColumnFilter",
          filterParams: {
            buttons: ["reset"],
            debounceMs: 1000,
            suppressAndOrCondition: true, // it will remove AND/OR conditions
            filterOptions: [
              "contains",
              "notContains",
              "equals",
              "notEqual",
              "startsWith",
              "endsWith",
              "In List",
              "Not In List"
              // "Is Empty",
              // "Is Not Empty"
            ]
          }
          // rowGroup: true
        },
        {
          headerName: "Channel Advisor ID",
          field: "channel_advisor_id",
          //minWidth: 100,
          sortable: true,
          filter: "agTextColumnFilter",
          filterParams: {
            buttons: ["reset"],
            debounceMs: 1000,
            suppressAndOrCondition: true, // it will remove AND/OR conditions
            filterOptions: [
              "contains",
              "notContains",
              "equals",
              "notEqual",
              "startsWith",
              "endsWith",
              "In List",
              "Not In List"
              // "Is Empty",
              // "Is Not Empty"
            ]
          }
          // rowGroup: true
        },
        {
          headerName: "Available in Store",
          field: "is_available_in_store",
          //minWidth: 100,
          filter: "agSetColumnFilter",
          cellRenderer: "BooleanRender",
          filterParams: {
            values: function(params) {
              // simulating async delay
              setTimeout(function() {
                params.success(["True", "False"]);
              }, 500);
            }
          }
        },
        {
          headerName: "Blocked",
          field: "is_blocked",
          //minWidth: 100,
          filter: "agSetColumnFilter",
          cellRenderer: "BooleanRender",
          filterParams: {
            values: function(params) {
              // simulating async delay
              setTimeout(function() {
                params.success(["True", "False"]);
              }, 500);
            }
          }
        },
        {
          headerName: "External Qty Blocked",
          field: "is_external_quantity_blocked",
          //minWidth: 100,
          filter: "agSetColumnFilter",
          cellRenderer: "BooleanRender",
          filterParams: {
            values: function(params) {
              // simulating async delay
              setTimeout(function() {
                params.success(["True", "False"]);
              }, 500);
            }
          }
        },
        {
          headerName: "Display in Store",
          field: "is_display_in_store",
          //minWidth: 100,
          filter: "agSetColumnFilter",
          cellRenderer: "BooleanRender",
          filterParams: {
            values: function(params) {
              // simulating async delay
              setTimeout(function() {
                params.success(["True", "False"]);
              }, 500);
            }
          }
        },
        {
          headerName: "Parent",
          field: "is_parent",
          //minWidth: 100,
          filter: "agSetColumnFilter",
          cellRenderer: "BooleanRender",
          filterParams: {
            values: function(params) {
              // simulating async delay
              setTimeout(function() {
                params.success(["True", "False"]);
              }, 500);
            }
          }
        },
        {
          headerName: "RelationShip",
          field: "is_in_relationship",
          //minWidth: 100,
          filter: "agSetColumnFilter",
          cellRenderer: "BooleanRender",
          filterParams: {
            values: function(params) {
              // simulating async delay
              setTimeout(function() {
                params.success(["True", "False"]);
              }, 500);
            }
          }
        },
        {
          headerName: "Deleted",
          field: "is_deleted",
          //minWidth: 100,
          filter: "agSetColumnFilter",
          cellRenderer: "BooleanRender",
          filterParams: {
            values: function(params) {
              // simulating async delay
              setTimeout(function() {
                params.success(["True", "False"]);
              }, 500);
            }
          }
        },
        {
          headerName: "Block Comment",
          field: "block_comment",
          //minWidth: 100,
          sortable: true,
          filter: "agTextColumnFilter",
          filterParams: {
            buttons: ["reset"],
            debounceMs: 1000,
            suppressAndOrCondition: true, // it will remove AND/OR conditions
            filterOptions: [
              "contains",
              "notContains",
              "equals",
              "notEqual",
              "startsWith",
              "endsWith",
              "In List",
              "Not In List"
              // "Is Empty",
              // "Is Not Empty"
            ]
          }
        },
        {
          headerName: "Asin",
          field: "asin",
          //minWidth: 100,
          sortable: true,
          filter: "agTextColumnFilter",
          filterParams: {
            buttons: ["reset"],
            debounceMs: 1000,
            suppressAndOrCondition: true, // it will remove AND/OR conditions
            filterOptions: [
              "contains",
              "notContains",
              "equals",
              "notEqual",
              "startsWith",
              "endsWith",
              "In List",
              "Not In List"
              // "Is Empty",
              // "Is Not Empty"
            ]
          }
        },
        {
          headerName: "EAN",
          field: "ean",
          //minWidth: 100,
          sortable: true,
          filter: "agTextColumnFilter",
          filterParams: {
            buttons: ["reset"],
            debounceMs: 1000,
            suppressAndOrCondition: true, // it will remove AND/OR conditions
            filterOptions: [
              "contains",
              "notContains",
              "equals",
              "notEqual",
              "startsWith",
              "endsWith",
              "In List",
              "Not In List"
              // "Is Empty",
              // "Is Not Empty"
            ]
          }
        },
        {
          headerName: "Condition",
          field: "condition",
          //minWidth: 100,
          sortable: true,
          filter: "agSetColumnFilter",
          filterParams: {
            values: function(params) {
              // simulating async delay
              setTimeout(function() {
                params.success(conditionList);
              }, 500);
            }
          }
        },
        {
          headerName: "Description",
          field: "description",
          //minWidth: 100,
          sortable: true,
          filter: "agTextColumnFilter",
          filterParams: {
            buttons: ["reset"],
            debounceMs: 1000,
            suppressAndOrCondition: true, // it will remove AND/OR conditions
            filterOptions: [
              "contains",
              "notContains",
              "equals",
              "notEqual",
              "startsWith",
              "endsWith",
              "In List",
              "Not In List"
              // "Is Empty",
              // "Is Not Empty"
            ]
          }
        },
        {
          headerName: "Flag Description",
          field: "flag_description",
          //minWidth: 100,
          sortable: true,
          filter: "agTextColumnFilter",
          filterParams: {
            buttons: ["reset"],
            debounceMs: 1000,
            suppressAndOrCondition: true, // it will remove AND/OR conditions
            filterOptions: [
              "contains",
              "notContains",
              "equals",
              "notEqual",
              "startsWith",
              "endsWith",
              "In List",
              "Not In List"
              // "Is Empty",
              // "Is Not Empty"
            ]
          }
        },
        {
          headerName: "Flag",
          field: "flag",
          //minWidth: 100,
          sortable: true,
          filter: "agSetColumnFilter",
          filterParams: {
            values: function(params) {
              // simulating async delay
              setTimeout(function() {
                params.success(flagList);
              }, 500);
            }
          }
        },
        {
          headerName: "Harmonized Code",
          field: "harmonized_code",
          //minWidth: 100,
          sortable: true,
          filter: "agTextColumnFilter",
          filterParams: {
            buttons: ["reset"],
            debounceMs: 1000,
            suppressAndOrCondition: true, // it will remove AND/OR conditions
            filterOptions: [
              "contains",
              "notContains",
              "equals",
              "notEqual",
              "startsWith",
              "endsWith",
              "In List",
              "Not In List"
              // "Is Empty",
              // "Is Not Empty"
            ]
          }
        },
        {
          headerName: "ISBN",
          field: "isbn",
          //minWidth: 100,
          sortable: true,
          filter: "agTextColumnFilter",
          filterParams: {
            buttons: ["reset"],
            debounceMs: 1000,
            suppressAndOrCondition: true, // it will remove AND/OR conditions
            filterOptions: [
              "contains",
              "notContains",
              "equals",
              "notEqual",
              "startsWith",
              "endsWith",
              "In List",
              "Not In List"
              // "Is Empty",
              // "Is Not Empty"
            ]
          }
        },
        {
          headerName: "Manufacturer",
          field: "manufacturer",
          //minWidth: 100,
          sortable: true,
          filter: "agTextColumnFilter",
          filterParams: {
            buttons: ["reset"],
            debounceMs: 1000,
            suppressAndOrCondition: true, // it will remove AND/OR conditions
            filterOptions: [
              "contains",
              "notContains",
              "equals",
              "notEqual",
              "startsWith",
              "endsWith",
              "In List",
              "Not In List"
              // "Is Empty",
              // "Is Not Empty"
            ]
          }
        },
        {
          headerName: "Short Description",
          field: "short_description",
          //minWidth: 100,
          sortable: true,
          filter: "agTextColumnFilter",
          filterParams: {
            buttons: ["reset"],
            debounceMs: 1000,
            suppressAndOrCondition: true, // it will remove AND/OR conditions
            filterOptions: [
              "contains",
              "notContains",
              "equals",
              "notEqual",
              "startsWith",
              "endsWith",
              "In List",
              "Not In List"
              // "Is Empty",
              // "Is Not Empty"
            ]
          }
        },
        {
          headerName: "Subtitle",
          field: "subtitle",
          //minWidth: 100,
          sortable: true,
          filter: "agTextColumnFilter",
          filterParams: {
            buttons: ["reset"],
            debounceMs: 1000,
            suppressAndOrCondition: true, // it will remove AND/OR conditions
            filterOptions: [
              "contains",
              "notContains",
              "equals",
              "notEqual",
              "startsWith",
              "endsWith",
              "In List",
              "Not In List"
              // "Is Empty",
              // "Is Not Empty"
            ]
          }
        },
        {
          headerName: "Tax Product Code",
          field: "tax_product_code",
          //minWidth: 100,
          sortable: true,
          filter: "agTextColumnFilter",
          filterParams: {
            buttons: ["reset"],
            debounceMs: 1000,
            suppressAndOrCondition: true, // it will remove AND/OR conditions
            filterOptions: [
              "contains",
              "notContains",
              "equals",
              "notEqual",
              "startsWith",
              "endsWith",
              "In List",
              "Not In List"
              // "Is Empty",
              // "Is Not Empty"
            ]
          }
        },
        {
          headerName: "UPC",
          field: "upc",
          //minWidth: 100,
          sortable: true,
          filter: "agTextColumnFilter",
          filterParams: {
            buttons: ["reset"],
            debounceMs: 1000,
            suppressAndOrCondition: true, // it will remove AND/OR conditions
            filterOptions: [
              "contains",
              "notContains",
              "equals",
              "notEqual",
              "startsWith",
              "endsWith",
              "In List",
              "Not In List"
              // "Is Empty",
              // "Is Not Empty"
            ]
          }
        },
        {
          headerName: "Warehouse Location",
          field: "warehouse_location",
          //minWidth: 100,
          sortable: true,
          filter: "agTextColumnFilter",
          filterParams: {
            buttons: ["reset"],
            debounceMs: 1000,
            suppressAndOrCondition: true, // it will remove AND/OR conditions
            filterOptions: [
              "contains",
              "notContains",
              "equals",
              "notEqual",
              "startsWith",
              "endsWith",
              "In List",
              "Not In List"
              // "Is Empty",
              // "Is Not Empty"
            ]
          }
        },
        {
          headerName: "Warranty",
          field: "warranty",
          //minWidth: 100,
          sortable: true,
          filter: "agTextColumnFilter",
          filterParams: {
            buttons: ["reset"],
            debounceMs: 1000,
            suppressAndOrCondition: true, // it will remove AND/OR conditions
            filterOptions: [
              "contains",
              "notContains",
              "equals",
              "notEqual",
              "startsWith",
              "endsWith",
              "In List",
              "Not In List"
              // "Is Empty",
              // "Is Not Empty"
            ]
          }
        },
        {
          headerName: "Multipack Qty",
          field: "multipack_quantity",
          //minWidth: 100,
          sortable: true,
          filter: "agNumberColumnFilter",
          filterParams: {
            buttons: ["reset"],
            debounceMs: 1000,
            suppressAndOrCondition: true
          }
        },
        {
          headerName: "Height",
          field: "height",
          //minWidth: 100,
          sortable: true,
          filter: "agNumberColumnFilter",
          filterParams: {
            buttons: ["reset"],
            debounceMs: 1000,
            suppressAndOrCondition: true
          }
        },
        {
          headerName: "Length",
          field: "length",
          //minWidth: 100,
          sortable: true,
          filter: "agNumberColumnFilter",
          filterParams: {
            buttons: ["reset"],
            debounceMs: 1000,
            suppressAndOrCondition: true
          }
        },
        {
          headerName: "Width",
          field: "width",
          //minWidth: 100,
          sortable: true,
          filter: "agNumberColumnFilter",
          filterParams: {
            buttons: ["reset"],
            debounceMs: 1000,
            suppressAndOrCondition: true
          }
        },
        {
          headerName: "Weight",
          field: "weight",
          //minWidth: 100,
          sortable: true,
          filter: "agNumberColumnFilter",
          filterParams: {
            buttons: ["reset"],
            debounceMs: 1000,
            suppressAndOrCondition: true
          }
        },
        {
          headerName: "Cost",
          field: "cost",
          //minWidth: 100,
          sortable: true,
          filter: "agNumberColumnFilter",
          filterParams: {
            buttons: ["reset"],
            debounceMs: 1000,
            suppressAndOrCondition: true
          }
        },
        {
          headerName: "Margin",
          field: "margin",
          //minWidth: 100,
          sortable: true,
          filter: "agNumberColumnFilter",
          filterParams: {
            buttons: ["reset"],
            debounceMs: 1000,
            suppressAndOrCondition: true
          }
        },
        {
          headerName: "Starting Price",
          field: "starting_price",
          //minWidth: 100,
          sortable: true,
          filter: "agNumberColumnFilter",
          filterParams: {
            buttons: ["reset"],
            debounceMs: 1000,
            suppressAndOrCondition: true
          }
        },
        {
          headerName: "Reserve Price",
          field: "reserve_price",
          //minWidth: 100,
          sortable: true,
          filter: "agNumberColumnFilter",
          filterParams: {
            buttons: ["reset"],
            debounceMs: 1000,
            suppressAndOrCondition: true
          }
        },
        {
          headerName: "Store Price",
          field: "store_price",
          //minWidth: 100,
          sortable: true,
          filter: "agNumberColumnFilter",
          filterParams: {
            buttons: ["reset"],
            debounceMs: 1000,
            suppressAndOrCondition: true
          }
        },
        {
          headerName: "Second Chance Price",
          field: "second_chance_price",
          //minWidth: 100,
          sortable: true,
          filter: "agNumberColumnFilter",
          filterParams: {
            buttons: ["reset"],
            debounceMs: 1000,
            suppressAndOrCondition: true
          }
        },
        {
          headerName: "Min Price",
          field: "min_price",
          //minWidth: 100,
          sortable: true,
          filter: "agNumberColumnFilter",
          filterParams: {
            buttons: ["reset"],
            debounceMs: 1000,
            suppressAndOrCondition: true
          }
        },
        {
          headerName: "Max Price",
          field: "max_price",
          //minWidth: 100,
          sortable: true,
          filter: "agNumberColumnFilter",
          filterParams: {
            buttons: ["reset"],
            debounceMs: 1000,
            suppressAndOrCondition: true
          }
        },
        {
          headerName: "Estimated Shipping Cost",
          field: "estimated_shipping_cost",
          //minWidth: 100,
          sortable: true,
          filter: "agNumberColumnFilter",
          filterParams: {
            buttons: ["reset"],
            debounceMs: 1000,
            suppressAndOrCondition: true
          }
        },
        {
          headerName: "Supplier Name",
          field: "supplier_name",
          //minWidth: 100,
          sortable: true,
          filter: "agTextColumnFilter",
          filterParams: {
            buttons: ["reset"],
            debounceMs: 1000,
            suppressAndOrCondition: true, // it will remove AND/OR conditions
            filterOptions: [
              "contains",
              "notContains",
              "equals",
              "notEqual",
              "startsWith",
              "endsWith",
              "In List",
              "Not In List"
              // "Is Empty",
              // "Is Not Empty"
            ]
          }
        },
        {
          headerName: "Supplier Code",
          field: "supplier_code",
          //minWidth: 100,
          sortable: true,
          filter: "agTextColumnFilter",
          filterParams: {
            buttons: ["reset"],
            debounceMs: 1000,
            suppressAndOrCondition: true, // it will remove AND/OR conditions
            filterOptions: [
              "contains",
              "notContains",
              "equals",
              "notEqual",
              "startsWith",
              "endsWith",
              "In List",
              "Not In List"
              // "Is Empty",
              // "Is Not Empty"
            ]
          }
        },
        {
          headerName: "Supplier Po",
          field: "supplier_po",
          //minWidth: 100,
          sortable: true,
          filter: "agTextColumnFilter",
          filterParams: {
            buttons: ["reset"],
            debounceMs: 1000,
            suppressAndOrCondition: true, // it will remove AND/OR conditions
            filterOptions: [
              "contains",
              "notContains",
              "equals",
              "notEqual",
              "startsWith",
              "endsWith",
              "In List",
              "Not In List"
              // "Is Empty",
              // "Is Not Empty"
            ]
          }
        },
        {
          headerName: "Classification",
          field: "classification",
          //minWidth: 100,
          sortable: true,
          filter: "agTextColumnFilter",
          filterParams: {
            buttons: ["reset"],
            debounceMs: 1000,
            suppressAndOrCondition: true, // it will remove AND/OR conditions
            filterOptions: [
              "contains",
              "notContains",
              "equals",
              "notEqual",
              "startsWith",
              "endsWith",
              "In List",
              "Not In List"
              // "Is Empty",
              // "Is Not Empty"
            ]
          }
        },
        {
          headerName: "Store Title",
          field: "store_title",
          //minWidth: 100,
          sortable: true,
          filter: "agTextColumnFilter",
          filterParams: {
            buttons: ["reset"],
            debounceMs: 1000,
            suppressAndOrCondition: true, // it will remove AND/OR conditions
            filterOptions: [
              "contains",
              "notContains",
              "equals",
              "notEqual",
              "startsWith",
              "endsWith",
              "In List",
              "Not In List"
              // "Is Empty",
              // "Is Not Empty"
            ]
          }
        },
        {
          headerName: "Store Description",
          field: "store_description",
          //minWidth: 100,
          sortable: true,
          filter: "agTextColumnFilter",
          filterParams: {
            buttons: ["reset"],
            debounceMs: 1000,
            suppressAndOrCondition: true, // it will remove AND/OR conditions
            filterOptions: [
              "contains",
              "notContains",
              "equals",
              "notEqual",
              "startsWith",
              "endsWith",
              "In List",
              "Not In List"
              // "Is Empty",
              // "Is Not Empty"
            ]
          }
        },
        {
          headerName: "Bundle Type",
          field: "bundle_type",
          //minWidth: 100,
          sortable: true,
          filter: "agTextColumnFilter",
          filterParams: {
            buttons: ["reset"],
            debounceMs: 1000,
            suppressAndOrCondition: true, // it will remove AND/OR conditions
            filterOptions: [
              "contains",
              "notContains",
              "equals",
              "notEqual",
              "startsWith",
              "endsWith",
              "In List",
              "Not In List"
              // "Is Empty",
              // "Is Not Empty"
            ]
          }
        },
        {
          headerName: "Allocated Qty",
          field: "open_allocated_quantity",
          //minWidth: 100,
          sortable: true,
          filter: "agNumberColumnFilter",
          filterParams: {
            buttons: ["reset"],
            debounceMs: 1000,
            suppressAndOrCondition: true
          }
        },
        {
          headerName: "Allocated Qty Pooled",
          field: "open_allocated_quantity_pooled",
          //minWidth: 100,
          sortable: true,
          filter: "agNumberColumnFilter",
          filterParams: {
            buttons: ["reset"],
            debounceMs: 1000,
            suppressAndOrCondition: true
          }
        },
        {
          headerName: "Pending Checkout Qty",
          field: "pending_checkout_quantity",
          //minWidth: 100,
          sortable: true,
          filter: "agNumberColumnFilter",
          filterParams: {
            buttons: ["reset"],
            debounceMs: 1000,
            suppressAndOrCondition: true
          }
        },
        {
          headerName: "Pending Checkout Qty Pooled",
          field: "pending_checkout_quantity_pooled",
          //minWidth: 100,
          sortable: true,
          filter: "agNumberColumnFilter",
          filterParams: {
            buttons: ["reset"],
            debounceMs: 1000,
            suppressAndOrCondition: true
          }
        },
        {
          headerName: "Pending Qty Pooled",
          field: "pending_payment_quantity_pooled",
          //minWidth: 100,
          sortable: true,
          filter: "agNumberColumnFilter",
          filterParams: {
            buttons: ["reset"],
            debounceMs: 1000,
            suppressAndOrCondition: true
          }
        },
        {
          headerName: "Pending Shipment Qty Pooled",
          field: "pending_shipment_quantity_pooled",
          //minWidth: 100,
          sortable: true,
          filter: "agNumberColumnFilter",
          filterParams: {
            buttons: ["reset"],
            debounceMs: 1000,
            suppressAndOrCondition: true
          }
        },
        {
          headerName: "Total Qty Pooled",
          field: "total_quantity_pooled",
          //minWidth: 100,
          sortable: true,
          filter: "agNumberColumnFilter",
          filterParams: {
            buttons: ["reset"],
            debounceMs: 1000,
            suppressAndOrCondition: true
          }
        },
        {
          headerName: "Qty Sold 7 Days",
          field: "quantity_sold_last7_days",
          //minWidth: 100,
          sortable: true,
          filter: "agNumberColumnFilter",
          filterParams: {
            buttons: ["reset"],
            debounceMs: 1000,
            suppressAndOrCondition: true
          }
        },
        {
          headerName: "Qty Sold 14 Days",
          field: "quantity_sold_last14_days",
          //minWidth: 100,
          sortable: true,
          filter: "agNumberColumnFilter",
          filterParams: {
            buttons: ["reset"],
            debounceMs: 1000,
            suppressAndOrCondition: true
          }
        },
        {
          headerName: "Qty Sold 30 Days",
          field: "quantity_sold_last30_days",
          //minWidth: 100,
          sortable: true,
          filter: "agNumberColumnFilter",
          filterParams: {
            buttons: ["reset"],
            debounceMs: 1000,
            suppressAndOrCondition: true
          }
        },
        {
          headerName: "Qty Sold 60 Days",
          field: "quantity_sold_last60_days",
          //minWidth: 100,
          sortable: true,
          filter: "agNumberColumnFilter",
          filterParams: {
            buttons: ["reset"],
            debounceMs: 1000,
            suppressAndOrCondition: true
          }
        },
        {
          headerName: "Qty Sold 90 Days",
          field: "quantity_sold_last90_days",
          //minWidth: 100,
          sortable: true,
          filter: "agNumberColumnFilter",
          filterParams: {
            buttons: ["reset"],
            debounceMs: 1000,
            suppressAndOrCondition: true
          }
        },
        {
          headerName: "Copy To Children",
          field: "copy_to_children",
          //minWidth: 100,
          sortable: true,
          filter: "agTextColumnFilter",
          filterParams: {
            buttons: ["reset"],
            debounceMs: 1000,
            suppressAndOrCondition: true, // it will remove AND/OR conditions
            filterOptions: [
              "contains",
              "notContains",
              "equals",
              "notEqual",
              "startsWith",
              "endsWith",
              "In List",
              "Not In List"
              // "Is Empty",
              // "Is Not Empty"
            ]
          }
        },
        {
          headerName: "Parent SKU",
          field: "parent_sku",
          //minWidth: 100,
          sortable: true,
          filter: "agTextColumnFilter",
          filterParams: {
            buttons: ["reset"],
            debounceMs: 1000,
            suppressAndOrCondition: true, // it will remove AND/OR conditions
            filterOptions: [
              "contains",
              "notContains",
              "equals",
              "notEqual",
              "startsWith",
              "endsWith",
              "In List",
              "Not In List"
              // "Is Empty",
              // "Is Not Empty"
            ]
          }
        },
        {
          headerName: "Channel Advisor Parent ID",
          field: "channel_advisor_parent_id",
          //minWidth: 100,
          sortable: true,
          filter: "agNumberColumnFilter",
          filterParams: {
            buttons: ["reset"],
            debounceMs: 1000,
            suppressAndOrCondition: true
          }
        },
        {
          headerName: "Relationship Name",
          field: "relationship_name",
          //minWidth: 100,
          sortable: true,
          filter: "agTextColumnFilter",
          filterParams: {
            buttons: ["reset"],
            debounceMs: 1000,
            suppressAndOrCondition: true, // it will remove AND/OR conditions
            filterOptions: [
              "contains",
              "notContains",
              "equals",
              "notEqual",
              "startsWith",
              "endsWith",
              "In List",
              "Not In List"
              // "Is Empty",
              // "Is Not Empty"
            ]
          }
        },
        {
          headerName: "Profile",
          field: "profile",
          //minWidth: 100,
          sortable: true,
          filter: "agSetColumnFilter",
          filterParams: {
            values: function(params) {
              // simulating async delay
              setTimeout(function() {
                params.success(profileList);
              }, 500);
            }
          }
        },
        {
          headerName: "Labels",
          field: "labels",
          //minWidth: 100,
          sortable: true,
          filter: "agSetColumnFilter",
          filterParams: {
            values: function(params) {
              // simulating async delay
              setTimeout(function() {
                params.success(labelList);
              }, 500);
            }
          }
        },
        {
          headerName: "Last Updated",
          field: "updated_on",
          sortable: true
        }
      ],
      defaultColDef: {
        flex: 1,
        resizable: true,
        tooltipComponent: "customTooltip"
        // floatingFilter: true
      },
      rowModelType: "serverSide",
      frameworkComponents: {
        customNoRowsOverlay: CustomNoRowsOverlay,
        customTooltip: CustomTooltip,
        imageRender: imageRender,
        loadingRender: loadingRender,
        publishedRender: publishedRender,
        BooleanRender: BooleanRender,
        viewRender: viewRender
      },
      loadingCellRenderer: "loadingRender",
      loadingCellRendererParams: { loadingMessage: "One moment please..." },
      loadingOverlayComponent: "loadingRender",
      totalProductsCount: 0,
      catalogfilterCount: 0,
      noRowsOverlayComponent: "customNoRowsOverlay",
      noRowsOverlayComponentParams: {
        noRowsMessageFunc: function() {
          return "Sorry - No Products!";
        }
      },
      rowSelection: "multiple",
      tooltipShowDelay: 0,
      filterStatus: false,
      product_ids: [],
      showAction: false,
      saveBtn: false,
      countNill: true,
      paginationPageSize: 100,
      cacheBlockSize: 100,
      context: { componentParent: this },
      saveInput: false, // to show the input tag, when save as is given
      displayDropDownConfig: [], // to get multiple-labels response
      activeId: "", // to show active Label in dropdown
      saveCheck: false
    };
    this.hideAlert = this.hideAlert.bind(this);
    this.handlePublish = this.handlePublish.bind(this);
    this.onSelectionChanged = this.onSelectionChanged.bind(this);
    this.onFilterCount = this.onFilterCount.bind(this);
    this.onShowAction = this.onShowAction.bind(this);
    this.fetchSync = this.fetchSync.bind(this);
    this.setDefaultWidth = this.setDefaultWidth.bind(this);
  }

  // used to get the data of selected rows
  onSelectionChanged = () => {
    // remove boolean values after filtering published to ids
    let product_ids = this.gridApi.getSelectedRows().map(function(eachRow) {
      return { id: eachRow.id, published: eachRow.is_published_to_voyageone };
    });
    this.setState({
      product_ids: product_ids,
      showAction: true
    });
  };

  // function to show no rows overlay
  onBtShowNoRows = () => {
    this.gridApi.showNoRowsOverlay();
  };

  // function to set the total and filtered count
  onFilterCount = (status, catalogfilterCount, totalProductsCount) => {
    status
      ? this.setState({ filterStatus: true, countNill: true })
      : this.setState({ filterStatus: false, countNill: true });
    this.setState({ catalogfilterCount, totalProductsCount });
  };

  // function to hide count
  onCountNill = () => {
    this.setState({ countNill: false });
  };

  // function to get the label name
  handleInputOfSave = event => {
    this.setState({ labelOfSaved: event.target.value });
  };

  // function to get the label's field visiblity & width
  handleDisplayChange = event => {
    let selectedId = event.target.value;
    this.setState({ saveBtn: true, saveCheck: true });
    this.onBtShowHideLoading(true); // to show loading

    fetchUrl(
      `${CUSTOMIZATION_GRID_API_URL}${selectedId}`,
      ({ status, response }) => {
        this.onBtShowHideLoading(false); // to show loading

        if (status) {
          this.setState({ activeId: selectedId });
          // this.saveState("save", selectedId)
          if (response.data) {
            Object.keys(response.data.fields).forEach(eachTo => {
              if (eachTo === "image" || eachTo === "created_on") {
                // setting default width to img and created_on fields
                this.gridColumnApi.setColumnWidth(eachTo, 75);
              } else {
                /* setting column Width & Visiblity by
                negative the hide value getting from BE */
                this.gridColumnApi.setColumnWidth(
                  eachTo,
                  response.data.field_widths[eachTo]
                );
                this.gridColumnApi.setColumnVisible(
                  eachTo,
                  !response.data.fields[eachTo]
                );
              }
            });
          }
        }
      }
    );
  };

  // function to show Action dropdown
  onShowAction = showActionBool => {
    this.setState({ showAction: showActionBool });
  };

  // function to enable/disable input tag to get label config name
  saveAsState = getValueOfSave => {
    this.setState({ saveInput: getValueOfSave });
  };

  clearFilters = () => {
    this.gridApi.setFilterModel(null);
  };

  paginationSetUp = setUpPage => {
    localStorage.setItem("page", setUpPage);
    window.location = "/catalogs";
  };

  // Function to save the configured field and api integration
  saveState = (inputMethod, prodId) => {
    let urlForSave;
    let methodOfSave;
    let paramsOfSave;
    // to get the hide status from grid for each fields
    let saveFieldsVisiblity = saveAction(this.gridColumnApi, "visiblity");
    // to get the width number from grid for each fields
    let saveFieldsWidth = saveAction(this.gridColumnApi, "width");

    this.setState({ saveBtn: false, saveCheck: false }); // to hide Save Btn
    this.onBtShowHideLoading(true); // to show loading

    // updating the existing labels
    if (inputMethod === "save") {
      urlForSave = `${CUSTOMIZATION_GRID_API_URL}${prodId}/`;
      methodOfSave = "put";
      paramsOfSave = {
        fields: saveFieldsVisiblity,
        field_widths: saveFieldsWidth
      };
    } else {
      // creating new label
      // when saveAs method called
      urlForSave = `${CUSTOMIZATION_GRID_API_URL}`;
      methodOfSave = "post";
      paramsOfSave = {
        fields: saveFieldsVisiblity,
        field_widths: saveFieldsWidth,
        label: this.state.labelOfSaved,
        user: localStorage.getItem("userId")
      };
    }

    apiEdit(urlForSave, paramsOfSave, methodOfSave, ({ status, response }) => {
      if (status) {
        this.onBtShowHideLoading(false); // to show loading
        /* after creation is completed, getting all
        the labels and setting the created label as active */
        inputMethod === "saveAs" && this.fetchProductDisplay("saveAs");
      } else {
        this.saveAsState(false); // to hide input tag for fetching label
        if (this.checkFunction(response)) {
          this.fetchMessage("redirectTrue");
        } else {
          this.fetchMessage(false, response.response, "cancelVisiblity");
        }
      }
    });
  };

  // this function will trigger whenever column width is changed
  onColumnMoved = event => {
    this.setState({ saveBtn: load ? true : false });
  };

  // this function will trigger whenever column visiblity is changed
  onColumnVisible = event => {
    responseOnGrid && load && this.setState({ saveBtn: load ? true : false });
  };

  // function to set width for columns visibled
  setDefaultWidth = () => {
    displayColumns.forEach(each => {
      if (each.is_active) {
        Object.keys(each.field_widths).forEach(eachKey => {
          if (eachKey === "image" || eachKey === "created_on") {
            this.gridColumnApi.setColumnWidth(eachKey, 75);
          } else {
            this.gridColumnApi.setColumnWidth(
              eachKey,
              each.field_widths[eachKey]
            );
          }
        });
      }
    });
  };

  cellValueChanged(event) {
    const retailValue = { retail_price: event.newValue };
    this.onBtShowHideLoading(true); // to show loading
    // when cell event is having no value initially
    if (typeof event.newValue !== "undefined") {
      apiEdit(
        `${CATALOG_API_URL}${event.data.id}/`,
        retailValue,
        "patch",
        ({ status, response }) => {
          if (status) {
            this.onBtShowHideLoading(false); // to hide loading
            this.fetchMessage(true, response, "retailPublish");
          } else {
            this.onBtShowHideLoading(false); // to hide input tag for fetching label
            if (this.checkFunction(response)) {
              this.fetchMessage("redirectTrue");
            } else {
              // var idsToUpdate = this.gridApi
              //   .getSelectedNodes()
              //   .map(function(node) {
              //     // console.log("node data ", node.data);
              //     return node.data.id;
              //   });
              this.gridApi.forEachNode(function(rowNode) {
                // if (idsToUpdate.includes(rowNode.data.id)) {
                if (event.data.id === rowNode.data.id) {
                  var updated = JSON.parse(JSON.stringify(rowNode.data));
                  updated.retail_price = event.oldValue;
                  rowNode.setData(updated);
                }
              });
              this.fetchMessage(false, response.response, "retailPublish");
            }
          }
        }
      );
    } else {
      this.onBtShowHideLoading(false); // to hide loading
    }
  }

  // fn triggers, whenever filter value is changed
  filterChanged(event) {
    pageParams =
      Object.keys(this.gridApi.getFilterModel()).length > 0 ? true : false;
    console.log(
      "filte changed ",
      this.gridApi.getFilterModel(),
      Object.keys(this.gridApi.getFilterModel())
    );
    this.state.columnDefs.forEach(each => {
      if (Object.keys(this.gridApi.getFilterModel()).includes(each.field)) {
        Array.from(
          document.getElementsByClassName(`ag-input-field-input`)
        ).forEach(o => {
          if (
            o.getAttribute("aria-label") === `${each.headerName} Filter Input`
          )
            o.className += " yellowFilterInput";
        });
      } else {
        Array.from(
          document.getElementsByClassName(`yellowFilterInput`)
        ).forEach(o => {
          if (
            o.getAttribute("aria-label") === `${each.headerName} Filter Input`
          ) {
            o.classList.remove("yellowFilterInput");
          }
        });
      }
    });
  }

  // to set the initially filtered param values
  callSetFilterModel = () => {
    console.log(" callsettt ", Object.keys(gridParams), this.state.columnDefs);
    this.gridApi.setFilterModel(gridParams);
    pageParams = Object.keys(gridParams).length > 0 ? true : false;
    // this.gridApi.setFilterModel(
    //   JSON.parse(localStorage.getItem("filterModelCatalogs"))
    // );

    if (Object.keys(gridParams).length > 0) {
      this.state.columnDefs.forEach(each => {
        if (Object.keys(gridParams).includes(each.field)) {
          Array.from(
            document.getElementsByClassName(`ag-input-field-input`)
          ).forEach(o => {
            if (
              o.getAttribute("aria-label") === `${each.headerName} Filter Input`
            )
              o.className += " yellowFilterInput";
          });
        }
      });
    }
    Object.keys(gridParams).length === 0 && this.gridApi.onFilterChanged();
  };

  onGridReady = params => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.setState({ params: params });
    // this.autoSizeAll(false);

    var datasource = createServerSideDatasource(
      this.onBtShowNoRows,
      this.onFilterCount,
      this.onShowAction,
      this.onCountNill,
      this.returnFilter
    );
    params.api.setServerSideDatasource(datasource);

    params.api.addGlobalListener(function(type, event) {
      if (type === "componentStateChanged" && !firstComp) {
        if (displayColumns.length > 0) {
          firstComp = true;
          params.api.gridOptionsWrapper.gridOptions.context.componentParent.setDefaultWidth(); // to set the width when grid loads
          params.api.gridOptionsWrapper.gridOptions.context.componentParent.callSetFilterModel(); // to set Filter Params in Grid Columns
        }
        displayColumns.length > 0 &&
          displayColumns.forEach(each => {
            // if is_active true, configuration is setup
            if (each.is_active) {
              Object.keys(each.fields).forEach(eachTo => {
                // negating the values of field to make the field visible
                return params.columnApi.setColumnVisible(
                  eachTo,
                  !each.fields[eachTo]
                );
              });
            }
          });
      }
      if (type === "columnHoverChanged" && responseOnGrid) {
        load = true;
      }
    });
  };

  // function to remove sweetalert popups
  hideAlert = (mode, modeAction) => {
    this.setState({ sweetAlert: "" });

    /*if modeAction is cancelVisiblity,
    then shows reset Action, else calling grid and deselecting all rows */
    if (modeAction !== "cancelVisiblity" && modeAction !== "retailPublish") {
      // load grid when shipment got success
      (mode === "success" || mode === "error") &&
        this.onGridReady(this.state.params);
      // deselect all rows when api got success or error
      (mode === "success" || mode === "error") &&
        this.state.params.api.deselectAll();
    } else {
      this.setState({ saveBtn: true });
    }
  };

  // function to show/hide loading overlay components
  onBtShowHideLoading = showHideState => {
    //  if true: show loading component
    // else hide loading component
    showHideState
      ? this.gridApi.showLoadingOverlay()
      : this.gridApi.hideOverlay();
  };

  // function to show Message pops on success/error response
  fetchMessage = (status, response, stateOfAction) => {
    const message = (
      <MessageComponent
        page="catalogs"
        action={stateOfAction}
        hideAlert={this.hideAlert}
        hideOverlay={this.onBtShowHideLoading}
        response={response}
        status={status}
      />
    );
    this.state.sweetAlert = message;
    // this.setState({ sweetAlert: message });
  };

  // function to check the error due to 404/500
  checkFunction = error => {
    if (
      error.response &&
      error.response.status &&
      (error.response.status === 404 || error.response.status === 500)
      //  || !error.response
    ) {
      return true;
    }
  };

  // function to call api for bulk shipment
  handlePublish = getState => {
    this.setState({ sweetAlert: "" }); // to hide input pop up of shipment
    this.onBtShowHideLoading(true); // to show loading
    apiPost(PUBLISH_API_URL, getState, ({ status, response }) => {
      if (status) {
        this.fetchMessage(true, response, "publish");
      } else {
        if (this.checkFunction(response)) {
          this.fetchMessage("redirectTrue");
        } else {
          this.fetchMessage(false, response.response, "publish");
        }
      }
    });
  };

  // Function to filter product_ids based on published or not
  filterSelection = modeOfAction => {
    if (modeOfAction === "sync") {
      // function to return list of ids
      publishIds = this.state.product_ids.map(each => each.id);
      return publishIds;
    } else {
      /* used lodash to filter the array of product_ids
    with field name published and modeOfaction */
      publishIds = _.filter(this.state.product_ids, [
        "published",
        modeOfAction
      ]).map(each => each.id);
      return publishIds;
    }
  };

  // function to fetch publish popups
  fetchPublish = () => {
    this.filterSelection(false);
    const sweet = (
      <PublishComponent
        handlePublish={this.handlePublish}
        product_ids={publishIds}
        hideAlert={this.hideAlert}
      />
    );
    this.setState({ sweetAlert: sweet });
  };

  // function to call unpublish api
  fetchUnPublish = () => {
    this.onBtShowHideLoading(true); // to show loading
    this.filterSelection(true);
    const data = { product_list: publishIds };
    apiPost(UNPUBLISH_API_URL, data, ({ status, response }) => {
      if (status) {
        this.fetchMessage(true, response, "unpublish");
      } else {
        if (this.checkFunction(response)) {
          this.fetchMessage("redirectTrue");
        } else {
          this.fetchMessage(false, response.response, "unpublish");
        }
      }
    });
  };

  // function to call sync api
  fetchSync = () => {
    this.onBtShowHideLoading(true); // to show loading
    this.filterSelection("sync");
    const data = { product_list: publishIds };
    apiPost(SYNC_API_URL, data, ({ status, response }) => {
      if (status) {
        this.fetchMessage(true, response, "sync");
      } else {
        if (this.checkFunction(response)) {
          this.fetchMessage("redirectTrue");
        } else {
          this.fetchMessage(false, response.response, "sync");
        }
      }
    });
  };

  returnFilter = () => {
    return queryParams;
  };

  // function to get product properties
  fetchProductDisplay = getValue => {
    fetchUrl(PRODUCT_PROPERTIES_API_URL, ({ status, response }) => {
      if (status) {
        this.setState({ response: response.data });
        productTypes = response.data.product_types;
        distributionCenters = response.data.distribution_centers;
        conditionList = response.data.condition;
        flagList = response.data.flag;
        profileList = response.data.profile;
        labelList = response.data.labels;
        displayColumns = response.data.display_config;
        gridParams = response.data.page_params[0].grid_params;
        queryParams = response.data.page_params[0].query_params.filter;
        this.setState({ displayDropDownConfig: response.data.display_config });
        let activeProduct =
          response.data.display_config.length > 0 &&
          _.find(response.data.display_config, { is_active: true });
        activeProduct &&
          this.setState({
            activeId: activeProduct.id
          });
        getValue && this.setState({ saveInput: false, saveBtn: false });
      }
    });
  };

  logQuery = query => {
    console.log(query);
    this.setState({ advanceApply: true, logQuery: query });
  };

  handleOnClick = event => {
    console.log("e ", event);
  };

  // When Apply Btn is clicked, it'll pass the rules of advanced filters
  advanceFilterChanged = () => {
    console.log(
      "advanceFilterChanged",
      this.state.logQuery,
      Object.keys(this.state.logQuery)
    );
    var sonir = Object.keys(this.state.logQuery).map(each => {
      console.log("Eeeeeeee ", each);
      var complexObj = {};

      if (each === "rules") {
        console.log("Eeeeeeeeeecdd ", each);
        window.logq = this.state.logQuery;
        this.state.logQuery.rules.forEach(e => {
          console.log("ccc ", complexObj);
          complexObj["field"] = e.field;
          complexObj["value"] = e.value;
          complexObj["operator"] = e.operator;
        });
      }
      console.log("complex obj ", complexObj)
      return complexObj;
    });
    console.log("sssooo ", sonir);
    // this.gridApi.onFilterChanged();
  };

  componentDidMount() {
    localStorage.setItem("page", false); // to set pagination
    firstComp = false;
    load = false;
    productTypes = [];
    distributionCenters = [];
    conditionList = [];
    flagList = [];
    labelList = [];
    profileList = {};
    publishIds = [];
    displayColumns = [];
    responseOnGrid = "";
    gridParams = "";
    queryParams = [];
    pageParams = "";
    this.fetchProductDisplay(""); // to fetch product properties
  }

  render() {
    const {
      columnDefs,
      defaultColDef,
      frameworkComponents,
      loadingOverlayComponent,
      loadingCellRenderer,
      loadingCellRendererParams,
      noRowsOverlayComponent,
      noRowsOverlayComponentParams,
      product_ids,
      rowModelType,
      rowSelection,
      saveBtn,
      showAction,
      sweetAlert,
      tooltipShowDelay,
      countNill,
      saveInput,
      displayDropDownConfig,
      activeId,
      saveCheck
    } = this.state;

    const displayConfigLabels =
      displayDropDownConfig.length > 1 &&
      displayDropDownConfig.map(each => (
        <>
          {!each.is_active && (
            <option id={each.id} value={each.id}>
              {each.label}
            </option>
          )}
        </>
      ));

    const firstLabel =
      displayDropDownConfig.length > 0 &&
      displayDropDownConfig.map(each => (
        <>
          {each.is_active && (
            <option id={each.id} value={each.id}>
              {each.label}
            </option>
          )}
        </>
      ));

    // const dom = <QueryBuilder fields={fields} onQueryChange={logQuery} />;

    return (
      <>
        <Permission />
        <MenuList />
        <div className="heading">
          <h1 id="catalog-title">
            Product Catalog{" "}
            <span id="catalog-count">
              {/* to show total and filtered count  */}
              {countNill
                ? this.state.filterStatus
                  ? `${this.state.catalogfilterCount} of ${
                      this.state.totalProductsCount
                    }`
                  : this.state.totalProductsCount === 0
                  ? ""
                  : this.state.totalProductsCount
                : ""}
            </span>
          </h1>
          {/* to show actions like publish/unpublish/sync */}
          {product_ids.length > 0 && showAction && (
            <div class="dropdown auction">
              <button onclick="myFunction()">
                <i class="fas fa-gavel" aria-hidden="true" /> Actions
              </button>
              <div id="myDropdown" class="dropdown-content">
                <p onClick={this.fetchPublish}>
                  <i class="fas fa-eye" aria-hidden="true" /> Publish
                </p>
                <p onClick={this.fetchUnPublish}>
                  <i class="fas fa-eye-slash" aria-hidden="true" /> Unpublish
                </p>
                <p onClick={this.fetchSync}>
                  <i class="fas fa-sync-alt" aria-hidden="true" /> Sync
                </p>
              </div>
            </div>
          )}
          {/* Below code defines, bind labels, save, saveAs btns and actions */}
          {saveInput ? (
            <div className="profileConfig">
              <input
                type="text"
                id="labelToSave"
                name="labelToSave"
                onChange={event => this.handleInputOfSave(event)}
              />{" "}
              <button
                onClick={() => this.saveState("saveAs")}
                className="prosave"
              >
                Save
              </button>
              <button onClick={() => this.saveAsState(false)}>Cancel</button>
            </div>
          ) : (
            displayDropDownConfig.length > 0 &&
            responseOnGrid && (
              <div className="profileConfig">
                <div className="profiles">
                  <select
                    name="config_profile"
                    id="config_profile"
                    multiple={false}
                    onChange={event => this.handleDisplayChange(event)}
                  >
                    {firstLabel}
                    {displayConfigLabels}
                  </select>
                </div>

                {/* {(saveBtn || saveCheck) && ( */}
                <div class="dropdown auction">
                  <button onclick="myFunction()">
                    <i className="fas fa-undo" /> Reset
                  </button>
                  <div id="myDropdown" class="dropdown-content">
                    {(saveBtn || saveCheck) && (
                      <>
                        <p onClick={() => this.saveState("save", activeId)}>
                          <i class="fas fa-save" aria-hidden="true" /> Save
                        </p>
                        <p onClick={() => this.saveAsState(true)}>
                          <i class="fas fa-save" aria-hidden="true" /> Save As
                        </p>
                      </>
                    )}
                    <p onClick={() => this.clearFilters()}>
                      <i class="fas fa-trash-alt" aria-hidden="true" /> Clear
                      All Filters
                    </p>
                    <p onClick={() => this.paginationSetUp(false)}>
                      <i class="fas fa-level-down-alt" aria-hidden="true" />{" "}
                      Enable Infinite Scrolling
                    </p>
                    <p onClick={() => this.paginationSetUp(true)}>
                      <i class="fas fa-angle-double-right" aria-hidden="true" />{" "}
                      Enable Pagination
                    </p>
                  </div>
                </div>
                {/* )} */}
              </div>
            )
          )}
        </div>
        {sweetAlert}
        {this.state.advanceApply === true && (
          <button onClick={this.advanceFilterChanged}>Apply</button>
        )}
        <QueryBuilder
          fields={fields}
          onQueryChange={this.logQuery}
          fieldSelector={this.handleOnClick}
        />

        <div
          id="myGrid"
          style={{
            height: "100%",
            width: "100%"
          }}
          className="ag-theme-alpine catalogs"
        >
          <AgGridReact
            columnDefs={columnDefs} // to show all column headers and fields
            defaultColDef={defaultColDef} // to enable common props for each fields
            rowModelType={rowModelType} // type of row to be served
            onGridReady={this.onGridReady} // when grid gets ready it invokes
            onColumnVisible={this.onColumnVisible.bind(this)}
            frameworkComponents={frameworkComponents} // to get all renderer components
            loadingOverlayComponent={loadingOverlayComponent}
            loadingCellRenderer={loadingCellRenderer} // default loading renderer
            loadingCellRendererParams={loadingCellRendererParams} // renderer Params to load msg
            noRowsOverlayComponent={noRowsOverlayComponent} // default no rows overlay component
            noRowsOverlayComponentParams={noRowsOverlayComponentParams} // to show default no rows message
            tooltipShowDelay={tooltipShowDelay} // to show tooltip action with delay of time
            rowSelection={rowSelection}
            onSelectionChanged={this.onSelectionChanged.bind(this)} // handle selected rows
            rowMultiSelectWithClick={true} // enable multiple row selection with clicks
            onColumnResized={this.onColumnMoved.bind(this)}
            context={this.state.context} // to pass the fns via this obj to other components
            onFilterChanged={this.filterChanged.bind(this)} // whenever filter is called, it triggers
            onCellValueChanged={this.cellValueChanged.bind(this)} // when cell values changed
            singleClickEdit={true}
            pagination={localStorage.getItem("page")} // if true, it enables pagination otherwise infinite scrolling
            paginationPageSize={this.state.paginationPageSize} // pagination page size
            cacheBlockSize={this.state.cacheBlockSize} // number of rows returned per request
            // suppressChangeDetection={true}
          />
        </div>
      </>
    );
  }
}

// function to return the name
const getColumnName = (order, name) => {
  return order === "asc"
    ? name === "dc_phoenix"
      ? "distribution_center_quantity__available_quantity"
      : name
    : name === "dc_phoenix"
    ? "-distribution_center_quantity__available_quantity"
    : `-${name}`;
};

// Function to return sort params
const getSort = params => {
  return params.request.sortModel[0].colId &&
    params.request.sortModel[0].sort === "asc"
    ? getColumnName("asc", params.request.sortModel[0].colId)
    : getColumnName("desc", params.request.sortModel[0].colId);
};

// Function to return filter condition key
const getCondition = (name, condition) => {
  if (setFields.includes(name)) {
    return setProperFields.includes(name)
      ? `${name}`
      : name === "profile"
      ? `${name}__account_name__in`
      : name === "labels"
      ? `${name}__name__in`
      : `${name}__in`;
  } else if (numberFields.includes(name)) {
    if (condition === "greaterThan") return `${name}__gt`;
    else if (condition === "greaterThanOrEqual") return `${name}__gte`;
    else if (condition === "lessThan") return `${name}__lt`;
    else if (condition === "lessThanOrEqual") return `${name}__lte`;
    else if (condition === "notEqual") return `exclude|${name}`;
    else return `${name}`;
  } else if (textFields.includes(name)) {
    if (condition === "contains") return `${name}__icontains`;
    else if (condition === "notContains") return `exclude|${name}__icontains`;
    else if (condition === "startsWith") return `${name}__istartswith`;
    else if (condition === "endsWith") return `${name}__iendswith`;
    else if (condition === "equals") return `${name}__iexact`;
    else if (condition === "notEqual") return `exclude|${name}__iexact`;
    else if (condition === "In List") return `${name}__in`;
    else if (condition === "Not In List") return `exclude|${name}__in`;
    else if (condition === "Is Empty" || condition === "Is Not Empty")
      return `${name}__isnull`;
    else return `${name}`;
  } else {
    return `${name}`;
  }
};

// Function to return filter value params
const getFilter = params => {
  var filterData;
  var rangeData = {};
  var data;
  var rangeDataList = {};

  var mapObj = Object.keys(params.request.filterModel).map(each => {
    let eachName =
      each === "dc_phoenix"
        ? "distribution_center_quantity__available_quantity"
        : each;
    data = getCondition(eachName, params.request.filterModel[each].type);
    filterData = {};
    // to return range list for number fields
    if (params.request.filterModel[each].type === "inRange") {
      let newRange = [];
      newRange.push(
        params.request.filterModel[each].filter,
        params.request.filterModel[each].filterTo
      );
      rangeDataList[data] = newRange;
      rangeData["range"] && delete rangeData["range"];
      rangeData["range"] = rangeDataList;
    } else if (params.request.filterModel[each].filterType === "set") {
      // to return checkbox values
      if (params.request.filterModel[each].values.length > 0) {
        filterData[data] =
          each === "product_type" ||
          each === "distribution_center" ||
          each === "profile" ||
          each === "labels" ||
          each === "flag"
            ? params.request.filterModel[each].values
            : params.request.filterModel[each].values[0] === "True"
            ? "True"
            : "False";
      }
    } else if (
      params.request.filterModel[each].type === "In List" ||
      params.request.filterModel[each].type === "Not In List"
    ) {
      let listNon = [];
      var splitValues = params.request.filterModel[each].filter.split(",");
      splitValues.forEach(each => listNon.push(each.trim()));
      filterData[data] = listNon;
    } else if (
      params.request.filterModel[each].type === "Is Empty" ||
      params.request.filterModel[each].type === "Is Not Empty"
    ) {
      filterData[data] =
        params.request.filterModel[each].type === "Is Empty" ? "True" : "False";
    } else {
      filterData[data] = encodeURIComponent(
        params.request.filterModel[each].filter
      );
    }

    return filterData;
  });
  Object.keys(rangeData).length > 0 && mapObj.push(rangeData);
  return mapObj;
};

function createServerSideDatasource(
  onBtShowNoRows,
  onFilterCount,
  onShowAction,
  onCountNill,
  returnFilter
) {
  return {
    getRows: function(params) {
      let sortId = "";
      let filter = [];
      let page = params.request.endRow / 100;
      let fill = [];
      // let fill = returnFilter();
      let urlMan = "";
      // fill && localStorage.setItem("filter_qs_exisits", true);
      console.log("fill ", fill, params.request.filterModel);
      if (
        params.request.sortModel.length > 0 ||
        Object.keys(params.request.filterModel).length > 0 ||
        params.request.groupKeys.length > 0
      ) {
        fill = getFilter(params).filter(e => Object.keys(e).length > 0);
        // filter = getFilter(params).filter(e => Object.keys(e).length > 0);
        if (params.request.sortModel.length) {
          sortId = getSort(params);
        } else if (params.request.groupKeys.length) {
          //TODO
          // sortId = "brand";
          // filter = [{ asin__icontains: "B0040UMIJW" }]
        }
      } else {
        sortId = "";
      }

      // const urlService = `${CATALOG_API_URL}?page=${page}&order_by=[${sortId &&
      //   JSON.stringify(sortId)}]&filter=${
      //   filter ? JSON.stringify(filter) : "[]"
      // }`;

      // const urlService = `${CATALOG_API_URL}?page=${page}&order_by=[${sortId &&
      //   JSON.stringify(sortId)}]&filter=${JSON.stringify(fill)}`;
      console.log("pagepara ", pageParams);
      if (pageParams === true || pageParams === false) {
        var urlService = `${CATALOG_API_URL}?page=${page}&order_by=[${sortId &&
          JSON.stringify(sortId)}]&filter=${JSON.stringify(fill)}`;
      }

      const headers = {
        Authorization: `Token ${localStorage.getItem("token")}`
      };

      console.log("compare fill ", fill);
      onCountNill();

      const data = { grid_params: params.request.filterModel };
      if (
        pageParams === true ||
        pageParams === false
        // fill.length > 0 &&
        // // localStorage.getItem("filter_qs_exisits") &&
        // urlMan !== urlService
      ) {
        urlMan = urlService;

        axios(urlService, {
          method: "post",
          headers,
          data
        })
          .then(response => {
            Object.keys(params.request.filterModel).length === 0
              ? onFilterCount(false, 0, response.data.total_products)
              : onFilterCount(
                  true,
                  response.data.count,
                  response.data.total_products
                );
            responseOnGrid = response.data.total_products;
            if (response.data.results.length > 0) {
              onShowAction(true); // to enable Action Dropdown
              let lastRow = (results, request) => {
                if (!results) return undefined;
                var currentLastRow = request.startRow + results.length;
                return currentLastRow < request.endRow
                  ? currentLastRow
                  : undefined;
              };
              params.successCallback(
                response.data.results,
                lastRow(response.data.results, params.request)
              );
            } else {
              onBtShowNoRows();
              onShowAction(false); // to disable Action Dropdown
              params.successCallback([{ columnNameField: "No Data" }], 0);
            }
          })
          .catch(error => {
            responseOnGrid = "";
            errorRedirect(error.response)
          });
      }
    }
  };
}

export default Catalogs;
