import React, { Component } from "react";
import { Breadcrumb } from "react-bootstrap";
import { Link } from "react-router-dom";

export default class BreadCrumb extends Component {
  render() {
    const { crumbs } = this.props;

    const crumbsDisplay = crumbs.map((each, index) => (
      <>
        <Breadcrumb.Item
          href={each.pageUrl}
          active={crumbs.length === index + 1 ? true : false}
        >
          {crumbs.length === index + 1 ? (
            <>{each.name}</>
          ) : (
            <Link to={each.pageUrl}>{each.name}</Link>
          )}
        </Breadcrumb.Item>
      </>
    ));

    return <Breadcrumb>{crumbsDisplay}</Breadcrumb>;
  }
}
